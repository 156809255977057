import * as consts from 'modules/journeys-manager-introduction/constants';

import BaseActions from 'core/actions';
import {
  JOURNEYS_MANAGER_LIST_FILTER_SET,
  JOURNEYS_MANAGER_LIST_GET,
  JOURNEYS_MANAGER_LIST_PAGINATION_SET,
  JOURNEYS_MANAGER_LIST_SORT_SET,
} from 'modules/journeys-manager-introduction/constants';

class Actions extends BaseActions {
  init({ payload = {}, meta = {}, status = null } = {}) {
    return this.create(this.getType('init', status), status, payload, meta);
  }

  destroy({ payload = {}, meta = {}, status = null } = {}) {
    return this.create(this.getType('destroy', status), status, payload, meta);
  }

  /**
   * @description payload: {limit:number, offset: number}, meta, status
   */
  setPagination({ payload = {}, meta = {}, status = null } = {}) {
    return this.create(
      this.getTypeWithStatus(JOURNEYS_MANAGER_LIST_PAGINATION_SET, status),
      status,
      payload,
      meta,
    );
  }

  setFilter({ payload = {}, meta = {}, status = null } = {}) {
    return this.create(
      this.getTypeWithStatus(JOURNEYS_MANAGER_LIST_FILTER_SET, status),
      status,
      payload,
      meta,
    );
  }

  setSorting({ payload = {}, meta = {}, status = null } = {}) {
    return this.create(
      this.getTypeWithStatus(JOURNEYS_MANAGER_LIST_SORT_SET, status),
      status,
      payload,
      meta,
    );
  }

  doListRequest({ payload = {}, meta = {}, status = null } = {}) {
    return this.create(
      this.getTypeWithStatus(JOURNEYS_MANAGER_LIST_GET, status),
      status,
      payload,
      meta,
    );
  }

  // getType = (action, status = '', type = '') => {
  //   let newType =
  //     consts[
  //       `${consts.NAME.toUpperCase()}${
  //         type ? `_${type.toUpperCase()}` : ''
  //       }_${action.toUpperCase()}`
  //     ];

  //   if (status) {
  //     newType += `/${consts.STATUS}/${status.toLowerCase()}`;
  //   }

  //   return newType;
  // };

  getType = (action, status = '', type = '') => {
    const constName = `${consts.NAME}${type ? '_' : ''}${type}_${action}`.toUpperCase();

    let newType = consts[constName];

    if (status) {
      newType += `/${consts.STATUS}/${status.toLowerCase()}`;
    }

    return newType;
  };

  getTypeWithStatus = (type, status = '') => {
    if (status) {
      return `${type}/${consts.STATUS}/${status.toLowerCase()}`;
    }

    return type;
  };
}

const acts = new Actions();
Object.freeze(acts);

export default acts;
