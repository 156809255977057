const countries = [
  {
    pardotId: '955678',
    key: 'USA',
    localization: 'contactUs.countries.USA',
  },
  {
    pardotId: '955680',
    key: 'CAN',
    localization: 'contactUs.countries.CAN',
  },
  {
    pardotId: '955682',
    key: 'AFG',
    localization: 'contactUs.countries.AFG',
  },
  {
    pardotId: '955684',
    key: 'ALB',
    localization: 'contactUs.countries.ALB',
  },
  {
    pardotId: '955686',
    key: 'DZA',
    localization: 'contactUs.countries.DZA',
  },
  {
    pardotId: '955688',
    key: 'ASM',
    localization: 'contactUs.countries.ASM',
  },
  {
    pardotId: '955690',
    key: 'AND',
    localization: 'contactUs.countries.AND',
  },
  {
    pardotId: '955692',
    key: 'AGO',
    localization: 'contactUs.countries.AGO',
  },
  {
    pardotId: '955694',
    key: 'AIA',
    localization: 'contactUs.countries.AIA',
  },
  {
    pardotId: '955696',
    key: 'ATA',
    localization: 'contactUs.countries.ATA',
  },
  {
    pardotId: '955698',
    key: 'ATG',
    localization: 'contactUs.countries.ATG',
  },
  {
    pardotId: '955700',
    key: 'ARG',
    localization: 'contactUs.countries.ARG',
  },
  {
    pardotId: '955702',
    key: 'ARM',
    localization: 'contactUs.countries.ARM',
  },
  {
    pardotId: '955704',
    key: 'ABW',
    localization: 'contactUs.countries.ABW',
  },
  {
    pardotId: '955706',
    key: 'AUS',
    localization: 'contactUs.countries.AUS',
  },
  {
    pardotId: '955708',
    key: 'AUT',
    localization: 'contactUs.countries.AUT',
  },
  {
    pardotId: '955710',
    key: 'AZE',
    localization: 'contactUs.countries.AZE',
  },
  {
    pardotId: '955712',
    key: 'BHS',
    localization: 'contactUs.countries.BHS',
  },
  {
    pardotId: '955714',
    key: 'BHR',
    localization: 'contactUs.countries.BHR',
  },
  {
    pardotId: '955716',
    key: 'BGD',
    localization: 'contactUs.countries.BGD',
  },
  {
    pardotId: '955718',
    key: 'BRB',
    localization: 'contactUs.countries.BRB',
  },
  {
    pardotId: '955720',
    key: 'BLR',
    localization: 'contactUs.countries.BLR',
  },
  {
    pardotId: '955722',
    key: 'BEL',
    localization: 'contactUs.countries.BEL',
  },
  {
    pardotId: '955724',
    key: 'BLZ',
    localization: 'contactUs.countries.BLZ',
  },
  {
    pardotId: '955726',
    key: 'BEN',
    localization: 'contactUs.countries.BEN',
  },
  {
    pardotId: '955728',
    key: 'BMU',
    localization: 'contactUs.countries.BMU',
  },
  {
    pardotId: '955730',
    key: 'BTN',
    localization: 'contactUs.countries.BTN',
  },
  {
    pardotId: '955732',
    key: 'BOL',
    localization: 'contactUs.countries.BOL',
  },
  {
    pardotId: '955734',
    key: 'BIH',
    localization: 'contactUs.countries.BIH',
  },
  {
    pardotId: '955736',
    key: 'BWA',
    localization: 'contactUs.countries.BWA',
  },
  {
    pardotId: '955738',
    key: 'BRA',
    localization: 'contactUs.countries.BRA',
  },
  {
    pardotId: '955740',
    key: 'IOT',
    localization: 'contactUs.countries.IOT',
  },
  {
    pardotId: '955742',
    key: 'VGB',
    localization: 'contactUs.countries.VGB',
  },
  {
    pardotId: '955744',
    key: 'BRN',
    localization: 'contactUs.countries.BRN',
  },
  {
    pardotId: '955746',
    key: 'BGR',
    localization: 'contactUs.countries.BGR',
  },
  {
    pardotId: '955748',
    key: 'BFA',
    localization: 'contactUs.countries.BFA',
  },
  {
    pardotId: '955750',
    key: 'BDI',
    localization: 'contactUs.countries.BDI',
  },
  {
    pardotId: '955752',
    key: 'KHM',
    localization: 'contactUs.countries.KHM',
  },
  {
    pardotId: '955754',
    key: 'CMR',
    localization: 'contactUs.countries.CMR',
  },
  {
    pardotId: '955756',
    key: 'CPV',
    localization: 'contactUs.countries.CPV',
  },
  {
    pardotId: '955758',
    key: 'CYM',
    localization: 'contactUs.countries.CYM',
  },
  {
    pardotId: '955760',
    key: 'CAF',
    localization: 'contactUs.countries.CAF',
  },
  {
    pardotId: '955762',
    key: 'TCD',
    localization: 'contactUs.countries.TCD',
  },
  {
    pardotId: '955764',
    key: 'CHL',
    localization: 'contactUs.countries.CHL',
  },
  {
    pardotId: '955766',
    key: 'CHN',
    localization: 'contactUs.countries.CHN',
  },
  {
    pardotId: '955768',
    key: 'CXR',
    localization: 'contactUs.countries.CXR',
  },
  {
    pardotId: '955770',
    key: 'CCK',
    localization: 'contactUs.countries.CCK',
  },
  {
    pardotId: '955772',
    key: 'COL',
    localization: 'contactUs.countries.COL',
  },
  {
    pardotId: '955774',
    key: 'COM',
    localization: 'contactUs.countries.COM',
  },
  {
    pardotId: '955776',
    key: 'COG',
    localization: 'contactUs.countries.COG',
  },
  {
    pardotId: '955778',
    key: 'COK',
    localization: 'contactUs.countries.COK',
  },
  {
    pardotId: '955780',
    key: 'CRI',
    localization: 'contactUs.countries.CRI',
  },
  {
    pardotId: '955782',
    key: 'HRV',
    localization: 'contactUs.countries.HRV',
  },
  {
    pardotId: '955784',
    key: 'CUB',
    localization: 'contactUs.countries.CUB',
  },
  {
    pardotId: '955786',
    key: 'CUW',
    localization: 'contactUs.countries.CUW',
  },
  {
    pardotId: '955788',
    key: 'CYP',
    localization: 'contactUs.countries.CYP',
  },
  {
    pardotId: '955790',
    key: 'CZE',
    localization: 'contactUs.countries.CZE',
  },
  {
    pardotId: '955792',
    key: 'CIV',
    localization: 'contactUs.countries.CIV',
  },
  {
    pardotId: '955794',
    key: 'COD',
    localization: 'contactUs.countries.COD',
  },
  {
    pardotId: '955796',
    key: 'DNK',
    localization: 'contactUs.countries.DNK',
  },
  {
    pardotId: '955798',
    key: 'DJI',
    localization: 'contactUs.countries.DJI',
  },
  {
    pardotId: '955800',
    key: 'DMA',
    localization: 'contactUs.countries.DMA',
  },
  {
    pardotId: '955802',
    key: 'DOM',
    localization: 'contactUs.countries.DOM',
  },
  {
    pardotId: '955804',
    key: 'ECU',
    localization: 'contactUs.countries.ECU',
  },
  {
    pardotId: '955806',
    key: 'EGY',
    localization: 'contactUs.countries.EGY',
  },
  {
    pardotId: '955808',
    key: 'SLV',
    localization: 'contactUs.countries.SLV',
  },
  {
    pardotId: '955810',
    key: 'GNQ',
    localization: 'contactUs.countries.GNQ',
  },
  {
    pardotId: '955812',
    key: 'ERI',
    localization: 'contactUs.countries.ERI',
  },
  {
    pardotId: '955814',
    key: 'EST',
    localization: 'contactUs.countries.EST',
  },
  {
    pardotId: '955816',
    key: 'ETH',
    localization: 'contactUs.countries.ETH',
  },
  {
    pardotId: '955818',
    key: 'FLK',
    localization: 'contactUs.countries.FLK',
  },
  {
    pardotId: '955820',
    key: 'FRO',
    localization: 'contactUs.countries.FRO',
  },
  {
    pardotId: '955822',
    key: 'FJI',
    localization: 'contactUs.countries.FJI',
  },
  {
    pardotId: '955824',
    key: 'FIN',
    localization: 'contactUs.countries.FIN',
  },
  {
    pardotId: '955826',
    key: 'FRA',
    localization: 'contactUs.countries.FRA',
  },
  {
    pardotId: '955828',
    key: 'GUF',
    localization: 'contactUs.countries.GUF',
  },
  {
    pardotId: '955830',
    key: 'PYF',
    localization: 'contactUs.countries.PYF',
  },
  {
    pardotId: '955832',
    key: 'ATF',
    localization: 'contactUs.countries.ATF',
  },
  {
    pardotId: '955834',
    key: 'GAB',
    localization: 'contactUs.countries.GAB',
  },
  {
    pardotId: '955836',
    key: 'GMB',
    localization: 'contactUs.countries.GMB',
  },
  {
    pardotId: '955838',
    key: 'GEO',
    localization: 'contactUs.countries.GEO',
  },
  {
    pardotId: '955840',
    key: 'DEU',
    localization: 'contactUs.countries.DEU',
  },
  {
    pardotId: '955842',
    key: 'GHA',
    localization: 'contactUs.countries.GHA',
  },
  {
    pardotId: '955844',
    key: 'GIB',
    localization: 'contactUs.countries.GIB',
  },
  {
    pardotId: '955846',
    key: 'GRC',
    localization: 'contactUs.countries.GRC',
  },
  {
    pardotId: '955848',
    key: 'GRL',
    localization: 'contactUs.countries.GRL',
  },
  {
    pardotId: '955850',
    key: 'GRD',
    localization: 'contactUs.countries.GRD',
  },
  {
    pardotId: '955852',
    key: 'GLP',
    localization: 'contactUs.countries.GLP',
  },
  {
    pardotId: '955854',
    key: 'GUM',
    localization: 'contactUs.countries.GUM',
  },
  {
    pardotId: '955856',
    key: 'GTM',
    localization: 'contactUs.countries.GTM',
  },
  {
    pardotId: '955858',
    key: 'GGY',
    localization: 'contactUs.countries.GGY',
  },
  {
    pardotId: '955860',
    key: 'GIN',
    localization: 'contactUs.countries.GIN',
  },
  {
    pardotId: '955862',
    key: 'GNB',
    localization: 'contactUs.countries.GNB',
  },
  {
    pardotId: '955864',
    key: 'GUY',
    localization: 'contactUs.countries.GUY',
  },
  {
    pardotId: '955866',
    key: 'HTI',
    localization: 'contactUs.countries.HTI',
  },
  {
    pardotId: '955868',
    key: 'HND',
    localization: 'contactUs.countries.HND',
  },
  {
    pardotId: '955870',
    key: 'HKG',
    localization: 'contactUs.countries.HKG',
  },
  {
    pardotId: '955872',
    key: 'HUN',
    localization: 'contactUs.countries.HUN',
  },
  {
    pardotId: '955874',
    key: 'ISL',
    localization: 'contactUs.countries.ISL',
  },
  {
    pardotId: '955876',
    key: 'IND',
    localization: 'contactUs.countries.IND',
  },
  {
    pardotId: '955878',
    key: 'IDN',
    localization: 'contactUs.countries.IDN',
  },
  {
    pardotId: '955880',
    key: 'IRN',
    localization: 'contactUs.countries.IRN',
  },
  {
    pardotId: '955882',
    key: 'IRQ',
    localization: 'contactUs.countries.IRQ',
  },
  {
    pardotId: '955884',
    key: 'IRL',
    localization: 'contactUs.countries.IRL',
  },
  {
    pardotId: '955886',
    key: 'IMN',
    localization: 'contactUs.countries.IMN',
  },
  {
    pardotId: '955888',
    key: 'ISR',
    localization: 'contactUs.countries.ISR',
  },
  {
    pardotId: '955890',
    key: 'ITA',
    localization: 'contactUs.countries.ITA',
  },
  {
    pardotId: '955892',
    key: 'JAM',
    localization: 'contactUs.countries.JAM',
  },
  {
    pardotId: '955894',
    key: 'JPN',
    localization: 'contactUs.countries.JPN',
  },
  {
    pardotId: '955896',
    key: 'JEY',
    localization: 'contactUs.countries.JEY',
  },
  {
    pardotId: '955898',
    key: 'JOR',
    localization: 'contactUs.countries.JOR',
  },
  {
    pardotId: '955900',
    key: 'KAZ',
    localization: 'contactUs.countries.KAZ',
  },
  {
    pardotId: '955902',
    key: 'KEN',
    localization: 'contactUs.countries.KEN',
  },
  {
    pardotId: '955904',
    key: 'KIR',
    localization: 'contactUs.countries.KIR',
  },
  {
    pardotId: '955906',
    key: 'KWT',
    localization: 'contactUs.countries.KWT',
  },
  {
    pardotId: '955908',
    key: 'KGZ',
    localization: 'contactUs.countries.KGZ',
  },
  {
    pardotId: '955910',
    key: 'LAO',
    localization: 'contactUs.countries.LAO',
  },
  {
    pardotId: '955912',
    key: 'LVA',
    localization: 'contactUs.countries.LVA',
  },
  {
    pardotId: '955914',
    key: 'LBN',
    localization: 'contactUs.countries.LBN',
  },
  {
    pardotId: '955916',
    key: 'LSO',
    localization: 'contactUs.countries.LSO',
  },
  {
    pardotId: '955918',
    key: 'LBR',
    localization: 'contactUs.countries.LBR',
  },
  {
    pardotId: '955920',
    key: 'LBY',
    localization: 'contactUs.countries.LBY',
  },
  {
    pardotId: '955922',
    key: 'LIE',
    localization: 'contactUs.countries.LIE',
  },
  {
    pardotId: '955924',
    key: 'LTU',
    localization: 'contactUs.countries.LTU',
  },
  {
    pardotId: '955926',
    key: 'LUX',
    localization: 'contactUs.countries.LUX',
  },
  {
    pardotId: '955928',
    key: 'MAC',
    localization: 'contactUs.countries.MAC',
  },
  {
    pardotId: '955930',
    key: 'MKD',
    localization: 'contactUs.countries.MKD',
  },
  {
    pardotId: '955932',
    key: 'MDG',
    localization: 'contactUs.countries.MDG',
  },
  {
    pardotId: '955934',
    key: 'MWI',
    localization: 'contactUs.countries.MWI',
  },
  {
    pardotId: '955936',
    key: 'MYS',
    localization: 'contactUs.countries.MYS',
  },
  {
    pardotId: '955938',
    key: 'MDV',
    localization: 'contactUs.countries.MDV',
  },
  {
    pardotId: '955940',
    key: 'MLI',
    localization: 'contactUs.countries.MLI',
  },
  {
    pardotId: '955942',
    key: 'MLT',
    localization: 'contactUs.countries.MLT',
  },
  {
    pardotId: '955944',
    key: 'MHL',
    localization: 'contactUs.countries.MHL',
  },
  {
    pardotId: '955946',
    key: 'MTQ',
    localization: 'contactUs.countries.MTQ',
  },
  {
    pardotId: '955948',
    key: 'MRT',
    localization: 'contactUs.countries.MRT',
  },
  {
    pardotId: '955950',
    key: 'MUS',
    localization: 'contactUs.countries.MUS',
  },
  {
    pardotId: '955952',
    key: 'MYT',
    localization: 'contactUs.countries.MYT',
  },
  {
    pardotId: '955954',
    key: 'MEX',
    localization: 'contactUs.countries.MEX',
  },
  {
    pardotId: '955956',
    key: 'FSM',
    localization: 'contactUs.countries.FSM',
  },
  {
    pardotId: '955958',
    key: 'MDA',
    localization: 'contactUs.countries.MDA',
  },
  {
    pardotId: '955960',
    key: 'MCO',
    localization: 'contactUs.countries.MCO',
  },
  {
    pardotId: '955962',
    key: 'MNG',
    localization: 'contactUs.countries.MNG',
  },
  {
    pardotId: '955964',
    key: 'MNE',
    localization: 'contactUs.countries.MNE',
  },
  {
    pardotId: '955966',
    key: 'MSR',
    localization: 'contactUs.countries.MSR',
  },
  {
    pardotId: '955968',
    key: 'MAR',
    localization: 'contactUs.countries.MAR',
  },
  {
    pardotId: '955970',
    key: 'MOZ',
    localization: 'contactUs.countries.MOZ',
  },
  {
    pardotId: '955972',
    key: 'MMR',
    localization: 'contactUs.countries.MMR',
  },
  {
    pardotId: '955974',
    key: 'NAM',
    localization: 'contactUs.countries.NAM',
  },
  {
    pardotId: '955976',
    key: 'NRU',
    localization: 'contactUs.countries.NRU',
  },
  {
    pardotId: '955978',
    key: 'NPL',
    localization: 'contactUs.countries.NPL',
  },
  {
    pardotId: '955980',
    key: 'NLD',
    localization: 'contactUs.countries.NLD',
  },
  {
    pardotId: '955982',
    key: 'NCL',
    localization: 'contactUs.countries.NCL',
  },
  {
    pardotId: '955984',
    key: 'NZL',
    localization: 'contactUs.countries.NZL',
  },
  {
    pardotId: '955986',
    key: 'NIC',
    localization: 'contactUs.countries.NIC',
  },
  {
    pardotId: '955988',
    key: 'NER',
    localization: 'contactUs.countries.NER',
  },
  {
    pardotId: '955990',
    key: 'NGA',
    localization: 'contactUs.countries.NGA',
  },
  {
    pardotId: '955992',
    key: 'NIU',
    localization: 'contactUs.countries.NIU',
  },
  {
    pardotId: '955994',
    key: 'NFK',
    localization: 'contactUs.countries.NFK',
  },
  {
    pardotId: '955996',
    key: 'PRK',
    localization: 'contactUs.countries.PRK',
  },
  {
    pardotId: '955998',
    key: 'MNP',
    localization: 'contactUs.countries.MNP',
  },
  {
    pardotId: '956000',
    key: 'NOR',
    localization: 'contactUs.countries.NOR',
  },
  {
    pardotId: '956002',
    key: 'OMN',
    localization: 'contactUs.countries.OMN',
  },
  {
    pardotId: '956004',
    key: 'PAK',
    localization: 'contactUs.countries.PAK',
  },
  {
    pardotId: '956006',
    key: 'PLW',
    localization: 'contactUs.countries.PLW',
  },
  {
    pardotId: '956008',
    key: 'PSE',
    localization: 'contactUs.countries.PSE',
  },
  {
    pardotId: '956010',
    key: 'PAN',
    localization: 'contactUs.countries.PAN',
  },
  {
    pardotId: '956012',
    key: 'PNG',
    localization: 'contactUs.countries.PNG',
  },
  {
    pardotId: '956014',
    key: 'PRY',
    localization: 'contactUs.countries.PRY',
  },
  {
    pardotId: '956016',
    key: 'PER',
    localization: 'contactUs.countries.PER',
  },
  {
    pardotId: '956018',
    key: 'PHL',
    localization: 'contactUs.countries.PHL',
  },
  {
    pardotId: '956020',
    key: 'PCN',
    localization: 'contactUs.countries.PCN',
  },
  {
    pardotId: '956022',
    key: 'POL',
    localization: 'contactUs.countries.POL',
  },
  {
    pardotId: '956024',
    key: 'PRT',
    localization: 'contactUs.countries.PRT',
  },
  {
    pardotId: '956026',
    key: 'PRI',
    localization: 'contactUs.countries.PRI',
  },
  {
    pardotId: '956028',
    key: 'QAT',
    localization: 'contactUs.countries.QAT',
  },
  {
    pardotId: '956030',
    key: 'ROU',
    localization: 'contactUs.countries.ROU',
  },
  {
    pardotId: '956032',
    key: 'RUS',
    localization: 'contactUs.countries.RUS',
  },
  {
    pardotId: '956034',
    key: 'RWA',
    localization: 'contactUs.countries.RWA',
  },
  {
    pardotId: '956036',
    key: 'REU',
    localization: 'contactUs.countries.REU',
  },
  {
    pardotId: '956038',
    key: 'BLM',
    localization: 'contactUs.countries.BLM',
  },
  {
    pardotId: '956040',
    key: 'SHN',
    localization: 'contactUs.countries.SHN',
  },
  {
    pardotId: '956042',
    key: 'KNA',
    localization: 'contactUs.countries.KNA',
  },
  {
    pardotId: '956044',
    key: 'LCA',
    localization: 'contactUs.countries.LCA',
  },
  {
    pardotId: '956046',
    key: 'SPM',
    localization: 'contactUs.countries.SPM',
  },
  {
    pardotId: '956048',
    key: 'VCT',
    localization: 'contactUs.countries.VCT',
  },
  {
    pardotId: '956050',
    key: 'WSM',
    localization: 'contactUs.countries.WSM',
  },
  {
    pardotId: '956052',
    key: 'SMR',
    localization: 'contactUs.countries.SMR',
  },
  {
    pardotId: '956054',
    key: 'STP',
    localization: 'contactUs.countries.STP',
  },
  {
    pardotId: '956056',
    key: 'SAU',
    localization: 'contactUs.countries.SAU',
  },
  {
    pardotId: '956058',
    key: 'SEN',
    localization: 'contactUs.countries.SEN',
  },
  {
    pardotId: '956060',
    key: 'SRB',
    localization: 'contactUs.countries.SRB',
  },
  {
    pardotId: '956062',
    key: 'SYC',
    localization: 'contactUs.countries.SYC',
  },
  {
    pardotId: '956064',
    key: 'SLE',
    localization: 'contactUs.countries.SLE',
  },
  {
    pardotId: '956066',
    key: 'SGP',
    localization: 'contactUs.countries.SGP',
  },
  {
    pardotId: '956068',
    key: 'SVK',
    localization: 'contactUs.countries.SVK',
  },
  {
    pardotId: '956070',
    key: 'SVN',
    localization: 'contactUs.countries.SVN',
  },
  {
    pardotId: '956072',
    key: 'SLB',
    localization: 'contactUs.countries.SLB',
  },
  {
    pardotId: '956074',
    key: 'SOM',
    localization: 'contactUs.countries.SOM',
  },
  {
    pardotId: '956076',
    key: 'ZAF',
    localization: 'contactUs.countries.ZAF',
  },
  {
    pardotId: '956078',
    key: 'KOR',
    localization: 'contactUs.countries.KOR',
  },
  {
    pardotId: '956080',
    key: 'SSD',
    localization: 'contactUs.countries.SSD',
  },
  {
    pardotId: '956082',
    key: 'ESP',
    localization: 'contactUs.countries.ESP',
  },
  {
    pardotId: '956084',
    key: 'LKA',
    localization: 'contactUs.countries.LKA',
  },
  {
    pardotId: '956086',
    key: 'SDN',
    localization: 'contactUs.countries.SDN',
  },
  {
    pardotId: '956088',
    key: 'SUR',
    localization: 'contactUs.countries.SUR',
  },
  {
    pardotId: '956090',
    key: 'SJM',
    localization: 'contactUs.countries.SJM',
  },
  {
    pardotId: '956092',
    key: 'SWZ',
    localization: 'contactUs.countries.SWZ',
  },
  {
    pardotId: '956094',
    key: 'SWE',
    localization: 'contactUs.countries.SWE',
  },
  {
    pardotId: '956096',
    key: 'CHE',
    localization: 'contactUs.countries.CHE',
  },
  {
    pardotId: '956098',
    key: 'SYR',
    localization: 'contactUs.countries.SYR',
  },
  {
    pardotId: '956100',
    key: 'TWN',
    localization: 'contactUs.countries.TWN',
  },
  {
    pardotId: '956102',
    key: 'TJK',
    localization: 'contactUs.countries.TJK',
  },
  {
    pardotId: '956104',
    key: 'TZA',
    localization: 'contactUs.countries.TZA',
  },
  {
    pardotId: '956106',
    key: 'THA',
    localization: 'contactUs.countries.THA',
  },
  {
    pardotId: '956108',
    key: 'TLS',
    localization: 'contactUs.countries.TLS',
  },
  {
    pardotId: '956110',
    key: 'TGO',
    localization: 'contactUs.countries.TGO',
  },
  {
    pardotId: '956112',
    key: 'TKL',
    localization: 'contactUs.countries.TKL',
  },
  {
    pardotId: '956114',
    key: 'TON',
    localization: 'contactUs.countries.TON',
  },
  {
    pardotId: '956116',
    key: 'TTO',
    localization: 'contactUs.countries.TTO',
  },
  {
    pardotId: '956118',
    key: 'TUN',
    localization: 'contactUs.countries.TUN',
  },
  {
    pardotId: '956120',
    key: 'TUR',
    localization: 'contactUs.countries.TUR',
  },
  {
    pardotId: '956122',
    key: 'TKM',
    localization: 'contactUs.countries.TKM',
  },
  {
    pardotId: '956124',
    key: 'TCA',
    localization: 'contactUs.countries.TCA',
  },
  {
    pardotId: '956126',
    key: 'TUV',
    localization: 'contactUs.countries.TUV',
  },
  {
    pardotId: '956128',
    key: 'VIR',
    localization: 'contactUs.countries.VIR',
  },
  {
    pardotId: '956130',
    key: 'UGA',
    localization: 'contactUs.countries.UGA',
  },
  {
    pardotId: '956132',
    key: 'UKR',
    localization: 'contactUs.countries.UKR',
  },
  {
    pardotId: '956134',
    key: 'ARE',
    localization: 'contactUs.countries.ARE',
  },
  {
    pardotId: '956136',
    key: 'GBR',
    localization: 'contactUs.countries.GBR',
  },
  {
    pardotId: '956138',
    key: 'UMI',
    localization: 'contactUs.countries.UMI',
  },
  {
    pardotId: '956140',
    key: 'URY',
    localization: 'contactUs.countries.URY',
  },
  {
    pardotId: '956142',
    key: 'UZB',
    localization: 'contactUs.countries.UZB',
  },
  {
    pardotId: '956144',
    key: 'VUT',
    localization: 'contactUs.countries.VUT',
  },
  {
    pardotId: '956146',
    key: 'VAT',
    localization: 'contactUs.countries.VAT',
  },
  {
    pardotId: '956148',
    key: 'VEN',
    localization: 'contactUs.countries.VEN',
  },
  {
    pardotId: '956150',
    key: 'VNM',
    localization: 'contactUs.countries.VNM',
  },
  {
    pardotId: '956152',
    key: 'WLF',
    localization: 'contactUs.countries.WLF',
  },
  {
    pardotId: '956154',
    key: 'ESH',
    localization: 'contactUs.countries.ESH',
  },
  {
    pardotId: '956156',
    key: 'YEM',
    localization: 'contactUs.countries.YEM',
  },
  {
    pardotId: '956158',
    key: 'ZMB',
    localization: 'contactUs.countries.ZMB',
  },
  {
    pardotId: '956160',
    key: 'ZWE',
    localization: 'contactUs.countries.ZWE',
  },
];

export default countries;
