/**
 * @example
 * this.form = FormControl({
 *  someKey: new FormElement('startValue', [...validators]),
 *  someKey2: new FormElement(null, [...validators]),
 * })
 *
 * this.form.onChange(() => {
 *    this.setState({
 *      formValue: this.form.getValues(),
 *    });
 *  });
 *
 * this.form.setSubmitFunction(() => {
 *    this.submit();
 *  });
 *
 * // in render method
 * <form className="form-body" onSubmit={(e) => this.form.submitForm(e)}>
 *  <textarea
 *    onChange={(e) => this.form.getFormElement('someKey').onChangeEvent(e)}
 *    onBlur={(e) => this.form.getFormElement('someKey').onBlurEvent(e)}
 *    value={formValue['someKey']}
 *  />
 * <textarea
 *    onChange={(e) => this.form.getFormElement('someKey2').onChangeEvent(e)}
 *    onBlur={(e) => this.form.getFormElement('someKey2').onBlurEvent(e)}
 *    value={formValue['someKey2']}
 *  />
 * </form>
 */
export default class FormControl {
  constructor(formElements = {}) {
    this.onChangeCallbackFunctions = [];
    this.formElements = formElements;
    this.submitFunction = () => {};

    this.initListener();
  }

  /**
   * @description for detect form update
   * @example 
   * this.form = new FormControl({});
   * this.form.onChange(() => {
      this.setState({
        formValue: this.form.getValues(),
      });
    });
   */
  onChange(fn) {
    this.onChangeCallbackFunctions.push(fn);
  }

  /**
   * @description for change all fields type to touched
   * this will be add possibility show error
   */
  setTouched() {
    Object.values(this.formElements).forEach((formElement) => formElement.setTouched());

    this.formChangeCallback();
  }

  initListener() {
    Object.values(this.formElements).forEach((formElement) => {
      formElement.onChange(() => this.formChangeCallback());
      formElement.onBlur(() => this.formChangeCallback());
    });
  }

  formChangeCallback() {
    this.onChangeCallbackFunctions.forEach((fn) => fn());
  }

  /**
   * @description return FormElement object by key
   * @return FormElement
   * @example
   * this.form = new FormControl({key1: new FormElement(null, [required])});
   *
   * this.form.getFormElement('key1');
   */
  getFormElement(key) {
    return this.formElements[key];
  }

  isFormValid() {
    return !Object.values(this.formElements).some((formElement) => !formElement.valid);
  }

  setValues(data) {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in this.formElements) {
      if (Object.hasOwnProperty.call(this.formElements, key)) {
        this.formElements[key].setValue(data[key] || undefined, false);
      }
    }

    this.formChangeCallback();
  }

  /**
   * @description for get object with all fields {[key]: relatedValue, ...}
   */
  getValues() {
    return Object.keys(this.formElements).reduce((values, key) => {
      // eslint-disable-next-line no-param-reassign
      values[key] = this.formElements[key].value;

      return values;
    }, {});
  }

  /**
   * @description for get object with all fields {[key]: relatedError, ...}
   */
  getErrors() {
    return Object.keys(this.formElements).reduce((values, key) => {
      // eslint-disable-next-line no-param-reassign
      values[key] = this.formElements[key].error;

      return values;
    }, {});
  }

  /**
   * @description attach component function to this class for submit functionality
   */
  setSubmitFunction(fn) {
    this.submitFunction = fn;
  }

  /**
   * @description for connection to form and add possibility run this functionality
   * separated
   * @example
   * <form className="form-body" onSubmit={(e) => this.form.submitForm(e)}>
   */
  submitForm(event) {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    this.setTouched();

    this.submitFunction();
  }
}
