/* eslint-disable import/no-mutable-exports */
// @ts-nocheck
import LOMIXConfig1200 from 'app/configs/LOMIXConfig1200';
import LOMIXConfig1024 from 'app/configs/LOMIXConfig1024';
import LOMIXConfigDefault from 'app/configs/LOMIXConfigDefault';
import LOMIXConfig3840 from 'app/configs/LOMIXConfig3840';
import { getWindowDimensions } from 'app/helpers';

const getConfig = () => {
  const { width } = getWindowDimensions();
  let screenConfig = LOMIXConfigDefault;

  if (width < 1200) {
    screenConfig = LOMIXConfig1024;
  }
  if (width >= 1200 && width < 1920) {
    screenConfig = LOMIXConfig1200;
  }
  if (width >= 2560) {
    screenConfig = LOMIXConfig3840;
  }
  return screenConfig;
};

export default getConfig;
