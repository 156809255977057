/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import HashtagObj from 'app/configs/hashtags';

import SignIcon from './assets/icons/sign-icon.svg';

import './assets/scss/index.scss';

const Hashtags = ({ tags, config, id }) => {
  const { t } = useTranslation();
  return (
    <div className={`${config.className}`}>
      {tags.length > 0 && (
        <>
          <SignIcon />
          <ul className={`${config.className}__list reset`}>
            {tags.map((tag, index) => (
              <>
                <li key={`${id}_${tag}`}>
                  {t(HashtagObj[tag].name)}
                  {index + 1 < tags.length && ', '}
                </li>
              </>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

const config = {
  className: 'c-hashtags',
};

Hashtags.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string),
  config: PropTypes.shape({
    className: PropTypes.string,
  }),
  id: PropTypes.string,
};

Hashtags.defaultProps = {
  tags: [],
  config,
  id: '',
};

export default Hashtags;
