import description from './description';
import OperatorAssist1 from './elements/OperatorAssist1';
import OperatorAssist2 from './elements/OperatorAssist2';
import OperatorAssist3 from './elements/OperatorAssist3';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [60.3, 67], width: 6.875, rotation: [55, 0] }],
  dancingAnts: [['hs1', [0, 83], { direction: 'reverse' }]],
  tinyBois: [
    { id: 'tb1', position: [60.3, 67], offset: [5.7, 2] },
    { id: 'tb2', position: [60.3, 67], offset: [5.7, 4.75] },
  ],
  elements: [
    new OperatorAssist1([59.2, 19.4], { size: 'wide' }),
    new OperatorAssist2([74.9, 42.31]),
    new OperatorAssist3([74.9, 60.2]),
  ],
};

export default config;
