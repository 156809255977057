const workflows = [
  {
    key: 'autonomousMining',
    localization: 'contactUs.workflows.autonomousMining',
    pardotId: '1008789',
  },
  {
    key: 'evaluation-planning-design',
    localization: 'contactUs.workflows.EvaluationPlanningDesign',
    pardotId: '1305216',
  },
  {
    key: 'drillAndBlast',
    localization: 'contactUs.workflows.drillAndBlast',
    pardotId: '1008793',
  },
  {
    key: 'materialMovement',
    localization: 'contactUs.workflows.materialMovement',
    pardotId: '1008795',
  },
  {
    key: 'safety',
    localization: 'contactUs.workflows.safety',
    pardotId: '1305219',
  },
  {
    key: 'surveyAndMonitoring',
    localization: 'contactUs.workflows.surveyAndMonitoring',
    pardotId: '1008797',
  },
  {
    key: 'undergroundMining',
    localization: 'contactUs.workflows.undergroundMining',
    pardotId: '1175569',
  },
];

export default workflows;
