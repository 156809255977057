import { createSelector } from 'reselect';

import { NAME } from 'modules/journeys-manager-introduction/constants';

// info: https://redux-toolkit.js.org/api/createSelector
const getInitialState = (state) => {
  return state[NAME];
};

export const isReady = createSelector(getInitialState, (state) => state.ready);
export const isListLoading = createSelector(getInitialState, (state) => state.listLoading);
export const getList = createSelector(getInitialState, (state) => state.list);
export const getSort = createSelector(getInitialState, (state) => state.sort);
export const getFilter = createSelector(getInitialState, (state) => state.filter);
export const getPagination = createSelector(getInitialState, (state) => state.pagination);

export default {
  isReady,
  isListLoading,
  getList,
  getSort,
  getFilter,
  getPagination,
};
