// @ts-nocheck
import React from 'react';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line react/prop-types
const ButtonManageMyJourneyApp = ({ onClick }) => {
  const { t } = useTranslation();
  const handleClick = () => {
    onClick({ action: 'openManageMyJourney' });
  };

  return (
    <div className="c-manage-my-journey-app">
      {/* eslint-disable-next-line react/button-has-type */}
      <button className="c-button c-button--size-default" onClick={handleClick}>
        <div className="c-button__text">{t('common.PERSONALISED JOURNEYS')}</div>
      </button>
    </div>
  );
};

export default ButtonManageMyJourneyApp;
