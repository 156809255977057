import description from './description';
import DrillBlastBlastMonitoring1 from './elements/drillBlastBlastMonitoring_1';
import DrillBlastBlastMonitoring2 from './elements/drillBlastBlastMonitoring_2';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [68, 71.8], width: 6.875, rotation: [62, 0] }],
  dancingAnts: [['hs1', [33.99, 100], { direction: 'reverse' }]],
  tinyBois: [{ id: 'tb1', position: [68, 71.8], offset: [6.5, 2.7] }],
  elements: [
    new DrillBlastBlastMonitoring1([67.1, 21]),
    new DrillBlastBlastMonitoring2([75, 52.7]),
  ],
};

export default config;
