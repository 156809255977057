/* eslint-disable class-methods-use-this */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import config from 'modules/workflows-overview/configs';

import Header from 'modules/workflows-overview/components/Header';

import MineMap from 'modules/workflows-overview/components/MineMap';

class WorkflowsOverview extends PureComponent {
  onEvent = (data) => {
    const { onEvent } = this.props;

    onEvent(data);
  };

  getHeader() {
    const { openedDeviceRotate } = this.props;

    return <Header openedDeviceRotate={openedDeviceRotate} pathName={config.current.title} />;
  }

  getMineMap() {
    const { visited } = this.props;
    return <MineMap onEvent={this.onEvent} visited={visited} />;
  }

  render() {
    return (
      <>
        {this.getHeader()}
        {this.getMineMap()}
      </>
    );
  }
}

WorkflowsOverview.propTypes = {
  openedDeviceRotate: PropTypes.bool,
  onEvent: PropTypes.func,
  visited: PropTypes.number,
};

WorkflowsOverview.defaultProps = {
  openedDeviceRotate: false,
  onEvent: () => {},
  visited: 0,
};

export default connect(null, null)(WorkflowsOverview);
