import React from 'react';
import PropTypes from 'prop-types';
import HASHTAGS from 'app/configs/hashtags';
import ButtonClose from 'app/components/modals/ButtonClose';
import Checkbox from '../../Checkbox';

import '../assets/scss/index.scss';

const HashtagWithDescriptionList = ({ values, handleChange, toggleWithDescriptions }) => {
  const onChange = (fieldName) => (isChecked) => {
    handleChange(fieldName, isChecked);
  };

  const closeHashtagWithDescription = () => {
    toggleWithDescriptions(false);
  };

  return (
    <>
      <div className="form-body form-scroll">
        <div className="hashtags-close">
          <ButtonClose onClick={closeHashtagWithDescription} />
        </div>
        {Object.keys(values).map((interest) => (
          <div className="field" key={`modal-${interest}`}>
            <Checkbox
              name={`modal-${interest}`}
              item={HASHTAGS[interest]}
              isChecked={values[interest]}
              handleChange={onChange(interest)}
              withDescription
            />
          </div>
        ))}
      </div>
    </>
  );
};

HashtagWithDescriptionList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  values: PropTypes.object,
  handleChange: PropTypes.func,
  toggleWithDescriptions: PropTypes.func,
};

HashtagWithDescriptionList.defaultProps = {
  values: null,
  handleChange: () => {},
  toggleWithDescriptions: () => {},
};

export default HashtagWithDescriptionList;
