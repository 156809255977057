// @ts-nocheck
/* eslint-disable react/button-has-type,react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ALLOW_EVENTS_CLASS } from 'app/constants/app';

import Error from './assets/icons/fail.svg';

const ErrorInfo = ({ handleClose }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="icon-wrap flex-center">
        <Error className="icon-wrap__error" />
      </div>
      <div className="text-center">{t('journeys-manager.Something went wrong')}</div>
      <div className="padding-top text-center">{t('journeys-manager.Continue to explore')}:</div>
      <div className="text-support text-center blue-text">
        <a
          href="mailto:info@hexagon.com"
          target="_blank"
          rel="noreferrer"
          className={ALLOW_EVENTS_CLASS}
          style={{
            textTransform: 'none',
          }}
        >
          info@hexagon.com
        </a>
      </div>
      <div
        className={`c-buttons-list flex-center padding-top ${ALLOW_EVENTS_CLASS}`}
        style={{ justifyContent: 'center' }}
      >
        <button className="c-button c-button_expand" onClick={handleClose}>
          {t('common.OK')}
        </button>
      </div>
    </>
  );
};

export default ErrorInfo;
