// @ts-nocheck
/* eslint-disable no-unused-vars */
/* eslint-disable react/button-has-type,react/prop-types */
import React from 'react';

import { useTranslation } from 'react-i18next';
import Success from './assets/icons/success.svg';

const DeleteSuccess = ({ handleClose }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex-center icon-wrap">
        <Success />
      </div>
      <div className="text-center margin-top">
        {t('download.Offline version for Life-of-Mine Experience deleted')}
      </div>
      <div className="c-buttons-list flex-center margin-top" style={{ justifyContent: 'center' }}>
        <button className="c-button c-button_expand" onClick={handleClose}>
          {t('common.OK')}
        </button>
      </div>
    </>
  );
};

export default DeleteSuccess;
