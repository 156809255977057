/* eslint-disable class-methods-use-this */
class BaseActions {
  init({ payload = {}, meta = {}, status = null } = {}) {
    return this.create(this.getType('init', status), status, payload, meta);
  }

  initComponent({ payload = {}, meta = {}, status = null } = {}) {
    return this.create(this.getType('init', status, 'component'), status, payload, meta);
  }

  updateComponent({ payload = {}, meta = {}, status = null } = {}) {
    return this.create(this.getType('update', status, 'component'), status, payload, meta);
  }

  reset({ payload = {}, meta = {}, status = null } = {}) {
    return this.create(this.getType('reset', status), status, payload, meta);
  }

  create(type, status, payload, meta) {
    return {
      type,
      status,
      payload,
      meta,
    };
  }

  insert(type, status, payload, meta) {
    return this.create(type, status, payload, meta);
  }

  update(type, status, payload, meta) {
    return this.create(type, status, payload, meta);
  }

  getType(action, status = '', type = '') {
    let newType = `${type ? `_${type.toUpperCase()}` : ''}_${action.toUpperCase()}`;

    if (status) {
      newType += `/status/${status.toLowerCase()}`;
    }

    return newType;
  }
}

export default BaseActions;
