import Box from 'modules/common/components/Box';

export default class DrillBlastDrilling2 {
  constructor(position) {
    this.id = 'drillBlastDrilling_2';
    this.component = Box;
    this.type = 'content';
    this.title = 'drill-blast.Drilling.elements.Machine Guidance';
    this.content = 'drill-blast.Drilling.elements.Machine Guidance Content';
    this.props = {
      target: 'tb1',
      position,
      cornerFrom: ['top', 'left'],
      hashtags: ['autonomy', 'productivity'],
    };
  }
}
