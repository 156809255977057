const description = {
  id: '1 of 5 reality-capture',
  currentNumber: 1,
  totalNumber: 5,
  title: 'survey-monitoring.Reality Capture.title',
  slug: 'reality-capture',
  pdfPath: 'RealityCapture',
  leadLayoutKey: 'reality-capture',
  apiSlug: 'reality-capture',
  productDetailsLink: 'https://leica-geosystems.com/en-us/industries/mining',
  description: 'survey-monitoring.Reality Capture.description',
  video: {
    name: 'wf_Reality_Capture.mp4',
    stop: 8.5,
    backgroundImage: 'Reality_Capture.jpg',
  },
  drawPoints: {
    hotspots: 0.25,
    dancingAnts: 8,
    secondaries: 8.5,
  },
};

export default description;
