const description = {
  id: '3 of 7',
  currentNumber: 3,
  totalNumber: 7,
  title: 'drill-blast.Safety.title',
  slug: 'safety',
  pdfPath: '03Safety',
  leadLayoutKey: 'drill-blast-safety',
  apiSlug: 'safety',
  productDetailsLink: 'https://hexagon.com/solutions/mine-blast-safety',
  description: 'drill-blast.Safety.description',
  video: {
    name: 'path 3_location_3.mp4',
    stop: 15,
    backgroundImage: 'Path_3_location_3_frame.jpg',
  },
  drawPoints: {
    hotspots: 2,
    dancingAnts: 6,
    secondaries: 6.5,
  },
};

export default description;
