/* eslint-disable no-case-declarations */

import {
  CONTACT_US_CLEAR_STORE,
  CONTACT_US_SEND_FORM_DATA,
} from 'app/components/modals/ContactUs/constants';
import { ERROR, STATUS, SUCCESS } from 'core/constants/statuses';

const initialState = {
  formSending: false,
  formSended: false,
};

const app = (state = initialState, action) => {
  switch (action.type) {
    case `${CONTACT_US_SEND_FORM_DATA}`:
      return {
        ...state,
        formSending: true,
        formSended: false,
      };
    case `${CONTACT_US_SEND_FORM_DATA}/${STATUS}/${SUCCESS}`:
      return {
        ...state,
        formSending: false,
        formSended: true,
        status: SUCCESS,
      };
    case `${CONTACT_US_SEND_FORM_DATA}/${STATUS}/${ERROR}`:
      return {
        ...state,
        formSending: false,
        formSended: false,
        status: ERROR,
      };
    case `${CONTACT_US_CLEAR_STORE}/${STATUS}/${SUCCESS}`:
      return {
        ...state,
        formSending: false,
        formSended: false,
        status: SUCCESS,
      };
    default: {
      return state;
    }
  }
};

export default app;
