import Box from 'modules/common/components/Box';

export default class PlanningInitial5 {
  constructor(position) {
    this.id = 'planningInitial_5';
    this.component = Box;
    this.type = 'content';
    this.title = 'planning.Capture Visualise Design.elements.Visualise and Design';
    this.content = 'planning.Capture Visualise Design.elements.Visualise and Design Content';
    this.props = {
      target: 'tb4',
      position,
      hashtags: ['integration'],
    };
  }
}
