/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/button-has-type */
import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import './assets/styles/ButtonContactUs.scss';
import { withTranslation } from 'react-i18next';

class ButtonContactUs extends PureComponent {
  componentDidUpdate(prevProps) {
    const { language } = this.props;
    if (language !== prevProps.language) {
      this.forceUpdate();
    }
  }

  handleClick = () => {
    const { onClick } = this.props;
    onClick({ action: 'openContactUs' });
  };

  render() {
    const { t } = this.props;

    return (
      <div className="c-contact-us">
        <button className="c-button c-button--size-default" onClick={this.handleClick}>
          <div className="c-button__text"> {t('Contact us')} </div>
        </button>
      </div>
    );
  }
}

ButtonContactUs.propTypes = {
  onClick: PropTypes.func,
  t: PropTypes.func,
  language: PropTypes.any,
};

ButtonContactUs.defaultProps = {
  onClick: () => {},
  t: () => {},
  language: null,
};

export default connect(null, null, null, { forwardRef: true })(withTranslation()(ButtonContactUs));
