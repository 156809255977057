import Box from 'modules/common/components/Box';

export default class SchedulingOptimising2 {
  constructor(position) {
    this.id = 'SchedulingOptimising2';
    this.component = Box;
    this.type = 'content';
    this.title = 'load-haul.Scheduling Optimising.elements.Activity Scheduler';
    this.content = 'load-haul.Scheduling Optimising.elements.Activity Scheduler Content';
    this.props = {
      target: 'tb1',
      position,
      hashtags: ['cost', 'productivity'],
    };
  }
}
