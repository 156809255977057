/* eslint-disable no-unused-vars */
import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import actions from 'app/actions';
import * as selectors from 'app/selectors';

import { ALLOW_EVENTS_CLASS } from 'app/constants/app';

import ButtonAccept from './ButtonAccept';
import ButtonClose from './ButtonClose';

import './assets/styles/index.scss';

class PrivacyPolicy extends PureComponent {
  render() {
    const { config, opened, showInfo3D, reload3D, t } = this.props;

    return (
      <div
        className={`${config.className} ${
          opened ? config.openClass : 'closed'
        } ${ALLOW_EVENTS_CLASS}`}
      >
        <div className={`${config.className}__container`}>
          <div className={`${config.className}__container-text`}>
            <div>{t('Due to technical limitations we couldn’t load 3D experience for your')}</div>
            <div className={`${config.className}__container-text_margin`}>
              {t('for your device')}.
            </div>
          </div>
          <div
            className={`${config.className}__container-accept ${config.className}__container-accept_margin`}
          >
            <ButtonAccept
              onClick={() => {
                showInfo3D({
                  show: false,
                  error3D: false,
                });

                reload3D();
              }}
            />
          </div>
          <div className="c-info__close">
            <ButtonClose
              onClick={() => {
                showInfo3D({
                  show: false,
                });
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

PrivacyPolicy.propTypes = {
  config: PropTypes.shape({
    className: PropTypes.string,
    openClass: PropTypes.string,
  }),
  opened: PropTypes.bool,
  showInfo3D: PropTypes.func,
  reload3D: PropTypes.func,
  t: PropTypes.func,
};

PrivacyPolicy.defaultProps = {
  config: {
    className: 'c-info',
    openClass: 'is-open',
  },
  opened: false,
  showInfo3D: () => {},
  reload3D: () => {},
  t: () => {},
};

const mapStateToProps = (state) => {
  return {
    opened: selectors.getVisibleInfo3D(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  showInfo3D: (payload) => dispatch(actions.showInfo3D({ payload })),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy));
