/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';

import Next from './assets/icons/next.svg';
import Prev from './assets/icons/prev.svg';

export const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div className={`nav-icon ${className}`} style={{ ...style }} onClick={onClick}>
      <Next />
    </div>
  );
};

export const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div className={`nav-icon ${className}`} style={{ ...style }} onClick={onClick}>
      <Prev />
    </div>
  );
};

NextArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.any,
  onClick: PropTypes.func,
};

NextArrow.defaultProps = {
  className: '',
  style: {},
  onClick: () => {},
};

PrevArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.any,
  onClick: PropTypes.func,
};

PrevArrow.defaultProps = {
  className: '',
  style: {},
  onClick: () => {},
};
