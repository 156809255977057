const description = {
  id: '1 of 3',
  currentNumber: 1,
  totalNumber: 3,
  title: 'underground.Safety.title',
  slug: 'underground-safety',
  pdfPath: 'UndergroundSafety',
  leadLayoutKey: 'underground-safety',
  apiSlug: 'underground-safety',
  productDetailsLink: 'https://hexagon.com/products/hexagon-ug-collision-avoidance-system',
  description: 'underground.Safety.description',
  video: {
    name: 'wf_Underground_Safety.mp4',
    stop: 29.25,
    backgroundImage: 'Underground_Safety.jpg',
  },
  drawPoints: {
    hotspots: 29.25,
    dancingAnts: 29.25,
    secondaries: 29.25,
  },
};

export default description;
