/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/prop-types */
/* eslint-disable react/button-has-type */
/* eslint-disable dot-notation */
/* eslint-disable react/destructuring-assignment,no-useless-escape,jsx-a11y/label-has-associated-control */
import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import CopyToClipboard from 'react-copy-to-clipboard';

import DublicateIcon from './assets/icons/dublicate.svg';

import './assets/scss/index.scss';

class JourneyCopyToClipboard extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      copyDone: false,
    };
  }

  copyDone() {
    this.setState({
      copyDone: true,
    });
  }

  render() {
    const { journeyId, t, i18n } = this.props;
    const { copyDone } = this.state;

    const link = `${window.location.origin}/${
      i18n.language === 'zh' ? 'zh/' : ''
    }lead/journeys/${journeyId}`;

    return (
      <div className="journey-copy-to-clipboard">
        <div className="journey-copy-to-clipboard_info">
          <DublicateIcon />
          <span>{link}</span>
        </div>
        <div className="journey-copy-to-clipboard_action">
          <CopyToClipboard text={link} onCopy={() => this.copyDone()}>
            <button
              disabled={copyDone}
              type="button"
              className="c-button c-button--size-sm journey-copy-to-clipboard_copy-action"
            >
              <div className="c-button__text">
                {copyDone ? t('common.Copied') : t('common.Copy')}
              </div>
            </button>
          </CopyToClipboard>
        </div>
      </div>
    );
  }
}

JourneyCopyToClipboard.propTypes = {
  journeyId: PropTypes.number,
};

JourneyCopyToClipboard.defaultProps = {
  journeyId: null,
};

export default withTranslation()(connect(null, null, null)(JourneyCopyToClipboard));
