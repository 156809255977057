import description from './description';
import DrillBlastBlastInsights1 from './elements/drillBlastBlastInsights_1';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [46.2, 62.67], width: 7.6, rotation: [62, 0] }],
  dancingAnts: [
    ['hs1', [50.57, 0]],
    ['hs1', [55, 0], { direction: 'reverse' }],
    ['hs1', [100, 4.78]],
    ['hs1', [100, 14.35], { direction: 'reverse' }],
  ],
  tinyBois: [],
  elements: [new DrillBlastBlastInsights1([19.79, 19.33])],
};

export default config;
