/* eslint-disable class-methods-use-this */
import HttpService from 'core/services/http/Http';

class Api {
  shareJourney = async (data = {}) => {
    const { id = null, emails = [] } = data;

    if (!id || !Array.isArray(emails) || emails.length === 0) return {};

    const response = await HttpService.post(`api/v1/journeys/${id}/share`, {}, emails).catch(() =>
      this.shareJourneyMock(id, emails),
    );

    return response;
  };

  shareJourneyMock(id = null, emails) {
    return {
      id,
      emails,
    };
  }
}

export default Object.freeze(new Api());
