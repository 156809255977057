const description = {
  id: '4 of 5',
  currentNumber: 4,
  totalNumber: 5,
  title: 'survey-monitoring.Geotech.title',
  slug: 'geotech',
  pdfPath: 'Geotech',
  leadLayoutKey: 'geotech',
  apiSlug: 'geotech',
  productDetailsLink: 'https://hexagon.com/products/hxgn-mineplan-resource-geo',
  description: 'survey-monitoring.Geotech.description',
  video: {
    name: 'path 5_location_4.mp4',
    stop: 8,
    backgroundImage: 'Path_5_location_4_frame.jpg',
  },
  drawPoints: {
    hotspots: 0.25,
    dancingAnts: 4,
    secondaries: 4.5,
  },
};

export default description;
