import Box from 'modules/common/components/Box';

export default class AssetHealth4 {
  constructor(position) {
    this.id = 'AssetHealth4';
    this.component = Box;
    this.type = 'content';
    this.title = 'load-haul.Asset Health.elements.Enterprise Asset Management';
    this.content = 'load-haul.Asset Health.elements.Enterprise Asset Management Content';
    this.props = {
      cornerFrom: ['top', 'left'],
      position,
    };
  }
}
