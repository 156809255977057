const description = {
  id: '2 of 5 planning',
  currentNumber: 2,
  totalNumber: 5,
  title: 'survey-monitoring.Planning.title',
  slug: 'planning',
  pdfPath: 'Planning',
  leadLayoutKey: 'planning',
  apiSlug: 'planning',
  productDetailsLink: 'https://hexagon.com/products/hexagon-mineplan-survey',
  description: 'survey-monitoring.Planning.description',
  video: {
    name: 'wf_Planning.mp4',
    stop: 6.5,
    backgroundImage: 'Planning.jpg',
  },
  drawPoints: {
    hotspots: 0.25,
    dancingAnts: 3.5,
    secondaries: 4,
  },
};

export default description;
