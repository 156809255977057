const description = {
  id: '2 of 5 drilling',
  currentNumber: 2,
  totalNumber: 5,
  title: 'evaluationPlanningDesign.Drilling.title',
  slug: 'drilling',
  pdfPath: 'ExplorationDrilling',
  leadLayoutKey: 'exploration-drilling',
  apiSlug: 'exploration-drilling',
  productDetailsLink: 'https://hexagon.com/products/hexagon-mineplan-exploration-geo',
  description: 'evaluationPlanningDesign.Drilling.description',
  video: {
    name: 'wf_Exploration_Drilling.mp4',
    stop: 1.9,
    backgroundImage: 'Exploration_Drilling.jpg',
  },
  drawPoints: {
    hotspots: 0.5,
    dancingAnts: 4,
    secondaries: 4.5,
  },
};

export default description;
