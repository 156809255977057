import Introduction from 'modules/workflows-overview-2d-introduction/configs/config1200';
import MineMap from 'modules/mine-map/configs/config1200';
import description from './description';

const config = {
  ...description,
  dimensions: { width: 1200, height: 690 },
  info: Introduction,
  locations: [MineMap],
};

export default config;
