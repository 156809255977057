import description from './description';
import SurveyMonitoringGeotech1 from './elements/surveyMonitoringGeotech_1';
import SurveyMonitoringGeotech2 from './elements/surveyMonitoringGeotech_2';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [39.5, 57.6], width: 12.75 }],
  dancingAnts: [
    ['hs1', [0, 79.88], { direction: 'reverse' }],
    ['hs1', [54.73, 100], { direction: 'reverse' }],
  ],
  tinyBois: [{ id: 'tb1', position: [39.5, 57.6], offset: [11, 1.6] }],
  elements: [new SurveyMonitoringGeotech1([35.4, 26.3]), new SurveyMonitoringGeotech2([51, 50.8])],
};

export default config;
