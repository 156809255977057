/* eslint-disable no-shadow */
/* eslint-disable no-plusplus */
export function isObject(value) {
  return Object.prototype.toString.call(value) === '[object Object]';
}

export function isBoolean(value) {
  return Object.prototype.toString.call(value) === '[object Boolean]';
}

export function isNumeric(value) {
  return !Number.isNaN(parseFloat(value)) && Number.isFinite(value);
}

export function isString(value) {
  return Object.prototype.toString.call(value) === '[object String]';
}

export function getSiteMap(config) {
  const paths = [];

  let i = -1;
  const n = config.length;

  while (++i < n) {
    const { id, title, slug, isInPaths, locations, disable } = config[i];

    if (isInPaths) {
      const group = {
        id,
        title,
        slug,
        disable,
        locations: [],
      };

      let j = -1;
      const m = locations.length;

      while (++j < m) {
        const { id, title, slug } = locations[j];

        if (slug !== 'introduction') {
          group.locations.push({
            id,
            title,
            slug,
          });
        }
      }

      paths.push(group);
    }
  }

  return { paths };
}

export function isPortraitOrientation() {
  return window.matchMedia('(orientation: portrait)').matches; // window.innerWidth <= window.innerHeight
}

export function isLandscapeOrientation() {
  return window.matchMedia('(orientation: landscape)'); // window.innerWidth > window.innerHeight
}

export function dotProduct(v1, v2) {
  return v1.x * v2.x + v1.y * v2.y;
}

export function crossProduct(v1, v2) {
  return v1.x * v2.y - v1.y * v2.x;
}

export function getAngle(v1, v2) {
  const dot = dotProduct(v1, v2);
  const cross = crossProduct(v1, v2);

  return Math.atan2(cross, dot) * (180 / Math.PI);
}

export function getStartVector(pivotX, pivotY, angle, len = 1) {
  // const x = x0 + Math.cos(angle) * len;
  // const y = y0 + Math.sin(angle) * len;
  // const endX = pivotX + len;
  // const endY = pivotY;
  // const x = (endX - pivotX) * Math.cos(angle) + (endY - pivotY) * Math.sin(angle) + pivotX;
  // const y = -1 * (endX - pivotX) * Math.sin(angle) + (endY - pivotY) * Math.cos(angle) + pivotY;
  const radian = (angle * Math.PI) / 180;
  const x = len * Math.cos(radian) + pivotX;
  const y = len * Math.sin(radian) + pivotY;

  return { x, y };
}

export function updateDocTitle(journey, location, i18n) {
  let title = 'Hexagon ┃ Life-of-Mine-Experience';

  if (journey) {
    title += ` ┃ ${i18n.t(journey.title)}`;
  }

  if (location && location.slug !== 'introduction') {
    title += ` ┃ ${i18n.t(location.title)}`;
  }

  document.title = title;
}

export function promiseTimeout(data, time = 1000) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(data), time);
  });
}

export function modifyApiSlug(config, apiSlugPrefix, apiSlugSuffix) {
  let { apiSlug } = config;
  apiSlug = apiSlugPrefix ? `${apiSlugPrefix}-${apiSlug}` : apiSlug;
  apiSlug = apiSlugSuffix ? `${apiSlug}-${apiSlugSuffix}` : apiSlug;

  return {
    ...config,
    apiSlug,
  };
}
