import description from './description';
import OperatorAssist1 from './elements/OperatorAssist1';
import OperatorAssist2 from './elements/OperatorAssist2';
import OperatorAssist3 from './elements/OperatorAssist3';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [57, 69.2], width: 7.375, rotation: [55, 0] }],
  dancingAnts: [['hs1', [0, 83], { direction: 'reverse' }]],
  tinyBois: [
    { id: 'tb1', position: [57, 69.2], offset: [5.7, 1.75] },
    { id: 'tb2', position: [57, 69.2], offset: [5.7, 5] },
  ],
  elements: [
    new OperatorAssist1([67.1, 18.2], { cornerFrom: ['bottom', 'left'] }),
    new OperatorAssist2([75, 42.9]),
    new OperatorAssist3([75, 57.4]),
  ],
};

export default config;
