import description from './description';

import RockCharacterization1 from './elements/RockCharacterization1';
import RockCharacterization2 from './elements/RockCharacterization2';
import RockCharacterization3 from './elements/RockCharacterization3';
import RockCharacterization4 from './elements/RockCharacterization4';
import RockCharacterization5 from './elements/RockCharacterization5';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [13.7, 41.4], width: 6.375 }],
  dancingAnts: [
    ['hs1', [0, 32.03], { direction: 'reverse' }],
    ['hs1', [0, 19.33], { direction: 'reverse' }],
    ['hs1', [50.57, 0]],
  ],
  tinyBois: [
    { id: 'tb1', position: [13.7, 41.4], offset: [0.1, 4.6] },
    { id: 'tb2', position: [13.7, 41.4], offset: [5.5, 4.6] },
    { id: 'tb3', position: [13.7, 41.4], offset: [2.9, 6.1] },
    { id: 'tb4', position: [13.7, 41.4], offset: [5.65, 1.2] },
  ],
  elements: [
    new RockCharacterization1([12.4, 17.2]),
    new RockCharacterization2([12.4, 62.5]),
    new RockCharacterization3([35.8, 47.6], { cornerFrom: ['top', 'left'] }),
    new RockCharacterization4([35.8, 62.5]),
    new RockCharacterization5([51.46, 31.25]),
  ],
};

export default config;
