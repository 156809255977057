import description from './description';
import SurveyMonitoringRealityCapture1 from './elements/surveyMonitoringRealityCapture_1';
import SurveyMonitoringRealityCapture2 from './elements/surveyMonitoringRealityCapture_2';
import SurveyMonitoringRealityCapture3 from './elements/surveyMonitoringRealityCapture_3';
import SurveyMonitoringRealityCapture4 from './elements/surveyMonitoringRealityCapture_4';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [52.8, 42.2], width: 6.375 }],
  dancingAnts: [['hs1', [47.76, 0], { direction: 'reverse' }]],
  tinyBois: [
    { id: 'tb1', position: [52.8, 42.2], offset: [3, 6.3] },
    { id: 'tb2', position: [52.8, 42.2], offset: [5.4, 1] },
    { id: 'tb3', position: [52.8, 42.2], offset: [5.4, 4.9] },
  ],
  elements: [
    new SurveyMonitoringRealityCapture1([51.4, 17.9], { size: 'wide' }),
    new SurveyMonitoringRealityCapture2([51.4, 59.2]),
    new SurveyMonitoringRealityCapture3([75, 40.36]),
    new SurveyMonitoringRealityCapture4([75, 54.3]),
  ],
};

export default config;
