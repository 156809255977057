import description from './description';
import DrillBlastBlastMovement1 from './elements/drillBlastBlastMovement_1';
import DrillBlastBlastMovement2 from './elements/drillBlastBlastMovement_2';
import DrillBlastBlastMovement3 from './elements/drillBlastBlastMovement_3';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [60, 70], width: 6.88, rotation: [35, 1] }],
  dancingAnts: [['hs1', [34.06, 100], { direction: 'reverse' }]],
  tinyBois: [
    { id: 'tb1', position: [60, 70], offset: [6.3, 2] },
    { id: 'tb2', position: [60, 70], offset: [6.3, 4.6] },
  ],
  elements: [
    new DrillBlastBlastMovement1([68, 16.4]),
    new DrillBlastBlastMovement2([76, 42.96]),
    new DrillBlastBlastMovement3([76, 63.41], { expandsFrom: ['bottom', 'right'] }),
  ],
};

export default config;
