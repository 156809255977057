/* eslint-disable react/forbid-prop-types,react/no-unused-prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import { ALLOW_EVENTS_CLASS } from 'app/constants/app';
import { useTranslation } from 'react-i18next';

import Portal from './Portal';

import '../assets/scss/index.scss';

const HashtagModal = ({ isOpen, children, isSaveButtonDisabled, handleClose, saveChanges }) => {
  const [active, setActive] = React.useState(false);
  const backdrop = React.useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    const { current } = backdrop;

    const transitionEnd = () => setActive(isOpen);

    const keyHandler = (e) => [27].indexOf(e.which) >= 0 && handleClose();

    const clickHandler = (e) => e.target === current && handleClose();

    if (current) {
      current.addEventListener('transitionend', transitionEnd);
      current.addEventListener('click', clickHandler);
      window.addEventListener('keyup', keyHandler);
    }

    if (isOpen) {
      window.setTimeout(() => {
        setActive(isOpen);
      }, 10);
    }

    return () => {
      if (current) {
        current.removeEventListener('transitionend', transitionEnd);
        current.removeEventListener('click', clickHandler);
      }
      window.removeEventListener('keyup', keyHandler);
    };
  }, [isOpen, handleClose, backdrop]);

  return (
    <>
      {(isOpen || active) && (
        <Portal className="modal-portal" parent={document.getElementById('root')}>
          <CSSTransition
            in={isOpen}
            timeout={500}
            classNames="modalZoom"
            mountOnEnter
            unmountOnExit
          >
            <div
              ref={backdrop}
              className={`c-interests-modal ${active && isOpen && 'active'} ${ALLOW_EVENTS_CLASS}`}
            >
              <div className="portal-content modal-content">
                {children}
                <div className="buttons-group">
                  <button
                    type="submit"
                    className="c-button"
                    onClick={handleClose}
                    disabled={isSaveButtonDisabled}
                  >
                    {t('common.Cancel')}
                  </button>
                  <button
                    type="submit"
                    className="c-button"
                    onClick={saveChanges}
                    disabled={isSaveButtonDisabled}
                  >
                    {t('common.Save changes')}
                  </button>
                </div>
              </div>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <div onClick={handleClose} className="modal-overlay" />
            </div>
          </CSSTransition>
        </Portal>
      )}
    </>
  );
};

HashtagModal.propTypes = {
  isOpen: PropTypes.bool,
  isSaveButtonDisabled: PropTypes.bool,
  handleClose: PropTypes.func,
  saveChanges: PropTypes.func,
  children: PropTypes.node,
};

HashtagModal.defaultProps = {
  isOpen: false,
  handleClose: () => {},
  saveChanges: () => {},
  children: null,
  isSaveButtonDisabled: true,
};

export default HashtagModal;
