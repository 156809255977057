import Box from 'modules/common/components/Box';

export default class LoadHaulDynamicData2 {
  constructor(position) {
    this.id = 'LoadHaulDynamicData2';
    this.component = Box;
    this.type = 'content';
    this.title = 'load-haul.Load&Haul Insights.elements.Bringing data together';
    this.content = 'load-haul.Load&Haul Insights.elements.Bringing data together Content';
    this.props = {
      target: 'tb2',
      position,
      cornerFrom: ['top', 'left'],
      hashtags: ['cost', 'esg', 'productivity', 'dataAnalytics'],
    };
  }
}
