import Box from 'modules/common/components/Box';

export default class UndergroundTaskManagement2 {
  constructor(position, props = {}) {
    this.id = 'undergroundTaskManagement_2';
    this.component = Box;
    this.type = 'content';
    this.title = 'underground.Task Management.elements.Interoperability';
    this.content = 'underground.Task Management.elements.Interoperability Content';
    this.props = {
      target: props.target || null,
      position,
      hashtags: ['integration', 'productivity'],
    };
  }
}
