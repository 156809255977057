import StageCard from 'modules/common/components/StageCard';

const description = {
  id: 1,
  currentNumber: 1,
  title: 'evaluationPlanningDesign.EvaluationPlanningDesign',
  slug: 'introduction',
  leadLayoutKey: 'evaluation-planning-design-introduction',
  apiSlug: 'evaluation-planning-design-introduction',
  description: 'evaluationPlanningDesign.description',
  video: {
    backgroundImage: 'Drillhole_Design.jpg',
  },
  drawPoints: {
    hotspots: 0.5,
    dancingAnts: 0.5,
    secondaries: 1,
  },
  hotspots: [],
  dancingAnts: [],
  tinyBois: [],
  elements: [{ id: 'EvaluationPlanningDesignIntroduction1', component: StageCard }],
};

export default description;
