import description from './description';
import UndergroundMining1 from './elements/undergroundMining_1';
import UndergroundMining2 from './elements/undergroundMining_2';
import UndergroundMining3 from './elements/undergroundMining_3';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [16, 48.7], width: 6.375 }],
  dancingAnts: [['hs1', [0, 37.1], { direction: 'reverse' }]],
  tinyBois: [
    { id: 'tb2', position: [16, 48.7], offset: [0.3, 4.9] },
    { id: 'tb1', position: [16, 48.7], offset: [6, 4] },
  ],
  elements: [
    new UndergroundMining1([12, 19.7]),
    new UndergroundMining2([27.6, 77.7]),
    new UndergroundMining3([12, 77.7]),
  ],
};

export default config;
