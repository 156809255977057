import * as consts from 'app/constants';

import BaseActions from 'core/actions';
import {
  SET_CAN_NAVIGATE,
  SET_DIRECTION,
  SET_IS_USER_LOGGED_IN,
  SET_JOURNEY,
  SET_LOCATION,
  SET_LOGGED_USER,
  SET_LOMIX_CONFIG,
  SET_NEXT_JOURNEY,
  SET_NEXT_LOCATION,
  SET_PREV_LOCATION,
  SET_PWA_PROGRESS,
  SET_PWA_ERROR,
  SET_PWA_LOADED,
  SET_REPLACE_JOURNEY,
  SET_TO_JOURNEY,
  SET_TO_LOG_IN,
  SET_AUDIO_MUTED,
  SET_LOCATION_NAME,
  SET_DEFERRED_CLICK_ELEMENT,
  SET_SHOW_AUTH_MODAL,
  SET_HIDE_HASHTAGS,
} from 'app/constants';

class Actions extends BaseActions {
  init({ payload = {}, meta = {}, status = null } = {}) {
    return this.create(this.getType('init', status), status, payload, meta);
  }

  togglePaths({ payload = {}, meta = {}, status = null } = {}) {
    return this.create(this.getType('toggle', status, 'paths'), status, payload, meta);
  }

  toggleIsStageAnimating({ payload = {}, meta = {}, status = null } = {}) {
    return this.create(this.getType('toggle', status, 'is_stage_animating'), status, payload, meta);
  }

  setModalContent({ payload = {}, meta = {}, status = null } = {}) {
    return this.create(this.getType('set', status, 'modal_content'), status, payload, meta);
  }

  setIsModalOpen({ payload = {}, meta = {}, status = null } = {}) {
    return this.create(this.getType('set', status, 'modal_open'), status, payload, meta);
  }

  setSiteMapOpen({ payload = {}, meta = {}, status = null } = {}) {
    return this.create(this.getType('set', status, 'sitemap_open'), status, payload, meta);
  }

  sendEmail({ payload = {}, meta = {}, status = null } = {}) {
    return this.create(this.getType('send', status, 'email'), status, payload, meta);
  }

  showTabletOrientation({ payload = {}, meta = {}, status = null } = {}) {
    return this.create(this.getType('show', status, 'tablet_orientation'), status, payload, meta);
  }

  acceptPrivacyPolicy({ payload = {}, meta = {}, status = null } = {}) {
    return this.create(this.getType('accept', status, 'privacy_policy'), status, payload, meta);
  }

  visitWorkflowsOverview({ payload = {}, meta = {}, status = null } = {}) {
    return this.create(this.getType('visit', status, 'workflows_overview'), status, payload, meta);
  }

  visitWorkflowsOverview2D({ payload = {}, meta = {}, status = null } = {}) {
    return this.create(
      this.getType('visit', status, 'workflows_overview_2d'),
      status,
      payload,
      meta,
    );
  }

  showInfo3D({ payload = {}, meta = {}, status = null } = {}) {
    return this.create(this.getType('show', status, 'info_3D'), status, payload, meta);
  }

  setLomixConfig = ({ payload }) => {
    return {
      type: SET_LOMIX_CONFIG,
      payload: payload.lomixConfig,
    };
  };

  setJourney = ({ payload }) => {
    return {
      type: SET_JOURNEY,
      payload,
    };
  };

  setNextJourney = ({ payload }) => {
    return {
      type: SET_NEXT_JOURNEY,
      payload,
    };
  };

  setLocation = ({ payload }) => {
    return {
      type: SET_LOCATION,
      payload,
    };
  };

  setLocationName = ({ payload }) => {
    return {
      type: SET_LOCATION_NAME,
      payload,
    };
  };

  setNextLocation = ({ payload }) => {
    return {
      type: SET_NEXT_LOCATION,
      payload,
    };
  };

  setPrevLocation = ({ payload }) => {
    return {
      type: SET_PREV_LOCATION,
      payload,
    };
  };

  setDirection = ({ payload }) => {
    return {
      type: SET_DIRECTION,
      payload,
    };
  };

  setCanNavigate = ({ payload }) => {
    return {
      type: SET_CAN_NAVIGATE,
      payload,
    };
  };

  setToJourney = ({ payload }) => {
    return {
      type: SET_TO_JOURNEY,
      payload,
    };
  };

  setReplaceJourney = ({ payload }) => {
    return {
      type: SET_REPLACE_JOURNEY,
      payload,
    };
  };

  setPwaProgress = ({ payload }) => {
    return {
      type: SET_PWA_PROGRESS,
      payload,
    };
  };

  setPwaLoaded = ({ payload }) => {
    return {
      type: SET_PWA_LOADED,
      payload,
    };
  };

  setPwaError = ({ payload }) => {
    return {
      type: SET_PWA_ERROR,
      payload,
    };
  };

  setLoggedUser = ({ payload }) => {
    return {
      type: SET_LOGGED_USER,
      payload,
    };
  };

  setIsUserLoggedIn = ({ payload }) => {
    return {
      type: SET_IS_USER_LOGGED_IN,
      payload,
    };
  };

  setToLogIn = ({ payload }) => {
    return {
      type: SET_TO_LOG_IN,
      payload,
    };
  };

  setShowAuthModal = ({ payload }) => {
    return {
      type: SET_SHOW_AUTH_MODAL,
      payload,
    };
  };

  setAudioMuted = ({ payload }) => {
    return {
      type: SET_AUDIO_MUTED,
      payload,
    };
  };

  setDeferredClickElement = ({ payload }) => {
    return {
      type: SET_DEFERRED_CLICK_ELEMENT,
      payload,
    };
  };

  setHideHashtags = ({ payload }) => {
    return {
      type: SET_HIDE_HASHTAGS,
      payload,
    };
  };

  getType = (action, status = '', type = '') => {
    let newType =
      consts[
        `${consts.NAME.toUpperCase()}${
          type ? `_${type.toUpperCase()}` : ''
        }_${action.toUpperCase()}`
      ];

    if (status) {
      newType += `/${consts.STATUS}/${status.toLowerCase()}`;
    }

    return newType;
  };

  showPwaModal({ payload = {}, meta = {}, status = null } = {}) {
    return this.create(this.getType('show', status, 'pwa_modal'), status, payload, meta);
  }

  showPwaConfirmDeleteModal({ payload = {}, meta = {}, status = null } = {}) {
    return this.create(this.getType('show', status, 'pwa_confirm_delete'), status, payload, meta);
  }

  setPwaConfirmDeleteProgress({ payload = {}, meta = {}, status = null } = {}) {
    return this.create(
      this.getType('set', status, 'pwa_confirm_delete_progress'),
      status,
      payload,
      meta,
    );
  }

  setJourneyToAssociate({ payload = {}, meta = {}, status = null } = {}) {
    return this.create(this.getType('set', status, 'journey_to_associate'), status, payload, meta);
  }

  shareJourney({ payload = {}, meta = {}, status = null } = {}) {
    return this.create(this.getType('share', status, 'journey'), status, payload, meta);
  }
}

const acts = new Actions();
Object.freeze(acts);

export default acts;
