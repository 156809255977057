import description from './description';
import UndergroundTaskManagement1 from './elements/undergroundTaskManagement_1';
import UndergroundTaskManagement2 from './elements/undergroundTaskManagement_2';
import UndergroundTaskManagement3 from './elements/undergroundTaskManagement_3';
import UndergroundTaskManagement4 from './elements/undergroundTaskManagement_4';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [16, 53.5], width: 6.375 }],
  dancingAnts: [['hs1', [0, 48.3], { direction: 'reverse' }]],
  tinyBois: [
    { id: 'tb1', position: [16, 53.5], offset: [0.2, 4.5] },
    { id: 'tb2', position: [16, 53.5], offset: [5.6, 4.5] },
    { id: 'tb3', position: [16, 53.5], offset: [3.12, 6.3] },
  ],

  elements: [
    new UndergroundTaskManagement1([12, 26]),
    new UndergroundTaskManagement2([19.8, 79.3], { target: 'tb3' }),
    new UndergroundTaskManagement3([4.1, 69.2], { target: 'tb1', cornerFrom: ['top', 'left'] }),
    new UndergroundTaskManagement4([35.4, 75.3], { target: 'tb2' }),
  ],
};

export default config;
