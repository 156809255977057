const description = {
  id: '1 of 9 drillhole-design',
  currentNumber: 1,
  totalNumber: 9,
  title: 'evaluationPlanningDesign.Drillhole Design.title',
  slug: 'drillhole-design',
  pdfPath: 'DrillholeDesign',
  leadLayoutKey: 'drillhole-design',
  apiSlug: 'drillhole-design',
  productDetailsLink: 'https://hexagon.com/products/product-groups/hxgn-mineplan/geosciences',
  description: 'evaluationPlanningDesign.Drillhole Design.description',
  video: {
    name: 'wf_Drillhole_Design.mp4',
    stop: 3.225,
    backgroundImage: 'Drillhole_Design.jpg',
  },
  drawPoints: {
    hotspots: 1,
    dancingAnts: 4.5,
    secondaries: 5,
  },
};

export default description;
