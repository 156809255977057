import description from './description';
import PlanningProductionDrilling1 from './elements/planningProductionDrilling_1';
import PlanningProductionDrilling2 from './elements/planningProductionDrilling_2';
import PlanningProductionDrilling3 from './elements/planningProductionDrilling_3';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [13.37, 48.7], width: 6.875, rotation: [60, 0] }],
  dancingAnts: [
    ['hs1', [0, 66.89], { direction: 'reverse' }],
    ['hs1', [51.35, 100]],
  ],
  tinyBois: [
    { id: 'tb1', position: [13.37, 48.7], offset: [6.7, 3.2] },
    { id: 'tb2', position: [13.37, 48.7], offset: [3.2, 5.1] },
  ],
  elements: [
    new PlanningProductionDrilling1([12.4, 18.75]),
    new PlanningProductionDrilling2([35.8, 47.65]),
    new PlanningProductionDrilling3([12.4, 62.63]),
  ],
};

export default config;
