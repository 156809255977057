const description = {
  id: '2 of 3',
  currentNumber: 2,
  totalNumber: 3,
  title: 'underground.Task Management.title',
  slug: 'task-management',
  leadLayoutKey: 'underground-task-management',
  pdfPath: 'UndergroundTaskManagement',
  apiSlug: 'underground-task-management',
  productDetailsLink: 'https://hexagon.com/products/hexagon-ug-pro',
  description: 'underground.Task Management.description',
  video: {
    name: 'wf_Underground_Task_Management.mp4',
    stop: 26,
    backgroundImage: 'Underground_Task_Management.jpg',
  },
  drawPoints: {
    hotspots: 26,
    dancingAnts: 26.1,
    secondaries: 26.4,
  },
};

export default description;
