import description from './description';
import MaterialMovementFatigueAndDistraction1 from './elements/materialMovementFatigueAndDistraction_1';
import MaterialMovementFatigueAndDistraction2 from './elements/materialMovementFatigueAndDistraction_2';
import MaterialMovementFatigueAndDistraction3 from './elements/materialMovementFatigueAndDistraction_3';
import MaterialMovementFatigueAndDistraction4 from './elements/materialMovementFatigueAndDistraction_4';
import MaterialMovementFatigueAndDistraction5 from './elements/materialMovementFatigueAndDistraction_5';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [16.1, 50.1], width: 6.375 }],
  dancingAnts: [
    ['hs1', [0, 30.27]],
    ['hs1', [0, 89.8], { direction: 'reverse' }],
  ],
  tinyBois: [
    { id: 'tb1', position: [16.1, 50.1], offset: [5.1, 5.3] },
    { id: 'tb2', position: [16.1, 50.1], offset: [5.6, 1] },
    { id: 'tb3', position: [16.1, 50.1], offset: [3.17, 5.9] },
    { id: 'tb4', position: [16.1, 50.1], offset: [1, 5.3] },
  ],
  elements: [
    new MaterialMovementFatigueAndDistraction1([14.37, 16.8]),
    new MaterialMovementFatigueAndDistraction2([14.37, 70]), // Combat fatigue and distraction мшвущ
    new MaterialMovementFatigueAndDistraction3([33.12, 42], {
      cornerFrom: ['top', 'left'],
      target: 'tb2',
    }), // State-of-the-art edge computing Content
    new MaterialMovementFatigueAndDistraction4([33.12, 57.12], { target: 'tb1' }), // Remote monitoring Content
    new MaterialMovementFatigueAndDistraction5([33.12, 70], { target: 'tb3' }), // Easy to install and quick to configure Content
  ],
};

export default config;
