import * as THREE from 'three';

export default class CustomSinCurve extends THREE.Curve {
  constructor(scale = 1, start, end) {
    super();

    this.scale = scale;
    this.end = end;
    this.start = start;
  }

  getPoint(t, optionalTarget = new THREE.Vector3()) {
    // x = 0, y = 0.5, z = 0

    // x = -10, y = 0.5, z = 10

    /* const tx = t * 3 - 1.5;
		const ty = Math.sin( 2 * Math.PI * t );
    const tz = 0; */
    // const start = [0, 0.5, 0];
    // const end = [-10, 0.5, 10];

    const fromRangeX = 1 - 0;
    const toRangeX = this.end[0] - this.start[0];
    const x = ((t - 0) * toRangeX) / fromRangeX + this.start[0];

    const fromRangeZ = 1 - 0;
    const toRangeZ = this.end[2] - this.start[2];
    const z = ((t - 0) * toRangeZ) / fromRangeZ + this.start[2];

    const tx = x; // t * this.end[0];
    const ty = 0.5;
    const tz = z; // t * this.end[2];

    return optionalTarget.set(tx, ty, tz).multiplyScalar(this.scale);
  }
}
