import Box from 'modules/common/components/Box';

export default class PlanningGradeСontrol2 {
  constructor(position) {
    this.id = 'planningGradeСontrol_2';
    this.component = Box;
    this.type = 'content';
    this.title = 'planning.Grade Control.title';
    this.content = 'planning.Grade Control.elements.Grade Control Content';

    this.props = {
      target: 'tb1',
      position,
      cornerFrom: ['top', 'left'],
      hashtags: ['productivity', 'cost'],
    };
  }
}
