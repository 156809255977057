/* eslint-disable no-unused-vars */
import React from 'react';

import PropTypes from 'prop-types';
import PowerOfOneCard from './PowerOfOneCard';

const PowerOfOneDesc = ({ isFirstVisit }) => {
  return <PowerOfOneCard />;
};

PowerOfOneDesc.propTypes = {
  isFirstVisit: PropTypes.bool,
};

PowerOfOneDesc.defaultProps = {
  isFirstVisit: false,
};
export default PowerOfOneDesc;
