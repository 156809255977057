// @ts-nocheck
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Checked from './assets/icons/checked.svg';

import './assets/scss/index.scss';

// eslint-disable-next-line no-unused-vars
const Checkbox = ({ isChecked, name, item, handleChange, withDescription }) => {
  const onChange = (e) => {
    handleChange(e.target.checked);
  };
  const { t } = useTranslation();

  return (
    <label className="checkbox-label" htmlFor={name}>
      <input
        type="checkbox"
        checked={isChecked}
        id={name}
        onChange={onChange}
        className="checkbox-input"
      />
      <Checked className="check-icon" />
      <div>
        <div className="checkbox-label__text">{t(item.name)}</div>
        {withDescription && (
          <div className="checkbox-label__description">{t(item.description)}</div>
        )}
      </div>
    </label>
  );
};

Checkbox.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  isChecked: PropTypes.bool,
  withDescription: PropTypes.bool,
  handleChange: PropTypes.func,
  name: PropTypes.string,
  item: PropTypes.object,
};

Checkbox.defaultProps = {
  isChecked: false,
  withDescription: false,
  handleChange: () => {},
  name: '',
  item: {},
};

export default Checkbox;
