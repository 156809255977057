/* eslint-disable no-unused-vars */
import { fork, takeEvery, put } from 'redux-saga/effects';

import { SUCCESS } from 'core/constants/statuses';

import actions from 'modules/journeys-manager/actions';
import {
  JOURNEYS_MANAGER_INIT,
  JOURNEYS_MANAGER_DESTROY,
} from 'modules/journeys-manager/constants';

export default function* root() {
  yield fork(watchInit);
  yield fork(watchDestroy);
}

function* init() {
  const payload = {};

  const initedDateTime = Date.now();

  const meta = {
    receivedAt: initedDateTime,
    updatedAt: initedDateTime,
  };

  yield put(actions.init({ status: SUCCESS, payload, meta }));
}

function* destroy() {
  const payload = {};

  const initedDateTime = Date.now();

  const meta = {
    receivedAt: initedDateTime,
    updatedAt: initedDateTime,
  };

  yield put(actions.destroy({ status: SUCCESS, payload, meta }));
}

function* watchInit() {
  yield takeEvery(JOURNEYS_MANAGER_INIT, init);
}

function* watchDestroy() {
  yield takeEvery(JOURNEYS_MANAGER_DESTROY, destroy);
}
