import Box from 'modules/common/components/Box';

export default class DrillBlastBlastMovement3 {
  constructor(position, props = {}) {
    this.id = 'drillBlastBlastMovement_3';
    this.component = Box;
    this.title = 'drill-blast.Blast Movement.elements.Blast Movement Monitors';
    this.type = 'content';
    this.content = 'drill-blast.Blast Movement.elements.Blast Movement Monitors Content';
    this.props = {
      target: 'tb2',
      position,
      expandsFrom: props.expandsFrom || null,
      hashtags: ['productivity', 'digitalisation'],
    };
  }
}
