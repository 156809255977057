// @ts-nocheck
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
/* eslint-disable react/button-has-type,react/prop-types */
import React from 'react';

import { useTranslation } from 'react-i18next';

import RemoveIcon from './assets/icons/remove.svg';

const Delete = ({ handleClose, handleConfirm }) => {
  const { t } = useTranslation();
  return (
    <>
      <h2>{t('download.Delete offline version')}</h2>
      <div>
        {t(
          "download.After confirming this action you won't be able to view Life-of-Mine Experience offline",
        )}
      </div>
      <div className="margin-top">{t('download.Are you sure you want to continue')}</div>
      <div className="c-buttons-list margin-top-7">
        <button className="c-button" onClick={handleClose}>
          {t('common.Cancel')}
        </button>
        <button className="c-button" onClick={handleConfirm}>
          <RemoveIcon /> {t('common.Delete')}
        </button>
      </div>
    </>
  );
};

export default Delete;
