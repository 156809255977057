// @ts-nocheck
/* eslint-disable react/forbid-prop-types,no-unused-vars */
import React, { useEffect, useState, useRef } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import configAPP from 'app/configs';

import * as selectors from 'app/selectors';

import { logout } from 'modules/auth/authService';

import './assets/scss/index.scss';

import { useTranslation } from 'react-i18next';
import Tags from './Tags';

const ProfileDropdown = ({ loggedUser }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [avatarL, setAvatarL] = useState('');

  const profileRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    const userNic = loggedUser?.name || loggedUser?.email;

    const letter = userNic?.charAt(0).toUpperCase() || 'L';

    setAvatarL(letter);
  }, [setAvatarL]);

  const checkOutsideClick = (e) => {
    if (!profileRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    // subscribe event
    window.addEventListener('mousedown', checkOutsideClick);
    return () => {
      // unsubscribe event
      window.removeEventListener('mousedown', checkOutsideClick);
    };
  }, []);

  const toogleOpen = () => {
    if (!configAPP.enableExhibition) {
      setIsOpen(!isOpen);
    }
  };

  return (
    <div className="profile" ref={profileRef}>
      <div
        className="avatar"
        style={{
          cursor: !configAPP.enableExhibition ? 'pointer' : 'auto',
        }}
        onClick={toogleOpen}
        onKeyDown={toogleOpen}
        role="button"
        tabIndex="0"
      >
        {avatarL}
      </div>
      <div className={`dropdown-section ${isOpen ? 'is-open' : ''}`}>
        <div className="item">
          <Tags />
        </div>

        <div className="item">
          <div className="item-button">
            <div
              role="button"
              onClick={logout}
              onKeyDown={logout}
              className="menu-button"
              tabIndex={0}
            >
              {t('auth.Log Out')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ProfileDropdown.propTypes = {
  loggedUser: PropTypes.object,
};

ProfileDropdown.defaultProps = {
  loggedUser: {},
};

const mapStateToProps = (state) => {
  return { loggedUser: selectors.getLoggedUser(state) };
};

export default connect(mapStateToProps)(ProfileDropdown);
