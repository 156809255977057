/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-empty,no-unused-vars */
/* eslint-disable prettier/prettier */
// @ts-nocheck
/* eslint-disable global-require,react/destructuring-assignment,react/prop-types,react/no-unused-state,no-restricted-syntax */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import config from 'modules/safety-slope-monitoring/configs';

import Layout from 'modules/common/layouts';

class Main extends PureComponent {
  onEvent = ({ action, data }) => {
    this.props.onEvent({ action, data });
  };

  render() {
    const { journey, location, requestDestroy, audioMuted, enabledTransition, backgroundImage } =
      this.props;

    return (
      <>
        <Layout
          journey={journey} // TEMP. ONLY FOR TEST
          location={location}
          backgroundImage={backgroundImage}
          drawPoints={journey?.info?.drawPoints}
          onEvent={this.onEvent}
          requestDestroy={requestDestroy}
          audioMuted={audioMuted}
          enabledTransition={enabledTransition}
        />
      </>
    );
  }
}

Main.propTypes = {
  config: PropTypes.shape({
    className: PropTypes.string,
    delayPrimaries: PropTypes.number,
    delaySecondaries: PropTypes.number,
    staggerBreakdowns: PropTypes.number,
    delayVideoBreakdown: PropTypes.number,
  }),
  location: PropTypes.object,
  playDefaultAudio: PropTypes.bool,
  audioMuted: PropTypes.bool,
  enabledTransition: PropTypes.bool,
  requestDestroy: PropTypes.bool,
};

Main.defaultProps = {
  config: {
    className: 'journey',
    delayPrimaries: 0.5,
    delaySecondaries: 0.25,
    staggerBreakdowns: 0.125,
    delayVideoBreakdown: 1.5,
  },
  location: {},
  playDefaultAudio: false,
  audioMuted: true,
  enabledTransition: false,
  requestDestroy: false,
};

const mapStateToProps = () => {
  const location = config.current;

  const playDefaultAudio = !!location?.video?.playDefaultSound;

  return {
    config: {
      className: 'journey',
      delayPrimaries: 0.5,
      delaySecondaries: 0.25,
      staggerBreakdowns: 0.125,
      delayVideoBreakdown: 1.5,
    },
    location,
    playDefaultAudio,
  };
};

export default connect(mapStateToProps, null)(Main);
