import description from './description';
import DrillBlastSafety1 from './elements/drillBlastSafety_1';
import DrillBlastSafety2 from './elements/drillBlastSafety_2';
import DrillBlastSafety3 from './elements/drillBlastSafety_3';
import DrillBlastSafety4 from './elements/drillBlastSafety_4';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [44.92, 71.19], width: 6.4375, rotation: [62, 0] }],
  dancingAnts: [
    ['hs1', [0, 91.69], { direction: 'reverse' }],
    ['hs1', [49.53, 100], { direction: 'reverse' }],
  ],
  tinyBois: [
    { id: 'tb1', position: [44.92, 71.19], offset: [-0.2, 3] },
    { id: 'tb2', position: [44.92, 71.19], offset: [6, 3] },
    { id: 'tb3', position: [44.92, 71.19], offset: [4.2, 1.7] },
  ],
  elements: [
    new DrillBlastSafety1([20.21, 18.6]),
    new DrillBlastSafety2([12.4, 58.4]),
    new DrillBlastSafety3([75, 26.4]),
    new DrillBlastSafety4([51.4, 26.4]),
  ],
};

export default config;
