import StageCard from 'modules/common/components/StageCard';

const config = {
  id: 4,
  title: 'load-haul.Load & Haul',
  slug: 'introduction',
  leadLayoutKey: 'load-haul-introduction',
  apiSlug: 'load-haul-introduction',
  description: 'load-haul.description',
  video: {
    backgroundImage: 'Scheduling_And_Optimizing.jpg',
  },
  drawPoints: {
    hotspots: 1,
    dancingAnts: 1,
    secondaries: 2,
  },
  hotspots: [],
  dancingAnts: [],
  tinyBois: [],
  elements: [{ id: 'materialMovementIntroduction_1', component: StageCard }],
};

export default config;
