const description = {
  id: '4 of 4',
  currentNumber: 4,
  totalNumber: 4,
  title: 'planning.Planning Insights.title',
  slug: 'planning-insights',
  pdfPath: 'PlanningInsights',
  leadLayoutKey: 'planning-insights',
  apiSlug: 'planning-insights',
  productDetailsLink: 'https://hexagon.com/solutions/mine-reality-capture',
  description: 'planning.Planning Insights.description',
  video: {
    backgroundImage: 'Planning_Insights.jpg',
    playDefaultSound: true,
  },
  drawPoints: {
    hotspots: 1,
    dancingAnts: 1.5,
    secondaries: 2,
  },
};

export default description;
