/* eslint-disable react/button-has-type */
/* eslint-disable dot-notation */
/* eslint-disable react/destructuring-assignment,no-useless-escape,jsx-a11y/label-has-associated-control */
import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import LocationsListView from 'modules/journeys-manager-journey/components/LocationsListView';
import { formatDate } from 'core/services/date';
import Button from 'app/components/Button';
import ProgressScreen from 'modules/journeys-manager-journey/components/ProgressScreen';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import JourneyCopyToClipboard from 'modules/journeys-manager-journey/components/JourneyCopyToClipboard';

import ArrowLeftIcon from 'app/assets/icons/arrow-left.svg';
import DublicateIcon from './assets/icons/dublicate.svg';
import EditIcon from './assets/icons/edit.svg';
import SubtractIcon from './assets/icons/subtract.svg';
import CloseIcon from './assets/icons/close.svg';

import './assets/scss/index.scss';

class ViewJourney extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpenShareJourneyPopup: false,
    };
  }

  componentDidMount() {
    const { onEvent } = this.props;

    onEvent({ action: 'viewDidMount' });
  }

  componentWillUnmount() {
    const { onEvent } = this.props;

    onEvent({ action: 'viewWillUnmount' });
  }

  onEvent = ({ action, data }) => {
    const { onEvent } = this.props;

    onEvent({ action, data });
  };

  duplicate() {
    const { onEvent, journey } = this.props;

    onEvent({ action: 'duplicateJourney', data: journey });
  }

  editJourney() {
    const { onEvent, journey } = this.props;

    onEvent({ action: 'editJourney', data: journey });
  }

  runJourney() {
    const { onEvent, journey } = this.props;

    onEvent({ action: 'runJourney', data: { id: journey?.id } });
  }

  openShareJourneyPopup() {
    this.setState({
      isOpenShareJourneyPopup: true,
    });
  }

  closeShareJourneyPopup() {
    this.setState({
      isOpenShareJourneyPopup: false,
    });
  }

  navigationBack() {
    const { onEvent } = this.props;

    onEvent({ action: 'navigationBack', data: { path: '/manager' } });
  }

  render() {
    const { allLomixConfigs, isLoading, journey, permission, t } = this.props;
    const { isOpenShareJourneyPopup } = this.state;

    if (isLoading) {
      return (
        <>
          <ProgressScreen />
        </>
      );
    }

    const isEditable = permission.edit && journey && journey.own && !journey.shared;

    return (
      <>
        <div className="journey-manager-view-page">
          <div className="journey-manager-view-header">
            <div>
              <button
                className="journey-manager-view-header__navigation"
                onClick={() => this.navigationBack()}
              >
                <ArrowLeftIcon />
                <span>{t('common.back')}</span>
              </button>
              <div className="journey-manager-view-header__title">
                {t('journeys-manager.VIEW JOURNEY')}
              </div>
            </div>
            <div className="journey-manager-view-header__actions">
              {permission.createJourney && journey && (
                <button
                  type="button"
                  className="c-button c-button--size-default journey-manager-view-header__action-duplicate"
                  onClick={() => this.duplicate()}
                >
                  <div className="c-button__text">
                    <DublicateIcon />
                    <span>{t('journeys-manager.Duplicate')}</span>
                  </div>
                </button>
              )}
              {isEditable && (
                <button
                  className="c-button c-button--size-default  journey-manager-view-header__action-edit"
                  onClick={() => this.editJourney()}
                >
                  <div className="c-button__text">
                    <EditIcon />
                    <span>{t('journeys-manager.edit')}</span>
                  </div>
                </button>
              )}
            </div>
          </div>

          <div className="journey-manager-view-content journey-manager-view-content--row">
            <div className="journey-view">
              <div className="view-body">
                <div className="main-info">
                  <div className="main-info_data">
                    {journey?.own && (
                      <div className="field field--first">
                        <div className="label">{t('journeys-manager.Private name')}</div>
                        <div className="text-wrapper">{journey?.privateName || '-'}</div>
                        <div className="private-info">
                          <div className="private-info_date">
                            {journey?.createdAt ? formatDate(journey.createdAt * 1000) : ''}
                          </div>
                          <div className="private-info_text">
                            <SubtractIcon />
                            <span>
                              {journey?.shared
                                ? t('journeys-manager.My shared journey')
                                : t('journeys-manager.My private journey')}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="field">
                      <div className="label"> {t('journeys-manager.Shareable name')}</div>
                      <div className="text-wrapper">{journey?.shareableName}</div>
                    </div>
                    <div className="field">
                      <div className="label">{t('common.description')}</div>
                      <div className="text-wrapper">{t(journey?.description)}</div>
                    </div>
                    <div className="main-info_action-secondary">
                      {!journey?.savedLocally && (
                        <button
                          type="button"
                          className="c-button c-button--size-sm main-info_action-share"
                          onClick={() => this.openShareJourneyPopup()}
                        >
                          <div className="c-button__text">{t('journeys-manager.Share')}</div>
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="main-info_action">
                    <Button
                      additionalClasses={['main-info_action-start-journey']}
                      onClick={() => this.runJourney()}
                      disabled={isLoading}
                    >
                      {t('journeys-manager.START JOURNEY')}
                    </Button>
                  </div>
                </div>

                <div className="locations">
                  <LocationsListView
                    locations={journey?.locations || []}
                    allLomixConfigs={allLomixConfigs}
                  />
                </div>

                {/* <div className="demo">Demo block</div> */}
              </div>
            </div>
          </div>
        </div>

        <Dialog
          className="c-dialog c-dialog--with-main-header share-journey-modal"
          open={isOpenShareJourneyPopup}
          onClose={() => this.closeShareJourneyPopup()}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle className="c-dialog-title c-dialog-title--normal" id="scroll-dialog-title">
            <span>{t('journeys-manager.SHARE JOURNEY')}</span>
            <button
              type="button"
              className="c-button c-button--size-sm c-button--link"
              onClick={() => this.closeShareJourneyPopup()}
              aria-labelledby="Close Popup"
            >
              <CloseIcon />
            </button>
          </DialogTitle>
          <DialogContent className="c-dialog-content">
            <div className="share-journey-modal_content-text">
              <p>{t('journeys-manager.shareDescription')}</p>
              <JourneyCopyToClipboard journeyId={journey?.id} />
            </div>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

ViewJourney.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  allLomixConfigs: PropTypes.arrayOf(PropTypes.any),
  // todo: move to separated file
  journey: PropTypes.shape({
    id: PropTypes.number,
    privateName: PropTypes.string,
    shareableName: PropTypes.string,
    description: PropTypes.string,
    hidden: PropTypes.bool,
    own: PropTypes.bool,
    shared: PropTypes.bool,
    savedLocally: PropTypes.bool,
    createdAt: PropTypes.number,
    updatedAt: PropTypes.number,
    locations: PropTypes.arrayOf(PropTypes.string),
  }),
  onEvent: PropTypes.func,
  t: PropTypes.func,
  isLoading: PropTypes.bool,
  permission: PropTypes.shape({
    createJourney: PropTypes.bool,
    edit: PropTypes.bool,
    delete: PropTypes.bool,
    duplicate: PropTypes.bool,
  }),
};

ViewJourney.defaultProps = {
  allLomixConfigs: [],
  journey: null,
  onEvent: () => {},
  t: () => {},
  isLoading: false,
  permission: {
    createJourney: false,
    edit: false,
    delete: false,
    duplicate: false,
  },
};

export default withTranslation()(connect(null, null)(ViewJourney));
