/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/button-has-type */
/* eslint-disable react/require-default-props */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import DeviceRotateSvg from 'app/assets/icons/device-rotate-big.svg';

import './assets/styles/index.scss';
import { withTranslation } from 'react-i18next';

class DeviceRotate extends PureComponent {
  handleClose = () => {
    const { onEvent } = this.props;
    onEvent({ action: 'close' });
  };

  handleEvent = ({ action, path }) => {
    const { onEvent } = this.props;
    onEvent({ action, path });
  };

  handleClickContactUs = () => {
    const { onEvent } = this.props;
    onEvent({ action: 'openContactUs' });
  };

  render() {
    const { config, opened, t } = this.props;

    return (
      <div className={`${config.className} ${opened ? config.openClass : ''}`}>
        <div className={`${config.className}__container`}>
          <div className={`${config.className}__content ${config.className}__content_margin`}>
            <div className={`${config.className}__content-box`}>
              <div>
                <DeviceRotateSvg />
              </div>
              <div className={`${config.className}__content-box-text fade-in`}>
                <div className={`${config.className}__content-description_margin`}>
                  {t('Please rotate your device')}
                </div>
                <div>{t('to landscape')}</div>
                <div
                  className={`${config.className}__content-hint ${config.className}__content-hint_margin`}
                >
                  {t('If screen does not rotate, make sure your screen orientation is unlocked')}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DeviceRotate.propTypes = {
  config: PropTypes.shape({
    className: PropTypes.string,
    openClass: PropTypes.string,
  }),
  opened: PropTypes.bool,
  onEvent: PropTypes.func,
  t: PropTypes.func,
};

DeviceRotate.defaultProps = {
  config: {
    className: 'c-device-rotate-modal',
    openClass: 'is-open',
  },
  opened: false,
  onEvent: () => {},
  t: () => {},
};

export default withTranslation()(connect(null, null, null, { forwardRef: true })(DeviceRotate));
