import description from './description';
import UndergroundDevelopmentDrilling1 from './elements/undergroundDevelopmentDrilling_1';
import UndergroundDevelopmentDrilling2 from './elements/undergroundDevelopmentDrilling_2';
import UndergroundDevelopmentDrilling3 from './elements/undergroundDevelopmentDrilling_3';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [13.7, 47.1], width: 6.375 }],
  dancingAnts: [['hs1', [0, 48.3], { direction: 'reverse' }]],
  tinyBois: [
    { id: 'tb1', position: [13.7, 47.1], offset: [0, 3] },
    { id: 'tb2', position: [13.7, 47.1], offset: [6.1, 3] },
  ],

  elements: [
    new UndergroundDevelopmentDrilling1([14.1, 16.51], { size: 'wide' }),
    new UndergroundDevelopmentDrilling2([11.6, 63.1], { target: 'tb1' }),
    new UndergroundDevelopmentDrilling3([35.4, 60.25], { target: 'tb2' }),
  ],
};

export default config;
