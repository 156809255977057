/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React, { PureComponent } from 'react';

import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import './assets/scss/index.scss';
// import ButtonClose from 'app/components/modals/ButtonClose';
import withNavigate from 'app/routers/withNavigate';
// import { loginMsal } from 'modules/auth/authMsalService';
import AuthTabs from 'modules/auth/components/AuthTabs';
import CloseButton from 'app/assets/icons/closeSmall.svg';

class Auth extends PureComponent {
  render() {
    const { config, onClickCloseAuth } = this.props;

    return (
      <div className={`${config.className}`}>
        <div className={`${config.className}__sidebar`}>
          <div className={`${config.className}__sidebar-close`} onClick={onClickCloseAuth}>
            <CloseButton />
          </div>
          <AuthTabs />
        </div>
      </div>
    );
  }
}

Auth.propTypes = {
  config: PropTypes.shape({
    className: PropTypes.string,
  }),
  onClickCloseAuth: PropTypes.func,
};

Auth.defaultProps = {
  config: {
    className: 'auth',
  },
  onClickCloseAuth: () => {},
};

export default withNavigate(connect(null, null)(Auth));
