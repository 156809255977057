/* eslint-disable react/button-has-type */
/* eslint-disable global-require,react/jsx-props-no-spreading,no-return-assign,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,react/destructuring-assignment,react/no-unused-state */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as selectors from 'app/selectors';

import Button from 'modules/common/components/Button';

import Carousel from 'modules/mine-map/components/Carousel';

import { ALLOW_EVENTS_CLASS } from 'app/constants/app';

import locationConfig from './locationConfig';

import './assets/scss/index.scss';

class Sidebar extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      locationInfo: null,
    };
  }

  componentDidMount() {
    this.setLocation();
  }

  componentDidUpdate(prevProps) {
    const { locationId } = this.props;

    if (prevProps.locationId !== locationId) {
      this.setLocation();
    }
  }

  setLocation = () => {
    const { locationId } = this.props;
    const locationInfo = locationConfig.filter((loc) => loc.slug === locationId);
    this.setState({ locationInfo: locationInfo[0] });
  };

  goToPrevLocation = () => {
    const {
      locationInfo: { id },
    } = this.state;

    const { onMoveToLocation } = this.props;

    const prevId = id - 1 >= 0 ? id - 1 : locationConfig[locationConfig.length - 1].id;
    const prevLocation = locationConfig.filter((loc) => loc.id === prevId);

    onMoveToLocation(prevLocation[0].slug);
  };

  goToNextLocation = () => {
    const {
      locationInfo: { id },
    } = this.state;

    const { onMoveToLocation } = this.props;
    const nextId = id + 1 > locationConfig.length - 1 ? 0 : id + 1;
    const nextLocation = locationConfig.filter((loc) => loc.id === nextId);

    onMoveToLocation(nextLocation[0].slug);
  };

  changeActiveSlide = (slug) => {
    const { onMoveToLocation } = this.props;
    onMoveToLocation(slug);
  };

  render() {
    const { config, onGoIntoLocation, locationId, isUserLoggedIn, t } = this.props;
    const { locationInfo } = this.state;

    return (
      <div className="scene-sidebar-content">
        <div className={`${config.className} ${ALLOW_EVENTS_CLASS}`}>
          <div className={`${config.className}__icons`}>
            {locationInfo?.slug && (
              <Carousel activeSlug={locationInfo?.slug} changeActive={this.changeActiveSlide} />
            )}
          </div>
          <div className={`${config.className}__info`}>
            <div className="title">
              <h2>{t(locationInfo?.name)}</h2>
            </div>
            <div className="description">{t(locationInfo?.description)}</div>
            <div className="bottom-button">
              <Button
                onClick={() => {
                  onGoIntoLocation(locationId);
                }}
              >
                {!isUserLoggedIn ? t('workflow.Sign up to ') : ''}
                {t('workflow.DIG DEEPER iNTO')} {t(locationInfo?.name)}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Sidebar.propTypes = {
  config: PropTypes.shape({
    className: PropTypes.string,
  }),
  locationId: PropTypes.string,
  onMoveToLocation: PropTypes.func,
  onGoIntoLocation: PropTypes.func,
  isUserLoggedIn: PropTypes.bool,
  t: PropTypes.func,
};

Sidebar.defaultProps = {
  config: {
    className: 'sidebar',
  },
  locationId: '',
  onMoveToLocation: () => {},
  onGoIntoLocation: () => {},
  isUserLoggedIn: false,
  t: () => {},
};

const mapStateToProps = (state) => {
  return {
    isUserLoggedIn: selectors.getIsUserLoggedIn(state),
  };
};

export default withTranslation()(connect(mapStateToProps, null)(Sidebar));
