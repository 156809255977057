/* eslint-disable react/no-unused-prop-types */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import gsap from 'gsap';

import { breakPointHeight, percentToRem } from 'app/helpers';

class TinyBoi extends PureComponent {
  constructor(props) {
    super(props);

    this.tinyBoi = React.createRef();
    this.center = React.createRef();
  }

  getTimeline() {
    return this.timeline;
  }

  init() {
    const { config } = this.props;

    // Make sure GSAP is always looking gawgeous
    gsap.config({ force3D: true });

    gsap.set(this.tinyBoi.current, { scale: 0, visibility: 'visible' });

    this.timeline = gsap.timeline({ paused: true }).to(this.tinyBoi.current, {
      scale: 1,
      duration: config.durationTinyBoi,
      ease: config.easeTinyBoi,
    });

    return this;
  }

  render() {
    const { config, position, offset, isSubdued, id } = this.props;
    const height = breakPointHeight();

    const topInRem = percentToRem(height, position[1]);

    return (
      <div
        ref={this.tinyBoi}
        id={id}
        className={`${config.className} ${isSubdued ? config.subduedClass : ''}`}
        style={{
          top: `calc(${topInRem}rem + ${offset[1]}rem)`,
          left: `calc(${position[0]}% + ${offset[0]}rem)`,
        }}
      >
        <div ref={this.center} className={`${config.className}__center`} />
      </div>
    );
  }
}

TinyBoi.propTypes = {
  config: PropTypes.shape({
    className: PropTypes.string,
    subduedClass: PropTypes.string,
    durationTinyBoi: PropTypes.number,
    easeTinyBoi: PropTypes.string,
  }),
  position: PropTypes.arrayOf(PropTypes.number),
  offset: PropTypes.arrayOf(PropTypes.number),
  isSubdued: PropTypes.bool,
  id: PropTypes.string,
};

TinyBoi.defaultProps = {
  config: {
    className: 'c-tiny-boi',
    subduedClass: 'is-subdued',
    durationTinyBoi: 0.25,
    easeTinyBoi: 'back.out(1.7)',
  },
  position: [0, 0],
  offset: [0, 0],
  isSubdued: false,
  id: 'tinyboi',
};

TinyBoi.id = 'TinyBoi';

const mapStateToProps = () => {
  const config = {
    className: 'c-tiny-boi',
    subduedClass: 'is-subdued',
    durationTinyBoi: 0.25,
    easeTinyBoi: 'back.out(1.7)',
  };

  return {
    config,
  };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(TinyBoi);
