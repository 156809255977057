import description from './description';
import PlanningInsights1 from './elements/PlanningInsights_1';

const config = {
  ...description,
  id: '4 of 4',
  currentNumber: 4,
  totalNumber: 4,
  hotspots: [{ id: 'hs1', position: [70, 82], width: 18.25, rotation: [67, 0] }],
  dancingAnts: [
    ['hs1', [41.99, 0]],
    ['hs1', [51.75, 0], { direction: 'reverse' }],
    ['hs1', [61.62, 0]],
    ['hs1', [0, 69.33], { direction: 'reverse' }],
  ],
  tinyBois: [],
  elements: [new PlanningInsights1([27.6, 32.17])],
};

export default config;
