import description from './description';
import PlanningGradeСontrol1 from './elements/planningGradeСontrol_1';
import PlanningGradeСontrol2 from './elements/planningGradeСontrol_2';
import PlanningGradeСontrol3 from './elements/planningGradeСontrol_3';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [15, 49], width: 9.125, rotation: [67, 0] }],
  dancingAnts: [
    ['hs1', [0, 32.03]],
    ['hs1', [0, 87.89], { direction: 'reverse' }],
  ],
  tinyBois: [
    { id: 'tb1', position: [15, 49], offset: [4, 5.9] },
    { id: 'tb2', position: [15, 49], offset: [8.8, 4.1] },
  ],
  elements: [
    new PlanningGradeСontrol1([12, 16.8]),
    new PlanningGradeСontrol2([12, 66.3]),
    new PlanningGradeСontrol3([27.6, 43.8]),
  ],
};

export default config;
