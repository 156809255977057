import description from './description';
import DrillBlastBlastFragmentationAnalysis1 from './elements/drillBlastBlastFragmentationAnalysis_1';
import DrillBlastBlastFragmentationAnalysis2 from './elements/drillBlastBlastFragmentationAnalysis_2';
import DrillBlastBlastFragmentationAnalysis3 from './elements/drillBlastBlastFragmentationAnalysis_3';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [57, 70], width: 7.815, rotation: [62, 0] }],
  dancingAnts: [['hs1', [0, 67.38], { direction: 'reverse' }]],
  tinyBois: [
    { id: 'tb1', position: [57, 70], offset: [6.3, 2.4] },
    { id: 'tb2', position: [57, 70], offset: [6.3, 4.9] },
  ],
  elements: [
    new DrillBlastBlastFragmentationAnalysis1([27.5, 42.62], { cornerFrom: ['top', 'right'] }),
    new DrillBlastBlastFragmentationAnalysis2([75, 39.9]),
    new DrillBlastBlastFragmentationAnalysis3([75, 56]),
  ],
};

export default config;
