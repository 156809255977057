import ConfigAdapter from 'app/components/modals/ContactUs/configs/configAdapter';
import countries from 'app/components/modals/ContactUs/configs/countries';
import fields from 'app/components/modals/ContactUs/configs/fields';
import levels from 'app/components/modals/ContactUs/configs/levels';
import roles from 'app/components/modals/ContactUs/configs/roles';
import workflows from 'app/components/modals/ContactUs/configs/workflows';

/* eslint-disable class-methods-use-this */
class FormDataHelper {
  getRequestObject(formData) {
    const combinedData = this.generateCombinedData(formData);
    const pardotData = this.generatePardotData(combinedData);

    return {
      rawData: { ...formData },
      combinedData,

      pardotData,
      pardotFormData: '',
    };
  }

  generateCombinedData(data) {
    return Object.keys(data).reduce(
      (accum, key) => ({ ...accum, [key]: this.generateCombinedValue(data[key], key) }),
      {},
    );
  }

  generateCombinedValue(value, fieldName) {
    const fieldsConfigAdapter = new ConfigAdapter(fields);

    const countriesConfigAdapter = new ConfigAdapter(countries);
    const levelsConfigAdapter = new ConfigAdapter(levels);
    const rolesConfigAdapter = new ConfigAdapter(roles);
    const workflowsConfigAdapter = new ConfigAdapter(workflows);

    const obj = fieldsConfigAdapter.mappedConfig[fieldName] || {};
    const fieldData = {
      fieldName: obj.key,
      pardotFieldId: obj.pardotId,
      pardotValueId: obj.pardotValueId || undefined,
      // pardotValue: obj.pardotValueId || value,
    };

    switch (fieldName) {
      case 'level':
        return { ...fieldData, ...this.getValueFromConfig(value, levelsConfigAdapter) };
      case 'country':
        return { ...fieldData, ...this.getValueFromConfig(value, countriesConfigAdapter) };
      case 'role':
        return { ...fieldData, ...this.getValueFromConfig(value, rolesConfigAdapter) };
      case 'workflows':
        return value.map((el) => ({
          ...fieldData,
          ...this.getValueFromConfig(el, workflowsConfigAdapter, true),
        }));
      default:
        return { ...fieldData, value, pardotValue: fieldData.pardotValueId || value };
    }
  }

  getValueFromConfig(value, configAdapter, usePardotValueId = false) {
    const obj = configAdapter.mappedConfig[value] || {};

    return {
      value,
      pardotValueId: usePardotValueId ? obj.pardotId || undefined : undefined,
      pardotValue: obj.pardotId || value,
    };
  }

  generatePardotData(combinedData) {
    const newObj = {};
    Object.keys(combinedData).forEach((key) => {
      if (!combinedData[key]) {
        return;
      }

      if (Array.isArray(combinedData[key])) {
        combinedData[key].forEach((combinedValue) => {
          const pardotParams = this.pardotDataFromCombinedValue(combinedValue);
          newObj[pardotParams.key] = pardotParams.value;
        });
      } else {
        const pardotParams = this.pardotDataFromCombinedValue(combinedData[key]);
        newObj[pardotParams.key] = pardotParams.value;
      }
    });

    return newObj;
  }

  pardotDataFromCombinedValue(combinedValue) {
    const field = combinedValue.pardotFieldId || combinedValue.fieldName;

    return {
      key: field + (combinedValue.pardotValueId ? `_${combinedValue.pardotValueId}` : ''),
      value: combinedValue.pardotValue || combinedValue.value,
    };
  }
}

const objectFormDataHelper = new FormDataHelper();
export default objectFormDataHelper;
