// @ts-nocheck
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as selectors from 'app/selectors';
import { useTranslation } from 'react-i18next';

import { toggleUserToLogIn } from 'modules/auth/authService';

import SignUp from '../SignUp';
import LogIn from '../LogIn';
import 'modules/auth/assets/scss/index.scss';
import './assets/scss/index.scss';

const AuthTabs = ({ userToLogIn }) => {
  const { t } = useTranslation();
  return (
    <div className="tabs-wrapper">
      <div className="tab-nav">
        <div
          className={`tab-nav-item ${userToLogIn ? '' : 'is-active'}`}
          onClick={() => toggleUserToLogIn(false)}
        >
          <div className="auth-title tab-title">{t('auth.Sign up')}</div>
        </div>
        <div
          className={`tab-nav-item ${userToLogIn ? 'is-active' : ''}`}
          onClick={() => toggleUserToLogIn(true)}
        >
          <div className="auth-title tab-title">{t('auth.Log in')}</div>
        </div>
      </div>
      <div className="tab-content">{userToLogIn ? <LogIn /> : <SignUp />}</div>
    </div>
  );
};

AuthTabs.propTypes = {
  userToLogIn: PropTypes.bool,
};

AuthTabs.defaultProps = {
  userToLogIn: false,
};

const mapStateToProps = (state) => {
  return { userToLogIn: selectors.getToLogIn(state) };
};

export default connect(mapStateToProps)(AuthTabs);
