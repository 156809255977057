import description from './description';
import SurveyMonitoringGeotech1 from './elements/surveyMonitoringGeotech_1';
import SurveyMonitoringGeotech2 from './elements/surveyMonitoringGeotech_2';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [39.5, 48.6], width: 6.375 }],
  dancingAnts: [
    ['hs1', [0, 79.88], { direction: 'reverse' }],
    ['hs1', [54.73, 100], { direction: 'reverse' }],
  ],
  tinyBois: [{ id: 'tb1', position: [39.5, 48.6], offset: [5.4, 1] }],
  elements: [new SurveyMonitoringGeotech1([35.4, 22.2]), new SurveyMonitoringGeotech2([51, 42.9])],
};

export default config;
