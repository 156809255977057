import description from './description';
import UndergroundProductionDrilling1 from './elements/undergroundProductionDrilling_1';
import UndergroundProductionDrilling2 from './elements/undergroundProductionDrilling_2';
import UndergroundProductionDrilling3 from './elements/undergroundProductionDrilling_3';
import UndergroundProductionDrilling4 from './elements/undergroundProductionDrilling_4';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [63.15, 17], width: 6.375 }],
  dancingAnts: [
    /* ['hs1', [0, 48.3], { direction: 'reverse' }] */
  ],
  tinyBois: [
    { id: 'tb1', position: [63.15, 17], offset: [6.375, 3.2] },
    { id: 'tb2', position: [63.15, 17], offset: [4.8, 5.7] },
    { id: 'tb3', position: [63.15, 17], offset: [3.2, 6.2] },
  ],

  elements: [
    new UndergroundProductionDrilling1([14.3, 17], { size: 'wide-l' }),
    new UndergroundProductionDrilling2([76, 26], { target: 'tb1' }),
    new UndergroundProductionDrilling3([76, 45.4], { target: 'tb2' }),
    new UndergroundProductionDrilling4([76, 64.2], { target: 'tb3' }),
  ],
};

export default config;
