import description from './description';
import PlanningInitial1 from './elements/planningInitial_1';
import PlanningInitial2 from './elements/planningInitial_2';
import PlanningInitial3 from './elements/planningInitial_3';
import PlanningInitial4 from './elements/planningInitial_4';
import PlanningInitial5 from './elements/planningInitial_5';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [29.5, 46.25], width: 6.875, rotation: [60, 0] }],
  dancingAnts: [['hs1', [0, 32.03]]],
  tinyBois: [
    { id: 'tb1', position: [29.5, 46.25], offset: [0.6, 4.3] },
    { id: 'tb2', position: [29.5, 46.25], offset: [3, 4.75] },
    { id: 'tb3', position: [29.5, 46.25], offset: [5.8, 4.3] },
    { id: 'tb4', position: [29.5, 46.25], offset: [5.8, 2.1] },
  ],
  elements: [
    new PlanningInitial1([19.58, 15.25]),
    new PlanningInitial2([27.5, 58.25]),
    new PlanningInitial3([51.25, 58.125]),
    new PlanningInitial4([51.25, 45]),
    new PlanningInitial5([59.16, 31.5]),
  ],
};

export default config;
