export const NAME = 'journeys_manager_journey';
export const JOURNEYS_MANAGER_JOURNEY_CLEAR_STORE = `${NAME}/clear_store`;
export const JOURNEYS_MANAGER_JOURNEY_CLEAR_DATA = `${NAME}/clear_data`;
export const JOURNEYS_MANAGER_JOURNEY_CLEAR_DUPLICATE = `${NAME}/clear_duplicate`;
export const JOURNEYS_MANAGER_JOURNEY_GET = `${NAME}/get`;
export const JOURNEYS_MANAGER_JOURNEY_CREATE = `${NAME}/create`;
export const JOURNEYS_MANAGER_JOURNEY_UPDATE = `${NAME}/update`;
export const JOURNEYS_MANAGER_JOURNEY_DELETE = `${NAME}/delete`;
export const JOURNEYS_MANAGER_JOURNEY_DUPLICATE = `${NAME}/duplicate`;

export const STATUS = 'status';
