import StageCard from 'modules/common/components/StageCard';

const description = {
  id: 5,
  currentNumber: 1,
  totalNumber: 5,
  title: 'safety.Safety',
  slug: 'introduction',
  leadLayoutKey: 'safety-introduction',
  apiSlug: 'safety-introduction',
  description: 'safety.description',
  video: {
    backgroundImage: 'Vehicle_Collision_Avoidance.jpg',
  },
  drawPoints: {
    hotspots: 0.5,
    dancingAnts: 0.5,
    secondaries: 0.5,
  },
  hotspots: [],
  dancingAnts: [],
  tinyBois: [],
  elements: [{ id: 'safetyIntroduction_1', component: StageCard }],
};

export default description;
