// @ts-nocheck
const globalAny = global;

export const getFromLS = (key) => {
  if (globalAny.localStorage) {
    try {
      return JSON.parse(globalAny.localStorage.getItem(key)) || null;
    } catch (e) {
      console.error(e);
    }
  }

  return null;
};

export const saveToLS = (key, value) => {
  if (globalAny.localStorage) {
    globalAny.localStorage.setItem(key, JSON.stringify(value));

    return {
      key: value,
    };
  }

  return null;
};

export const removeFromLS = (key) => {
  if (globalAny.localStorage) {
    try {
      globalAny.localStorage.removeItem(key);
    } catch (e) {
      console.error(e);
    }
  }
};
