/* eslint-disable no-empty */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
// @ts-nocheck

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { isTablet } from 'react-device-detect';

import * as selectors from 'app/selectors';

import actions from 'app/actions';

import { connect } from 'react-redux';

import Button from 'modules/common/components/Button';
import { sendGTEvent } from 'modules/analytics/services/googleAnalytics';
import { withTranslation } from 'react-i18next';

class ModalDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.videoRef = React.createRef();

    this.state = {
      fullyViewed: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { content } = this.props;

    if (prevProps?.content?.vid !== content?.vid) {
      this.setState({ fullyViewed: false });
    }
  }

  closeBtn = () => {
    const { config, setIsModalOpen, setModalContent, content } = this.props;
    const { fullyViewed } = this.state;

    let timing = '';

    if (this.videoRef?.current) {
      if (!fullyViewed) {
        const { currentTime } = this.videoRef.current;
        timing = `${currentTime}sec`;

        sendGTEvent({
          event: 'video_modal',
          modal: content.title,
          location: `${content.locationName}`,
          viewed: timing,
        });
      }
    } else {
      const eventText = `image_${content?.title}_viewed`;
      sendGTEvent({ event: eventText });
    }

    setIsModalOpen({ isOpen: false });

    window.setTimeout(() => {
      setModalContent({ content: null });
    }, config.animationDuration * 1000);
  };

  fullyViewed = () => {
    const { content } = this.props;
    this.setState({ fullyViewed: true });
    sendGTEvent({
      event: 'video_modal',
      modal: content.title,
      location: `${content.locationName}`,
      viewed: 'full',
    });
  };

  render() {
    const { config, isOpen, content, t, i18n } = this.props;

    // TODO: REPLACE! ONLY FOR DEMO!

    let showVideo = false;
    let videoURL = null;
    let showImg = false;
    let body = null;

    if (Object.prototype.toString.call(content) === '[object Object]') {
      if (content.vid) {
        try {
          videoURL = content && `/media/${i18n.language}/videos/modal/${content.vid}`;
          showVideo = true;
        } catch {
          console.log('Error load video', `Can not find video ${content.vid}`);
        }
      } else if (content.src) {
        showImg = true;
      }
    }

    if (showVideo) {
      body = (
        <video
          ref={this.videoRef}
          controls
          autoPlay
          preload="auto"
          src={videoURL}
          height="auto"
          disablePictureInPicture
          onEnded={this.fullyViewed}
        />
      );
    } else if (showImg) {
      body = <img src={content.src} alt={content.title} />;
    } else {
      body = `${t('Can not find content')} ${JSON.stringify(content)}`;
    }

    return (
      <div className={`${config.className} ${isOpen ? config.openClass : ''}`}>
        <div className={`${config.className}__container`}>
          <Button
            size="sm"
            additionalClasses={[
              `${config.className}__close-button`,
              `${isTablet ? 'close-top' : ''}`,
            ]}
            onClick={this.closeBtn}
          >
            <svg className={`${config.className}__close-button-svg`} viewBox="0 0 100 100">
              <line x1="0" x2="100" y1="100" y2="0" />
              <line x1="0" x2="100" y1="0" y2="100" />
            </svg>
          </Button>
          <div className={`${config.className}__content`}>{body}</div>
        </div>
      </div>
    );
  }
}

ModalDialog.propTypes = {
  config: PropTypes.shape({
    className: PropTypes.string,
    openClass: PropTypes.string,
    animationDuration: PropTypes.number,
  }),
  i18n: PropTypes.shape({
    language: PropTypes.string,
  }),
  img: PropTypes.string,
  isOpen: PropTypes.bool,
  content: PropTypes.any,
  setIsModalOpen: PropTypes.func,
  setModalContent: PropTypes.func,
  t: PropTypes.func,
};

ModalDialog.defaultProps = {
  config: {
    className: 'c-video-modal',
    openClass: 'is-open',
    animationDuration: 0.5,
  },
  i18n: {
    // language: 'en',
  },
  img: '',
  isOpen: false,
  content: null,
  setIsModalOpen: () => {},
  setModalContent: () => {},
  t: () => {},
};

const mapStateToProps = (state) => {
  return {
    isOpen: selectors.isOpenModal(state),
    content: selectors.getContentModal(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  setIsModalOpen: (payload) => dispatch(actions.setIsModalOpen({ payload })),
  setModalContent: (payload) => dispatch(actions.setModalContent({ payload })),
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ModalDialog),
);
