const description = {
  id: '3 of 4',
  currentNumber: 3,
  totalNumber: 4,
  title: 'planning.Grade Control.title',
  slug: 'grade-control',
  pdfPath: 'GradeControl',
  leadLayoutKey: 'grade-control',
  apiSlug: 'grade-control',
  productDetailsLink: 'https://hexagon.com/products/hexagon-mineplan-grade-control-geo',
  description: 'planning.Grade Control.description',
  video: {
    name: 'wf_Grade_Control.mp4',
    stop: 9,
    backgroundImage: 'Grade_Control.jpg',
  },
  drawPoints: {
    hotspots: 0.25,
    dancingAnts: 7.5,
    secondaries: 8,
  },
};

export default description;
