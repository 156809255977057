// @ts-nocheck
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ArrowButton from 'modules/common/components/ArrowButton';

import './assets/scss/styles.scss';
import { withTranslation } from 'react-i18next';

class Index extends PureComponent {
  constructor(props) {
    super(props);

    this.pathLabelRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
  }

  componentDidUpdate() {
    this.pathLabelRef.current.style.width = `${Math.ceil(this.pathLabelRef.current.clientWidth)}px`;
  }

  onResize = () => {
    if (!this.pathLabelRef.current) return;

    this.pathLabelRef.current.style.width = null;
    this.pathLabelRef.current.style.width = `${Math.ceil(this.pathLabelRef.current.clientWidth)}px`;
  };

  handleClickPrevious = () => {
    const { onEvent } = this.props;

    onEvent({ action: 'header:navigation:previous' });
  };

  handleClickNext = () => {
    const { onEvent } = this.props;

    onEvent({ action: 'header:navigation:next' });
  };

  render() {
    const {
      config,
      nextTitle,
      openedDeviceRotate,
      disablePrev,
      disableNext,
      pathName,
      pathId,
      showNavigationArrows,
      t,
    } = this.props;

    return (
      <div
        className={`common__header ${config.className}__secondary ${config.visibleClass} ${
          openedDeviceRotate ? 'device-rotate__blur' : ''
        }`}
      >
        <div
          className={`${config.className}__marker ${config.className}__wayfinder ${config.className}__wayfinder--left`}
          ref={this.pathLabelRef}
        >
          <h1 className={`${config.className}__wayfinder-label`}>
            {pathId && <span className={`${config.className}__wayfinder-number`}>{pathId}:</span>}
            {pathName && (
              <span className={`${config.className}__wayfinder-name`}>{t(pathName)}</span>
            )}
          </h1>
        </div>

        {showNavigationArrows && (
          <nav className={`${config.className}__seeker`}>
            <div
              className={`
          ${config.className}__previous-button ${config.className}__wayfinder ${
                config.className
              }__wayfinder--right 
          ${disablePrev ? config.disabledClass : ''}
      `}
              onClick={this.handleClickPrevious}
            >
              <ArrowButton direction="backwards" />
            </div>

            <div
              className={`
          ${config.className}__next-button 
          ${config.className}__wayfinder 
          ${disableNext ? config.disabledClass : ''}
        `}
              onClick={this.handleClickNext}
            >
              <div
                className={`
            ${config.className}__next-location 
            ${config.className}__wayfinder-label
          `}
              >
                <div className={`${config.className}__wayfinder-label`}>
                  <span className={`${config.className}__wayfinder-number`}>{`${t(
                    'common.Next',
                  )}:`}</span>
                  <span className={`${config.className}__wayfinder-name`}>{t(nextTitle)}</span>
                </div>

                <ArrowButton />
              </div>
            </div>
          </nav>
        )}
      </div>
    );
  }
}

Index.propTypes = {
  config: PropTypes.shape({
    className: PropTypes.string,
    visibleClass: PropTypes.string,
    disabledClass: PropTypes.string,
  }),
  openedDeviceRotate: PropTypes.bool,
  pathName: PropTypes.string,
  pathId: PropTypes.string,
  disablePrev: PropTypes.bool,
  disableNext: PropTypes.bool,
  nextTitle: PropTypes.string,
  showNavigationArrows: PropTypes.bool,
  onEvent: PropTypes.func,
  t: PropTypes.func,
};

Index.defaultProps = {
  config: {
    className: 'header',
    visibleClass: 'is-visible',
    disabledClass: 'is-disabled',
  },
  openedDeviceRotate: false,
  pathName: '',
  pathId: '',
  disablePrev: true,
  disableNext: true,
  nextTitle: '',
  // nextLabel: '',
  showNavigationArrows: false,
  onEvent: () => {},
  t: () => {},
};

Index.id = 'HeaderNavigation';

export default withTranslation()(connect(null, null, null, { forwardRef: true })(Index));
