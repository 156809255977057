import description from './description';
import OperatorAssist1 from './elements/OperatorAssist1';
import OperatorAssist2 from './elements/OperatorAssist2';
import OperatorAssist3 from './elements/OperatorAssist3';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [63, 70.5], width: 14.75, rotation: [55, 0] }],
  dancingAnts: [['hs1', [0, 83], { direction: 'reverse' }]],
  tinyBois: [
    { id: 'tb1', position: [63, 70.5], offset: [11, 3.2] },
    { id: 'tb2', position: [63, 70.5], offset: [11, 10] },
  ],
  elements: [
    new OperatorAssist1([66.7, 24.3], { cornerFrom: ['bottom', 'left'] }),
    new OperatorAssist2([82.9, 56.5]),
    new OperatorAssist3([82.9, 70.1]),
  ],
};

export default config;
