import Box from 'modules/common/components/Box';

export default class SurveyMonitoringMonitoring4 {
  constructor(position) {
    this.id = 'surveyMonitoringMonitoring_4';
    this.component = Box;
    this.type = 'content';
    this.title = 'survey-monitoring.Monitoring.elements.Critical Slope Monitoring';
    this.content = 'survey-monitoring.Monitoring.elements.Critical Slope Monitoring Content';
    this.props = {
      target: 'tb3',
      position,
      hashtags: ['digitalisation', 'healthSafety'],
      cornerFrom: ['top', 'left'],
    };
  }
}
