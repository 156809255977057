import description from './description';
import DrillBlastBlastDesign1 from './elements/drillBlastBlastDesign_1';
import DrillBlastBlastDesign2 from './elements/drillBlastBlastDesign_2';
import DrillBlastBlastDesign3 from './elements/drillBlastBlastDesign_3';
import DrillBlastBlastDesign4 from './elements/drillBlastBlastDesign_4';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [18, 42], width: 6.375 }],
  dancingAnts: [
    ['hs1', [0, 21.28]],
    ['hs1', [0, 93.55], { direction: 'reverse' }],
    ['hs1', [48.33, 0]],
  ],
  tinyBois: [
    { id: 'tb1', position: [18, 42], offset: [5.6, 1.2] },
    { id: 'tb2', position: [18, 42], offset: [0.2, 4.5] },
    { id: 'tb3', position: [18, 42], offset: [5.6, 4.5] },
  ],
  elements: [
    new DrillBlastBlastDesign1([11.66, 15.85]),
    new DrillBlastBlastDesign2([43.33, 30.12]),
    new DrillBlastBlastDesign3([11.66, 57.37]),
    new DrillBlastBlastDesign4([43.33, 45.62]),
  ],
};

export default config;
