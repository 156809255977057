import Box from 'modules/common/components/Box';

export default class Insights2 {
  constructor(position) {
    this.id = 'Insights2';
    this.component = Box;
    this.type = 'content';
    this.title = 'evaluationPlanningDesign.Insights.elements.From the field to the cloud';
    this.content = 'evaluationPlanningDesign.Insights.elements.From the field to the cloud Content';
    this.props = {
      target: 'hs1',
      position,
      cornerFrom: ['bottom', 'left'],
      hashtags: ['integration'],
    };
  }
}
