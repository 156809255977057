import Box from 'modules/common/components/Box';

export default class PlanningInitial2 {
  constructor(position, props = {}) {
    this.id = 'planningInitial_2';
    this.component = Box;
    this.title = 'planning.Capture Visualise Design.elements.Mineplan';
    this.content = '';
    this.img = '';
    this.video = 'md_Mineplan.mp4';
    this.props = {
      target: 'tb1',
      position,
      hashtags: ['integration'],
      cornerFrom: props.cornerFrom || null,
    };
  }
}
