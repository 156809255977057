import Box from 'modules/common/components/Box';

export default class SafetyInsights3 {
  constructor(position, props = {}) {
    this.id = 'SafetyInsights_3';
    this.component = Box;
    this.title = 'safety.SafetyInsights.elements.SAFETY ANALYTICS';
    this.video = 'md_Safety_Analytics.mp4';
    this.props = {
      target: 'tb1',
      position,
      cornerFrom: props.cornerFrom || null,
      hashtags: ['healthSafety', 'dataAnalytics'],
    };
  }
}
