import configAPP from 'app/configs';
import HttpService from 'core/services/http/Http';
import localJourneysManager from 'core/services/localJourneysManager';

class Api {
  // eslint-disable-next-line class-methods-use-this
  async getMyJourneys(params) {
    if (configAPP.enableJourneysForExhibition) {
      return localJourneysManager.getMyJourneys();
    }

    const response = await HttpService.get('api/v1/journeys', { params });

    return response;
  }
}

export default Object.freeze(new Api());
