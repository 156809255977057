/* eslint-disable consistent-return */
/* eslint-disable no-await-in-loop */
/* eslint-disable dot-notation */
// @ts-nocheck
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-plusplus */
import { getFromLS } from 'core/services/localStorage';
import { isLocalhost } from 'serviceWorkerRegistration';

class CollateralService {
  constructor() {
    // console.log('CacheService:constructor');
    this.currentIndex = -1;
    this.files = null;
    this.timeoutId = null;

    const localStorageLang = getFromLS('CacheServiceLang');
    this.language = localStorageLang?.code || 'en';
  }

  async getCollateralFiles(language = 'en') {
    try {
      const response = await fetch(
        `/media/${language}/collateral.json${!isLocalhost ? `?id=${Date.now()}` : ''}`,
        {
          headers: { 'Content-Type': 'application/json' },
        },
      );

      const filesResponse = await response.json();

      return filesResponse;
    } catch (e) {
      if (getFromLS('pwa') === 'pwa') {
        const response = await fetch(`/media/${language}/collateral.json`, {
          headers: { 'Content-Type': 'application/json' },
        });

        const filesResponse = await response.json();

        return filesResponse;
      }

      throw e;
    }
  }
}

const objectCollateralService = new CollateralService();
export default objectCollateralService;
