const description = {
  id: '5 of 5 survey-monitoring-insights',
  currentNumber: 5,
  totalNumber: 5,
  title: 'survey-monitoring.SurveyMonitoringInsights.title',
  slug: 'survey-monitoring-insights',
  pdfPath: 'Survey&MonitoringInsights',
  leadLayoutKey: 'survey-monitoring-insights',
  apiSlug: 'survey-monitoring-insights',
  productDetailsLink: 'https://hexagon.com/products/product-groups/hxgn-minemonitoring',
  description: 'survey-monitoring.SurveyMonitoringInsights.description',
  video: {
    backgroundImage: 'Survey_And_Monitoring_Insights.jpg',
    playDefaultSound: true,
    backgroundImageDestroyTime: 1.9,
  },
  drawPoints: {
    hotspots: 1,
    dancingAnts: 1.5,
    secondaries: 2,
  },
};

export default description;
