import Box from 'modules/common/components/Box';

export default class SurveyMonitoringRealityCapture4 {
  constructor(position) {
    this.id = 'surveyMonitoringRealityCapture_4';
    this.component = Box;
    this.title = 'survey-monitoring.Reality Capture.elements.Mobile Agile 3D Reality Capture';
    this.content = '';
    this.img = '';
    this.video = 'md_Mobile_Agile_3D_Reality_Capture.mp4';
    this.props = {
      target: 'tb3',
      position,
      hashtags: ['digitalisation', 'productivity'],
    };
  }
}
