import Box from 'modules/common/components/Box';

export default class MaterialMovementSlopeMonitoring4 {
  constructor(position) {
    this.id = 'materialMovementSlopeMonitoring_4';
    this.component = Box;
    this.type = 'content';
    this.title = 'safety.Slope Monitoring.elements.Detailed Monitoring';
    this.content = 'safety.Slope Monitoring.elements.Detailed Monitoring Content';
    this.props = {
      target: 'tb3',
      position,
      cornerFrom: ['top', 'left'],
      hashtags: ['healthSafety', 'esg'],
    };
  }
}
