import description from './description';
import AssetHealth1 from './elements/AssetHealth1';
import AssetHealth2 from './elements/AssetHealth2';
import AssetHealth3 from './elements/AssetHealth3';
import AssetHealth4 from './elements/AssetHealth4';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [9, 47.3], width: 10 }],
  dancingAnts: [['hs1', [0, 22.64], { direction: 'reverse' }]],
  tinyBois: [
    { id: 'tb1', position: [9, 47.3], offset: [8.8, 2.3] },
    { id: 'tb2', position: [9, 47.3], offset: [4.5, 9.3] },
  ],
  elements: [
    new AssetHealth1([12, 19.9]),
    new AssetHealth2([19.8, 46.2]),
    new AssetHealth3([12, 60]),
    new AssetHealth4([26.5, 60]),
  ],
};

export default config;
