/* eslint-disable import/prefer-default-export */
import store from 'app/store';
import actions from 'app/actions';
import { getFromLS, removeFromLS, saveToLS } from '../../core/services/localStorage';

export const userForm = {
  email: '',
  name: '',
  company: '',
  interest: {
    cost: false,
    productivity: false,
    digitalisation: false,
    dataAnalytics: false,
    integration: false,
    autonomy: false,
    healthSafety: false,
    esg: false,
  },
  gdpr: false,
};

export const toggleUserToLogIn = (value) => {
  store.dispatch(actions.setToLogIn({ payload: value }));
};

export const toggleUserIsLoggedIn = (value, userData) => {
  saveToLS('isUserLoggedIn', value);

  saveToLS('loggedUser', userData);

  store.dispatch(actions.setLoggedUser({ payload: userData }));
  store.dispatch(actions.setIsUserLoggedIn({ payload: value }));
};

export const updateInterests = (newInterests) => {
  const user = getFromLS('user');
  const prevInterests = user?.interest ? user?.interest : {};
  const updatedUser = { ...user, interest: { ...prevInterests, ...newInterests } };
  saveToLS('user', updatedUser);
  saveToLS('loggedUser', updatedUser);
  store.dispatch(actions.setLoggedUser({ payload: updatedUser }));
};

export const logout = () => {
  saveToLS('isUserLoggedIn', false);
  removeFromLS('loggedUser');
  store.dispatch(actions.setLoggedUser({ payload: null }));
  store.dispatch(actions.setIsUserLoggedIn({ payload: false }));
};

export const signUp = (formData) => {
  const userData = { ...userForm, ...formData };
  saveToLS('user', userData);
  toggleUserIsLoggedIn(true, userData);
};
