import description from './description';
import SurveyMonitoringPlanning1 from './elements/surveyMonitoringPlanning_1';
import SurveyMonitoringPlanning2 from './elements/surveyMonitoringPlanning_2';
import SurveyMonitoringPlanning3 from './elements/surveyMonitoringPlanning_3';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [8.2, 44], width: 6.375 }],
  dancingAnts: [
    ['hs1', [48.12, 0]],
    ['hs1', [100, 66.69], { direction: 'reverse' }],
  ],
  tinyBois: [
    { id: 'tb1', position: [8.2, 44], offset: [5.4, 1] },
    { id: 'tb2', position: [8.2, 44], offset: [5.4, 4.9] },
  ],
  elements: [
    new SurveyMonitoringPlanning1([4.2, 21.7]),
    new SurveyMonitoringPlanning2([19.8, 44.2]),
    new SurveyMonitoringPlanning3([27.6, 57.1]),
  ],
};

export default config;
