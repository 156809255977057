import description from './description';
import PlanningInitial1 from './elements/planningInitial_1';
import PlanningInitial2 from './elements/planningInitial_2';
import PlanningInitial3 from './elements/planningInitial_3';
import PlanningInitial4 from './elements/planningInitial_4';
import PlanningInitial5 from './elements/planningInitial_5';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [21.1, 43.5], width: 6.875, rotation: [60, 0] }],
  dancingAnts: [['hs1', [0, 32.03]]],
  tinyBois: [
    { id: 'tb1', position: [21.1, 43.5], offset: [0.6, 4.3] },
    { id: 'tb2', position: [21.1, 43.5], offset: [3, 4.75] },
    { id: 'tb3', position: [21.1, 43.5], offset: [5.8, 4.3] },
    { id: 'tb4', position: [21.1, 43.5], offset: [4.8, 1.7] },
  ],
  elements: [
    new PlanningInitial1([20.21, 17.18], { size: 'wide' }),
    new PlanningInitial2([20.21, 58.72]),
    new PlanningInitial3([43.65, 56.25]),
    new PlanningInitial4([43.65, 43.5]),
    new PlanningInitial5([59.27, 30.34]),
  ],
};

export default config;
