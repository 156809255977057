import Box from 'modules/common/components/Box';

export default class SchedulingOptimising5 {
  constructor(position) {
    this.id = 'SchedulingOptimising5';
    this.component = Box;
    this.title = 'load-haul.Scheduling Optimising.elements.Schedule OPTIMISER';
    this.img = '';
    this.video = 'md_Schedule_Optimizer.mp4';
    this.props = {
      target: 'tb4',
      position,
      hashtags: ['cost', 'productivity'],
    };
  }
}
