import description from './description';
import MaterialMovementSlopeMonitoring1 from './elements/materialMovementSlopeMonitoring_1';
import MaterialMovementSlopeMonitoring2 from './elements/materialMovementSlopeMonitoring_2';
import MaterialMovementSlopeMonitoring3 from './elements/materialMovementSlopeMonitoring_3';
import MaterialMovementSlopeMonitoring4 from './elements/materialMovementSlopeMonitoring_4';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [16, 53], width: 13.625, rotation: [60, 0] }],
  dancingAnts: [
    ['hs1', [0, 20.11], { direction: 'reverse' }],
    ['hs1', [0, 87.5], { direction: 'reverse' }],
  ],
  tinyBois: [
    { id: 'tb1', position: [16, 53], offset: [0.8, 8.6] },
    { id: 'tb2', position: [16, 53], offset: [11, 8.6] },
    { id: 'tb3', position: [16, 53], offset: [11, 3.6] },
  ],
  elements: [
    new MaterialMovementSlopeMonitoring1([20, 22]),
    new MaterialMovementSlopeMonitoring2([12, 68.2]),
    new MaterialMovementSlopeMonitoring3([27.6, 56.1], { cornerFrom: ['top', 'left'] }),
    new MaterialMovementSlopeMonitoring4([27.6, 43.5]),
  ],
};

export default config;
