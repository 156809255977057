/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/button-has-type */
import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { withTranslation } from 'react-i18next';

import LocationSvg from './assets/icons/location.svg';

import './assets/styles/ButtonSiteMap.scss';

class ButtonSiteMap extends PureComponent {
  componentDidUpdate(prevProps) {
    const { language } = this.props;
    if (language !== prevProps.language) {
      this.forceUpdate();
    }
  }

  handleClick = () => {
    const { onClick } = this.props;
    onClick({ action: 'openSiteMap' });
  };

  render() {
    const { t } = this.props;

    return (
      <div className="c-sitemap">
        <button className="c-button c-button--size-default" onClick={this.handleClick}>
          <div className="c-button__text">
            <div className="c-button__icon">
              <LocationSvg />
            </div>
            <span>{t('Site Map')}</span>
          </div>
        </button>
      </div>
    );
  }
}

ButtonSiteMap.propTypes = {
  onClick: PropTypes.func,
  t: PropTypes.func,
  language: PropTypes.any,
};

ButtonSiteMap.defaultProps = {
  onClick: () => {},
  t: () => {},
  language: null,
};

export default connect(null, null, null, { forwardRef: true })(withTranslation()(ButtonSiteMap));
