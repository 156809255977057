import Box from 'modules/common/components/Box';

export default class DrillBlastBlastMovement2 {
  constructor(position) {
    this.id = 'drillBlastBlastMovement_2';
    this.component = Box;
    this.title = 'drill-blast.Blast Movement.elements.Post-Blast Ore Movement';
    this.video = 'md_Post_Blast_ORE_Movement.mp4';
    this.props = { target: 'tb1', position, hashtags: ['productivity', 'digitalisation'] };
  }
}
