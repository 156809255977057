import description from './description';
import SurveyMonitoringRealityCapture1 from './elements/surveyMonitoringRealityCapture_1';
import SurveyMonitoringRealityCapture2 from './elements/surveyMonitoringRealityCapture_2';
import SurveyMonitoringRealityCapture3 from './elements/surveyMonitoringRealityCapture_3';
import SurveyMonitoringRealityCapture4 from './elements/surveyMonitoringRealityCapture_4';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [63, 44.1], width: 6.375 }],
  dancingAnts: [['hs1', [47.76, 0], { direction: 'reverse' }]],
  tinyBois: [
    { id: 'tb1', position: [63, 44.1], offset: [3, 6.3] },
    { id: 'tb2', position: [63, 44.1], offset: [5.4, 1] },
    { id: 'tb3', position: [63, 44.1], offset: [5.4, 4.9] },
  ],
  elements: [
    new SurveyMonitoringRealityCapture1([58.8, 21.6]),
    new SurveyMonitoringRealityCapture2([58.8, 60.8], { cornerFrom: ['top', 'left'] }), // 3D Reality Capture video
    new SurveyMonitoringRealityCapture3([74.55, 44.2]), // 3D Reality Capture Content
    new SurveyMonitoringRealityCapture4([74.5, 56.8]), // Mobile Agile 3D Reality Capture video
  ],
};

export default config;
