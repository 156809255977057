const description = {
  id: '1 of 5 mine-map',
  currentNumber: 1,
  totalNumber: 5,
  code: 1,
  title: 'load-haul.Scheduling Optimising.title',
  slug: 'scheduling-optimising',
  pdfPath: 'Scheduling&Optimising',
  leadLayoutKey: 'scheduling-optimising',
  apiSlug: 'scheduling-optimising',
  productDetailsLink:
    'https://hexagon.com/products/product-groups/hxgn-mineplan/open-pit-engineering',
  description: 'load-haul.Scheduling Optimising.description',
  video: {
    name: 'wf_Scheduling_And_Optimizing.mp4',
    stop: 7.5,
    backgroundImage: 'Scheduling_And_Optimizing.jpg',
  },
  drawPoints: {
    hotspots: 1,
    dancingAnts: 8.5,
    secondaries: 9,
  },
};

export default description;
