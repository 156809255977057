import Introduction from 'modules/lead-journeys/configs/config1024';
import description from './description';

const config = {
  ...description,
  dimensions: { width: 1920, height: 1080 },
  info: Introduction,
  locations: [],
  activeLocation: null,
};

export default config;
