/* eslint-disable react/prop-types */
/* eslint-disable react/button-has-type */
/* eslint-disable dot-notation */
/* eslint-disable react/destructuring-assignment,no-useless-escape,jsx-a11y/label-has-associated-control */
import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import './assets/scss/index.scss';
import { withTranslation } from 'react-i18next';

class LocationListElement extends PureComponent {
  render() {
    const { name, pathName, showPathName, elIndex, t } = this.props;

    return (
      <div className="location-list-element">
        <div className="location-list-element__index">{elIndex}.</div>
        <div className="location-list-element__content">
          <div className="location-list-element__name">{t(name)}</div>
          {showPathName && <div className="location-list-element__path-name">{t(pathName)}</div>}
        </div>
      </div>
    );
  }
}

LocationListElement.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  name: PropTypes.string,
  pathName: PropTypes.string,
  elIndex: PropTypes.number,
  showPathName: PropTypes.bool,
  t: PropTypes.func,
};

LocationListElement.defaultProps = {
  name: null,
  pathName: null,
  elIndex: null,
  showPathName: false,
  t: () => {},
};

export default withTranslation()(
  connect(null, null, null, { forwardRef: true })(LocationListElement),
);
