// @ts-nocheck
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/button-has-type,no-nested-ternary */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ERROR } from 'core/constants/statuses';

import * as selectors from 'app/selectors';

import './assets/styles.scss';

import Delete from './Delete';
import DeleteProgress from './DeleteProgress';
import DeleteSuccess from './DeleteSuccess';
import DeleteError from './DeleteError';

const config = {
  className: 'c-download-app-modal',
  openClass: 'is-open',
};

// eslint-disable-next-line react/prop-types,no-unused-vars
const ConfirmDeleteInfo = ({ opened, status, progress, handleCancel, handleConfirm }) => {
  const handleClose = () => {
    handleCancel();
  };

  const handleDelete = () => {
    handleConfirm();
  };

  const getState = () => {
    if (status === ERROR) {
      return <DeleteError handleClose={handleClose} />;
    }

    if (progress === 100) {
      return <DeleteSuccess handleClose={handleClose} />;
    }

    if (progress > 0 && progress < 100) {
      return <DeleteProgress />;
    }

    return <Delete handleClose={handleClose} handleConfirm={handleDelete} />;
  };

  return (
    <div className={`${config.className} ${opened ? config.openClass : ''}`}>
      <div className={`${config.className}__container`}>
        <div className={`${config.className}__content`}>{getState()}</div>
      </div>
    </div>
  );
};

ConfirmDeleteInfo.propTypes = {
  opened: PropTypes.bool,
  status: PropTypes.string,
  progress: PropTypes.number,
};

ConfirmDeleteInfo.defaultProps = {
  opened: false,
  status: null,
  progress: 0,
};

const mapStateToProps = (state) => {
  const opened = selectors.isOpenPwaConfirmDelete(state);
  const status = selectors.getPwaConfirmDeleteStatus(state);
  const progress = selectors.getPwaConfirmDeleteProgress(state);

  return {
    opened,
    status,
    progress,
  };
};

export default connect(mapStateToProps)(ConfirmDeleteInfo);
