import Box from 'modules/common/components/Box';

export default class UndergroundMining2 {
  constructor(position) {
    this.id = 'undergroundMining_2';
    this.component = Box;
    this.type = 'content';
    this.title = 'underground.Safety.elements.Integration';
    this.content = 'underground.Safety.elements.Integration Content';
    this.props = {
      target: 'tb1',
      position,
      hashtags: ['integration', 'healthSafety'],
    };
  }
}
