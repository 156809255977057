// fixme: this is must be generated according to config
export default [
  {
    id: 0,
    name: 'evaluationPlanningDesign.EvaluationPlanningDesign',
    slug: 'evaluation-planning-design',
    icon: 'side-icon.png',
    description: 'evaluationPlanningDesign.workflowDescription',
  },
  {
    id: 1,
    name: 'planning.Planning',
    slug: 'planning',
    icon: 'side-icon.png',
    description: 'planning.workflowDescription',
  },
  {
    id: 2,
    name: 'drill-blast.Drill Blast',
    slug: 'drill-blast',
    icon: 'side-icon.png',
    description: 'drill-blast.workflowDescription',
  },
  {
    id: 3,
    name: 'load-haul.Load & Haul',
    slug: 'load-haul',
    icon: 'side-icon.png',
    description: 'load-haul.workflowDescription',
  },
  {
    id: 4,
    name: 'safety.Safety',
    slug: 'safety',
    icon: 'side-icon.png',
    description: 'safety.workflowDescription',
  },
  {
    id: 5,
    name: 'survey-monitoring.Survey Monitoring',
    slug: 'survey-monitoring',
    icon: 'side-icon.png',
    description: 'survey-monitoring.workflowDescription',
  },
  {
    id: 6,
    name: 'insights.Insights',
    slug: 'insights',
    icon: 'side-icon.png',
    description: 'insights.workflowDescription',
  },
];
