import description from './description';
import DrillBlastDrilling1 from './elements/drillBlastDrilling_1';
import DrillBlastDrilling2 from './elements/drillBlastDrilling_2';
import DrillBlastDrilling3 from './elements/drillBlastDrilling_3';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [15.5, 57.5], width: 14.5, rotation: [54, 0] }],
  dancingAnts: [
    ['hs1', [0, 18.35], { direction: 'reverse' }],
    ['hs1', [0, 81.93]],
  ],
  tinyBois: [
    { id: 'tb1', position: [15.5, 57.5], offset: [10.5, 3] },
    { id: 'tb2', position: [15.5, 57.5], offset: [14, 6] },
  ],
  elements: [
    new DrillBlastDrilling1([11.9, 19.9]),
    new DrillBlastDrilling2([27.6, 48.6]),
    new DrillBlastDrilling3([27.6, 63.1]),
  ],
};

export default config;
