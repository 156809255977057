const description = {
  id: '4 of 5',
  currentNumber: 4,
  totalNumber: 5,
  title: 'evaluationPlanningDesign.Rock Characterisation.title',
  slug: 'rock-characterisation',
  pdfPath: 'RockCharacterisation',
  leadLayoutKey: 'rock-characterisation',
  apiSlug: 'rock-characterisation',
  productDetailsLink: 'https://hexagon.com/products/hexagon-mineplan-resource-geo',
  description: 'evaluationPlanningDesign.Rock Characterisation.description',
  video: {
    name: 'wf_Rock_Characterization.mp4',
    stop: 3,
    backgroundImage: 'Rock_Characterization.jpg',
  },
  drawPoints: {
    hotspots: 0.25,
    dancingAnts: 4.5,
    secondaries: 5,
  },
};

export default description;
