const description = {
  id: '5 of 7',
  currentNumber: 5,
  totalNumber: 7,
  title: 'drill-blast.Blast Movement.title',
  slug: 'blast-movement',
  pdfPath: 'BlastMovement',
  leadLayoutKey: 'blast-movement',
  apiSlug: 'blast-movement',
  productDetailsLink: 'https://hexagon.com/products/hexagon-blast-movement-monitoring',
  description: 'drill-blast.Blast Movement.description',
  video: {
    name: 'wf_Blast_Movement.mp4',
    stop: 10.5,
    backgroundImage: 'Blast_Movement.jpg',
  },
  drawPoints: {
    hotspots: 0.01,
    dancingAnts: 11,
    secondaries: 11.5,
  },
};

export default description;
