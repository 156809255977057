import { createSelector } from 'reselect';

import { NAME } from 'modules/lead/constants';

import { ERROR } from 'core/constants/statuses';

// info: https://redux-toolkit.js.org/api/createSelector
const getInitialState = (state) => {
  return state[NAME].ready;
};

export const isReady = createSelector(getInitialState, (ready) => ready);

const isErrorProp = (state) => {
  return state[NAME].status;
};

export const isError = createSelector(isErrorProp, (status) => status === ERROR);

const getJourneyProp = (state) => {
  return state[NAME].journey;
};

export const getJourney = createSelector(getJourneyProp, (journey) => journey);

export default {
  isReady,
  isError,
  getJourney,
};
