import description from './description';
import MaterialMovementFatigueAndDistraction1 from './elements/materialMovementFatigueAndDistraction_1';
import MaterialMovementFatigueAndDistraction2 from './elements/materialMovementFatigueAndDistraction_2';
import MaterialMovementFatigueAndDistraction3 from './elements/materialMovementFatigueAndDistraction_3';
import MaterialMovementFatigueAndDistraction4 from './elements/materialMovementFatigueAndDistraction_4';
import MaterialMovementFatigueAndDistraction5 from './elements/materialMovementFatigueAndDistraction_5';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [16.1, 50], width: 12.75 }],
  dancingAnts: [
    ['hs1', [0, 30.27]],
    ['hs1', [0, 89.8], { direction: 'reverse' }],
  ],
  tinyBois: [
    { id: 'tb1', position: [16.1, 50], offset: [11.6, 9] },
    { id: 'tb2', position: [16.1, 50], offset: [11.5, 2.6] },
    { id: 'tb3', position: [16.1, 50], offset: [6, 12.3] },
    { id: 'tb4', position: [16.1, 50], offset: [0.3, 9] },
  ],
  elements: [
    new MaterialMovementFatigueAndDistraction1([12, 19.9]),
    new MaterialMovementFatigueAndDistraction2([12, 83]),
    new MaterialMovementFatigueAndDistraction3([35.6, 49.76], {
      cornerFrom: ['top', 'left'],
      target: 'tb2',
    }),
    new MaterialMovementFatigueAndDistraction4([35.6, 67.7], { target: 'tb1' }),
    new MaterialMovementFatigueAndDistraction5([35.6, 83], { target: 'tb3' }),
  ],
};

export default config;
