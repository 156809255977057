const description = {
  id: '1 of 9 capture-visualise-design',
  currentNumber: 1,
  totalNumber: 9,
  code: 1,
  title: 'planning.Capture Visualise Design.title',
  slug: 'capture-visualise-design',
  pdfPath: 'CaptureVisualiseDesign',
  leadLayoutKey: 'capture-visualise-design',
  apiSlug: 'capture-visualise-design',
  productDetailsLink:
    'https://hexagon.com/products/product-groups/hxgn-mineplan/open-pit-engineering',
  description: 'planning.Capture Visualise Design.description',
  video: {
    name: 'wf_Capture_Vizualize_Design.mp4',
    stop: 6,
    backgroundImage: 'Capture_Vizualize_Design.jpg',
  },
  drawPoints: {
    hotspots: 2,
    dancingAnts: 5.5,
    secondaries: 6,
  },
};

export default description;
