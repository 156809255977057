import Box from 'modules/common/components/Box';

export default class UndergroundProductionDrilling4 {
  constructor(position, props = {}) {
    this.id = 'undergroundProductionDrilling_4';
    this.component = Box;
    this.title = 'underground.Production Drilling.elements.Production Optimiser';
    this.video = 'md_Production_Optimiser.mp4';
    this.props = {
      target: props.target || 'tb3',
      position,
      hashtags: ['productivity'],
    };
  }
}
