import description from './description';
import MarkerClass from './elements/MarkerClass';
import Content from './elements/Content';
import Video from './elements/Video';
// import ContentOne from './elements/ContentOne';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [15.5, 43], width: 6.375 }],
  dancingAnts: [
    ['hs1', [0, 33.98], { direction: 'reverse' }],
    ['hs1', [50.57, 0]],
  ],
  tinyBois: [
    { id: 'tb1', position: [15.5, 43], offset: [5.6, 1.2] },
    { id: 'tb2', position: [15.5, 43], offset: [2.9, 6.1] },
    // { id: 'tb3', position: [15.5, 43], offset: [5.6, 4.8] },
  ],
  elements: [
    new MarkerClass([13.5, 15.5]),
    new Content([37, 42.57]),
    new Video([13.5, 60.28]),
    // new ContentOne([37, 58.62]),
  ],
};

export default config;
