import description from './description';
import DrillBlastBlastInsights1 from './elements/drillBlastBlastInsights_1';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [47.8, 51.5], width: 7.6, rotation: [62, 0] }], // 56.44
  dancingAnts: [
    ['hs1', [50.57, 0]],
    ['hs1', [55, 0], { direction: 'reverse' }],
    ['hs1', [100, 4.78]],
    ['hs1', [100, 14.35], { direction: 'reverse' }],
  ],
  tinyBois: [],
  elements: [new DrillBlastBlastInsights1([11.75, 17.25])],
};

export default config;
