/* eslint-disable no-shadow */
/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './assets/scss/index.scss';

import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfViewer = ({ currentFilePath, zoom }) => {
  const [numPages, setNumPages] = useState(null);

  // const [pageNumber, setPageNumber] = useState(1);

  // const goToPrevPage = () => setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

  // const goToNextPage = () => setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  return (
    <>
      <Document file={{ url: currentFilePath }} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from(new Array(numPages), (el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} scale={zoom} />
        ))}
      </Document>
    </>
  );
};

PdfViewer.propTypes = {
  currentFilePath: PropTypes.string,
  zoom: PropTypes.number,
};

PdfViewer.defaultProps = {
  currentFilePath: '',
  zoom: 1,
};

export default PdfViewer;
