import description from './description';
import MaterialMovementSlopeMonitoring1 from './elements/materialMovementSlopeMonitoring_1';
import MaterialMovementSlopeMonitoring2 from './elements/materialMovementSlopeMonitoring_2';
import MaterialMovementSlopeMonitoring3 from './elements/materialMovementSlopeMonitoring_3';
import MaterialMovementSlopeMonitoring4 from './elements/materialMovementSlopeMonitoring_4';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [15.9, 44.6], width: 6.815, rotation: [60, 0] }],
  dancingAnts: [
    ['hs1', [0, 20.11], { direction: 'reverse' }],
    ['hs1', [0, 87.5], { direction: 'reverse' }],
  ],
  tinyBois: [
    { id: 'tb1', position: [15.9, 44.6], offset: [0.1, 4.3] },
    { id: 'tb2', position: [15.9, 44.6], offset: [5.5, 4.3] },
    { id: 'tb3', position: [15.9, 44.6], offset: [5.5, 1.9] },
  ],
  elements: [
    new MaterialMovementSlopeMonitoring1([19.8, 18.8]),
    new MaterialMovementSlopeMonitoring2([11.8, 57.5]), // Critical Slope Monitoring Content
    new MaterialMovementSlopeMonitoring3([27.6, 47.4], { cornerFrom: ['top', 'left'] }), // .Quick Movement Monitoring Content
    new MaterialMovementSlopeMonitoring4([27.6, 36.7]), //
  ],
};

export default config;
