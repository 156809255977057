import description from './description';
import PlanningGradeСontrol1 from './elements/planningGradeСontrol_1';
import PlanningGradeСontrol2 from './elements/planningGradeСontrol_2';
import PlanningGradeСontrol3 from './elements/planningGradeСontrol_3';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [16, 47], width: 9.125, rotation: [67, 0] }],
  dancingAnts: [
    ['hs1', [0, 32.03]],
    ['hs1', [0, 87.89], { direction: 'reverse' }],
  ],
  tinyBois: [
    { id: 'tb1', position: [16, 47], offset: [4, 5.9] },
    { id: 'tb2', position: [16, 47], offset: [8.8, 4.1] },
  ],
  elements: [
    new PlanningGradeСontrol1([11.6, 15.25]),
    new PlanningGradeСontrol2([11.6, 63.62]),
    new PlanningGradeСontrol3([35.4, 42.37]),
  ],
};

export default config;
