import Box from 'modules/common/components/Box';

export default class OperatorAssist2 {
  constructor(position) {
    this.id = 'OperatorAssist2';
    this.component = Box;
    this.title = 'load-haul.Operator Assist.elements.Reverse Assist';
    this.video = 'md_Reverse_Assist.mp4';
    this.props = {
      target: 'tb1',
      position,
      hashtags: ['autonomy'],
    };
  }
}
