import Box from 'modules/common/components/Box';

export default class AssetHealth3 {
  constructor(position) {
    this.id = 'AssetHealth3';
    this.component = Box;
    this.title = 'load-haul.Asset Health.title';
    this.content = '';
    this.video = 'md_Asset_Health.mp4';
    this.props = { target: 'tb2', position, hashtags: ['digitalisation', 'cost'] };
  }
}
