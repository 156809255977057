const description = {
  id: '2 of 7',
  currentNumber: 2,
  totalNumber: 7,
  title: 'drill-blast.Drilling.title',
  slug: 'drilling',
  pdfPath: 'ProductionDrilling',
  leadLayoutKey: 'production-drilling',
  apiSlug: 'production-drilling',
  productDetailsLink: 'https://hexagon.com/products/hexagon-drill-assist',
  description: 'drill-blast.Drilling.description',
  video: {
    name: 'wf_Production_Drilling.mp4',
    stop: 19,
    backgroundImage: 'Production_Drilling.jpg',
  },
  drawPoints: {
    hotspots: 16,
    dancingAnts: 19,
    secondaries: 19.5,
  },
};

export default description;
