/* eslint-disable react/destructuring-assignment,no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable array-callback-return */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-destructuring */
/* eslint-disable consistent-return */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/button-has-type */
// @ts-nocheck
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import analytics from 'modules/analytics';

import LOMIXConfig from 'LOMIXConfig';

import * as selectors from 'app/selectors';

import actions from 'app/actions';

import Box from 'modules/common/components/Box';
import ArrowButton from 'modules/common/components/ArrowButton';

import { ALLOW_EVENTS_CLASS } from 'app/constants/app';

import LocationSvg from 'app/assets/icons/location.svg';
import { withTranslation } from 'react-i18next';

class PathsNew extends PureComponent {
  constructor(props) {
    super(props);

    this.googleAnalytics = analytics.services.googleAnalytics;

    this.paths = React.createRef();
  }

  componentDidMount() {
    if (this.props.show) {
      this.paths.current.init().animate();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.journeyId !== this.props.journeyId) {
      this.paths.current.safariFix();
    }

    if (prevProps.show !== this.props.show) {
      if (this.props.show) {
        this.paths.current.animate();

        if (!this.props.isOpen) {
          this.props.togglePaths();
        }
      } else {
        this.paths.current.animate(false);

        if (this.props.isOpen) {
          this.props.togglePaths();
        }
      }
    }
  }

  toggle = () => {
    const { togglePaths } = this.props;
    togglePaths();
  };

  onMouseEnter = (id) => {
    const { onEvent } = this.props;
    onEvent({ action: 'active', id });
  };

  onMouseLeave = (id) => {
    const { onEvent } = this.props;
    onEvent({ action: 'deactive', id });
  };

  openSiteMap = () => {
    const { onEvent } = this.props;

    onEvent({
      action: 'openSiteMap',
    });
  };

  goToJourney(id = null) {
    const { onEvent } = this.props;
    onEvent({ action: 'goToJourney', id });
  }

  render() {
    const {
      config,
      isOpen,
      show,
      isWorkflowsOverview,
      navigableJourneys,
      journeyId,
      isUserLoggedIn,
      t,
    } = this.props;

    const activeClasses = 'router-link-exact-active router-link-active path-2d-active';

    return (
      <Box
        ref={this.paths}
        position={[0, 'auto']}
        additionalClasses={[config.className, isOpen ? config.openClass : '', ALLOW_EVENTS_CLASS]}
        contentAdditionalClasses={[`${config.className}__content`]}
      >
        <header className={`${config.className}__header`}>
          <div className={`${config.className}__title`}>
            <div className={`${config.className}__title-name`} />
            {!isWorkflowsOverview && (
              <ArrowButton
                additionalClasses={[`${config.className}__title-arrow`]}
                direction="backwards"
                onClick={(e) => {
                  e.preventDefault();
                  this.toggle(e);
                }}
              />
            )}
          </div>
        </header>
        <nav className="paths-new__nav">
          {navigableJourneys.map((journey) => {
            return (
              <div key={journey.slug} className={`${config.className}__nav-link`}>
                <Link
                  className={`${journeyId === journey.slug ? `${activeClasses} ` : ''}${
                    config.className
                  }__nav-item ${journey.disable ? 'disabled-link' : ''} `}
                  to={{ pathname: `/${journey.slug}` }}
                  event=""
                  onClick={($event) => {
                    $event.preventDefault();
                    if (!journey.disable) {
                      this.goToJourney(journey.slug);
                    }
                  }}
                  onMouseEnter={($event) => {
                    $event.preventDefault();
                    if (!journey.disable) {
                      this.onMouseEnter(journey.slug);
                    }
                  }}
                  onMouseLeave={($event) => {
                    $event.preventDefault();
                    if (!journey.disable) {
                      this.onMouseLeave(journey.slug);
                    }
                  }}
                >
                  <span className="paths-new__nav-number">0{journey.id}</span>
                  <span className="paths-new__nav-name">{t(journey.title)}</span>
                </Link>
              </div>
            );
          })}
        </nav>
        <div className={`${config.className}__footer`}>
          {false && isUserLoggedIn ? (
            <Link
              className={`${config.className}__nav-item`}
              to={{ pathname: `/site-map` }}
              event=""
              onClick={($event) => {
                $event.preventDefault();
                this.googleAnalytics.sendGTEvent({ event: 'site_map_open' });

                this.openSiteMap($event);
              }}
            >
              <i className="icon-left">
                <LocationSvg />
              </i>
              <span className="site-map_link site-map_link__margin">Site Map</span>
            </Link>
          ) : null}
        </div>
      </Box>
    );
  }
}

PathsNew.propTypes = {
  config: PropTypes.shape({
    className: PropTypes.string,
    openClass: PropTypes.string,
  }),
  isWorkflowsOverview: PropTypes.bool,
  isOpen: PropTypes.bool,
  show: PropTypes.bool,
  navigableJourneys: PropTypes.array,
  onEvent: PropTypes.func,
  togglePaths: PropTypes.func,
  t: PropTypes.func,
};

PathsNew.defaultProps = {
  config: {
    className: 'paths-new',
    openClass: 'is-open',
  },
  isWorkflowsOverview: false,
  isOpen: false,
  show: true,
  navigableJourneys: [],
  onEvent: () => {},
  togglePaths: () => {},
  t: () => {},
};

PathsNew.id = 'PathsNew';

const mapStateToProps = (state, props) => {
  const isWorkflowsOverview = props.journeyId === 'workflows-overview';
  const navigableJourneys = LOMIXConfig.filter((j) => j.isInPaths);

  let isOpen = selectors.arePathsOpen(state);

  const location = selectors.getLocation(state);
  const path = location?.slug;

  if (!props.show) {
    isOpen = false;
  }

  return {
    isWorkflowsOverview,
    isOpen,
    navigableJourneys,
    path,
    isUserLoggedIn: selectors.getIsUserLoggedIn(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  togglePaths: (payload) => dispatch(actions.togglePaths({ payload })),
  setToJourney: (payload) => dispatch(actions.setToJourney({ payload })),
  setReplaceJourney: (payload) => dispatch(actions.setReplaceJourney({ payload })),
  setCanNavigate: (payload) => dispatch(actions.setCanNavigate({ payload })),
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(PathsNew),
);
