import description from './description';
import LoadHaulDynamicData1 from './elements/LoadHaulDynamicData1';
import LoadHaulDynamicData2 from './elements/LoadHaulDynamicData2';
import LoadHaulDynamicData3 from './elements/LoadHaulDynamicData3';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [55.2, 64.7], width: 12.75 }],
  dancingAnts: [],
  tinyBois: [
    { id: 'tb2', position: [55.2, 64.7], offset: [0.4, 8.8] },
    { id: 'tb1', position: [55.2, 64.7], offset: [11.2, 8.8] },
  ],
  elements: [
    new LoadHaulDynamicData1([66.7, 28.7]),
    new LoadHaulDynamicData2([43.2, 80.4]),
    new LoadHaulDynamicData3([66.7, 66.8]),
  ],
};

export default config;
