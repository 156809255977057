const description = {
  id: '5 of 5 safety-insights',
  currentNumber: 5,
  totalNumber: 5,
  code: 5,
  title: 'safety.SafetyInsights.title',
  slug: 'safety-insights',
  pdfPath: 'SafetyInsights',
  leadLayoutKey: 'safety-insights',
  apiSlug: 'safety-insights',
  productDetailsLink: 'https://hexagon.com/products/hexagon-op-collision-avoidance-system',
  description: 'safety.SafetyInsights.description',
  video: {
    backgroundImage: 'Safety_Insights.jpg',
    backgroundImageDestroyTime: 1.5,
  },
  drawPoints: {
    hotspots: 1,
    dancingAnts: 1.5,
    secondaries: 2,
  },
};

export default description;
