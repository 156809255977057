/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable react/destructuring-assignment,react/prop-types,react/no-unused-state,react/forbid-prop-types */
/* eslint-disable react/sort-comp */
/* eslint-disable class-methods-use-this */
/* eslint-disable prefer-destructuring */
/* eslint-disable prettier/prettier */
/* eslint-disable no-restricted-syntax */
// @ts-nocheck
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable array-callback-return */
/* eslint-disable global-require */
/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import eventBus from 'EventBus';

import VideoBackground from 'modules/common/components/VideoBackground';

import AnimationManager from 'modules/workflows-overview-2d-introduction/services/AnimationManager';

import SidebarLeft from 'modules/workflows-overview-2d-introduction/components/SidebarLeft';

import './assets/scss/index.scss';

class Location extends PureComponent {
  constructor(props) {
    super(props);

    this.sidebarLeft = React.createRef();

    this.backgroundImage = React.createRef();

    this.isAnimationEnded = false;
    this.isVideoEnded = false;
    this.loadedVideo = false;
    this.startAnimOnlyOne = true;

    this.state = {
      video: {
        play: false,
        canPause: true,
      },
      decreaseVolume: false,
    };

    this.rollbackedAnimation = false;

    this.animationManager = new AnimationManager({
      listener: this,
    });

    this.action = null;
  }

  componentDidMount() {
    if (!this.existVideo()) {
      setTimeout(() => {
        this.videoLoaded(true);
      }, 0);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.requestDestroy !== this.props.requestDestroy && this.props.requestDestroy) {
      this.resume();
    }
  }

  onUpdate({ action, data = {} } = {}) {
    switch (action) {
      case 'toggleAnimationEnded': {
        this.toggleAnimationEnded(data.isEnded);
        break;
      }
      case 'toggleIsStageAnimating': {
        this.toggleIsStageAnimating(data.isAnimating);
        break;
      }
      case 'startVideo': {
        if (this.canPlayVideo()) {
          this.toggleVideoPlaying(true);
        }
        break;
      }
      case 'breakdownVideo': {
        this.animPaused = true;

        this.props.onEvent({
          action: 'breakdownVideo',
          data: {
            videoPlay: this.state.video.play,
          },
        });
        break;
      }
      case 'startVideoEnd': {
        if (!this.props.location?.video.name && this.props.location?.video?.backgroundImageDestroyTime) {
          this.timeout = setTimeout(() => {
            this.props.onEvent({
              action: 'startVideoEnd',
              data: {
                ...data,
                action: this.action,
              },
            });
    
            if (this.props.locationName) {
              this.animPaused = false;
              return this.toggleVideoPlaying(true);
            }
          }, (this.props.location?.video?.backgroundImageDestroyTime || 0) * 1000)
          
          break;
        }

        this.props.onEvent({
          action: 'startVideoEnd',
          data: {
            ...data,
            action: this.action,
          },
        });

        if (this.props.locationName) {
          this.animPaused = false;
          return this.toggleVideoPlaying(true);
        }
        break;
      }
      case 'rollbackAnimation': {
        this.rollbackAnimation();
        break;
      }
      case 'cancelResumeFromCard': {
        this.props.onEvent({ action: 'cancelResumeFromCard' });

        eventBus.dispatch('cancelContinueFromCard');

        this.startAnimOnlyOne = false;

        this.videoLoaded(false);
        break;
      }
      default: {
        //
      }
    }
  }

  checkReady = () => {
    const { enabledTransition, config } = this.props;

    if (this.startAnimOnlyOne && this.loadedVideo && !enabledTransition) {
      this.startAnimOnlyOne = false;

      this.animationManager.init(config, this.props.drawPoints, this.sidebarLeft?.current);
      this.animate();
    }
  };

  videoStopped = (isPlaying, canPause) => {
    this.toggleVideoPlaying(isPlaying, canPause);

    if (this.animPaused) {
      this.toggleIsStageAnimating(false);
    }
  };

  toggleVideoPlaying = (isPlaying, canPause) => {
    let { video } = this.state;

    video = {
      ...video,
      play: isPlaying,
    };

    if (canPause !== undefined) {
      video = {
        ...video,
        canPause,
      };
    }

    this.setState({ video });
  };

  toggleVideoEnded = (isEnded) => {
    this.isVideoEnded = isEnded;

    if (isEnded) {
      this.checkAnimationEnded();
    }
  };

  videoLoaded = (value = true) => {
    this.loadedVideo = value;
    this.checkReady();
  };

  toggleAnimationEnded = (isEnded) => {
    this.isAnimationEnded = isEnded;

    if (isEnded) {
      this.checkAnimationEnded();
    }
  };

  checkAnimationEnded = () => {
    if (this.isVideoEnded && this.isAnimationEnded) {
      this.isAnimationEnded = false;
      this.isVideoEnded = false;

      this.props.onEvent({ action: 'animation:end' });
    }
  };

  resetAnimationFlow = () => {
    this.toggleVideoPlaying(false, true);
    this.toggleVideoEnded(false);
    this.toggleAnimationEnded(false);
    this.animPaused = false;
  };

  toggleIsStageAnimating = (isAnimating) => {
    this.props.onEvent({
      action: 'toggleIsStageAnimating',
      data: { isStageAnimating: isAnimating },
    });
  };

  rollbackAnimation = () => {
    this.rollbackedAnimation = true;

    this.props.onEvent({ action: 'rollbackedAnimationEnd' });
  };

  animate() {
    this.setState({
      decreaseVolume: false,
    });

    this.animationManager?.play();

    return this;
  }

  resume = () => {
    this.setState({
      decreaseVolume: true,
    });

    this.rollbackedAnimation = false;
    this.animationManager.resume();

    return this;
  };

  resumeFromCard = () => {
    // const { /* location, */ onEvent } = this.props;

    // onEvent('resumeFromCard');

    this.animationManager.resume();

    /* this.animationManager.addEventCallback('onComplete', 'setLocationName', {
      slug: location.slug,
    }); */

    return this;
  };

  cancelResumeFromCard = () => {
    this.animationManager.resume();

    // this.animationManager.addEventCallback('onComplete', 'cancelResumeFromCard');

    return this;
  };

  handleEvent = ({ action }) => {
    this.action = action;

    switch (action) {
      case 'resume':
        this.resume();
        break;
      case 'resumeFromCard':
        this.resumeFromCard();
        break;
      case 'cancelResumeFromCard':
        this.cancelResumeFromCard();
        break;
      default: {
        // console.log('handleEvent:action', action);
      }
    }
  };

  canPlayVideo() {
    return this.existVideo();
  }

  existVideo() {
    return !!this.props.location?.video.name;
  }

  onHandleClickSidebarLeft = () => {
    this.action = 'goToMineMap';
    // this.props.onEvent({ action: 'goToMineMap' });

    // const { setDirection /* location, setLocationName */ } = this.props;
    // setDirection('goToMineMap');

    /* this.timeline.eventCallback('onComplete', () => {
      return setLocationName(location.slug);
    }); */

    this.resume();
  };

  render() {
    const { location, playDefaultAudio, muted, backgroundImage, video, enabledTransition } =
      this.props;

    const { decreaseVolume, video: videoSettings } = this.state;

    return (
      <>
        <VideoBackground
          backgroundImage={backgroundImage}
          video={video}
          videoSettings={videoSettings}
          videoStopped={this.videoStopped}
          videoEnded={this.toggleVideoEnded}
          videoLoaded={this.videoLoaded}
          playDefaultAudio={playDefaultAudio}
          disableDefaultAudio
          muted={muted}
          decreaseVolume={decreaseVolume}
          enabledTransition={enabledTransition}
        />

        <div ref={this.sidebarLeft} className="sidebar-left-wrapper">
          <div className="sidebar-container">
            <SidebarLeft
              currentLocation={location?.slug || 'evaluation-planning-design'}
              onClick={this.onHandleClickSidebarLeft}
            />
          </div>
        </div>
      </>
    );
  }
}

Location.propTypes = {
  config: PropTypes.shape({
    className: PropTypes.string,
    delayPrimaries: PropTypes.number,
    delaySecondaries: PropTypes.number,
    staggerBreakdowns: PropTypes.number,
    delayVideoBreakdown: PropTypes.number,
  }),
  enabledTransition: PropTypes.bool,
  playDefaultAudio: PropTypes.bool,
  muted: PropTypes.bool,
  location: PropTypes.object,
  requestDestroy: PropTypes.bool,
};

Location.defaultProps = {
  config: {
    className: 'journey',
    delayPrimaries: 0.5,
    delaySecondaries: 0.25,
    staggerBreakdowns: 0.125,
    delayVideoBreakdown: 1.5,
  },
  enabledTransition: false,
  playDefaultAudio: false,
  muted: true,
  location: {},
  requestDestroy: false,
};

const mapStateToProps = (state, props) => {
  const config = {
    className: 'journey',
    delayPrimaries: 0.5,
    delaySecondaries: 0.25,
    staggerBreakdowns: 0.125,
    delayVideoBreakdown: 1.5,
  };

  const { location } = props;

  const playDefaultAudio = !!location?.video?.playDefaultSound;

  return {
    config,
    playDefaultAudio,
  };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(Location);
