import description from './description';
import UndergroundDevelopmentDrilling1 from './elements/undergroundDevelopmentDrilling_1';
import UndergroundDevelopmentDrilling2 from './elements/undergroundDevelopmentDrilling_2';
import UndergroundDevelopmentDrilling3 from './elements/undergroundDevelopmentDrilling_3';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [13.7, 47.1], width: 6.375 }],
  dancingAnts: [['hs1', [0, 48.3], { direction: 'reverse' }]],
  tinyBois: [
    { id: 'tb1', position: [13.7, 47.1], offset: [0, 3.9] },
    { id: 'tb2', position: [13.7, 47.1], offset: [6.1, 2.2] },
  ],

  elements: [
    new UndergroundDevelopmentDrilling1([14.3, 17]),
    new UndergroundDevelopmentDrilling2([28, 50], { target: 'tb1' }),
    new UndergroundDevelopmentDrilling3([12.4, 64.5], { target: 'tb2' }),
  ],
};

export default config;
