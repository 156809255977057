/* eslint-disable react/forbid-prop-types */
/* eslint-disable global-require,react/jsx-props-no-spreading,no-return-assign,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,react/destructuring-assignment,react/no-unused-state */
// @ts-nocheck

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as selectors from 'app/selectors';

import Button from 'modules/common/components/Button';
import { withTranslation } from 'react-i18next';
import ArrowNextIcon from './assets/icons/arrow-next.svg';
import ArrowPrevIcon from './assets/icons/arrow-prev.svg';

import locationConfig from './locationConfig';

import './assets/scss/index.scss';
import Carousel from '../Carousel';

class Sidebar extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      locationInfo: null,
    };
  }

  componentDidMount() {
    this.setLocation();
  }

  componentDidUpdate(prevProps) {
    const { currentLocation } = this.props;

    if (prevProps.currentLocation !== currentLocation) {
      this.setLocation();
    }
  }

  setLocation = () => {
    const { currentLocation } = this.props;
    const locationInfo = locationConfig.filter((loc) => loc.slug === currentLocation);
    this.setState({ locationInfo: locationInfo[0] });
  };

  goToPrevLocation = () => {
    const {
      locationInfo: { id },
    } = this.state;

    const { onMoveToLocation } = this.props;

    const prevId = id - 1 >= 0 ? id - 1 : locationConfig[locationConfig.length - 1].id;
    const prevLocation = locationConfig.filter((loc) => loc.id === prevId);

    onMoveToLocation(prevLocation[0].slug);
  };

  goToNextLocation = () => {
    const {
      locationInfo: { id },
    } = this.state;

    const { onMoveToLocation } = this.props;
    const nextId = id + 1 > locationConfig.length - 1 ? 0 : id + 1;
    const nextLocation = locationConfig.filter((loc) => loc.id === nextId);

    onMoveToLocation(nextLocation[0].slug);
  };

  changeActiveSlide = (slug) => {
    const { onMoveToLocation } = this.props;
    onMoveToLocation(slug);
  };

  render() {
    const { config, onGoIntoLocation, isUserLoggedIn, t } = this.props;
    const { locationInfo } = this.state;

    return (
      <div className="scene-sidebar-content">
        <div className={`${config.className}`}>
          <div className={`${config.className}__icons`}>
            <Carousel activeSlug={locationInfo?.slug} changeActive={this.changeActiveSlide} />
          </div>
          <div className={`${config.className}__info`}>
            <div className="title">
              <h2>{t(locationInfo?.name)}</h2>
              <div className={`${config.className}__navigate`}>
                <ArrowPrevIcon onClick={this.goToPrevLocation} />
                <ArrowNextIcon onClick={this.goToNextLocation} />
              </div>
            </div>
            <div className="description">{t(locationInfo?.description)}</div>
            <div className="bottom-button">
              <Button onClick={onGoIntoLocation}>
                {!isUserLoggedIn ? t('workflow.Sign up to ') : ''}
                {t('workflow.DIG DEEPER iNTO')} {t(locationInfo?.name)}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Sidebar.propTypes = {
  config: PropTypes.shape({
    className: PropTypes.string,
  }),
  currentLocation: PropTypes.string,
  onMoveToLocation: PropTypes.func,
  onGoIntoLocation: PropTypes.func,
  isUserLoggedIn: PropTypes.bool,
  t: PropTypes.func,
};

Sidebar.defaultProps = {
  config: {
    className: 'sidebar',
  },
  currentLocation: '',
  onMoveToLocation: () => {},
  onGoIntoLocation: () => {},
  isUserLoggedIn: false,
  t: () => {},
};

const mapStateToProps = (state) => {
  return {
    isUserLoggedIn: selectors.getIsUserLoggedIn(state),
  };
};

export default withTranslation()(connect(mapStateToProps, null)(Sidebar));
