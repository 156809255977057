import { NAME } from 'app/components/modals/ContactUs/constants';
import { createSelector } from 'reselect';

// info: https://redux-toolkit.js.org/api/createSelector
const getInitialState = (state) => {
  return state[NAME];
};

export const isFormSended = createSelector(getInitialState, (state) => state.formSended);
export const isFormSending = createSelector(getInitialState, (state) => state.formSending);

export default {
  isFormSended,
  isFormSending,
};
