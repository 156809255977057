import description from './description';
import DrillBlastBlastDesign1 from './elements/drillBlastBlastDesign_1';
import DrillBlastBlastDesign2 from './elements/drillBlastBlastDesign_2';
import DrillBlastBlastDesign3 from './elements/drillBlastBlastDesign_3';
import DrillBlastBlastDesign4 from './elements/drillBlastBlastDesign_4';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [16, 47.5], width: 12.75 }],
  dancingAnts: [
    ['hs1', [0, 21.28]],
    ['hs1', [0, 93.55], { direction: 'reverse' }],
    ['hs1', [48.33, 0]],
  ],
  tinyBois: [
    { id: 'tb1', position: [16, 47.5], offset: [11.5, 2.5] },
    { id: 'tb2', position: [16, 47.5], offset: [0.3, 8.7] },
    { id: 'tb3', position: [16, 47.5], offset: [11.5, 8.7] },
  ],
  elements: [
    new DrillBlastBlastDesign1([12, 19.9]),
    new DrillBlastBlastDesign2([27.6, 47.1]),
    new DrillBlastBlastDesign3([12, 76.27]),
    new DrillBlastBlastDesign4([27.6, 62.84]),
  ],
};

export default config;
