import Box from 'modules/common/components/Box';

export default class DrillBlastSafety4 {
  constructor(position) {
    this.id = 'drillBlastSafety_4';
    this.component = Box;
    this.title = 'drill-blast.Safety.elements.Blast Monitoring';
    this.video = '3c-3.mp4';
    this.props = {
      target: 'tb3',
      position,
      cornerFrom: ['bottom', 'left'],
      hashtags: ['healthSafety', 'productivity'],
    };
  }
}
