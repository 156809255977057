// @ts-nocheck
/* eslint-disable no-unused-vars */
/* eslint-disable react/button-has-type,react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';

const DeleteProgress = () => {
  const { t } = useTranslation();

  return (
    <>
      <h2>{t('download.Delete offline version')}</h2>
      <div className="flex-center icon-wrap margin-top-2">
        <div className="spinner" />
      </div>
    </>
  );
};

export default DeleteProgress;
