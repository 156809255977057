/* eslint-disable global-require */
/* eslint-disable jsx-a11y/alt-text */
// @ts-nocheck
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import actions from 'app/actions';

import HexagonLogoSVG from 'assets/images/brand/hexagon-logo.svg';

import Box from 'modules/common/components/Box';

import Button from 'modules/common/components/Button';
import { withTranslation } from 'react-i18next';

class TitleCard extends PureComponent {
  constructor(props) {
    super(props);

    this.titleCard = React.createRef();
  }

  handleClick = () => {
    const { togglePaths, onEvent } = this.props;
    togglePaths({ isOpen: true });
    onEvent({ action: 'resumeFromCard' });
  };

  getTimeline() {
    return this.titleCard.current.getTimeline();
  }

  init() {
    return this.titleCard.current.init();
  }

  render() {
    const { config, t } = this.props;

    return (
      <Box
        ref={this.titleCard}
        additionalClasses={config.className}
        contentAdditionalClasses={[`${config.className}__content`]}
      >
        <div className={`${config.className}__logo-container`}>
          <div className={`${config.className}__logo`}>
            <HexagonLogoSVG />
          </div>
        </div>

        <div className={`${config.className}__title ${config.className}__title_margin`}>
          <div className={`${config.className}__title-thicc`}>Life-of-Mine</div>

          <div className={`${config.className}__title-thin ${config.className}__title-thin_margin`}>
            {t('Experience')}
          </div>
        </div>

        <div className={`${config.className}__tagline`}>{t('DiscoverText')}</div>

        <div className="title-card__buttons c-button-group title-card__buttons_margin">
          <Button onClick={this.handleClick}>{t('Start your Experience')}</Button>
        </div>
      </Box>
    );
  }
}

TitleCard.propTypes = {
  config: PropTypes.shape({
    className: PropTypes.string,
  }),
  onEvent: PropTypes.func,
  togglePaths: PropTypes.func,
  t: PropTypes.func,
};

TitleCard.defaultProps = {
  config: {
    className: 'title-card',
  },
  onEvent: () => {},
  togglePaths: () => {},
  t: () => {},
};

TitleCard.id = 'TitleCard';

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  togglePaths: (payload) => dispatch(actions.togglePaths({ payload })),
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true, test: '1' })(TitleCard),
);
