// @ts-nocheck
/* eslint-disable react/button-has-type,react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import Error from './assets/icons/fail.svg';

const ErrorInfo = ({ handleClose }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="icon-wrap flex-center">
        <Error className="icon-wrap__error" />
      </div>
      <div className="text-center">
        {t('download.Something went wrong and download hasn’t been completed')}
      </div>
      <div className="padding-top text-center">
        {t('download.Please try again later or contact')}:
      </div>
      <div className="text-support text-center blue-text">
        <a
          href="mailto:info@hexagon.com"
          target="_blank"
          rel="noreferrer"
          style={{
            textTransform: 'none',
          }}
        >
          info@hexagon.com
        </a>
      </div>
      <div className="c-buttons-list flex-center padding-top" style={{ justifyContent: 'center' }}>
        <button className="c-button c-button_expand" onClick={handleClose}>
          {t('common.OK')}
        </button>
      </div>
    </>
  );
};

export default ErrorInfo;
