import description from './description';
import AssetHealth1 from './elements/AssetHealth1';
import AssetHealth2 from './elements/AssetHealth2';
import AssetHealth3 from './elements/AssetHealth3';
import AssetHealth4 from './elements/AssetHealth4';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [8.8, 40], width: 5 }],
  dancingAnts: [['hs1', [0, 24], { direction: 'reverse' }]],
  tinyBois: [
    { id: 'tb1', position: [8.8, 40], offset: [4.5, 1.475] },
    { id: 'tb2', position: [8.8, 40], offset: [2.2, 4.7] },
  ],
  elements: [
    new AssetHealth1([12, 17]),
    new AssetHealth2([19.8, 39]),
    new AssetHealth3([12, 50.6]),
    new AssetHealth4([26.5, 50.6]),
  ],
};

export default config;
