const description = {
  id: '2 of 5 fatigue-and-distraction',
  currentNumber: 2,
  totalNumber: 5,
  code: 2,
  title: 'safety.Fatigue and Distraction.title',
  slug: 'fatigue-and-distraction',
  pdfPath: 'Fatigue&Distraction',
  leadLayoutKey: 'fatigue-distraction',
  apiSlug: 'fatigue-distraction',
  productDetailsLink: 'https://hexagon.com/products/hexagon-op-operator-alertness-system',
  description: 'safety.Fatigue and Distraction.description',
  video: {
    name: 'wf_Fatigue_And_Distraction.mp4',
    stop: 15,
    backgroundImage: 'Fatigue_And_Distraction.jpg',
  },
  drawPoints: {
    hotspots: 15,
    dancingAnts: 15.5,
    secondaries: 16,
  },
};

export default description;
