import description from './description';
import MaterialMovementSlopeMonitoring1 from './elements/materialMovementSlopeMonitoring_1';
import MaterialMovementSlopeMonitoring2 from './elements/materialMovementSlopeMonitoring_2';
import MaterialMovementSlopeMonitoring3 from './elements/materialMovementSlopeMonitoring_3';
import MaterialMovementSlopeMonitoring4 from './elements/materialMovementSlopeMonitoring_4';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [13.5, 50.5], width: 6.815, rotation: [60, 0] }],
  dancingAnts: [
    ['hs1', [0, 20.11], { direction: 'reverse' }],
    ['hs1', [0, 87.5], { direction: 'reverse' }],
  ],
  tinyBois: [
    { id: 'tb1', position: [13.5, 50.5], offset: [0.1, 4] },
    { id: 'tb2', position: [13.5, 50.5], offset: [6.3, 4] },
    { id: 'tb3', position: [13.5, 50.5], offset: [6.3, 2.5] },
  ],
  elements: [
    new MaterialMovementSlopeMonitoring1([12.4, 19.33], { size: 'wide' }),
    new MaterialMovementSlopeMonitoring2([12.4, 64.6]),
    new MaterialMovementSlopeMonitoring3([35.8, 55.6]),
    new MaterialMovementSlopeMonitoring4([35.8, 42.6]),
  ],
};

export default config;
