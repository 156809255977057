const description = {
  id: '5 of 5 load-haul-insights',
  currentNumber: 5,
  totalNumber: 5,
  code: 5,
  title: 'load-haul.Load&Haul Insights.title',
  slug: 'load-haul-insights',
  pdfPath: 'Load&HaulInsights',
  leadLayoutKey: 'load-haul-insights',
  apiSlug: 'load-haul-insights',
  productDetailsLink: 'https://hexagon.com/products/hexagon-op-pro',
  description: 'load-haul.Load&Haul Insights.description',
  video: {
    name: 'wf_Load_And_Haul_Insights.mp4',
    stop: 32,
    backgroundImage: 'Load_And_Haul_Insights.jpg',
  },
  drawPoints: {
    hotspots: 32,
    dancingAnts: 32.1,
    secondaries: 32.2,
  },
};

export default description;
