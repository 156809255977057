import description from './description';
import SurveyMonitoringRealityCapture1 from './elements/surveyMonitoringRealityCapture_1';
import SurveyMonitoringRealityCapture2 from './elements/surveyMonitoringRealityCapture_2';
import SurveyMonitoringRealityCapture3 from './elements/surveyMonitoringRealityCapture_3';
import SurveyMonitoringRealityCapture4 from './elements/surveyMonitoringRealityCapture_4';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [63, 52.3], width: 12.75 }],
  dancingAnts: [['hs1', [47.76, 0], { direction: 'reverse' }]],
  tinyBois: [
    { id: 'tb1', position: [63, 52.3], offset: [6, 12.1] },
    { id: 'tb2', position: [63, 52.3], offset: [11.2, 2.2] },
    { id: 'tb3', position: [63, 52.3], offset: [11.2, 8.9] },
  ],
  elements: [
    new SurveyMonitoringRealityCapture1([58.8, 25.7], { cornerFrom: ['bottom', 'left'] }),
    new SurveyMonitoringRealityCapture2([58.8, 72.1], { cornerFrom: ['top', 'left'] }),
    new SurveyMonitoringRealityCapture3([74.5, 52.4], { cornerFrom: ['top', 'left'] }),
    new SurveyMonitoringRealityCapture4([74.5, 67.3]),
  ],
};

export default config;
