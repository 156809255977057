// @ts-nocheck
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Location from 'modules/journeys-manager-introduction/containers/Location';
import actions from 'modules/journeys-manager-introduction/actions';

class Main extends PureComponent {
  componentDidMount() {
    this.onEvent({ action: 'doFirstListRequest', data: null });
  }

  onEvent = ({ action, data }) => {
    // eslint-disable-next-line react/prop-types
    const { onEvent, doListRequest, setFilter } = this.props;

    switch (action) {
      case 'doFirstListRequest': {
        doListRequest({});
        break;
      }
      case 'setFilter': {
        setFilter(data);
        break;
      }
      default: {
        onEvent({ action, data });
      }
    }
  };

  render() {
    const { permission, allLomixConfigs } = this.props;

    return (
      <>
        <Location
          onEvent={this.onEvent}
          permission={permission}
          allLomixConfigs={allLomixConfigs}
        />
      </>
    );
  }
}

Main.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  allLomixConfigs: PropTypes.arrayOf(PropTypes.any),
  config: PropTypes.shape({
    className: PropTypes.string,
    delayPrimaries: PropTypes.number,
    delaySecondaries: PropTypes.number,
    staggerBreakdowns: PropTypes.number,
    delayVideoBreakdown: PropTypes.number,
    doListRequest: PropTypes.func,
    setFilter: PropTypes.func,
  }),
  permission: PropTypes.shape({
    createJourney: PropTypes.bool,
    edit: PropTypes.bool,
    delete: PropTypes.bool,
    duplicate: PropTypes.bool,
  }),
};

Main.defaultProps = {
  allLomixConfigs: [],
  config: {
    className: 'journey',
    delayPrimaries: 0.5,
    delaySecondaries: 0.25,
    staggerBreakdowns: 0.125,
    delayVideoBreakdown: 1.5,
    doListRequest: () => {},
    setFilter: () => {},
  },
  permission: {
    createJourney: false,
    edit: false,
    delete: false,
    duplicate: false,
  },
};

const mapStateToProps = () => {
  return {
    config: {
      className: 'journey',
      delayPrimaries: 0.5,
      delaySecondaries: 0.25,
      staggerBreakdowns: 0.125,
      delayVideoBreakdown: 1.5,
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  doListRequest: (payload, status) => dispatch(actions.doListRequest({ payload, status })),
  setFilter: (payload, status) => dispatch(actions.setFilter({ payload, status })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
