const config = {
  id: '1 of 5 mine-map',
  currentNumber: 1,
  totalNumber: 5,
  title: 'workflow.Mine Map',
  slug: 'mine-map',
  hasMainNav: true,
  isInPaths: true,
  hidePath: false,
  description: 'workflow.Mine Map description1',
  video: {
    backgroundImage: 'wf_2D_Mine_Map.jpg',
  },
  audio: {
    default: {
      disable: true,
    },
  },
  drawPoints: {
    hotspots: 1,
    dancingAnts: 4.5,
    secondaries: 5,
  },
  hotspots: [],
  dancingAnts: [],
  tinyBois: [],
  elements: [],
  pointers: [
    {
      id: 'evaluation-planning-design',
      label: {
        number: '01',
        title: 'evaluationPlanningDesign.EvaluationPlanningDesign',
      },
      position: [1162, 240],
      rotation: [0, 0],
      default: {
        label: {
          number: {
            translateX: '-36',
          },
        },
        // width: 2.275,
        width: 61,
        height: 139,
        scale: 0.00833333,
        viewBox: {
          x: 61,
          y: 139,
        },
        rect: {
          x: 0.833984,
          y: 0, // 0.12146,
          width: 60,
          height: 60,
        },
        circle: {
          outer: {
            x: 30.834,
            y: 34,
            r: 11,
            strokeWidth: 2,
          },
          inner: {
            x: 30.834,
            y: 34,
            r: 4,
          },
        },
        line: {
          x1: 30.8337,
          y1: 44,
          x2: 30.8337,
          y2: 139,
          d: 'M29.834 44H31.834V139H29.834V44Z',
        },
        splash: {
          opacity: 0,
          width: 120,
          height: 120,
        },
      },
      active: {
        // width: 2.275,
        width: 61,
        height: 139,
        scale: 0.00833333,
        viewBox: {
          x: 61,
          y: 139,
        },
        rect: {
          x: 0.833984,
          y: 0, // 0.12146,
          width: 60,
          height: 60,
        },
        circle: {
          outer: {
            x: 30.834,
            y: 30,
            r: 15,
            strokeWidth: 2,
          },
          inner: {
            x: 30.834,
            y: 30,
            r: 4,
          },
        },
        line: {
          x1: 30.8337,
          y1: 44,
          x2: 30.8337,
          y2: 139,
          d: 'M29.834 44H31.834V139H29.834V44Z',
        },
        splash: {
          opacity: 1,
          width: 120,
          height: 120,
        },
      },
      zoomIn: {
        video: {
          name: 'wf_2D_Mine_Map_Evaluation_Planning_And_Design_Zoom_In.mp4',
          backgroundImage: 'wf_2D_Mine_Map_Evaluation_Planning_And_Design_Zoom_Out.jpg',
        },
      },
      zoomOut: {
        video: {
          name: 'wf_2D_Mine_Map_Evaluation_Planning_And_Design_Zoom_Out.mp4',
          backgroundImage: 'wf_2D_Mine_Map.jpg',
        },
      },
    },
    {
      id: 'drill-blast',
      label: {
        number: '02',
        title: 'drill-blast.Drill Blast',
      },
      position: [889, 344],
      rotation: [0, 0],
      default: {
        label: {
          number: {
            translateX: '-36',
          },
        },
        // width: 2.275,
        width: 61,
        height: 159,
        scale: 0.00833333,
        viewBox: {
          x: 61,
          y: 159,
        },
        rect: {
          x: 0.833984,
          y: 0,
          width: 60,
          height: 60,
        },
        circle: {
          outer: {
            x: 30.834,
            y: 34,
            r: 11,
            strokeWidth: 2,
          },
          inner: {
            x: 30.834,
            y: 34,
            r: 4,
          },
        },
        line: {
          x1: 30.8337,
          y1: 63.6581,
          x2: 30.8337,
          y2: 159,
          d: 'M29.834 44H31.834V159H29.834V44Z',
        },
        splash: {
          opacity: 0,
          width: 120,
          height: 120,
        },
      },
      active: {
        // width: 2.275,
        width: 61,
        height: 159,
        scale: 0.00833333,
        viewBox: {
          x: 61,
          y: 159,
        },
        rect: {
          x: 0.833984,
          y: 0,
          width: 60,
          height: 60,
        },
        circle: {
          outer: {
            x: 30.834,
            y: 30,
            r: 15,
            strokeWidth: 2,
          },
          inner: {
            x: 30.834,
            y: 30,
            r: 4,
          },
        },
        line: {
          x1: 30.8337,
          y1: 63.6581,
          x2: 30.8337,
          y2: 159,
          d: 'M29.834 44H31.834V159H29.834V44Z',
        },
        splash: {
          opacity: 1,
          width: 120,
          height: 120,
        },
      },
      zoomIn: {
        video: {
          name: 'wf_2D_Mine_Map_Drill_And_Blast_Zoom_In.mp4',
          backgroundImage: 'wf_2D_Mine_Map_Drill_And_Blast_Zoom_Out.jpg',
        },
      },
      zoomOut: {
        video: {
          name: 'wf_2D_Mine_Map_Drill_And_Blast_Zoom_Out.mp4',
          backgroundImage: 'wf_2D_Mine_Map.jpg',
        },
      },
    },
    {
      id: 'load-haul',
      label: {
        number: '03',
        title: 'load-haul.Load & Haul',
      },
      position: [1000, 396],
      rotation: [0, 0],
      default: {
        label: {
          number: {
            translateX: '-36',
          },
        },
        // width: 2.275,
        width: 61,
        height: 109,
        scale: 0.00833333,
        viewBox: {
          x: 61,
          y: 109,
        },
        rect: {
          x: 0.833984,
          y: 0,
          width: 60,
          height: 60,
        },
        circle: {
          outer: {
            x: 30.834,
            y: 34,
            r: 11,
            strokeWidth: 2,
          },
          inner: {
            x: 30.834,
            y: 34,
            r: 4,
          },
        },
        line: {
          x1: 30.8337,
          y1: 44,
          x2: 30.8337,
          y2: 109,
          d: 'M29.834 44H31.834V109H29.834V44Z',
        },
        splash: {
          opacity: 0,
          width: 120,
          height: 120,
        },
      },
      active: {
        // width: 2.275,
        width: 61,
        height: 109,
        scale: 0.00833333,
        viewBox: {
          x: 61,
          y: 109,
        },
        rect: {
          x: 0.833984,
          y: 0,
          width: 60,
          height: 60,
        },
        circle: {
          outer: {
            x: 30.834,
            y: 30,
            r: 15,
            strokeWidth: 2,
          },
          inner: {
            x: 30.834,
            y: 30,
            r: 4,
          },
        },
        line: {
          x1: 30.8337,
          y1: 44,
          x2: 30.8337,
          y2: 109,
          d: 'M29.834 44H31.834V109H29.834V44Z',
        },
        splash: {
          opacity: 1,
          width: 120,
          height: 120,
        },
      },
      zoomIn: {
        video: {
          name: 'wf_2D_Mine_Map_Load_And_Haul_Zoom_In.mp4',
          backgroundImage: 'wf_2D_Mine_Map_Load_And_Haul_Zoom_Out.jpg',
        },
      },
      zoomOut: {
        video: {
          name: 'wf_2D_Mine_Map_Load_And_Haul_Zoom_Out.mp4',
          backgroundImage: 'wf_2D_Mine_Map.jpg',
        },
      },
    },
    {
      id: 'safety',
      label: {
        number: '05',
        title: 'safety.Safety',
      },
      position: [921, 424],
      rotation: [0, 0],
      default: {
        label: {
          number: {
            translateX: '-36',
          },
        },
        // width: 2.275,
        width: 60,
        height: 90,
        scale: 0.00833333,
        viewBox: {
          x: 60,
          y: 90,
        },
        rect: {
          x: 0,
          y: 0,
          width: 60,
          height: 60,
        },
        circle: {
          outer: {
            x: 30,
            y: 33.9995,
            r: 11,
            strokeWidth: 2,
          },
          inner: {
            x: 30,
            y: 33.9995,
            r: 4,
          },
        },
        line: {
          x1: 29.9998,
          y1: 49.169,
          x2: 29.9998,
          y2: 90,
          d: 'M29 45H31V90H29V45Z',
        },
        splash: {
          opacity: 0,
          width: 120,
          height: 120,
        },
      },
      active: {
        // width: 2.275,
        width: 60,
        height: 90,
        scale: 0.00833333,
        viewBox: {
          x: 60,
          y: 90,
        },
        rect: {
          x: 0,
          y: 0,
          width: 60,
          height: 60,
        },
        circle: {
          outer: {
            x: 30,
            y: 30,
            r: 15,
            strokeWidth: 2,
          },
          inner: {
            x: 30,
            y: 30,
            r: 4,
          },
        },
        line: {
          x1: 29.9822,
          y1: 49.169,
          x2: 29.9822,
          y2: 90,
          d: 'M28.9824 45H30.9824V90H28.9824V45Z',
        },
        splash: {
          opacity: 1,
          width: 120,
          height: 120,
        },
      },
      zoomIn: {
        video: {
          name: 'wf_2D_Mine_Map_Safety_Zoom_In.mp4',
          backgroundImage: 'wf_2D_Mine_Map_Safety_Zoom_Out.jpg',
        },
      },
      zoomOut: {
        video: {
          name: 'wf_2D_Mine_Map_Safety_Zoom_Out.mp4',
          backgroundImage: 'wf_2D_Mine_Map.jpg',
        },
      },
    },
    {
      id: 'survey-monitoring',
      label: {
        number: '04',
        title: 'survey-monitoring.Survey Monitoring',
      },
      position: [807, 219],
      rotation: [0, 0],
      default: {
        label: {
          number: {
            translateX: '-36',
          },
        },
        // width: 2.275,
        width: 61,
        height: 229,
        scale: 0.00833333,
        viewBox: {
          x: 61,
          y: 229,
        },
        rect: {
          x: 0.833984,
          y: 0,
          width: 60,
          height: 60,
        },
        circle: {
          outer: {
            x: 30.834,
            y: 34,
            r: 11,
            strokeWidth: 2,
          },
          inner: {
            x: 30.834,
            y: 34,
            r: 4,
          },
        },
        line: {
          x1: 30.8337,
          y1: 44,
          x2: 30.8337,
          y2: 229,
          d: 'M29.834 44H31.834V229H29.834V44Z',
        },
        splash: {
          opacity: 0,
          width: 120,
          height: 120,
        },
      },
      active: {
        // width: 2.275,
        width: 61,
        height: 229,
        scale: 0.00833333,
        viewBox: {
          x: 61,
          y: 229,
        },
        rect: {
          x: 0.833984,
          y: 0,
          width: 60,
          height: 60,
        },
        circle: {
          outer: {
            x: 30.834,
            y: 30,
            r: 15,
            strokeWidth: 2,
          },
          inner: {
            x: 30.834,
            y: 30,
            r: 4,
          },
        },
        line: {
          x1: 30.8337,
          y1: 44,
          x2: 30.8337,
          y2: 229,
          d: 'M29.834 44H31.834V229H29.834V44Z',
        },
        splash: {
          opacity: 1,
          width: 120,
          height: 120,
        },
      },
      zoomIn: {
        video: {
          name: 'wf_2D_Mine_Map_Survey_And_Monitoring_Zoom_In.mp4',
          backgroundImage: 'wf_2D_Mine_Map_Survey_And_Monitoring_Zoom_Out.jpg',
        },
      },
      zoomOut: {
        video: {
          name: 'wf_2D_Mine_Map_Survey_And_Monitoring_Zoom_Out.mp4',
          backgroundImage: 'wf_2D_Mine_Map.jpg',
        },
      },
    },
    {
      id: 'insights',
      label: {
        number: '06',
        title: 'insights.Insights',
      },
      ignoreMeForParentPosition: true,
      position: [740, 284],
      rotation: [0, 0],
      default: {
        label: {
          number: {
            translateX: '-36',
          },
        },
        // width: 2.275,
        width: 61,
        height: 89,
        scale: 0.00833333,
        viewBox: {
          x: 61,
          y: 89,
        },
        rect: {
          x: 0.833984,
          y: 0,
          width: 60,
          height: 60,
        },
        circle: {
          outer: {
            x: 30.834,
            y: 34,
            r: 11,
            strokeWidth: 2,
          },
          inner: {
            x: 30.834,
            y: 34,
            r: 4,
          },
        },
        line: {
          x1: 30.8337,
          y1: 44,
          x2: 30.8337,
          y2: 89,
          d: 'M29.834 44H31.834V89H29.834V44Z',
        },
        splash: {
          opacity: 0,
          width: 120,
          height: 120,
        },
      },
      active: {
        // width: 2.275,
        width: 61,
        height: 89,
        scale: 0.00833333,
        viewBox: {
          x: 61,
          y: 89,
        },
        rect: {
          x: 0.833984,
          y: 0,
          width: 60,
          height: 60,
        },
        circle: {
          outer: {
            x: 30.834,
            y: 30,
            r: 15,
            strokeWidth: 2,
          },
          inner: {
            x: 30.834,
            y: 30,
            r: 4,
          },
        },
        line: {
          x1: 30.8337,
          y1: 44,
          x2: 30.8337,
          y2: 89,
          d: 'M29.834 44H31.834V89H29.834V44Z',
        },
        splash: {
          opacity: 1,
          width: 120,
          height: 120,
        },
      },
      zoomIn: {
        video: {
          name: 'wf_2D_Mine_Map_Insights_Zoom_In.mp4',
          backgroundImage: 'wf_2D_Mine_Map_Insights-Zoom-Out.jpg',
        },
      },
      zoomOut: {
        video: {
          name: 'wf_2D_Mine_Map_Insights_Zoom_Out.mp4',
          backgroundImage: 'wf_2D_Mine_Map.jpg',
        },
      },
    },
  ],
  connectivityLines: {
    offsetX: 38.4,
    offsetY: 104,
    width: 426,
    height: 202,
  },
};

export default config;
