import Box from 'modules/common/components/Box';

export default class FleetManagement6 {
  constructor(position) {
    this.id = 'FleetManagement_6';
    this.component = Box;
    this.title = 'load-haul.Autonomous Road Trains.title';
    this.video = '4b-2.mp4';
    this.props = {
      target: 'tb5',
      position,
      hashtags: ['autonomy', 'productivity'],
    };
  }
}
