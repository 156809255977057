import React from 'react';

import './assets/styles.scss';
import { useTranslation } from 'react-i18next';

const ProgressScreen = () => {
  const { t } = useTranslation();
  return (
    <div className="contact-us-progress-wrapper">
      <div className="block-screen">
        <div className="block-screen__content">
          <div className="spinner" />
          <div className="content margin-top-text">
            <p>{t('common.Please wait')}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgressScreen;
