const description = {
  id: '3 of 5 operator-assist',
  currentNumber: 3,
  totalNumber: 5,
  code: 3,
  title: 'load-haul.Operator Assist.title',
  slug: 'operator-assist',
  pdfPath: '03OperatorAssist',
  leadLayoutKey: 'operator-assist',
  apiSlug: 'operator-assist',
  productDetailsLink: 'https://hexagon.com/products/hxgn-mineoperate-reverse-assist',
  description: 'load-haul.Operator Assist.description',
  video: {
    name: 'wf_Operator_Assist.mp4',
    stop: 10.5,
    backgroundImage: 'Operator_Assist.jpg',
  },
  drawPoints: {
    hotspots: 0.5,
    dancingAnts: 10,
    secondaries: 10.5,
  },
};

export default description;
