// @ts-nocheck
/* eslint-disable react/forbid-prop-types */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import config from 'modules/journeys-manager/configs';

import withNavigate from 'app/routers/withNavigate';
import notification from 'core/services/Notification';

import JourneysManagerIntroduction from 'modules/journeys-manager-introduction/layouts';
import JourneysManagerJourney from 'modules/journeys-manager-journey/layouts';

import actions from 'modules/journeys-manager/actions';
import * as selectors from 'modules/journeys-manager/selectors';

class Main extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      location: null,
    };
  }

  componentDidMount() {
    const { locationId, init } = this.props;
    init();
    this.init(locationId);
  }

  componentDidUpdate(prevProps) {
    const { locationId } = this.props;

    if (prevProps.locationId !== locationId) {
      this.init(locationId);
    }
  }

  componentWillUnmount() {
    const { destroy } = this.props;

    notification.closeAll();

    destroy();
  }

  onEvent = ({ action, data }) => {
    // eslint-disable-next-line react/prop-types
    const { navigate, onEvent } = this.props;

    switch (action) {
      case 'goToJourney': {
        notification.closeAll();

        navigate(`/manager/journey/${data.id}`); // without animation
        break;
      }
      case 'runJourney': {
        notification.closeAll();

        navigate(`/lead/journeys/${data.id}`); // without animation
        // this.onPathsEvent({ action: 'goToPath', path: `/manager/journey/${data.id}` }); // with animation
        break;
      }
      case 'createNewJourney': {
        navigate(`/manager/journey`); // without animation
        // this.onPathsEvent({ action: 'goToPath', path: `/manager/journey/${data.id}` }); // with animation
        break;
      }
      case 'navigationBack': {
        this.setState({
          location: null,
        });

        if (data && data.path) {
          navigate(data.path); // without animation
        } else {
          navigate(-1); // without animation
        }
        // this.onPathsEvent({ action: 'goToPath', path: `/manager/journey/${data.id}` }); // with animation
        break;
      }
      default: {
        // eslint-disable-next-line react/destructuring-assignment, react/prop-types
        onEvent({ action, data });
      }
    }
  };

  getLocation() {
    const { id, allLomixConfigs } = this.props;
    const { location } = this.state;

    const isJourneyManagement = this.isManagement();

    const basePermission = {
      createJourney: isJourneyManagement,
      edit: isJourneyManagement,
      delete: isJourneyManagement,
      duplicate: isJourneyManagement,
    };

    switch (location?.slug) {
      case 'introduction': {
        return (
          <JourneysManagerIntroduction
            onEvent={this.onEvent}
            permission={basePermission}
            allLomixConfigs={allLomixConfigs}
          />
        );
      }
      case 'journey': {
        return (
          <JourneysManagerJourney
            id={id}
            onEvent={this.onEvent}
            allLomixConfigs={allLomixConfigs}
            permission={basePermission}
          />
        );
      }
      default: {
        return null;
      }
    }
  }

  isManagement() {
    const { loggedUser } = this.props;

    return !!(
      loggedUser?.idTokenClaims?.extension_access === 'journeyManagement' ||
      loggedUser?.username?.endsWith('@hexagon.com')
    );
  }

  init(locationId = null) {
    const { journey } = this.props;

    let location = null;

    if (Array.isArray(journey?.locations)) {
      for (let i = 0, n = journey.locations.length; i < n; i += 1) {
        const lc = journey.locations[i];

        if (lc.slug === locationId) {
          location = lc;

          this.setState({
            location,
          });
          break;
        }
      }
    }

    return {
      location,
    };
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const { authed, navigate } = this.props;

    if (!authed) {
      navigate(`/workflows-overview`);
      return null;
    }

    return <>{this.getLocation()}</>;
  }
}

Main.propTypes = {
  allLomixConfigs: PropTypes.arrayOf(PropTypes.any),
  loggedUser: PropTypes.any,
  authed: PropTypes.bool,
  config: PropTypes.shape({
    className: PropTypes.string,
    delayPrimaries: PropTypes.number,
    delaySecondaries: PropTypes.number,
    staggerBreakdowns: PropTypes.number,
    delayVideoBreakdown: PropTypes.number,
  }),
  onEvent: PropTypes.func,
  init: PropTypes.func,
  destroy: PropTypes.func,
  journey: PropTypes.object,
  locationId: PropTypes.string,
  id: PropTypes.string,
};

Main.defaultProps = {
  allLomixConfigs: [],
  loggedUser: null,
  authed: false,
  config: {
    className: 'journey',
    delayPrimaries: 0.5,
    delaySecondaries: 0.25,
    staggerBreakdowns: 0.125,
    delayVideoBreakdown: 1.5,
  },
  onEvent: () => {},
  init: () => {},
  destroy: () => {},
  journey: null,
  locationId: null,
  id: null,
};

const mapStateToProps = (state) => {
  const journey = config.current;

  return {
    config: {
      className: 'journey',
      delayPrimaries: 0.5,
      delaySecondaries: 0.25,
      staggerBreakdowns: 0.125,
      delayVideoBreakdown: 1.5,
    },
    ready: selectors.isReady(state), // todo: add to this
    journey,
  };
};

const mapDispatchToProps = (dispatch) => ({
  init: (payload) => dispatch(actions.init({ payload })),
  destroy: (payload) => dispatch(actions.destroy({ payload })),
});

export default withNavigate(connect(mapStateToProps, mapDispatchToProps)(Main));
