/* eslint-disable react/destructuring-assignment,no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable array-callback-return */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-destructuring */
/* eslint-disable consistent-return */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/button-has-type */
// @ts-nocheck
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import LOMIXConfig from 'LOMIXConfig';
import * as selectors from 'app/selectors';
import actions from 'app/actions';
import configApp from 'app/configs';

import LocationSvg from 'app/assets/icons/location.svg';
import ThreeDSvg from 'app/assets/icons/threed.svg';
import { sendGTEvent } from 'modules/analytics/services/googleAnalytics';

import Box from 'modules/common/components/Box';
import ArrowButton from 'modules/common/components/ArrowButton';
import { withTranslation } from 'react-i18next';

// noinspection JSUnresolvedVariable
class PathsNew extends PureComponent {
  constructor(props) {
    super(props);

    this.enableSiteMap = configApp.enableSiteMap;

    this.paths = React.createRef();
  }

  componentDidMount() {
    this.paths.current.init().animate();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.journeyId !== this.props.journeyId) {
      this.paths.current.safariFix();
    }
  }

  toggle = () => {
    const { togglePaths } = this.props;
    togglePaths();
  };

  openSiteMap = () => {
    const { onEvent } = this.props;

    onEvent({
      action: 'openSiteMap',
    });
  };

  open3dMap = (path) => {
    const { onEvent } = this.props;
    onEvent({ action: 'goToPath', path });
  };

  goToJourney(path = null) {
    const { isWorkflowsOverview, togglePaths, setToJourney, setReplaceJourney, setCanNavigate } =
      this.props;

    if (this.props.path === path) {
      return false;
    }

    togglePaths({ isOpen: false });

    if (isWorkflowsOverview) {
      setReplaceJourney(true);
    }
    setToJourney(path);
    setCanNavigate(true);
  }

  render() {
    const {
      config,
      isOpen,
      isWorkflowsOverview,
      navigableJourneys,
      journeyId,
      availableWorkflowsOverview,
      t,
      showPathSiteMap,
    } = this.props;

    const activeClasses = 'router-link-exact-active router-link-active';

    const mapLabel = t('workflow.Mine Map');
    let workflowsOverviewPath = null;

    if (availableWorkflowsOverview) {
      workflowsOverviewPath = '/workflows-overview';
    } else {
      workflowsOverviewPath = '/workflows-overview/mine-map';
    }

    return (
      <Box
        ref={this.paths}
        position={[0, 'auto']}
        additionalClasses={[config.className, isOpen ? config.openClass : '']}
        contentAdditionalClasses={[`${config.className}__content`]}
      >
        <header className={`${config.className}__header`}>
          <div className={`${config.className}__title`}>
            <div className={`${config.className}__title-name`} />
            {!isWorkflowsOverview && (
              <ArrowButton
                additionalClasses={[`${config.className}__title-arrow`]}
                direction="backwards"
                onClick={(e) => {
                  e.preventDefault();
                  this.toggle(e);
                }}
              />
            )}
          </div>
        </header>
        <nav className="paths-new__nav">
          {navigableJourneys.map((journey) => {
            return (
              <div key={journey.slug} className={`${config.className}__nav-link`}>
                <Link
                  className={`${journeyId === journey.slug ? `${activeClasses} ` : ''}${
                    config.className
                  }__nav-item ${journey.disable ? 'disabled-link' : ''} `}
                  to={{ pathname: `/${journey.slug}` }}
                  event=""
                  onClick={($event) => {
                    $event.preventDefault();
                    if (!journey.disable) {
                      this.goToJourney(`/${journey.slug}`);
                    }
                  }}
                >
                  <span className="paths-new__nav-number">0{journey.id}</span>
                  <span className="paths-new__nav-name">{t(journey.title)}</span>
                </Link>
              </div>
            );
          })}
        </nav>
        <div className={`${config.className}__footer`}>
          {this.enableSiteMap && showPathSiteMap ? (
            <Link
              className={`${config.className}__nav-item`}
              to={{ pathname: `/site-map` }}
              event=""
              onClick={($event) => {
                $event.preventDefault();
                sendGTEvent({ event: 'site_map_open' });

                this.openSiteMap($event);
              }}
            >
              <i className="icon-left">
                <LocationSvg />
              </i>
              <span className="site-map_link site-map_link__margin">{t('Site Map')}</span>
            </Link>
          ) : null}
          <Link
            className={`${config.className}__nav-item`}
            onClick={($event) => {
              $event.preventDefault();
              sendGTEvent({ event: 'navigate_to_3d_map' });
              this.open3dMap(workflowsOverviewPath);
            }}
            to={{ pathname: workflowsOverviewPath }}
          >
            <i className="icon-left">
              <ThreeDSvg height={22} width={22} />
            </i>
            <span className="site-map_link site-map_link__margin">{mapLabel}</span>
          </Link>
        </div>
      </Box>
    );
  }
}

PathsNew.propTypes = {
  config: PropTypes.shape({
    className: PropTypes.string,
    openClass: PropTypes.string,
  }),
  isWorkflowsOverview: PropTypes.bool,
  isOpen: PropTypes.bool,
  navigableJourneys: PropTypes.array,
  onEvent: PropTypes.func,
  togglePaths: PropTypes.func,
  t: PropTypes.func,
  showPathSiteMap: PropTypes.bool,
};

PathsNew.defaultProps = {
  config: {
    className: 'paths-new',
    openClass: 'is-open',
  },
  isWorkflowsOverview: false,
  isOpen: false,
  navigableJourneys: [],
  onEvent: () => {},
  togglePaths: () => {},
  t: () => {},
  showPathSiteMap: true,
};

PathsNew.id = 'PathsNew';

const mapStateToProps = (state, props) => {
  const isWorkflowsOverview = props.journeyId === 'workflows-overview';
  const navigableJourneys = LOMIXConfig.filter((j) => j.isInPaths);

  const isOpen = selectors.arePathsOpen(state);

  const location = selectors.getLocation(state);
  const path = location?.slug;

  return {
    isWorkflowsOverview,
    isOpen,
    navigableJourneys,
    path,
    availableWorkflowsOverview: selectors.isAvailableWorkflowsOverview(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  togglePaths: (payload) => dispatch(actions.togglePaths({ payload })),
  setToJourney: (payload) => dispatch(actions.setToJourney({ payload })),
  setReplaceJourney: (payload) => dispatch(actions.setReplaceJourney({ payload })),
  setCanNavigate: (payload) => dispatch(actions.setCanNavigate({ payload })),
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(PathsNew),
);
