const description = {
  id: '7 of 7',
  currentNumber: 7,
  totalNumber: 7,
  title: 'drill-blast.DrillBlastInsights.title',
  slug: 'drill-blast-insights',
  pdfPath: 'Drill&BlastInsights',
  leadLayoutKey: 'drill-blast-insights',
  apiSlug: 'drill-blast-insights',
  productDetailsLink: 'https://hexagon.com/products/hexagon-blast-movement-monitoring',
  description: 'drill-blast.DrillBlastInsights.description',
  video: {
    backgroundImage: 'Drill_And_Blast_Insights.jpg',
    playDefaultSound: true,
    backgroundImageDestroyTime: 1.5,
  },
  drawPoints: {
    hotspots: 1,
    dancingAnts: 1.5,
    secondaries: 3,
  },
};

export default description;
