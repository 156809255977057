import Box from 'modules/common/components/Box';

export default class EvaluationPlanningDesignResourceModel3 {
  constructor(position) {
    this.id = 'EvaluationPlanningDesignResourceModel3';
    this.component = Box;
    this.title = 'evaluationPlanningDesign.Resource Model.elements.Block Model Manager';
    this.video = 'md_Block_Model_Manager.mp4';
    this.props = {
      target: 'tb2',
      position,
      hashtags: ['digitalisation'],
    };
  }
}
