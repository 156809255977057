// @ts-nocheck
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import './assets/scss/index.scss';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as selectors from 'app/selectors';
import HASHTAGS from 'app/configs/hashtags';
import { useTranslation } from 'react-i18next';
import Round from './assets/icons/round.svg';
import EditHashtags from '../Hashtags/EditHashtags';

const Tags = ({ loggedUser }) => {
  const [userInterests, setUserInterests] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const interest = loggedUser?.interest || {};
    const filtered = Object.entries(interest).filter(([, value]) => value) || [];
    setUserInterests(filtered.map((item) => item[0]));
  }, [loggedUser]);

  const openEditInterestsModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="tags">
        <div className="description">{t('auth.Information priority')}:</div>
        <div
          className="list  item-button"
          onClick={openEditInterestsModal}
          onKeyDown={openEditInterestsModal}
          role="button"
          tabIndex="0"
        >
          <div className="icon">
            <Round />
          </div>
          <div className="list-links">
            {userInterests &&
              userInterests.map((i) => {
                return `#${t(HASHTAGS[i]?.name)} `;
              })}
          </div>
          <i className="arrow right" />
        </div>
      </div>
      <EditHashtags isOpen={isModalOpen} onClose={closeModal} />
    </>
  );
};

Tags.propTypes = {
  loggedUser: PropTypes.object,
};

Tags.defaultProps = {
  loggedUser: null,
};

const mapStateToProps = (state) => {
  return { loggedUser: selectors.getLoggedUser(state) };
};

export default connect(mapStateToProps)(Tags);
