/**
 * @description each validation function must return function
 * if validation failed function must return object with validation error
 * if validation pass then `null`
 */

export function pattern(regExpression) {
  return (formValue) => {
    return regExpression && regExpression.test(formValue) ? null : { pattern: true };
  };
}

export function required() {
  return (formValue) => {
    let isValid = true;

    switch (typeof formValue) {
      case 'number':
        isValid = !!formValue || formValue === 0;
        break;
      case 'boolean':
        isValid = formValue === true || formValue === false;
        break;
      case 'undefined':
        isValid = false;
        break;
      case 'symbol':
        isValid = true;
        break;
      default:
        isValid = !!formValue;
    }

    return isValid ? null : { required: true };
  };
}

export function minArrayLength(minNumber) {
  return (formValue) => {
    return formValue && formValue.length >= minNumber ? null : { minArrayLength: true };
  };
}
