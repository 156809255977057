import * as consts from 'modules/journeys-manager/constants';

import BaseActions from 'core/actions';

class Actions extends BaseActions {
  init({ payload = {}, meta = {}, status = null } = {}) {
    return this.create(this.getType('init', status), status, payload, meta);
  }

  destroy({ payload = {}, meta = {}, status = null } = {}) {
    return this.create(this.getType('destroy', status), status, payload, meta);
  }

  // getType = (action, status = '', type = '') => {
  //   let newType =
  //     consts[
  //       `${consts.NAME.toUpperCase()}${
  //         type ? `_${type.toUpperCase()}` : ''
  //       }_${action.toUpperCase()}`
  //     ];

  //   if (status) {
  //     newType += `/${consts.STATUS}/${status.toLowerCase()}`;
  //   }

  //   return newType;
  // };

  getType = (action, status = '', type = '') => {
    const constName = `${consts.NAME}${type ? '_' : ''}${type}_${action}`.toUpperCase();

    let newType = consts[constName];

    if (status) {
      newType += `/${consts.STATUS}/${status.toLowerCase()}`;
    }

    return newType;
  };

  getTypeWithStatus = (type, status = '') => {
    if (status) {
      return `${type}/${consts.STATUS}/${status.toLowerCase()}`;
    }

    return type;
  };
}

const acts = new Actions();
Object.freeze(acts);

export default acts;
