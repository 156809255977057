// @ts-nocheck
export function formatDate(dt) {
  const date = new Date(dt);

  const uMonth = `0${date.getUTCMonth() + 1}`.slice(-2);
  const uDate = `0${date.getUTCDate()}`.slice(-2);

  return `${uDate}/${uMonth}/${date.getUTCFullYear()}`;
}

export function formatTime(dt) {
  const date = new Date(dt);

  const uHours = `0${date.getUTCHours()}`.slice(-2);
  const uMinutes = `0${date.getUTCMinutes()}`.slice(-2);

  return `${uHours}:${uMinutes}`;
}
