import description from './description';
import UndergroundDevelopmentDrilling1 from './elements/undergroundDevelopmentDrilling_1';
import UndergroundDevelopmentDrilling2 from './elements/undergroundDevelopmentDrilling_2';
import UndergroundDevelopmentDrilling3 from './elements/undergroundDevelopmentDrilling_3';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [16.1, 79.6], width: 12.75 }],
  dancingAnts: [['hs1', [0, 48.3], { direction: 'reverse' }]],
  tinyBois: [
    { id: 'tb1', position: [16.1, 79.6], offset: [11.2, 9.8] },
    { id: 'tb2', position: [16.1, 79.6], offset: [11.2, 2.4] },
  ],

  elements: [
    new UndergroundDevelopmentDrilling1([19.8, 43.6]),
    new UndergroundDevelopmentDrilling2([27.6, 77.43], {
      target: 'tb2',
      cornerFrom: ['top', 'left'],
    }),
    new UndergroundDevelopmentDrilling3([27.6, 92.7], { target: 'tb1' }),
  ],
};

export default config;
