/* eslint-disable react/button-has-type */
import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import './assets/styles/ButtonAccept.scss';
import { withTranslation } from 'react-i18next';

class ButtonAccept extends PureComponent {
  handleClick = () => {
    const { onClick } = this.props;
    onClick({ action: 'openContactUs' });
  };

  render() {
    const { t } = this.props;
    return (
      <div className="c-accept">
        <button className="c-button c-button--size-default" onClick={this.handleClick}>
          <div className="c-button__text"> {t('reload 3D version')} </div>
        </button>
      </div>
    );
  }
}

ButtonAccept.propTypes = {
  onClick: PropTypes.func,
  t: PropTypes.func,
};

ButtonAccept.defaultProps = {
  onClick: () => {},
  t: () => {},
};

export default withTranslation()(connect(null, null, null, { forwardRef: true })(ButtonAccept));
