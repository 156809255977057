// @ts-nocheck
/* eslint-disable react/button-has-type,react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import Download from './assets/icons/download.svg';

const DownloadInfo = ({ handleConfirmDownload, handleClose }) => {
  const { t } = useTranslation();
  return (
    <>
      <h2>{t('download.Download offline version')}</h2>
      <div>{t('Explore interactive 3D Life-of-Mine Experience in offline mode')}</div>
      <div className="text__line_margin-top">
        {t('download.Once downloaded, 3D version will be available in online as well')}
      </div>
      <div className="padding-top">
        {t('download.Confirm your action by clicking Download button')}
        <div className="note-text padding-top blue-text">
          {t(
            'download.Please note that the application will use approximately memory and requires RAM for the 3D interaction',
          )}
        </div>
      </div>
      <div className="c-buttons-list margin-top">
        <button className="c-button" onClick={handleClose}>
          {t('common.Cancel')}
        </button>
        <button className="c-button" onClick={handleConfirmDownload}>
          <Download /> {t('download.Download')}
        </button>
      </div>
    </>
  );
};

export default DownloadInfo;
