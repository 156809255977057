import configAPP from 'app/configs';
import description from './description';
import FleetManagement1 from './elements/FleetManagement1';
import FleetManagement2 from './elements/FleetManagement2';
import FleetManagement3 from './elements/FleetManagement3';
import FleetManagement4 from './elements/FleetManagement4';
import FleetManagement5 from './elements/FleetManagement5';
import FleetManagement6 from './elements/FleetManagement6';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [16, 51.5], width: 12.75 }],
  dancingAnts: [
    ['hs1', [0, 22.36]],
    ['hs1', [39.89, 100], { direction: 'reverse' }],
  ],
  tinyBois: [
    { id: 'tb1', position: [16, 51.5], offset: [4.9240625 * 2, 0.5178125 * 2] },
    { id: 'tb2', position: [16, 51.5], offset: [5.89 * 2, 1.8615625 * 2] },
    { id: 'tb3', position: [16, 51.5], offset: [0.2, 9.4] },
    { id: 'tb4', position: [16, 51.5], offset: [6.1 * 2, 3.4553125 * 2] },
    // eslint-disable-next-line prettier/prettier
    configAPP.enableExhibition ? { id: 'tb5', position: [16, 51.5], offset: [3.1 * 2, 6.15 * 2] } : undefined,
  ],
  elements: [
    new FleetManagement1([12, 18.5]),
    new FleetManagement2([30.9, 42.5], { cornerFrom: ['top', 'left'] }),
    new FleetManagement3([30.9, 54.5], { cornerFrom: ['top', 'left'] }),
    new FleetManagement4([10, 78.5]),
    new FleetManagement5([30.9, 66.5], { cornerFrom: ['top', 'left'] }),
    configAPP.enableExhibition ? new FleetManagement6([30.9, 78.5]) : undefined,
  ],
};

export default config;
