import Box from 'modules/common/components/Box';

export default class UndergroundProductionDrilling2 {
  constructor(position, props = {}) {
    this.id = 'undergroundProductionDrilling_2';
    this.component = Box;
    this.type = 'content';
    this.title = 'underground.Production Drilling.elements.Increase profitability';
    this.content = 'underground.Production Drilling.elements.Increase profitability Content';
    this.props = {
      target: props.target || 'tb1',
      position,
      hashtags: ['productivity'],
      cornerFrom: props.cornerFrom || null,
    };
  }
}
