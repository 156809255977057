/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable react/button-has-type */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/forbid-prop-types */
// @ts-nocheck

import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import { CSSTransition } from 'react-transition-group';

import { isTablet, isFirefox } from 'react-device-detect';
import ArrowButton from 'modules/common/components/ArrowButton';

import './assets/scss/index.scss';
import { useTranslation } from 'react-i18next';
// import SpreadOut from './assets/icons/spreadOut.svg';

import BroshureSvg from './assets/icons/brochure.svg';
import DataSheetsSvg from './assets/icons/dataSheets.svg';
import CaseStudySvg from './assets/icons/caseStudy.svg';
import BenefitsSvg from './assets/icons/benefits.svg';
import PdfViewerContainer from './PdfViewerContainer';
// import PdfViewer from './PdfViewer';

const config = {
  className: 'c-product-info-modal',
  openClass: 'is-open',
};

const ProductDetailModal = ({ isOpen, location, handleClose, locationFiles }) => {
  const [currentFile, setCurrentFile] = useState(null);

  const [currentFilePath, setCurrentFilePath] = useState('');
  const { t } = useTranslation();

  const iframeRef = useRef();

  const openThisFile = (file) => {
    setCurrentFile(file);
    setCurrentFilePath(file.url);
  };

  const addPossibleBreak = (title) => {
    return title.replace(/\u005F/g, '\u005F\u200B');
  };

  const getIcon = (file) => {
    if (file.type.includes('Brochure')) {
      return <BroshureSvg />;
    }
    if (file.type.includes('DataSheets')) {
      return <DataSheetsSvg />;
    }
    if (file.type.includes('CaseStudy')) {
      return <CaseStudySvg />;
    }

    if (file.type.includes('Benefits')) {
      return <BenefitsSvg />;
    }

    return <BroshureSvg />;
  };

  const onClosePanel = () => {
    setCurrentFile(null);
    setCurrentFilePath('');
    handleClose();
  };

  // useEffect(() => {
  //   if (iframeRef.current && currentFilePath) {
  //     iframeRef.current.setAttribute('src', `${currentFilePath}#view=fitH`);
  //   }

  //   // console.log('currentFilePath', currentFilePath);
  // }, [currentFilePath, iframeRef]);

  return (
    <CSSTransition in={isOpen} timeout={0} classNames="info-visible" unmountOnExit>
      <div className={`${config.className} ${isOpen ? config.openClass : ''}`}>
        <div className="files-window">
          <div className="close-container">
            <ArrowButton direction="backwards" onClick={onClosePanel} />
            {/* <SpreadOut onClick={onClosePanel} /> */}
          </div>

          <div className="files-wrapper">
            <div className="files-names">
              <div className="title">
                {t(location?.title)} {t('pdf.PRODUCT DETAILS')}
              </div>
              <div className="file-list">
                {locationFiles?.length > 0 &&
                  locationFiles.map((file) => {
                    return (
                      <div
                        className={`file ${file.id === currentFile?.id ? 'active' : ''}`}
                        key={file.id}
                        onClick={() => {
                          openThisFile(file);
                        }}
                      >
                        {isTablet ? (
                          <a
                            href={currentFilePath}
                            target="_blank"
                            rel="noreferrer"
                            className="tablet-link"
                          >
                            <div className="icon">{getIcon(file)}</div>
                            <span>{addPossibleBreak(file?.title)}</span>
                          </a>
                        ) : (
                          <>
                            <div className="icon">{getIcon(file)}</div>
                            <span>{addPossibleBreak(file?.title)}</span>
                          </>
                        )}
                      </div>
                    );
                  })}
              </div>
              <div className="product-link">
                <a href={location?.productDetailsLink} target="_blank" rel="noreferrer">
                  {t('pdf.See')} {t(location?.title)} {t('pdf.at')} hexagon.com
                </a>
              </div>
            </div>

            <div className="files-content">
              {currentFilePath ? (
                <PdfViewerContainer currentFilePath={currentFilePath} title={currentFile.name} />
              ) : (
                <div className="empty-pdf">
                  {isTablet
                    ? t('pdf.Please select a file to preview Due to technical limitations')
                    : t('pdf.Please select a file to preview')}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

ProductDetailModal.propTypes = {
  isOpen: PropTypes.bool,
  location: PropTypes.any,
  handleClose: PropTypes.func,
  locationFiles: PropTypes.array,
};

ProductDetailModal.defaultProps = {
  isOpen: false,
  location: {},
  handleClose: () => {},
  locationFiles: [],
};

export default connect()(ProductDetailModal);
