// @ts-nocheck
const config = {
  enableSiteMap: !!(
    process.env.REACT_APP_ENABLE_SITE_MAP === true ||
    process.env.REACT_APP_ENABLE_SITE_MAP === 'true'
  ),
  enableDownload: !!(
    process.env.REACT_APP_ENABLE_DOWNLOAD === true ||
    process.env.REACT_APP_ENABLE_DOWNLOAD === 'true'
  ),
  enableExhibition: process.env.REACT_APP_VERSION_TYPE === 'exhibition',
  buildForChina: !!(
    process.env.REACT_APP_BUILD_FOR_CHINA === true ||
    process.env.REACT_APP_BUILD_FOR_CHINA === 'true'
  ),
  enableJourneysForExhibition: process.env.REACT_APP_VERSION_TYPE === 'exhibition' && false,
};

Object.freeze(config);

export default config;
