import description from './description';
import DrillBlastBlastMovement1 from './elements/drillBlastBlastMovement_1';
import DrillBlastBlastMovement2 from './elements/drillBlastBlastMovement_2';
import DrillBlastBlastMovement3 from './elements/drillBlastBlastMovement_3';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [58, 71.5], width: 14.52, rotation: [35, 0] }],
  dancingAnts: [['hs1', [34.06, 100], { direction: 'reverse' }]],
  tinyBois: [
    { id: 'tb1', position: [58, 71.5], offset: [11.2, 2.2] },
    { id: 'tb2', position: [58, 71.5], offset: [11.2, 11] },
  ],
  elements: [
    new DrillBlastBlastMovement1([65, 23]),
    new DrillBlastBlastMovement2([68, 44.3]),
    new DrillBlastBlastMovement3([75, 67]),
  ],
};

export default config;
