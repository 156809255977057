import Box from 'modules/common/components/Box';

export default class FleetManagement4 {
  constructor(position) {
    this.id = 'FleetManagement4';
    this.component = Box;
    this.title = 'load-haul.Fleet Management.title';
    this.video = 'md_Fleet_Management.mp4';
    this.props = {
      target: 'tb3',
      position,
      hashtags: ['cost', 'productivity'],
    };
  }
}
