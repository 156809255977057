import description from './description';
import AssetHealth1 from './elements/AssetHealth1';
import AssetHealth2 from './elements/AssetHealth2';
import AssetHealth3 from './elements/AssetHealth3';
import AssetHealth4 from './elements/AssetHealth4';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [15, 42.6], width: 5 }],
  dancingAnts: [['hs1', [0, 24], { direction: 'reverse' }]],
  tinyBois: [
    { id: 'tb1', position: [15, 42.6], offset: [4.5, 1.475] },
    { id: 'tb2', position: [15, 42.6], offset: [2.2, 4.7] },
  ],
  elements: [
    new AssetHealth1([11.6, 16.5]),
    new AssetHealth2([27.5, 42.62]), // Asset Health Content
    new AssetHealth3([11.6, 56.25]), // Asset Health video
    new AssetHealth4([34.1, 56.25]), // Enterprise Asset Management Content
  ],
};

export default config;
