// @ts-nocheck
/* eslint-disable react/button-has-type,react/prop-types */
import React from 'react';

import Button from 'modules/common/components/Button';

import { useTranslation } from 'react-i18next';
import ButtonClose from './ButtonClose';

const FinishInfo = ({ name, handleClose, handleExploreMore, handleWatchJourneyAgain }) => {
  const { t } = useTranslation();
  return (
    <>
      <div
        style={{
          position: 'absolute',
          top: '1.25rem',
          right: '1.25rem',
          zIndex: 10,
        }}
      >
        <ButtonClose onClick={handleClose} />
      </div>
      <h2>{t('journeys-manager.YOU HAVE FINISHED THIS JOURNEY')}</h2>
      <div className="text__line_margin-top">
        {t('journeys-manager.You have explored', { name })}
      </div>
      <div className="padding-top">{t('journeys-manager.Explore even more')}</div>
      <div className="c-buttons-list margin-top">
        <div
          className="text-support margin-left text-center blue-text"
          onClick={handleWatchJourneyAgain}
          onKeyPress={handleWatchJourneyAgain}
          role="button"
          tabIndex={0}
        >
          {t('journeys-manager.Watch journey again')}
        </div>
        <div className="button margin-left">
          <div className="stage-card__buttons">
            <Button onClick={handleExploreMore}> {t('journeys-manager.Explore More')}</Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FinishInfo;
