import BaseActions from 'core/actions';
import {
  JOURNEYS_MANAGER_JOURNEY_CLEAR_DATA,
  JOURNEYS_MANAGER_JOURNEY_CLEAR_DUPLICATE,
  JOURNEYS_MANAGER_JOURNEY_CLEAR_STORE,
  JOURNEYS_MANAGER_JOURNEY_CREATE,
  JOURNEYS_MANAGER_JOURNEY_DELETE,
  JOURNEYS_MANAGER_JOURNEY_DUPLICATE,
  JOURNEYS_MANAGER_JOURNEY_GET,
  JOURNEYS_MANAGER_JOURNEY_UPDATE,
  STATUS,
} from 'modules/journeys-manager-journey/constants';

class Actions extends BaseActions {
  /**
   * @description payload: {id: string}, meta, status
   */
  doGetRequest({ payload = {}, meta = {}, status = null } = {}) {
    return this.create(
      this.getTypeWithStatus(JOURNEYS_MANAGER_JOURNEY_GET, status),
      status,
      payload,
      meta,
    );
  }

  createJourney({ payload = {}, meta = {}, status = null } = {}) {
    return this.create(
      this.getTypeWithStatus(JOURNEYS_MANAGER_JOURNEY_CREATE, status),
      status,
      payload,
      meta,
    );
  }

  updateJourney({ payload = {}, meta = {}, status = null } = {}) {
    return this.create(
      this.getTypeWithStatus(JOURNEYS_MANAGER_JOURNEY_UPDATE, status),
      status,
      payload,
      meta,
    );
  }

  deleteJourney({ payload = {}, meta = {}, status = null } = {}) {
    return this.create(
      this.getTypeWithStatus(JOURNEYS_MANAGER_JOURNEY_DELETE, status),
      status,
      payload,
      meta,
    );
  }

  duplicateJourney({ payload = {}, meta = {}, status = null } = {}) {
    return this.create(
      this.getTypeWithStatus(JOURNEYS_MANAGER_JOURNEY_DUPLICATE, status),
      status,
      payload,
      meta,
    );
  }

  clearStore({ payload = {}, meta = {}, status = null } = {}) {
    return this.create(
      this.getTypeWithStatus(JOURNEYS_MANAGER_JOURNEY_CLEAR_STORE, status),
      status,
      payload,
      meta,
    );
  }

  clearData({ payload = {}, meta = {}, status = null } = {}) {
    return this.create(
      this.getTypeWithStatus(JOURNEYS_MANAGER_JOURNEY_CLEAR_DATA, status),
      status,
      payload,
      meta,
    );
  }

  clearDuplicate({ payload = {}, meta = {}, status = null } = {}) {
    return this.create(
      this.getTypeWithStatus(JOURNEYS_MANAGER_JOURNEY_CLEAR_DUPLICATE, status),
      status,
      payload,
      meta,
    );
  }

  getTypeWithStatus = (type, status = '') => {
    if (status) {
      return `${type}/${STATUS}/${status.toLowerCase()}`;
    }

    return type;
  };
}

const acts = new Actions();
Object.freeze(acts);

export default acts;
