/* eslint-disable react/no-unknown-property */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/default-props-match-prop-types */
// @ts-nocheck
/* eslint-disable consistent-return */
/* eslint-disable global-require */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-dynamic-require */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import ReactPlayer from 'react-player';

import actions from 'app/actions';
import { withTranslation } from 'react-i18next';

// import { isFirefox, isChrome, isSafari } from 'react-device-detect';

class VideoBackground extends PureComponent {
  constructor(props) {
    super(props);

    this.backgroundImageRef = React.createRef();
    this.backgroundVideoRef = React.createRef();
    this.audioPlayerRef = React.createRef();
    // this.startLoadVideo = true;
    this.videoTimeStop = 0;
    this.endedVideo = false;
    this.timeForDecreaseVolume = 1.5;
    this.currentTimeForDecreaseVolume = 0;
    this.volumeForDecreaseVolume = 0;

    this.state = {
      mutedAudio: this.props.muted,
      playDefaultAudio: this.props.playDefaultAudio,
      mutedDefaultAudio: this.props.muted,
      volumeDefaultAudio: 0,
    };

    this.currentTime = 0;
    this.currentVideoLanguage = props.i18n.language;
  }

  componentDidMount() {
    console.log('DEBUG:VIDEO:componentDidMount');
    const videoRef = this.backgroundVideoRef.current;
    // console.log('DEBUG:COMMON:LOCATION:VIDEO:componentDidMount', !!videoRef);
    if (videoRef) {
      videoRef.oncanplay = this.onVideoLoaded;
      videoRef.ontimeupdate = this.onVideoTimeUpdate;
      videoRef.onended = this.onVideoEnded;

      this.videoTimeStop = this.props.video.stop;
    }

    if (!this.state.mutedDefaultAudio) {
      this.playAudio();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps?.videoSettings?.play !== this.props?.videoSettings?.play) {
      const videoRef = this.backgroundVideoRef.current;
      if (videoRef) {
        videoRef.currentTime = this.currentTime;

        if (this.props.videoSettings.play) {
          if (this.currentTime > 0) {
            this.videoTimeStop = videoRef.duration;
          } else {
            this.videoTimeStop = this.props.video.stop;
          }

          videoRef.muted = this.state.mutedAudio;
          videoRef.playsInline = true;

          const promise = videoRef?.play();

          if (promise) {
            promise
              .then(() => {
                // Autoplay started
              })
              .catch((error) => {
                console.warn('Autoplay was prevented');
                // Autoplay was prevented.
                this.setAudioSettings(true);
                videoRef.muted = true;
                videoRef.playsInline = true;
                videoRef?.play();
              })
              .catch((error) => {
                console.warn(
                  'Something wrong. Please,check user permissions for playing audio and video',
                  error,
                );

                this.props.videoStopped(false, false);
              });
          }
        } else {
          // console.log('VIDEO:componentDidUpdate:video:pause');
          videoRef.pause();
        }
      }
    }
    // console.log('VIDEO:componentDidUpdate', prevProps.video !== this.props.video);
    /* console.log(
      'DEBUG:COMMON:LOCATION:VIDEO:componentDidUpdate:START:',
      prevProps.video?.name !== this.props.video?.name,
      prevProps.video?.name,
      this.props.video?.name,
      '---VIDEO--REF--',
      !!this.backgroundVideoRef?.current,
      '--TIME--',
      this.backgroundVideoRef?.current?.currentTime,
    );
    console.log(
      'DEBUG:COMMON:LOCATION:VIDEO:componentDidUpdate:onVideoEnded',
      !this.backgroundVideoRef?.current && !this.endedVideo,
      !this.backgroundVideoRef?.current,
      !this.endedVideo,
    ); */
    if (prevProps.video?.name !== this.props.video?.name) {
      this.currentTime = 0;
      this.startDecreaseVolume = false;
      this.endedVideo = false;
      const videoRef = this.backgroundVideoRef?.current;

      if (!videoRef) {
        // console.log('VIDEO:componentDidUpdate:!videoRef');
        this.setState({
          playDefaultAudio: true,
        });
        this.onVideoEnded();
      } else {
        // console.log('VIDEO:componentDidUpdate:videoRef');
        this.setState({
          playDefaultAudio: this.props.playDefaultAudio,
        });

        videoRef.oncanplay = this.onVideoLoaded;
        videoRef.ontimeupdate = this.onVideoTimeUpdate;
        videoRef.onended = this.onVideoEnded;
        videoRef.playsInline = true;
      }
    } else if (!this.backgroundVideoRef?.current && !this.endedVideo) {
      this.currentTime = 0;
      /* console.log(
        'VIDEO:onVideoEnded:!this.backgroundVideoRef?.current && !this.endedVideo',
        !this.backgroundVideoRef?.current && !this.endedVideo,
      ); */
      this.setState({
        playDefaultAudio: true,
      });
      this.endedVideo = true;
      this.startDecreaseVolume = false;
      this.onVideoEnded();
    }

    if (prevProps.muted !== this.props.muted) {
      this.setState({
        mutedDefaultAudio: this.props.muted,
        mutedAudio: this.props.muted,
      });
    }

    if (prevProps.decreaseVolume !== this.props.decreaseVolume) {
      if (this.props.decreaseVolume) {
        this.startDecreaseVolume = true;
      } else {
        this.startDecreaseVolume = false;
      }
    }

    if (
      prevState.mutedDefaultAudio !== this.state.mutedDefaultAudio &&
      !this.state.mutedDefaultAudio
    ) {
      this.playAudio();
    }
  }

  componentWillUnmount() {
    const videoRef = this.backgroundVideoRef.current;

    if (videoRef) {
      videoRef.oncanplay = null;
      videoRef.ontimeupdate = null;
      videoRef.onended = null;
    }
  }

  onVideoLoaded = () => {
    if (this.backgroundVideoRef.current) {
      this.backgroundVideoRef.current.volume = 0.5;
    }

    this.props.videoLoaded();

    if (this.currentVideoLanguage !== this.props.i18n.language) {
      const videoRef = this.backgroundVideoRef.current;
      videoRef.currentTime = this.currentTime;
      videoRef.play();
    }

    this.currentVideoLanguage = this.props.i18n.language;
  };

  onVideoTimeUpdate = () => {
    if (this.currentVideoLanguage !== this.props.i18n.language) {
      return;
    }

    const videoRef = this.backgroundVideoRef.current;
    /* console.log(
      'DEBUG:COMMON:LOCATION:VIDEO:onVideoTimeUpdate',
      videoRef,
      videoRef?.currentTime,
      videoRef?.duration,
      this.currentTime,
      this.videoTimeStop,
    ); */
    if (videoRef) {
      if (this.currentTime >= videoRef?.duration) {
        this.endedVideo = true;
        this.onVideoEnded();
        return;
      }

      this.currentTime = videoRef.currentTime;

      if (this.currentTime >= this.videoTimeStop) {
        videoRef.pause();
        this.props.videoStopped(false, false);
      } else if (this.videoTimeStop - this.currentTime <= 1 && !this.state.playDefaultAudio) {
        this.setState({
          playDefaultAudio: true,
          mutedDefaultAudio: this.props.muted,
        });
      }
    }
  };

  onVideoEnded = () => {
    // console.log('DEBUG:COMMON:LOCATION:VIDEO:onVideoEnded', true);
    this.props.videoEnded(true);
  };

  onProgressAudioPlayer = (e) => {
    // const duration = this.audioPlayerRef.current.getDuration() / 2;
    const currentTime = this.audioPlayerRef.current.getCurrentTime();

    let volume = null;

    if (this.startDecreaseVolume) {
      if (this.state.volumeDefaultAudio !== 0) {
        const endTime = Date.now();

        if (this.currentTimeForDecreaseVolume === 0) {
          this.volumeForDecreaseVolume = this.state.volumeDefaultAudio;
          this.currentTimeForDecreaseVolume = endTime;
          volume = this.state.volumeDefaultAudio - 0.1;
        } else {
          const diff = endTime - this.currentTimeForDecreaseVolume;
          if (diff > this.timeForDecreaseVolume) {
            this.startDecreaseVolume = false;
            this.currentTimeForDecreaseVolume = 0;
            this.volumeForDecreaseVolume = 0;
          }

          volume = this.volumeForDecreaseVolume * (1 - diff / this.timeForDecreaseVolume);
        }
      } else {
        this.startDecreaseVolume = false;
        this.currentTimeForDecreaseVolume = 0;
        this.volumeForDecreaseVolume = 0;
        volume = 0;
      }
    } else {
      volume = 1;

      if (currentTime <= 1 && this.state.volumeDefaultAudio !== 1) {
        volume = this.normilize(this.audioPlayerRef.current.getCurrentTime(), 0, 1) + 0.4;
      }
    }

    if (volume > 1) {
      volume = 1;
    } else if (volume < 0) {
      volume = 0;
    }

    this.setState({
      volumeDefaultAudio: volume,
    });
  };

  normilize = (val, min, max) => {
    return (val - min) / (max - min);
  };

  setAudioSettings = (value) => {
    this.props.setAudioMuted({ muted: value });
  };

  onPlay = () => {
    const videoRef = this.backgroundVideoRef.current;

    if (videoRef) {
      videoRef.muted = false;
      videoRef?.play();
    }
  };

  playAudio = () => {
    const audio = this.audioPlayerRef?.current?.getInternalPlayer();

    const promise = audio?.play();

    if (promise) {
      promise
        .then(() => {
          // Autoplay started
        })
        .catch(() => {
          console.warn('Audio:Autoplay was prevented');
          // Autoplay was prevented.
          this.setAudioSettings(true);
          audio.muted = true;
          audio.playsInline = true;
          audio?.play();
        })
        .catch((error) => {
          console.warn('Something wrong. Please,check user permissions for playing audio', error);
        });
    } else {
      setTimeout(() => {
        this.playAudio();
      }, 0);
    }
  };

  render() {
    const { config, video, backgroundImage, disableDefaultAudio, i18n } = this.props;

    const { volumeDefaultAudio, mutedDefaultAudio, mutedAudio } = this.state;

    return (
      <>
        <section className={`${config.className}__background`}>
          {!disableDefaultAudio && (
            <ReactPlayer
              ref={this.audioPlayerRef}
              className="audio-player"
              url={require(`assets/audio/ambience_construction.wav`).default}
              // playing={!mutedDefaultAudio}
              muted={mutedDefaultAudio}
              loop
              volume={volumeDefaultAudio}
              onProgress={this.onProgressAudioPlayer}
            />
          )}
          {backgroundImage && (
            <img
              ref={this.backgroundImageRef}
              className={`${config.className}__background-image`}
              src={`/media/videos/journeys/backgrounds/${backgroundImage}`}
              alt="Lomix"
            />
          )}
          {video?.name && (
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <video
              ref={this.backgroundVideoRef}
              className={`${config.className}__background-video`}
              src={`/media/${i18n.language}/videos/journeys/videos/${video.name}`}
              muted={mutedAudio}
              volume={0}
              autoPlay=""
              plays-inline="true"
              webkit-playsinline="true"
              disablePictureInPicture
            />
          )}
        </section>
      </>
    );
  }
}

VideoBackground.propTypes = {
  video: PropTypes.object,
  i18n: PropTypes.shape({
    language: PropTypes.string,
  }),
  videoSettings: PropTypes.object,
  backgroundImage: PropTypes.string,
  videoStopped: PropTypes.func,
  videoEnded: PropTypes.func,
  videoLoaded: PropTypes.func,
  playDefaultAudio: PropTypes.bool,
  disableDefaultAudio: PropTypes.bool,
  muted: PropTypes.bool,
  decreaseVolume: PropTypes.bool,
};

VideoBackground.defaultProps = {
  config: {
    className: 'journey',
    delayPrimaries: 0.5,
    delaySecondaries: 0.25,
    staggerBreakdowns: 0.125,
    delayVideoBreakdown: 1.5,
  },
  i18n: {
    // language: 'en',
  },
  video: null,
  videoSettings: {},
  backgroundImage: null,
  playDefaultAudio: false,
  disableDefaultAudio: false,
  muted: true,
  decreaseVolume: false,
  videoStopped: () => {},
  videoEnded: () => {},
  videoLoaded: () => {},
};

const mapDispatchToProps = (dispatch) => ({
  setAudioMuted: (payload) => dispatch(actions.setAudioMuted({ payload })),
});

export default withTranslation()(
  connect(null, mapDispatchToProps, null, { forwardRef: true })(VideoBackground),
);
