/* eslint-disable class-methods-use-this */
/* eslint-disable no-console */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable func-names */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unused-prop-types */
// @ts-nocheck
/* eslint-disable react/no-array-index-key */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import gsap from 'gsap';
import DrawSVGPlugin from 'gsap/DrawSVGPlugin';

import { connect } from 'react-redux';

import { breakPointHeight, getRemAsPx, percentToRem } from 'app/helpers';

import SplashPNG from 'app/assets/icons/splash@2x.png';

import { isObject, getStartVector, getAngle } from 'core/utils';

const SPLASH_WIDTH = 89;
const SPLASH_HEIGHT = 30;

class DancingAnts extends PureComponent {
  constructor(props) {
    super(props);

    this.svg = React.createRef();
    this.ants = React.createRef();
    this.ants.current = [];
    this.splashes = React.createRef();
    this.splashes.current = [];
    this.dancingAnts = React.createRef();

    this.state = {
      normalizedAnts: [],
    };

    this.hotspotsPoints = {};

    this.timelineSplashes = null;

    this.startedAnimation = null;
  }

  componentDidMount() {
    const normalizedAnts = this.getNormalizedAnts();
    this.setState({ normalizedAnts });

    window.addEventListener('resize', this.onResize);
  }

  componentDidUpdate(prevProps) {
    const { ants, hotspots, tinyBois } = this.props;

    if (
      prevProps.ants !== ants ||
      prevProps.hotspots !== hotspots ||
      prevProps.tinyBois !== tinyBois
    ) {
      this.killTimeline();

      const normalizedAnts = this.getNormalizedAnts();

      this.setState({ normalizedAnts });
    }
  }

  componentWillUnmount() {
    this.killTimeline();
    window.removeEventListener('resize', this.onResize);
  }

  onResize = () => {
    this.killTimeline();
    const normalizedAnts = this.getNormalizedAnts();
    this.setState({ normalizedAnts });
    setTimeout(() => {
      if (this.timeline) {
        gsap.set(this.ants.current, { strokeDasharray: 'none' });
      }
    }, 0);
  };

  getTimeline() {
    return this.timeline;
  }

  getNormalizedAnts() {
    const { ants, hotspots } = this.props;
    const normalizedAnts = [];

    for (const ant of ants.values()) {
      const normalizedAnt = [];

      let direction = null;

      for (const endpoint of ant.values()) {
        if (isObject(endpoint)) {
          direction = endpoint.direction;
        } else {
          let normalizedEndpoint = endpoint;

          if (typeof endpoint === 'string') {
            if (endpoint.includes('hs')) {
              const hotspot = hotspots.find((hs) => hs.id === endpoint);

              if (hotspot) {
                const windowWidth = window.innerWidth;
                const windowHeight = breakPointHeight();

                const hsWidthInPx = getRemAsPx(hotspot.width);

                const centerX = hotspot.position[0] + ((100 / windowWidth) * hsWidthInPx) / 2;

                const topInRem = percentToRem(windowHeight, hotspot.position[1]);

                const centerY =
                  (100 / this.dancingAnts.current.offsetHeight) * getRemAsPx(topInRem) +
                  ((100 / this.dancingAnts.current.offsetHeight) * hsWidthInPx) / 2;

                normalizedEndpoint = [centerX, centerY];

                this.hotspotsPoints[`${centerX}_${centerY}`] = true;
              }
            }

            // If the endpoint is tiny boi:
            // if (endpoint.includes('tb')) {
            //   // Get x/y percentages
            //   const tinyBoi = tinyBois.find((tb) => tb.id === endpoint);
            //
            //   if (tinyBoi) {
            //     const tbWidth = (0.5 / 100) * offsetWidth;
            //
            //     // convert x/y % into px
            //     let tbX = offsetWidth * (tinyBoi.position[0] / 100);
            //     let tbY = offsetHeight * (tinyBoi.position[1] / 100);
            //
            //     // Add half of tb width to x/y px
            //     tbX += tbWidth / 2;
            //     tbY += tbWidth / 2;
            //
            //     // Convert px values back into % after adding tb width
            //     tbX = (tbX / offsetWidth) * 100;
            //     tbY = (tbY / offsetHeight) * 100;
            //
            //     normalizedEndpoint = [tbX, tbY];
            //   }
            // }
          }
          normalizedAnt.push(normalizedEndpoint);
        }
      }

      normalizedAnt.push(this.getSplashInfo([normalizedAnt[0], normalizedAnt[1]], direction));

      normalizedAnts.push(normalizedAnt);
    }

    return normalizedAnts;
  }

  getSplashInfo(ant, direction) {
    let startPoint = null;
    let endPoint = null;

    if (this.hotspotsPoints[`${ant[0][0]}_${ant[0][1]}`] && direction !== 'reverse') {
      startPoint = ant[1];
      endPoint = ant[0];
    } else {
      startPoint = ant[0];
      endPoint = ant[1];
    }

    const halfWidth = SPLASH_WIDTH / 2;
    const halfHeight = SPLASH_HEIGHT / 2;

    const percentWidth = window.innerWidth / 100;
    const percentHeight = this.dancingAnts.current.offsetHeight / 100;

    const startX = startPoint[0] * percentWidth;
    const startY = startPoint[1] * percentHeight;
    const endX = endPoint[0] * percentWidth;
    const endY = endPoint[1] * percentHeight;

    const length = Math.sqrt((endX - startX) ** 2 + (endY - startY) ** 2);

    const angle = getAngle({ x: endX - startX, y: endY - startY }, { x: 1, y: 0 });

    const startVector = getStartVector(startX, startY, -1 * (angle + 180));
    startVector.x -= halfWidth;
    startVector.y -= halfHeight;

    const startSecondVector = getStartVector(startX, startY, -1 * angle, halfWidth);
    startSecondVector.x -= halfWidth;
    startSecondVector.y -= halfHeight;

    const endVector = getStartVector(startX, startY, -1 * angle, length - halfWidth);
    endVector.x -= halfWidth;
    endVector.y -= halfHeight;

    return {
      startX: startX - halfWidth,
      startY: startY - halfHeight,
      endX: endX - halfWidth,
      endY: endY - halfHeight,
      angle,
      percentWidth,
      percentHeight,
      startVector,
      startSecondVector,
      endVector,
      length,
    };
  }

  killTimeline() {
    if (this.timelineSplashes) {
      this.timelineSplashes.kill();
      gsap.set(this.splashes.current, { opacity: 0 });
    }
  }

  init() {
    const { ants } = this.props;

    const { config } = this.props;

    gsap.registerPlugin(DrawSVGPlugin);
    gsap.config({ force3D: true });

    const timeline = gsap.timeline({ paused: true });

    if (ants.length) {
      gsap.set(this.ants.current, { drawSVG: false });
      gsap.set(this.dancingAnts.current, { visibility: 'visible' });

      const makeDance = function (dancingAnts, dance = true) {
        return function () {
          dancingAnts.startedAnimation = !dance;

          if (dance) {
            dancingAnts.initTimelineSplashes();
            dancingAnts.timelineSplashes.play();
          }

          for (const target of this._targets.values()) {
            if (dance) {
              target.classList.add(dancingAnts.props.config.dancingClass);
            } else {
              target.classList.remove(dancingAnts.props.config.dancingClass);
            }
          }
        };
      };

      timeline
        .addLabel('start')
        .set(this.ants.current, { onComplete: makeDance(this, false) })
        .to(this.ants.current, {
          drawSVG: true,
          stagger: config.staggerAnts,
          duration: config.durationAnts,
        })
        .to(this.ants.current, {
          duration: config.durationDasharray,
          onComplete: makeDance(this),
        });
    }

    this.timeline = timeline;

    return this;
  }

  initTimelineSplashes() {
    this.killTimeline();

    this.timelineSplashes = gsap.timeline({
      repeat: -1,
      paused: true,
    });

    this.timelineSplashes.addLabel('start');
    this.timelineSplashes.addPause('start');

    const { normalizedAnts } = this.state;

    let i = -1;
    const n = normalizedAnts.length;

    while (++i < n) {
      const ant = normalizedAnts[i];

      const { endX, endY, startVector, endVector, startSecondVector } = ant[2];

      const timelineSplash = gsap.timeline({
        repeat: -1,
        duration: 1,
        repeatDelay: 0,
        stagger: 0,
      });

      const current = this.splashes.current[i];

      timelineSplash
        .to(current, {
          delay: 0,
          opacity: 0,
          left: `${startVector.x}`,
          top: `${startVector.y}`,
        })
        .to(current, {
          opacity: 1,
          delay: 0,
          left: `${startSecondVector.x}`,
          top: `${startSecondVector.y}`,
          duration: 0.1,
        })
        .to(current, {
          delay: 0,
          opacity: 0.4,
          left: `${endVector.x}`,
          top: `${endVector.y}`,
          duration: 1,
          ease: 'slow(0.7, 0.7, false)',
        })
        .to(current, {
          delay: 0,
          opacity: 0,
          left: `${endX}`,
          top: `${endY}`,
        });

      this.timelineSplashes.add(timelineSplash, 'start');
    }
  }

  render() {
    const { config } = this.props;
    const { normalizedAnts } = this.state;

    this.splashes.current.length = 0;
    this.ants.current.length = 0;

    return (
      <div ref={this.dancingAnts} className={config.className}>
        {normalizedAnts.map((ant, index) => {
          return (
            <img
              key={`splash_${ant[2].startVector.x}_${ant[2].startVector.y}`}
              ref={(el) => {
                this.splashes.current[index] = el;
              }}
              className="splash"
              height={SPLASH_HEIGHT}
              width={SPLASH_WIDTH}
              id="splash"
              src={SplashPNG}
              alt="Splash"
              style={{
                top: `${ant[2].startVector.y}px`,
                left: `${ant[2].startVector.x}px`,
                transform: `rotate(${-1 * ant[2].angle}deg)`,
                opacity: 0,
              }}
            />
          );
        })}
        <svg ref={this.svg} className={`${config.className}__svg`}>
          {normalizedAnts.map((ant, index) => {
            return (
              <React.Fragment key={`ant__w_${ant[0][0]}_${ant[0][1]}_${ant[1][0]}_${ant[1][1]}`}>
                <line
                  ref={(el) => {
                    this.ants.current[index] = el;
                  }}
                  className={`${config.className}__ant`}
                  x1={`${ant[1][0]}%`}
                  y1={`${ant[1][1]}%`}
                  x2={`${ant[0][0]}%`}
                  y2={`${ant[0][1]}%`}
                  stroke={`url(#ant${index})`}
                  strokeDasharray="0px, 999999px"
                />
                <defs>
                  <linearGradient
                    id={`ant${index}`}
                    x1={`${ant[1][0]}%`}
                    y1={`${ant[1][1]}%`}
                    x2={`${ant[0][0]}%`}
                    y2={`${ant[0][1]}%`}
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#0197BA" />
                    <stop offset="0.12" stopColor="#0A9BB5" />
                    <stop offset="0.31" stopColor="#22A4A9" />
                    <stop offset="0.55" stopColor="#4AB495" />
                    <stop offset="0.83" stopColor="#80CA79" />
                    <stop offset="1" stopColor="#A5D867" />
                  </linearGradient>
                </defs>
              </React.Fragment>
            );
          })}
        </svg>
      </div>
    );
  }
}

DancingAnts.propTypes = {
  config: PropTypes.shape({
    className: PropTypes.string,
    dancingClass: PropTypes.string,
    staggerAnts: PropTypes.number,
    durationAnts: PropTypes.number,
    dasharray: PropTypes.array,
    durationDasharray: PropTypes.number,
  }),
  ants: PropTypes.array,
  what: PropTypes.string,
  hotspots: PropTypes.array,
  tinyBois: PropTypes.array,
  offsetWidth: PropTypes.number,
  offsetHeight: PropTypes.number,
};

DancingAnts.defaultProps = {
  config: {
    className: 'c-dancing-ants',
    dancingClass: 'is-dancing',
    staggerAnts: 0.2,
    durationAnts: 0.4,
    dasharray: [4, 6],
    durationDasharray: 0.35,
  },
  ants: [],
  what: '',
  hotspots: [],
  tinyBois: [],
  offsetWidth: 0,
  offsetHeight: 0,
};

DancingAnts.id = 'DancingAnts';

const mapStateToProps = () => {
  const config = {
    className: 'c-dancing-ants',
    dancingClass: 'is-dancing',
    staggerAnts: 0.2,
    durationAnts: 0.4,
    dasharray: [4, 6],
    durationDasharray: 0.35,
  };

  return {
    config,
  };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(DancingAnts);
