import description from './description';
import ResourceModel1 from './elements/resourceModel1';
import ResourceModel2 from './elements/resourceModel2';
import ResourceModel3 from './elements/resourceModel3';
import ResourceModel4 from './elements/resourceModel4';
import ResourceModel5 from './elements/resourceModel5';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [16.1, 54.2], width: 12.75 }],
  dancingAnts: [
    ['hs1', [0, 33.98], { direction: 'reverse' }],
    ['hs1', [50.57, 0]],
  ],
  tinyBois: [
    { id: 'tb1', position: [16.1, 54.2], offset: [5.7, 12] },
    { id: 'tb2', position: [16.1, 54.2], offset: [0.1, 8.5] },
    { id: 'tb3', position: [16.1, 54.2], offset: [11.6, 8.5] },
    { id: 'tb4', position: [16.1, 54.2], offset: [11.6, 3.2] },
  ],
  elements: [
    new ResourceModel1([12, 20]),
    new ResourceModel2([27.6, 69.8]),
    new ResourceModel3([12, 74.4]),
    new ResourceModel4([27.6, 56.9], ['top', 'left']),
    new ResourceModel5([27.6, 43.9], ['top', 'left']),
  ],
};

export default config;
