import description from './description';
import LoadHaulDynamicData1 from './elements/LoadHaulDynamicData1';
import LoadHaulDynamicData2 from './elements/LoadHaulDynamicData2';
import LoadHaulDynamicData3 from './elements/LoadHaulDynamicData3';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [47.5, 48.5], width: 6.375 }],
  dancingAnts: [],
  tinyBois: [
    { id: 'tb2', position: [47.5, 48.5], offset: [0.4, 4.8] },
    { id: 'tb1', position: [47.5, 48.5], offset: [5.6, 4.8] },
  ],
  elements: [
    new LoadHaulDynamicData1([51.25, 17], { size: 'wide-l' }),
    new LoadHaulDynamicData2([19.5, 57]),
    new LoadHaulDynamicData3([67.1, 54.5]),
  ],
};

export default config;
