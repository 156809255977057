import Box from 'modules/common/components/Box';

export default class SurveyMonitoringPlanning2 {
  constructor(position) {
    this.id = 'surveyMonitoringPlanning_2';
    this.component = Box;
    this.type = 'content';
    this.title = 'survey-monitoring.Planning.elements.CYCLONE 3DR';
    this.content = 'survey-monitoring.Planning.elements.CYCLONE 3DR Content';
    this.props = {
      target: 'tb1',
      position,
      hashtags: ['digitalisation', 'integration'],
    };
  }
}
