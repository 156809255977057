// @ts-nocheck
import React from 'react';
import { useTranslation } from 'react-i18next';
import { editProfileMsal } from '../authMsalService';

const EditProfileButton = () => {
  const { t } = useTranslation();
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,jsx-a11y/interactive-supports-focus
    <div role="button" id="editProfileButton" onClick={editProfileMsal} tabIndex={0}>
      {t('auth.Edit Profile')}
    </div>
  );
};

export default EditProfileButton;
