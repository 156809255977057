import description from './description';
import UndergroundTaskManagement1 from './elements/undergroundTaskManagement_1';
import UndergroundTaskManagement2 from './elements/undergroundTaskManagement_2';
import UndergroundTaskManagement3 from './elements/undergroundTaskManagement_3';
import UndergroundTaskManagement4 from './elements/undergroundTaskManagement_4';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [13.7, 46.5], width: 6.375 }],
  dancingAnts: [['hs1', [0, 48.3], { direction: 'reverse' }]],
  tinyBois: [
    { id: 'tb1', position: [13.7, 46.5], offset: [0.3, 4.9] },
    { id: 'tb2', position: [13.7, 46.5], offset: [6, 4] },
    { id: 'tb3', position: [13.7, 46.5], offset: [5.6, 1.2] },
  ],

  elements: [
    new UndergroundTaskManagement1([12.4, 17], { size: 'wide' }),
    new UndergroundTaskManagement2([35.8, 64.8], { target: 'tb2' }),
    new UndergroundTaskManagement3([59.2, 59.1], { target: 'tb3' }),
    new UndergroundTaskManagement4([12.4, 66.1], { target: 'tb1' }),
  ],
};

export default config;
