/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import config from 'modules/home-introduction/configs';

import Layout from 'modules/common/layouts';
import { ALLOW_EVENTS_CLASS } from 'app/constants/app';

class Main extends PureComponent {
  onEvent = ({ action, data }) => {
    switch (action) {
      case 'video:end': {
        this.props.onEvent({ action: 'location:destroy', data });
        break;
      }
      default: {
        this.props.onEvent({ action, data });
      }
    }
  };

  render() {
    const { journey, location, requestDestroy, audioMuted, enabledTransition, backgroundImage } =
      this.props;

    return (
      <div className={ALLOW_EVENTS_CLASS}>
        <Layout
          journey={journey} // TEMP. ONLY FOR TEST
          location={location}
          backgroundImage={backgroundImage}
          onEvent={this.onEvent}
          requestDestroy={requestDestroy}
          audioMuted={audioMuted}
          enabledTransition={enabledTransition}
          disableDefaultAudio={!!location?.audio?.default?.disable}
        />
      </div>
    );
  }
}

Main.propTypes = {
  config: PropTypes.shape({
    className: PropTypes.string,
    delayPrimaries: PropTypes.number,
    delaySecondaries: PropTypes.number,
    staggerBreakdowns: PropTypes.number,
    delayVideoBreakdown: PropTypes.number,
  }),
  location: PropTypes.object,
  audioMuted: PropTypes.bool,
  enabledTransition: PropTypes.bool,
  requestDestroy: PropTypes.bool,
};

Main.defaultProps = {
  config: {
    className: 'journey',
    delayPrimaries: 0.5,
    delaySecondaries: 0.25,
    staggerBreakdowns: 0.125,
    delayVideoBreakdown: 1.5,
  },
  location: {},
  audioMuted: true,
  enabledTransition: false,
  requestDestroy: false,
};

const mapStateToProps = () => {
  const location = config.current;

  const playDefaultAudio = !!location?.video?.playDefaultSound;

  return {
    config: {
      className: 'journey',
      delayPrimaries: 0.5,
      delaySecondaries: 0.25,
      staggerBreakdowns: 0.125,
      delayVideoBreakdown: 1.5,
    },
    location,
    playDefaultAudio,
  };
};

export default connect(mapStateToProps, null)(Main);
