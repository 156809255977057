/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18nConfig';

import { CSSTransition } from 'react-transition-group';

import withNavigate from 'app/routers/withNavigate';

import Arrow from 'app/assets/icons/arrow-small.svg';

const options = [
  { value: 'en', label: 'English', selecteLabel: 'EN' },
  { value: 'zh', label: '中文', selecteLabel: '中文' },
];

const LanguageSelect = ({ navigate }) => {
  const config = { className: 'language-select' };
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const selectRef = useRef(null);

  useEffect(() => {
    const { pathname } = window.location;

    if (pathname.startsWith('/zh/')) {
      i18n.changeLanguage('zh');
      const selectElement = document.getElementById('language-select');

      if (selectElement) {
        setSelectedOption(options[1]);
      }
    }

    if (i18n.language === 'zh') {
      navigate(`/zh/${pathname}`);
      setSelectedOption(options[1]);
    }
  }, [window.location, setSelectedOption, i18n, navigate]);

  useEffect(() => {
    const selectElement = document.getElementById('language-select');
    const lang = i18n.language;

    if (selectElement) {
      setSelectedOption(options[lang === 'zh' ? 1 : 0]);
    }
  }, [i18n, setSelectedOption, document]);

  const changeLanguageHandler = (optionValue) => {
    const newLanguage = optionValue.value;

    setSelectedOption(optionValue);
    setIsOpen(false);

    let { pathname } = window.location;

    if (pathname.startsWith(`/${i18n.language}`)) {
      pathname = pathname.slice(i18n.language.length + 1);
    }

    i18n.changeLanguage(newLanguage);

    navigate(pathname);
  };
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const checkOutsideClick = (e) => {
    if (!selectRef?.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('click', checkOutsideClick);

    return () => {
      window.removeEventListener('click', checkOutsideClick);
    };
  }, []);

  return (
    <div className={`${config.className}  ${isOpen ? 'is-open' : ''}`} ref={selectRef}>
      <div className={`dropdown-wrapper ${isOpen ? 'is-open' : ''}`}>
        <div className={`dropdown-toggle ${isOpen ? 'is-open' : ''}`} onClick={toggleDropdown}>
          <span> {selectedOption ? selectedOption.selecteLabel : 'Select an option'}</span>

          <Arrow className={`arrow ${isOpen ? 'up' : 'down'}`} />
        </div>
      </div>

      <CSSTransition in={isOpen} timeout={0} classNames="langSelect" mountOnEnter unmountOnExit>
        <div className="dropdown-menu">
          {options.map((option) => (
            <div
              key={option.value}
              className={`dropdown-item ${
                selectedOption.value === option.value ? 'is-active' : ''
              }`}
              onClick={() => changeLanguageHandler(option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      </CSSTransition>
    </div>
  );
};

LanguageSelect.propTypes = {
  navigate: PropTypes.func,
};

LanguageSelect.defaultProps = {
  navigate: () => {},
};

export default withNavigate(LanguageSelect);
