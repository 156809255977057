import description from './description';
import UndergroundProductionDrilling1 from './elements/undergroundProductionDrilling_1';
import UndergroundProductionDrilling2 from './elements/undergroundProductionDrilling_2';
import UndergroundProductionDrilling3 from './elements/undergroundProductionDrilling_3';
import UndergroundProductionDrilling4 from './elements/undergroundProductionDrilling_4';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [63.6, 24], width: 12.75 }],
  dancingAnts: [],
  tinyBois: [
    { id: 'tb1', position: [63.6, 24], offset: [11.8, 8] },
    { id: 'tb2', position: [63.6, 24], offset: [9.1, 11.3] },
    { id: 'tb3', position: [63.6, 24], offset: [3.7, 11.9] },
  ],

  elements: [
    new UndergroundProductionDrilling1([13.7, 18.55], { size: 'wide' }),
    new UndergroundProductionDrilling2([71.8, 41], { target: 'tb1' }),
    new UndergroundProductionDrilling3([71.8, 60.8], { target: 'tb2' }),
    new UndergroundProductionDrilling4([71.8, 83], { target: 'tb3' }),
  ],
};

export default config;
