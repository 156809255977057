import description from './description';
import UndergroundMining1 from './elements/undergroundMining_1';
import UndergroundMining2 from './elements/undergroundMining_2';
import UndergroundMining3 from './elements/undergroundMining_3';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [13.7, 46.5], width: 6.375 }],
  dancingAnts: [['hs1', [0, 48.3], { direction: 'reverse' }]],
  tinyBois: [
    { id: 'tb2', position: [13.7, 46.5], offset: [0.3, 4.9] },
    { id: 'tb1', position: [13.7, 46.5], offset: [6, 4] },
  ],

  elements: [
    new UndergroundMining1([17, 12.5], { size: 'wide' }),
    new UndergroundMining2([35.8, 64.5]),
    new UndergroundMining3([12.4, 64.5]),
  ],
};

export default config;
