/* eslint-disable react/forbid-prop-types */
/* eslint-disable dot-notation */
/* eslint-disable react/destructuring-assignment,no-useless-escape,jsx-a11y/label-has-associated-control */
import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import './assets/scss/index.scss';
import { withTranslation } from 'react-i18next';
import ContactUsForm from 'app/components/modals/ContactUs/components/ContactUsForm';
import { isFormSended, isFormSending } from 'app/components/modals/ContactUs/selectors';
import actions from 'app/components/modals/ContactUs/actions';

class ContactUsFormWrapper extends PureComponent {
  // eslint-disable-next-line react/sort-comp, class-methods-use-this
  sendRequest(data) {
    const { sendForm } = this.props;

    sendForm(data);
  }

  render() {
    const { t, showSuccessesMessage, loading } = this.props;

    return (
      <div className="contact-us-form-wrapper">
        <div className="contact-us-form-wrapper__title">
          {showSuccessesMessage
            ? t('contactUs.wrapper.successMassage')
            : t('contactUs.wrapper.title')}
        </div>
        {!showSuccessesMessage && (
          <ContactUsForm loading={loading} onSubmit={(data) => this.sendRequest(data)} />
        )}
      </div>
    );
  }
}

ContactUsFormWrapper.propTypes = {
  showSuccessesMessage: PropTypes.bool,
  loading: PropTypes.bool,
  t: PropTypes.func,
  sendForm: PropTypes.func,
};

ContactUsFormWrapper.defaultProps = {
  showSuccessesMessage: false,
  loading: false,
  t: () => {},
  sendForm: () => {},
};

const mapStateToProps = (state) => {
  const showSuccessesMessage = isFormSended(state);
  const loading = isFormSending(state);

  return {
    showSuccessesMessage,
    loading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  sendForm: (payload) => dispatch(actions.sendForm({ payload })),
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ContactUsFormWrapper),
);
