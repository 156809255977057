import description from './description';
import DrillBlastBlastFragmentationAnalysis1 from './elements/drillBlastBlastFragmentationAnalysis_1';
import DrillBlastBlastFragmentationAnalysis2 from './elements/drillBlastBlastFragmentationAnalysis_2';
import DrillBlastBlastFragmentationAnalysis3 from './elements/drillBlastBlastFragmentationAnalysis_3';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [62.5, 73], width: 7.815, rotation: [62, 0] }],
  dancingAnts: [['hs1', [0, 67.38], { direction: 'reverse' }]],
  tinyBois: [
    { id: 'tb1', position: [62.5, 73], offset: [6.3, 2.4] },
    { id: 'tb2', position: [62.5, 73], offset: [6.3, 4.9] },
  ],
  elements: [
    new DrillBlastBlastFragmentationAnalysis1([66.7, 22], { cornerFrom: ['bottom', 'left'] }),
    new DrillBlastBlastFragmentationAnalysis2([77.5, 48.7]),
    new DrillBlastBlastFragmentationAnalysis3([82.3, 63.5]),
  ],
};

export default config;
