/* eslint-disable react/forbid-prop-types */
/* eslint-disable dot-notation */
/* eslint-disable react/destructuring-assignment,no-useless-escape,jsx-a11y/label-has-associated-control */
import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import './assets/scss/index.scss';
import FormControl from 'core/form/FormControl';
import FormElement from 'core/form/FormElement';
import { pattern, required } from 'core/form/validation';
import ErrorField from 'modules/auth/components/ErrorField';
import notification from 'core/services/Notification';
import { withTranslation } from 'react-i18next';
import WorkflowsCheckBoxes from 'app/components/modals/ContactUs/components/WorkflowsCheckBoxes';
import { Checkbox } from '@mui/material';
import ConfigAdapter from 'app/components/modals/ContactUs/configs/configAdapter';
import fields from 'app/components/modals/ContactUs/configs/fields';
import roles from 'app/components/modals/ContactUs/configs/roles';
import levels from 'app/components/modals/ContactUs/configs/levels';
import countries from 'app/components/modals/ContactUs/configs/countries';
import ProgressScreen from 'app/components/modals/ContactUs/components/ProgressScreen';
import CheckboxCheckedIcon from './assets/icons/checkbox.svg';
import CheckboxDefaultIcon from './assets/icons/checkbox-false.svg';

const phonPattern = /^(?:\(|\)|\+|\-|[0-9])*[0-9]+(?:\(|\)|\+|\-|[0-9])*$/;
const emailPattern =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class ContactUsForm extends PureComponent {
  constructor(props) {
    super(props);

    this.fieldsConfig = new ConfigAdapter(fields);

    this.form = new FormControl({
      firstName: new FormElement(undefined, [required()]),
      lastName: new FormElement(undefined, [required()]),
      workEmail: new FormElement(undefined, [required(), pattern(emailPattern)]),
      phone: new FormElement(undefined, [required(), pattern(phonPattern)]),
      company: new FormElement(undefined, [required()]),
      mine: new FormElement(undefined, []),
      role: new FormElement(undefined, [required()]),
      level: new FormElement(undefined, [required()]),
      country: new FormElement(undefined, [required()]),
      workflows: new FormElement([], []),
      question: new FormElement(undefined, []),
      privacy: new FormElement(undefined, [required()]),
    });

    if (props.journey) {
      this.form.setValues(props.journey);
    }

    this.state = {
      formValue: this.form.getValues(),
      formErrors: this.form.getErrors(),
    };
  }

  componentDidMount() {
    this.form.onChange(() => {
      this.setState({
        formValue: this.form.getValues(),
        formErrors: this.form.getErrors(),
      });
    });

    this.form.setSubmitFunction(() => {
      this.submit();
    });

    this.props.onFormCreated(this.form);
  }

  // eslint-disable-next-line react/sort-comp
  submit() {
    const { t } = this.props;
    if (!this.form.isFormValid()) {
      // todo: use notification library
      // eslint-disable-next-line no-alert
      notification.error(t('contactUs.form.notValidNotification'));
      return;
    }

    const data = this.form.getValues();

    this.props.onSubmit(data);
  }

  render() {
    const { formValue, formErrors } = this.state;
    const { t, loading } = this.props;

    return (
      <div className="contact-us-form">
        <form className="form-body" onSubmit={(e) => this.form.submitForm(e)}>
          <div className="main-data">
            <div className="field field--first">
              <label className="input-label" htmlFor="firstName">
                <span
                  className={`required ${
                    formErrors['firstName'] ? 'input-label__text--with-error' : ''
                  }`}
                >
                  {t(this.fieldsConfig.getLocalizationKey('firstName'))}
                </span>
              </label>
              <div
                className={`
                text-input-wrapper
                ${formErrors['firstName'] ? 'text-input-wrapper--with-error' : ''}
              `}
              >
                <input
                  onChange={(e) => this.form.getFormElement('firstName').onChangeEvent(e)}
                  onBlur={(e) => this.form.getFormElement('firstName').onBlurEvent(e)}
                  value={formValue['firstName']}
                  id="firstName"
                  className="text-input"
                />
              </div>
              {formErrors['firstName']?.required && (
                <ErrorField error={{ text: t('contactUs.form.requiredField') }} />
              )}
            </div>

            <div className="field">
              <label className="input-label" htmlFor="lastName">
                <span
                  className={`required ${
                    formErrors['lastName'] ? 'input-label__text--with-error' : ''
                  }`}
                >
                  {t(this.fieldsConfig.getLocalizationKey('lastName'))}
                </span>
              </label>
              <div
                className={`
                text-input-wrapper
                ${formErrors['lastName'] ? 'text-input-wrapper--with-error' : ''}
              `}
              >
                <input
                  onChange={(e) => this.form.getFormElement('lastName').onChangeEvent(e)}
                  onBlur={(e) => this.form.getFormElement('lastName').onBlurEvent(e)}
                  value={formValue['lastName']}
                  id="lastName"
                  className="text-input"
                />
              </div>
              {formErrors['lastName']?.required && (
                <ErrorField error={{ text: t('contactUs.form.requiredField') }} />
              )}
            </div>

            <div className="field">
              <label className="input-label" htmlFor="workEmail">
                <span
                  className={`required ${
                    formErrors['workEmail'] ? 'input-label__text--with-error' : ''
                  }`}
                >
                  {t(this.fieldsConfig.getLocalizationKey('workEmail'))}
                </span>
              </label>
              <div
                className={`
                text-input-wrapper
                ${formErrors['workEmail'] ? 'text-input-wrapper--with-error' : ''}
              `}
              >
                <input
                  onChange={(e) => this.form.getFormElement('workEmail').onChangeEvent(e)}
                  onBlur={(e) => this.form.getFormElement('workEmail').onBlurEvent(e)}
                  value={formValue['workEmail']}
                  id="workEmail"
                  className="text-input"
                />
              </div>
              {formErrors['workEmail']?.required && (
                <ErrorField error={{ text: t('contactUs.form.requiredField') }} />
              )}
              {formErrors['workEmail']?.pattern && (
                <ErrorField error={{ text: t('contactUs.form.emailNotValid') }} />
              )}
            </div>

            <div className="field">
              <label className="input-label" htmlFor="phone">
                <span
                  className={`required ${
                    formErrors['phone'] ? 'input-label__text--with-error' : ''
                  }`}
                >
                  {t(this.fieldsConfig.getLocalizationKey('phone'))}
                </span>
              </label>
              <div
                className={`
                text-input-wrapper
                ${formErrors['phone'] ? 'text-input-wrapper--with-error' : ''}
              `}
              >
                <input
                  onChange={(e) => this.form.getFormElement('phone').onChangeEvent(e)}
                  onBlur={(e) => this.form.getFormElement('phone').onBlurEvent(e)}
                  value={formValue['phone']}
                  id="phone"
                  className="text-input"
                />
              </div>
              {formErrors['phone']?.required && (
                <ErrorField error={{ text: t('contactUs.form.requiredField') }} />
              )}
              {formErrors['phone']?.pattern && (
                <ErrorField error={{ text: t('contactUs.form.phoneNotValid') }} />
              )}
            </div>

            <div className="field">
              <label className="input-label" htmlFor="company">
                <span
                  className={`required ${
                    formErrors['company'] ? 'input-label__text--with-error' : ''
                  }`}
                >
                  {t(this.fieldsConfig.getLocalizationKey('company'))}
                </span>
              </label>
              <div
                className={`
                text-input-wrapper
                ${formErrors['company'] ? 'text-input-wrapper--with-error' : ''}
              `}
              >
                <input
                  onChange={(e) => this.form.getFormElement('company').onChangeEvent(e)}
                  onBlur={(e) => this.form.getFormElement('company').onBlurEvent(e)}
                  value={formValue['company']}
                  id="company"
                  className="text-input"
                />
              </div>
              {formErrors['company']?.required && (
                <ErrorField error={{ text: t('contactUs.form.requiredField') }} />
              )}
            </div>

            <div className="field">
              <label className="input-label" htmlFor="mine">
                <span className={`${formErrors['mine'] ? 'input-label__text--with-error' : ''}`}>
                  {t(this.fieldsConfig.getLocalizationKey('mine'))}
                </span>
              </label>
              <div
                className={`
                text-input-wrapper
                ${formErrors['mine'] ? 'text-input-wrapper--with-error' : ''}
              `}
              >
                <input
                  onChange={(e) => this.form.getFormElement('mine').onChangeEvent(e)}
                  onBlur={(e) => this.form.getFormElement('mine').onBlurEvent(e)}
                  value={formValue['mine']}
                  id="mine"
                  className="text-input"
                />
              </div>
              {formErrors['mine']?.required && (
                <ErrorField error={{ text: t('contactUs.form.requiredField') }} />
              )}
            </div>

            <div className="field">
              <label className="input-label" htmlFor="role">
                <span
                  className={`required ${
                    formErrors['role'] ? 'input-label__text--with-error' : ''
                  }`}
                >
                  {t(this.fieldsConfig.getLocalizationKey('role'))}
                </span>
              </label>
              <div
                className={`
                text-input-wrapper text-input-wrapper--select
                ${formErrors['role'] ? 'text-input-wrapper--with-error' : ''}
              `}
              >
                <select
                  name="role"
                  id="role"
                  className="text-input"
                  onChange={(e) => this.form.getFormElement('role').onChangeEvent(e)}
                  onBlur={(e) => this.form.getFormElement('role').onBlurEvent(e)}
                  value={formValue['role']}
                >
                  <option value="">{t('contactUs.form.pleaseSelectOption')}</option>
                  {roles.map((role) => (
                    <option key={role.key} value={role.key}>
                      {t(role.localization)}
                    </option>
                  ))}
                </select>
              </div>
              {formErrors['role']?.required && (
                <ErrorField error={{ text: t('contactUs.form.requiredField') }} />
              )}
            </div>

            <div className="field">
              <label className="input-label" htmlFor="level">
                <span
                  className={`required ${
                    formErrors['level'] ? 'input-label__text--with-error' : ''
                  }`}
                >
                  {t(this.fieldsConfig.getLocalizationKey('level'))}
                </span>
              </label>
              <div
                className={`
                text-input-wrapper text-input-wrapper--select
                ${formErrors['level'] ? 'text-input-wrapper--with-error' : ''}
              `}
              >
                <select
                  name="level"
                  id="level"
                  className="text-input"
                  onChange={(e) => this.form.getFormElement('level').onChangeEvent(e)}
                  onBlur={(e) => this.form.getFormElement('level').onBlurEvent(e)}
                  value={formValue['level']}
                >
                  <option value="">{t('contactUs.form.pleaseSelectOption')}</option>
                  {levels.map((level) => (
                    <option key={level.key} value={level.key}>
                      {t(level.localization)}
                    </option>
                  ))}
                </select>
              </div>
              {formErrors['level']?.required && (
                <ErrorField error={{ text: t('contactUs.form.requiredField') }} />
              )}
            </div>

            <div className="field field--100">
              <label className="input-label" htmlFor="country">
                <span
                  className={`required ${
                    formErrors['country'] ? 'input-label__text--with-error' : ''
                  }`}
                >
                  {t(this.fieldsConfig.getLocalizationKey('country'))}
                </span>
              </label>
              <div
                className={`
                text-input-wrapper text-input-wrapper--select
                ${formErrors['country'] ? 'text-input-wrapper--with-error' : ''}
              `}
              >
                <select
                  name="country"
                  id="country"
                  className="text-input"
                  onChange={(e) => this.form.getFormElement('country').onChangeEvent(e)}
                  onBlur={(e) => this.form.getFormElement('country').onBlurEvent(e)}
                  value={formValue['country']}
                >
                  <option value="">{t('contactUs.form.pleaseSelectOption')}</option>
                  {countries.map((country) => (
                    <option key={country.key} value={country.key}>
                      {t(country.localization)}
                    </option>
                  ))}
                </select>
              </div>
              {formErrors['country']?.required && (
                <ErrorField error={{ text: t('contactUs.form.requiredField') }} />
              )}
            </div>
            <div className="field field--100">
              <label className="input-label" htmlFor="workflows">
                <span
                  className={`${formErrors['workflows'] ? 'input-label__text--with-error' : ''}`}
                >
                  {t(this.fieldsConfig.getLocalizationKey('workflows'))}
                </span>
              </label>
              <WorkflowsCheckBoxes
                onChange={(e) => this.form.getFormElement('workflows').onChangeEvent(e)}
                value={formValue['workflows']}
              />
              {formErrors['workflows']?.required && (
                <ErrorField error={{ text: t('contactUs.form.requiredField') }} />
              )}
            </div>

            <div className="field field--100">
              <label className="input-label" htmlFor="question">
                <span
                  className={`${formErrors['question'] ? 'input-label__text--with-error' : ''}`}
                >
                  {t(this.fieldsConfig.getLocalizationKey('question'))}
                </span>
              </label>
              <div
                className={`
                text-input-wrapper
                ${formErrors['question'] ? 'text-input-wrapper--with-error' : ''}
              `}
              >
                <textarea
                  rows={10}
                  onChange={(e) => this.form.getFormElement('question').onChangeEvent(e)}
                  onBlur={(e) => this.form.getFormElement('question').onBlurEvent(e)}
                  value={formValue['question']}
                  id="question"
                  className="text-input text-input--area"
                />
              </div>
              {formErrors['question']?.required && (
                <ErrorField error={{ text: t('contactUs.form.requiredField') }} />
              )}
            </div>

            <div className="field field--100">
              <label className="input-label">
                <span
                  className={`required ${
                    formErrors['privacy'] ? 'input-label__text--with-error' : ''
                  }`}
                >
                  {t(this.fieldsConfig.getLocalizationKey('privacy'))}
                </span>
              </label>
              <div className="privacy-check-boxes__element">
                <Checkbox
                  id="privacy"
                  icon={<CheckboxDefaultIcon />}
                  checkedIcon={<CheckboxCheckedIcon />}
                  name="privacy"
                  onChange={(e) => this.form.getFormElement('privacy').onChangeEvent(e)}
                  onBlur={(e) => this.form.getFormElement('privacy').onBlurEvent(e)}
                  checked={formValue['privacy'] || false}
                />
                <label className="privacy-check-boxes__element-label" htmlFor="privacy">
                  {t('contactUs.form.privacyLabel')}
                </label>
              </div>
              {formErrors['privacy']?.required && (
                <ErrorField error={{ text: t('contactUs.form.requiredField') }} />
              )}

              <p>
                <span className="font-10">
                  {t('contactUs.privacyInfo.hexagonMiningInc')} &nbsp;
                  <a
                    className="link"
                    href="https://hexagon.com/legal/privacy-notice"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t('contactUs.privacyInfo.privacyPolicy')}
                  </a>
                  &nbsp; {t('contactUs.privacyInfo.describes')}
                  <br />
                  {t('contactUs.privacyInfo.unsubscribeInfo')}
                  <br />
                  {t('contactUs.privacyInfo.youAgreeTo')} &nbsp;
                  <a
                    className="link"
                    href="https://hexagon.com/legal/terms-of-use"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t('contactUs.privacyInfo.termsOfUse')}
                  </a>
                </span>
              </p>
            </div>
          </div>

          <button className="c-button c-button--size-default" type="submit" disabled={loading}>
            {t('common.Submit')}
          </button>
        </form>

        {loading && <ProgressScreen />}
      </div>
    );
  }
}

ContactUsForm.propTypes = {
  journey: PropTypes.shape({
    privateName: PropTypes.string,
    shareableName: PropTypes.string,
    description: PropTypes.string,
    locations: PropTypes.arrayOf(PropTypes.string),
  }),
  loading: PropTypes.bool,
  onFormCreated: PropTypes.func,
  onSubmit: PropTypes.func,
  t: PropTypes.func,
};

ContactUsForm.defaultProps = {
  journey: null,
  loading: false,
  onFormCreated: () => {},
  onSubmit: () => {},
  t: () => {},
};

export default withTranslation()(connect(null, null, null, { forwardRef: true })(ContactUsForm));
