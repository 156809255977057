/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/destructuring-assignment,no-useless-escape,jsx-a11y/label-has-associated-control */
import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getFromLS } from 'core/services/localStorage';

import './assets/scss/index.scss';

import Button from 'modules/common/components/Button';

import actions from 'app/actions';
import * as selectors from 'app/selectors';
import { ALLOW_EVENTS_CLASS, AUTH_ERRORS, AUTH_INTRO } from 'app/constants/app';
import { sendGTEvent } from 'modules/analytics/services/googleAnalytics';
import { loginMsal } from 'modules/auth/authMsalService';
import { withTranslation } from 'react-i18next';
import ErrorField from '../ErrorField';

import { signUp, toggleUserIsLoggedIn } from '../../authService';
import Gdpr from '../Gdpr';

class LogIn extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      gdpr: false,
      error: '',
      isLoginButtonDisabled: true,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { email, gdpr, error } = this.state;

    if (prevState.email !== email || prevState.gdpr !== gdpr) {
      if (error || !email.length || !gdpr) {
        this.toggleLoginButtonDisable(true);
      } else {
        this.toggleLoginButtonDisable(false);
      }
    }
  }

  validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
  };

  toggleLoginButtonDisable = (isDisabled) => {
    this.setState({ isLoginButtonDisabled: isDisabled });
  };

  onEmailChange = (e) => {
    const { error } = this.state;

    if (!e.target.value) {
      this.setState({ error: '' });
      this.toggleLoginButtonDisable(true);
      return;
    }

    if (error) {
      this.toggleLoginButtonDisable(true);
    }
    if (!this.validateEmail(e.target.value)) {
      this.setState({ error: AUTH_ERRORS.login.validate });
      this.toggleLoginButtonDisable(true);
    } else {
      this.setState({ error: '', email: e.target.value });
      this.toggleLoginButtonDisable(false);
    }
  };

  login = () => {
    const { setCanNavigate } = this.props;
    const { email } = this.state;

    const registeredUser = getFromLS('user');
    const { email: registeredEmail } = registeredUser || '';

    if (registeredEmail === email) {
      toggleUserIsLoggedIn(true, registeredUser);
      sendGTEvent({ event: 'log_in' });
      setCanNavigate(true);
    } else {
      signUp({ email });
      sendGTEvent({ event: 'sign_up' });

      setCanNavigate(true);
    }
  };

  onCheck = (field, value) => {
    this.setState({
      gdpr: value,
    });
  };

  render() {
    const { config, isJourneyLocation, t } = this.props;

    const { isLoginButtonDisabled, error, gdpr } = this.state;

    return (
      <div className={`${config.className} ${ALLOW_EVENTS_CLASS}`}>
        {/* <div className="auth-title">Log in</div> */}
        <div className="form-describe">
          <div className="text-describe">{t(AUTH_INTRO)}</div>
        </div>
        <div className={`form-body ${isJourneyLocation ? 'full-screen' : ''}`}>
          <div className="field">
            <label className="input-label" htmlFor="email">
              {t('forms.Email')}
            </label>
            <input
              placeholder="email@company.com"
              onChange={this.onEmailChange}
              id="email"
              className="text-input"
            />
            {error && <ErrorField error={error} />}
          </div>
          <div className="field with-scroll">
            <Gdpr handleChange={this.onCheck} value={gdpr} />
          </div>
          {/* <SignUpField /> */}

          <div className="bottom-button">
            <Button onClick={this.login} disabled={isLoginButtonDisabled}>
              {t('auth.Log in')}
            </Button>
          </div>

          <div className="emplyee-login" onClick={loginMsal}>
            {t('auth.Hexagon employee log in')}
          </div>
        </div>
      </div>
    );
  }
}

LogIn.propTypes = {
  config: PropTypes.shape({
    className: PropTypes.string,
  }),
  setCanNavigate: PropTypes.func,
  t: PropTypes.func,
  isJourneyLocation: PropTypes.bool,
};

LogIn.defaultProps = {
  config: {
    className: 'auth-form',
  },
  setCanNavigate: () => {},
  t: () => {},
  isJourneyLocation: false,
};

const mapDispatchToProps = (dispatch) => ({
  setCanNavigate: (payload) => dispatch(actions.setCanNavigate({ payload })),
});

const mapStateToProps = (state) => {
  const journey = selectors.getJourney(state);
  const isNavigableJourney = journey?.isInPaths;

  return {
    isJourneyLocation: isNavigableJourney,
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(LogIn));
