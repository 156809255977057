import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import gsap from 'gsap';

import { breakPointHeight, percentToRem } from 'app/helpers';

class Hotspot extends PureComponent {
  constructor(props) {
    super(props);

    this.hotspot = React.createRef();
    this.circle = React.createRef();
    this.circleSVG = React.createRef();
    this.circleBackground = React.createRef();
    this.circlePulse = React.createRef();
    this.circleCenter = React.createRef();
    this.circleTarget = React.createRef();
    this.circleSecondary = React.createRef();
    this.circlePrimary = React.createRef();
  }

  getTimeline() {
    return this.timeline;
  }

  init() {
    const { config } = this.props;

    // Make sure GSAP is always looking gawgeous
    gsap.config({ force3D: true });

    const circleSecondary = [
      this.circleBackground.current,
      this.circleCenter.current,
      this.circleTarget.current,
    ];

    gsap.set([this.circle.current, circleSecondary], { scale: 0, transformOrigin: 'center' });
    gsap.set(this.hotspot.current, { visibility: 'visible' });

    this.timeline = gsap
      .timeline({ paused: true })
      .to(this.circle.current, {
        scale: 1,
        duration: config.durationCircle,
        ease: config.easeCircle,
      })
      .to(
        circleSecondary,
        {
          scale: 1,
          stagger: config.staggerCircleSecondary,
          duration: config.durationCircleSecondary,
        },
        `>${config.delayCircleSecondary}`,
      );

    return this;
  }

  render() {
    const { config, rotation, width, position, isActive, id } = this.props;
    const height = breakPointHeight();

    const topInRem = percentToRem(height, position[1]);

    return (
      <div
        ref={this.hotspot}
        id={id}
        className={`${config.className} ${isActive ? config.activeClass : ''}`}
        style={{
          top: `${topInRem}rem`,
          left: `${position[0]}%`,
          width: `${width}rem`,
          transform: `rotateX(${rotation[0]}deg) rotateY(${rotation[1]}deg)`,
        }}
      >
        <div ref={this.circle} className={`${config.className}__circle`}>
          <svg
            ref={this.circleSVG}
            viewBox="0 0 100 100"
            className={`${config.className}__circle-svg`}
          >
            <circle
              ref={this.circleBackground}
              className={`${config.className}__circle-background`}
              cx="50"
              cy="50"
              r="42.5"
            />
            <circle
              ref={this.circlePulse}
              className={`${config.className}__circle-pulse`}
              cx="50"
              cy="50"
              r="42.5"
            />
            <circle
              ref={this.circleCenter}
              className={`${config.className}__circle-center`}
              cx="50"
              cy="50"
              r="5"
            />
            <circle
              ref={this.circleTarget}
              className={`${config.className}__circle-target`}
              cx="50"
              cy="50"
              r="14"
            />
            <path
              ref={this.circleSecondary}
              className={`${config.className}__circle-secondary`}
              d="M79.3,43.3c0.5,2.1,0.7,4.4,0.7,6.7c0,6.4-2,12.4-5.5,17.3 M50,80c4.8,0,9.4-1.1,13.4-3.2 M24.8,66.3c4.9,7.5,13,12.7,22.4,13.5 M28,29.6C23,35,20,42.1,20,50 M55.1,20.4C53.5,20.2,51.8,20,50,20c-5.6,0-10.9,1.5-15.4,4.2 M63.9,23.4c-1.9-1-3.9-1.8-6-2.4"
            />
            <path
              ref={this.circlePrimary}
              className={`${config.className}__circle-primary`}
              d="M52,7.5c6.6,0.3,12.9,2.1,18.4,5.1 M77.9,18c-1.6-1.4-3.4-2.7-5.2-3.9 M89.9,35.4c-1.4-3.7-3.2-7.2-5.5-10.3M92.4,47.9c-0.1-2.4-0.4-4.8-0.9-7.1 M91.6,59c0.5-2.1,0.8-4.3,0.9-6.6 M68.2,88.4c10.5-5,18.6-14.1,22.2-25.3 M59.7,91.4c1.5-0.4,3.1-0.8,4.5-1.3 M22.3,82.3c7.4,6.4,17.1,10.2,27.7,10.2c0.5,0,1.1,0,1.6,0 M11.1,67.1c1.8,4.1,4.2,7.8,7.1,11.1M9.3,62.1c0.2,0.8,0.5,1.6,0.8,2.4 M49.3,7.5C26.1,7.9,7.5,26.8,7.5,50c0,3.3,0.4,6.5,1.1,9.6"
            />
            <circle className={`${config.className}__circle-outer`} cx="50" cy="50" r="50" />
          </svg>
        </div>
      </div>
    );
  }
}

Hotspot.propTypes = {
  config: PropTypes.shape({
    className: PropTypes.string,
    activeClass: PropTypes.string,
    durationCircle: PropTypes.number,
    easeCircle: PropTypes.string,
    delayCircleSecondary: PropTypes.number,
    staggerCircleSecondary: PropTypes.number,
    durationCircleSecondary: PropTypes.number,
  }),
  rotation: PropTypes.arrayOf(PropTypes.number),
  width: PropTypes.number,
  position: PropTypes.arrayOf(PropTypes.number),
  isActive: PropTypes.bool,
  id: PropTypes.string,
};

Hotspot.defaultProps = {
  config: {
    className: 'c-hotspot',
    activeClass: 'is-active',
    durationCircle: 0.5,
    easeCircle: 'back.out(2)',
    delayCircleSecondary: -0.15,
    staggerCircleSecondary: 0.15,
    durationCircleSecondary: 0.25,
  },
  rotation: [0, 0],
  width: 8,
  position: [0, 0],
  isActive: true,
  id: 'hotspot',
};

Hotspot.id = 'Hotspot';

const mapStateToProps = () => {
  const config = {
    className: 'c-hotspot',
    activeClass: 'is-active',
    durationCircle: 0.5,
    easeCircle: 'back.out(2)',
    delayCircleSecondary: -0.15,
    staggerCircleSecondary: 0.15,
    durationCircleSecondary: 0.25,
  };

  return {
    config,
  };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(Hotspot);
