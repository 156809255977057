// @ts-nocheck
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/button-has-type,no-nested-ternary */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import './assets/styles.scss';

import Finish from './Finish';
import Error from './Error';

const config = {
  className: 'c-lead-modal',
  openClass: 'is-open',
};

// eslint-disable-next-line react/prop-types,no-unused-vars
const Modal = ({ opened, name, error, onEvent }) => {
  const [hasError, setError] = useState(error);

  useEffect(() => {
    if (error && !hasError) {
      setError(error);
    } else if (!error && hasError) {
      setTimeout(() => {
        setError(error);
      }, 500);
    }
  }, [error]);

  const handleCloseError = () => {
    onEvent({ action: 'closeError' });
  };

  const handleClose = () => {
    onEvent({ action: 'close' });
  };

  const handleExploreMore = () => {
    onEvent({ action: 'exploreMore' });
  };

  const handleWatchJourneyAgain = () => {
    onEvent({ action: 'watchJourneyAgain' });
  };

  const getState = () => {
    if (hasError) {
      return <Error handleClose={handleCloseError} />;
    }

    return (
      <Finish
        name={name}
        handleClose={handleClose}
        handleExploreMore={handleExploreMore}
        handleWatchJourneyAgain={handleWatchJourneyAgain}
      />
    );
  };

  return (
    <>
      <div className={`${config.className} ${opened ? config.openClass : ''}`}>
        <div className={`${config.className}__container`}>
          <div className={`${config.className}__content`}>{getState()}</div>
        </div>
      </div>
    </>
  );
};

Modal.propTypes = {
  opened: PropTypes.bool,
  name: PropTypes.string,
  error: PropTypes.bool,
  onEvent: PropTypes.func,
};

Modal.defaultProps = {
  opened: false,
  name: '',
  error: false,
  onEvent: () => {},
};

export default connect(null, null)(Modal);
