import configAPP from 'app/configs';
import description from './description';
import FleetManagement1 from './elements/FleetManagement1';
import FleetManagement2 from './elements/FleetManagement2';
import FleetManagement3 from './elements/FleetManagement3';
import FleetManagement4 from './elements/FleetManagement4';
import FleetManagement5 from './elements/FleetManagement5';
import FleetManagement6 from './elements/FleetManagement6';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [13.7, 41.9], width: 6.375 }],
  dancingAnts: [
    ['hs1', [0, 22.36]],
    ['hs1', [39.89, 100], { direction: 'reverse' }],
  ],
  tinyBois: [
    { id: 'tb1', position: [13.7, 41.9], offset: [4.9240625, 0.5178125] },
    { id: 'tb2', position: [13.7, 41.9], offset: [5.89, 1.8615625] },
    { id: 'tb3', position: [13.7, 41.9], offset: [0.2, 4.45] },
    { id: 'tb4', position: [13.7, 41.9], offset: [6.1, 3.4553125] },
    // eslint-disable-next-line prettier/prettier
    configAPP.enableExhibition ? { id: 'tb5', position: [13.7, 41.9], offset: [5.5, 4.9240625] } : undefined,
  ],
  elements: [
    new FleetManagement1([12.4, 16.53], { size: 'wide' }),
    new FleetManagement2([51.4, 29.81]),
    new FleetManagement3([35.8, 44.9]),
    new FleetManagement4([12.4, 68.38]),
    new FleetManagement5([35.8, 56.64], { cornerFrom: ['top', 'left'] }),
    configAPP.enableExhibition ? new FleetManagement6([35.8, 68.38]) : undefined,
  ],
};

export default config;
