import description from './description';
import DrillBlastBlastDesign1 from './elements/drillBlastBlastDesign_1';
import DrillBlastBlastDesign2 from './elements/drillBlastBlastDesign_2';
import DrillBlastBlastDesign3 from './elements/drillBlastBlastDesign_3';
import DrillBlastBlastDesign4 from './elements/drillBlastBlastDesign_4';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [21.5, 40], width: 6.375 }],
  dancingAnts: [
    ['hs1', [0, 21.28]],
    ['hs1', [0, 93.55], { direction: 'reverse' }],
    ['hs1', [48.33, 0]],
  ],
  tinyBois: [
    { id: 'tb1', position: [21.5, 40], offset: [5.6, 1.2] },
    { id: 'tb2', position: [21.5, 40], offset: [0.2, 4.5] },
    { id: 'tb3', position: [21.5, 40], offset: [5.6, 4.5] },
  ],
  elements: [
    new DrillBlastBlastDesign1([12.4, 15.88]),
    new DrillBlastBlastDesign2([43.55, 28.9]),
    new DrillBlastBlastDesign3([12.4, 56.11]),
    new DrillBlastBlastDesign4([35.83, 45.31]),
  ],
};

export default config;
