import Box from 'modules/common/components/Box';

export default class LoadHaulDynamicData3 {
  constructor(position) {
    this.id = 'LoadHaulDynamicData3';
    this.component = Box;
    this.title = 'load-haul.Load&Haul Insights.elements.One Smart Digital Reality';
    this.content = '';
    this.video = 'md_One_Smart_Digital_Reality.mp4';
    this.props = {
      target: 'tb1',
      position,
      cornerFrom: ['top', 'left'],
      hashtags: ['cost', 'esg', 'productivity', 'dataAnalytics'],
    };
  }
}
