/* eslint-disable react/prop-types */
import React from 'react';

import { useTranslation } from 'react-i18next';
import { toggleUserToLogIn } from '../../authService';

const ErrorField = ({ error }) => {
  const { t } = useTranslation();
  return (
    <div className="error-field">
      {t(error.text)}
      {error.linkText && (
        <span onClick={toggleUserToLogIn} role="button" onKeyDown={toggleUserToLogIn} tabIndex={0}>
          {t(error.linkText)}
        </span>
      )}
    </div>
  );
};

export default ErrorField;
