/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/sort-comp */
/* eslint-disable no-empty,no-unused-vars */
/* eslint-disable prettier/prettier */
// @ts-nocheck
/* eslint-disable global-require,react/destructuring-assignment,react/prop-types,react/no-unused-state,no-restricted-syntax */
import React, { PureComponent } from 'react';

import { connect } from 'react-redux';

import PropTypes from 'prop-types';
// import i18n from 'i18nConfig';

import ReactPlayer from 'react-player';

import actions from 'app/actions';

import { isPortraitOrientation } from 'core/utils';
import { isMobile, isTablet, isMacOs } from 'react-device-detect';

import * as selectors from 'app/selectors';

import MobileBlockScreen from 'app/components/MobileBlockScreen';
import DeviceRotate from 'app/components/modals/DeviceRotate';
import ModalDialog from 'app/components/modals/ModalDialog';
import PrivacyPolicy from 'app/components/notifications/PrivacyPolicy';
import DownloadApp from 'app/components/modals/DownloadApp';
import ContactUs from 'app/components/modals/ContactUs';
import Header from 'app/components/Header';
import AudioMute from 'app/components/Audio/Mute';
import PathsNew from 'app/components/PathsNew';
import SiteMap from 'app/components/modals/SiteMap';

import { ALLOW_EVENTS_CLASS, CLEAR_DEFER_CLASS } from 'app/constants/app';

import Home from 'modules/home/layouts';
import WorkflowsOverview from 'modules/workflows-overview/containers/WorkflowsOverview';
import WorkflowsOverview2D from 'modules/workflows-overview-2d/layouts';
import EvaluationPlanningDesign from 'modules/evaluation-planning-design/layouts';
import DrillBlast from 'modules/drill-blast/layouts';
import LoadHaul from 'modules/load-haul/layouts';
import SurveyMonitoring from 'modules/survey-monitoring/layouts';
import Insights from 'modules/insights/layouts';
import LeadLayout from 'modules/lead/layouts';
import ModalDialogLead from 'modules/lead/components/modals';
import JourneysManager from 'modules/journeys-manager/layouts';
import Safety from 'modules/safety/layouts';
import Sidebar from 'modules/sidebar/components/Sidebar';

import getConfig from 'getLOMIXConfig';
import withNavigate from 'app/routers/withNavigate';

import eventBus from 'EventBus';
import { getFromLS, removeFromLS, saveToLS } from 'core/services/localStorage';

class App extends PureComponent {
  constructor(props) {
    super(props);

    this.cache = {
      audioMuted: null,
    };

    this.errorModalDialogLead = false;

    this.state = {
      enabledPortraitOrientation: false,
      openedDeviceRotate: false,
      classBlur: '',
      showDeviceRotate: false,
      openedContactUs: false,

      lomixConfig: null,
      journey: null,
      location: null,
      nextLocation: null,
      nextJourney: null,
      enabledTransition: false,

      audio: {
        muted: true,
      },

      canShowHashtagsModal: false,
      showPath: false,
      showHeader: false,

      disableClickLogo: false,
      showHeaderExploreMore: false,
      showHeaderMyJourneys: props.isUserLoggedIn,
      showHeaderContactUS: true,
      showHeaderDownload: true,
      showHeaderProfile: true,

      openedModalDialogLead: false,
    };
  }

  componentDidMount() {
    const { isUserLoggedIn, navigate } = this.props;
    // this.prevUserLogout();
    this.updateConfig();
    this.initLoadingScreen();

    if (isTablet) {
      this.checkIfTablet();

      window.addEventListener('resize', this.checkIfTablet, false);
    }
    window.addEventListener('resize', this.updateConfig, false);

    if (!isUserLoggedIn) {
      window.addEventListener('click', this.handleGlobalMouseClick, true);
    }
    if (isUserLoggedIn) {
      window.removeEventListener('click', this.handleGlobalMouseClick, true);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      routeLocation,
      canNavigate,
      direction,
      toJourney,
      replaceJourney,
      togglePaths,
      isOpenModelDialog,
      contentModelDialog,
      isUserLoggedIn,
      locationName,
      setCanNavigate,
      availableWorkflowsOverview,
      params,
      isInterests,
      // i18n,
      navigate,
    } = this.props;
    const { lomixConfig, journey } = this.state;

    if (prevState.journey !== journey) {
      if (this.isWorkflowOverviewPage2D(journey)) {
        togglePaths({ isOpen: true });
      }
    }

    if (prevProps.routeLocation !== routeLocation) {
      this.setAppData();
    }

    if (prevState.lomixConfig !== lomixConfig) {
      this.setAppData();
    }

    if (prevProps.locationName !== locationName && locationName) {
      this.setAppData();
    }

    if (prevProps.availableWorkflowsOverview !== availableWorkflowsOverview) {
      const { journeyId } = params;

      if (routeLocation.pathname.startsWith('/workflows-overview')) {
        // this.setAppData();
        this.goToPath('/workflows-overview');
        return;
      }
      // this.setAppData();
    }

    if (prevProps.isUserLoggedIn !== isUserLoggedIn && !isUserLoggedIn) {
      window.addEventListener('click', this.handleGlobalMouseClick, true);

      if (this.props.availableWorkflowsOverview) {
        this.workflowsOverview();
      } else {
        this.workflowsOverview2D();
      }
    }

    if (prevProps.isUserLoggedIn !== isUserLoggedIn && isUserLoggedIn) {
      window.removeEventListener('click', this.handleGlobalMouseClick, true);

      this.shareJourney();

      this.navigateToJourneyIfCan();
    }

    // if (
    //   (prevProps.isUserLoggedIn !== isUserLoggedIn && isUserLoggedIn) ||
    //   (prevProps.isInterests !== isInterests && isInterests) ||
    //   prevProps.locationName !== locationName
    // ) {
    //   this.clearDeferredClickElement();
    // }

    if (prevProps.params !== params) {
      this.navigateToJourneyIfCan();
    }

    if (prevProps.canNavigate !== canNavigate && canNavigate) {
      setCanNavigate(false);

      if (direction === 'next') {
        this.goToNextLocation();
      } else if (direction === 'prev') {
        this.goToPrevLocation();
      } else if (direction === 'goToMineMap') {
        this.goToMineMap();
      }
      if (toJourney) {
        this.goToPath(toJourney, replaceJourney);
      }
    }

    if (prevProps.isOpenModelDialog !== isOpenModelDialog) {
      if (isOpenModelDialog) {
        if (contentModelDialog.vid) {
          this.cache.audioMuted = this.props.audioMuted;

          this.setAudioSettings('muted', true);
        }
      } else if (this.cache.audioMuted !== null) {
        this.setAudioSettings('muted', this.cache.audioMuted);
        this.cache.audioMuted = null;
      }
    }
  }

  componentWillUnmount() {
    if (isTablet) {
      window.removeEventListener('resize', this.checkIfTablet);
    }

    window.removeEventListener('click', this.handleGlobalMouseClick, true);

    window.removeEventListener('resize', this.updateConfig);
  }

  // prevUserLogout = () => {
  //   const { setLoggedUser } = this.props;
  //   const existedUser = getFromLS('loggedUser');
  //   if (getFromLS('isUserLoggedIn') && 'interest' in existedUser) {
  //     saveToLS('isUserLoggedIn', false);
  //     saveToLS('prevUser', true);
  //     removeFromLS('loggedUser');
  //     setLoggedUser(null);
  //   }
  // };

  setAppData = () => {
    const {
      params,
      routeLocation,
      locationName,
      setJourney,
      setLocation,
      setLocationName,
      setNextLocation,
      setPrevLocation,
      setNextJourney,
      setDirection,
      setHideHashtags,
      availableWorkflowsOverview,
      isUserLoggedIn,
      loggedUser,
    } = this.props;

    const { lomixConfig } = this.state;

    let { journeyId } = params;
    const { locationId } = params;

    const locationSlug = !locationName ? locationId : locationName;

    setLocationName(locationSlug);
    setHideHashtags(false);

    if (!params.journeyId && routeLocation.pathname === '/workflows-overview') {
      journeyId = availableWorkflowsOverview ? 'workflows-overview' : 'workflows-overview-2d';
    } else if (!params.journeyId && routeLocation.pathname === '/sign-up') {
      journeyId = 'sign-up';
    } else if (journeyId === 'workflows-overview' && !availableWorkflowsOverview) {
      journeyId = 'workflows-overview-2d';
    }

    const journey = lomixConfig?.find((j) => j.slug === journeyId) || lomixConfig[0];
    this.setState({
      journey,
    });

    if (this.isLead()) {
      this.setState({
        disableClickLogo: false,
        showHeaderExploreMore: true,
        showHeaderMyJourneys: isUserLoggedIn,
        showHeaderContactUS: true,
        showHeaderDownload: false,
        showHeaderProfile: false,
      });
    } else {
      this.setState({
        disableClickLogo: false,
        showHeaderExploreMore: false,
        showHeaderMyJourneys: isUserLoggedIn,
        showHeaderContactUS: true,
        showHeaderDownload: true,
        showHeaderProfile: true,
      });
    }

    if (journey?.slug !== 'lead') {
      this.setState({
        showPath:
          journey?.slug !== 'home' &&
          journey?.slug !== 'workflows-overview-2d' &&
          journey?.slug !== 'manager' &&
          journey?.slug !== 'workflows-overview',
      });
    }

    setJourney(journey);

    const location =
      journey?.slug === 'home' ||
      this.isWorkflowOverviewPage(journey) ||
      (journey?.slug === 'workflows-overview-2d' && !locationId)
        ? journey.info
        : journey.locations.find((l) => l.slug === locationSlug) || journey.locations[0];

    this.setState({
      location,
    });

    setLocation(location);

    const currentLocationIndex = journey.locations.findIndex((l) => l.slug === locationSlug);

    const nextLocation =
      (currentLocationIndex !== -1 && journey.locations[currentLocationIndex + 1]) || null;

    this.setState({ nextLocation });
    setNextLocation(nextLocation);

    const prevLocation = journey.locations[currentLocationIndex - 1] || null;
    this.setState({ prevLocation });
    setPrevLocation(prevLocation);

    const navigableJourneys = lomixConfig.filter((j) => j.isInPaths);

    const nextJourney =
      navigableJourneys.find((j) => j.id === journey.id + 1) ||
      navigableJourneys.find((j) => j.id === 1);
    setNextJourney(nextJourney);
    this.setState({ nextJourney });
    setDirection(null);
  };

  updateConfig = () => {
    const { setLomixConfig } = this.props;

    const lomixConfig = getConfig();

    const payload = {
      lomixConfig,
    };
    this.setState({ lomixConfig });
    setLomixConfig(payload);
  };

  handleEventDownload = ({ action }) => {
    switch (action) {
      case 'close': {
        this.props.showPwaModal({ show: false });
        break;
      }
      default: {
      }
    }
  };

  handleEventContactUs = ({ action }) => {
    switch (action) {
      case 'sendEmail': {
        this.setState({ openedContactUs: false });
        break;
      }
      case 'close': {
        this.setState({ openedContactUs: false });
        break;
      }
      default: {
      }
    }
  };

  handleGlobalMouseClick = (e) => {
    const { routeLocation, isMsalUser, setDeferredClickElement } = this.props;

    const isLeadJourneyPath = routeLocation.pathname.indexOf('/lead/') > -1;

    if (isLeadJourneyPath) return;

    // if (isMsalUser && !this.hasSomeParentTheAllowEventClass(e.target, ALLOW_EVENTS_CLASS)) {
    //   e.stopPropagation();
    //   e.preventDefault();
    //   loginMsal();
    // } else

    if (!isMsalUser && !this.hasSomeParentTheAllowEventClass(e.target, ALLOW_EVENTS_CLASS)) {
      e.stopPropagation();
      e.preventDefault();

      let deffer = e.target;

      if (e.target.onclick === null) {
        deffer = this.findParentWithOnclick(e.target);
      }
      setDeferredClickElement(deffer);
    }

    if (!isMsalUser && e?.target.className?.toString().includes(CLEAR_DEFER_CLASS)) {
      setDeferredClickElement(false);
    }
  };

  // clearDeferredClickElement = () => {
  //   const { isUserLoggedIn, isMsalUser } = this.props;

  //   if (!isMsalUser && !isUserLoggedIn && !this.isWorkflowOverviewPage()) return;

  //   window.removeEventListener('click', this.handleGlobalMouseClick, true);
  // };

  hasSomeParentTheAllowEventClass = (element, classname) => {
    if (element?.className?.toString().includes(classname)) return true;
    return (
      element.parentNode && this.hasSomeParentTheAllowEventClass(element.parentNode, classname)
    );
  };

  findParentWithOnclick = (element) => {
    if (element.parentNode?.onclick !== null) {
      return element.parentNode;
    }

    return this.findParentWithOnclick(element.parentNode);
  };

  checkIfTablet = () => {
    if (isPortraitOrientation()) {
      const { showedTabletPortraitOrientation, showTabletOrientation } = this.props;

      if (showedTabletPortraitOrientation) {
        this.setState({
          enabledPortraitOrientation: true,
          openedDeviceRotate: false,
          classBlur: '',
          showDeviceRotate: true,
        });
      } else {
        this.setState({
          enabledPortraitOrientation: true,
          openedDeviceRotate: true,
          classBlur: 'device-rotate__blur',
          showDeviceRotate: false,
        });

        const payload = {
          orientation: 'portrait',
        };

        showTabletOrientation(payload);
      }
    } else {
      this.setState({
        enabledPortraitOrientation: false,
        openedDeviceRotate: false,
        classBlur: '',
        showDeviceRotate: false,
      });
    }
  };

  goToNextLocation = () => {
    const { navigate, setCanNavigate, setDirection, setLocationName } = this.props;
    const { journey, nextLocation, nextJourney } = this.state;

    setCanNavigate(false);
    setDirection(null);
    setLocationName(null);

    if (journey?.slug === 'home') {
      navigate(`/workflows-overview`);
    } else if (nextLocation) {
      navigate(`/${journey?.slug}/${nextLocation?.slug}`);
    } else {
      this.setState({ enabledTransition: true });
      this.enablePageTransition();
      setTimeout(() => {
        this.disablePageTransition();
        this.setState({ enabledTransition: false });
      }, 1000);
      setTimeout(() => {
        navigate(`/${nextJourney?.slug}`);
      }, 1500);
    }
  };

  goToPrevLocation = () => {
    const { navigate, setCanNavigate, setDirection, setLocationName } = this.props;
    const { journey, prevLocation } = this.state;
    this.setState({ enabledTransition: true });
    this.enablePageTransition();

    setTimeout(() => {
      this.disablePageTransition();
      this.setState({ enabledTransition: false });
    }, 1000);
    setTimeout(() => {
      if (prevLocation) {
        navigate(`/${journey.slug}/${prevLocation?.slug}`);
      } else {
        navigate(`/${journey.slug}`);
      }
    }, 1500);
    setCanNavigate(false);
    setDirection(null);
    setLocationName(null);
  };

  goToMineMap = () => {
    const { navigate, setCanNavigate, setDirection, setLocationName } = this.props;

    setCanNavigate(false);
    setDirection(null);
    setLocationName(null);

    navigate(`/workflows-overview/mine-map`);
  };

  handleClickDownload = (isOpen) => {
    this.props.showPwaModal({ show: isOpen });
  };

  handleHeaderEvent = ({ action }) => {
    let internalAction = null;

    if (this.isLead() && action === 'openLogo') {
      internalAction = 'openExploreMore';
    } else {
      internalAction = action;
    }

    switch (internalAction) {
      case 'openExploreMore': {
        if (this.isLead() && Object.prototype.hasOwnProperty.call(this.props.params, 'id')) {
          this.props.setJourneyToAssociate({ id: this.props.params.id });
        }

        if (this.props.isUserLoggedIn) {
          this.shareJourney();
        }

        if (this.props.isAvailableWorkflowsOverview) {
          this.goToPath('/workflows-overview');
        } else {
          this.goToPath('/workflows-overview/mine-map');
        }
        break;
      }
      case 'openContactUs': {
        this.setState({
          openedContactUs: true,
        });
        break;
      }
      case 'openManageMyJourney': {
        this.goToPath('/manager');
        break;
      }
      case 'openDownload': {
        this.props.showPwaModal({ show: true });
        break;
      }
      case 'openLogo':
      case 'workflowsOverview': {
        let path = null;

        if (this.props.availableWorkflowsOverview) {
          path = `/workflows-overview`;
        } else {
          path = `/workflows-overview/mine-map`;
        }

        this.props.setSiteMapOpen({ isOpen: false });

        const { routeLocation, togglePaths } = this.props;

        if (path !== routeLocation.pathname) {
          this.goToPath(path);
        } else {
          togglePaths({ isOpen: true });
        }
        break;
      }
      case 'openSiteMap': {
        this.openSiteMap();
        break;
      }
      default: {
      }
    }
  };

  enablePageTransition = () => {
    const layerClass = '.page-transition__layer';
    const layers = document.querySelectorAll(layerClass);

    for (const layer of layers) {
      layer.classList.add('active');
    }
  };

  isWorkflowOverviewPage = (journey) => {
    return journey?.slug === 'workflows-overview';
  };

  isWorkflowOverviewPage2D = (journey) => {
    return journey?.slug === 'workflows-overview-2d';
  };

  isManagerPage = (journey) => {
    return journey?.slug === 'manager';
  };

  disablePageTransition = () => {
    const layerClass = '.page-transition__layer';
    const layers = document.querySelectorAll(layerClass);
    eventBus.dispatch('toggleSidebarOpened', { isOpen: false });

    this.countTransitionend = 0;
    for (const layer of layers) {
      layer.classList.remove('active');
    }
  };

  goToPath = (path, replace = false) => {
    const {
      navigate,
      setDirection,
      setCanNavigate,
      setToJourney,
      setLocationName,
      setJourney,
      togglePaths,
    } = this.props;

    togglePaths({ isOpen: false });

    setCanNavigate(false);
    setDirection(null);
    setToJourney(null);
    setJourney(null);
    setLocationName(null);
    this.setState({ enabledTransition: true });
    this.enablePageTransition();
    setTimeout(() => {
      this.disablePageTransition();
      this.setState({ enabledTransition: false });
    }, 1000);
    setTimeout(() => {
      navigate(path, { replace });
    }, 1500);
  };

  goToPathWithoutTransition = (path, replace = false) => {
    if (isMacOs) {
      const {
        navigate,
        setDirection,
        setCanNavigate,
        setToJourney,
        setLocationName,
        setJourney,
        togglePaths,
      } = this.props;
  
      togglePaths({ isOpen: false });
  
      setCanNavigate(false);
      setDirection(null);
      setToJourney(null);
      setJourney(null);
      setLocationName(null);
  
      setTimeout(() => {
        navigate(path, { replace });
      }, 1000);

      return;
    }

    this.props.navigate(path);
  };

  handlePathsEvent = ({ action, path }) => {
    switch (action) {
      case 'goToPath': {
        this.props.setSiteMapOpen({ isOpen: false });
        const { routeLocation, togglePaths } = this.props;
        if (path !== routeLocation.pathname) {
          this.goToPath(path);
        } else {
          togglePaths({ isOpen: true });
        }
        break;
      }
      case 'workflowsOverview': {
        if (this.props.availableWorkflowsOverview) {
          this.workflowsOverview();
        } else {
          this.workflowsOverview2D();
        }
        break;
      }
      case 'openSiteMap': {
        this.openSiteMap();
        break;
      }
      case 'openContactUs': {
        this.setState({
          openedContactUs: true,
        });
        break;
      }
      default: {
      }
    }
  };

  handleEventSiteMap = ({ action, path }) => {
    switch (action) {
      case 'goToPath': {
        this.props.setSiteMapOpen({ isOpen: false });
        const { routeLocation, togglePaths } = this.props;
        if (path !== routeLocation.pathname) {
          this.goToPath(path);
        } else {
          togglePaths({ isOpen: true });
        }
        break;
      }
      case 'closeSiteMap': {
        this.props.setSiteMapOpen({ isOpen: false });

        const { journey, togglePaths } = this.props;

        if (this.isWorkflowOverviewPage(journey)) {
          togglePaths({ isOpen: true });
        }
        break;
      }
      case 'openContactUs': {
        this.setState({
          openedContactUs: true,
        });
        this.props.setSiteMapOpen({ isOpen: false });
        break;
      }
      default: {
      }
    }
  };

  workflowsOverview = () => {
    this.goToPath(`/workflows-overview`);
  };

  workflowsOverview2D = () => {
    this.goToPath(`/workflows-overview/mine-map`);
  };

  setAudioSettings = (name, value) => {
    this.props.setAudioMuted({ [name]: value });
  };

  handleEventAudio = ({ action, enabled }) => {
    switch (action) {
      case 'toogleMute': {
        this.setAudioSettings('muted', enabled);
        break;
      }
      default: {
      }
    }
  };

  getJourney = () => {
    const { enabledTransition, hotspotIds, journey, nextJourney, lomixConfig } = this.state;

    const locationId = this.props.params.locationId || 'introduction';
    console.log('DEBUG:APP:getJourney', journey?.slug, locationId);
    switch (journey?.slug) {
      case 'home': {
        return (
          <Home
            locationId={locationId}
            enabledTransition={enabledTransition}
            isStageAnimating={this.props.isStageAnimating}
            audioMuted={this.props.audioMuted}
            onEvent={this.onEvent}
          />
        );
      }
      case 'evaluation-planning-design': {
        return (
          <EvaluationPlanningDesign
            locationId={locationId}
            enabledTransition={enabledTransition}
            hotspotIds={hotspotIds}
            isStageAnimating={this.props.isStageAnimating}
            audioMuted={this.props.audioMuted}
            nextTitle={nextJourney?.title}
            onEvent={this.onEvent}
            authed={this.props.isUserLoggedIn}
          />
        );
      }
      case 'workflows-overview': {
        if (this.errorModalDialogLead) {
          this.showModalDialogErrorLead();
        }
        return <WorkflowsOverview
                  visited={this.props.visitedWorkflowsOverview}
                  onEvent={this.onEvent}
                />;
      }
      case 'workflows-overview-2d': {
        if (this.errorModalDialogLead) {
          this.showModalDialogErrorLead();
        }
        return (
          <WorkflowsOverview2D
            locationId={locationId}
            enabledTransition={enabledTransition}
            hotspotIds={hotspotIds}
            isStageAnimating={this.props.isStageAnimating}
            audioMuted={this.props.audioMuted}
            nextTitle={nextJourney?.title}
            visited={this.props.visitedWorkflowsOverview2D}
            onEvent={this.onEvent}
            authed={this.props.isUserLoggedIn}
          />
        );
      }
      case 'drill-blast': {
        return (
          <DrillBlast
            locationId={locationId}
            enabledTransition={enabledTransition}
            hotspotIds={hotspotIds}
            isStageAnimating={this.props.isStageAnimating}
            audioMuted={this.props.audioMuted}
            nextTitle={nextJourney?.title}
            onEvent={this.onEvent}
            authed={this.props.isUserLoggedIn}
          />
        );
      }
      case 'load-haul': {
        return (
          <LoadHaul
            locationId={locationId}
            enabledTransition={enabledTransition}
            hotspotIds={hotspotIds}
            isStageAnimating={this.props.isStageAnimating}
            audioMuted={this.props.audioMuted}
            nextTitle={nextJourney?.title}
            onEvent={this.onEvent}
            authed={this.props.isUserLoggedIn}
          />
        );
      }
      case 'survey-monitoring': {
        return (
          <SurveyMonitoring
            locationId={locationId}
            enabledTransition={enabledTransition}
            hotspotIds={hotspotIds}
            isStageAnimating={this.props.isStageAnimating}
            audioMuted={this.props.audioMuted}
            nextTitle={nextJourney?.title}
            onEvent={this.onEvent}
            authed={this.props.isUserLoggedIn}
          />
        );
      }
      case 'insights': {
        return (
          <Insights
            locationId={locationId}
            enabledTransition={enabledTransition}
            hotspotIds={hotspotIds}
            isStageAnimating={this.props.isStageAnimating}
            audioMuted={this.props.audioMuted}
            nextTitle={nextJourney?.title}
            onEvent={this.onEvent}
            authed={this.props.isUserLoggedIn}
          />
        );
      }
      case 'manager': {
        return (
          <JourneysManager
            locationId={locationId}
            id={this.props.params.id}
            enabledTransition={enabledTransition}
            hotspotIds={hotspotIds}
            isStageAnimating={this.props.isStageAnimating}
            audioMuted={this.props.audioMuted}
            nextTitle={nextJourney?.title}
            onEvent={this.onEvent}
            authed={this.props.isUserLoggedIn && !!this.props.loggedUser?.idTokenClaims}
            loggedUser={this.props.loggedUser}
            allLomixConfigs={lomixConfig}
          />
        );
      }
      case 'lead': {
        const { subjourneyId, sublocationId } = this.props.params;

        return (
          <LeadLayout
            journeyId={`${journey.id}`}
            locationId={locationId}
            id={this.props.params.id}
            subjourneyId={subjourneyId}
            sublocationId={sublocationId}
            enabledTransition={enabledTransition}
            isStageAnimating={this.props.isStageAnimating}
            audioMuted={this.props.audioMuted}
            onEvent={this.onEvent}
            authed={this.props.isUserLoggedIn && !!this.props.loggedUser.idTokenClaims}
            modules={getConfig()}
          />
        );
      }
      case 'safety': {
        return (
          <Safety
            locationId={locationId}
            enabledTransition={enabledTransition}
            hotspotIds={hotspotIds}
            isStageAnimating={this.props.isStageAnimating}
            audioMuted={this.props.audioMuted}
            nextTitle={nextJourney?.title}
            onEvent={this.onEvent}
            authed={this.props.isUserLoggedIn}
          />
        );
      }
      default: {
        /* if (!this.isHome()) {
           this.onEvent({ action: 'home:goto' });
        } */
        return null;
      }
    }
  };

  initLoadingScreen() {
    const { togglePaths } = this.props;
    const { journey } = this.state;

    if (this.isWorkflowOverviewPage(journey)) {
      togglePaths({ isOpen: true });
    }
    this.setState({ enabledTransition: true });
    this.enablePageTransition();
    setTimeout(() => {
      this.disablePageTransition();
      this.setState({ enabledTransition: false });
    }, 1000);
  }

  openSiteMap() {
    const { togglePaths, setSiteMapOpen } = this.props;

    togglePaths({ isOpen: false });

    setSiteMapOpen({ isOpen: true });
  }

  canShowPath() {
    const { showPath } = this.state;
    return !!showPath;
  }

  getVisibleHeader() {
    const { journey, location, showHeader } = this.state;

    if (this.isWorkflowOverviewPage2D(journey)) {
      return location?.hasMainNav;
    }

    return journey?.hasMainNav || showHeader;
  }

  onEvent = ({ action, data }) => {
    switch (action) {
      case 'goToPathWithoutTransition': {
        this.goToPathWithoutTransition(data?.path);
        break;
      }
      case 'goToPath': {
        this.goToPath(data?.path);
        break;
      }
      case 'toggleIsStageAnimating': {
        this.props.toggleIsStageAnimating({ isStageAnimating: data.value });
        break;
      }
      case 'setCanNavigate': {
        this.props.setCanNavigate(data.value);
        break;
      }
      case 'togglePaths': {
        const { togglePaths } = this.props;
        togglePaths({ isOpen: data.isOpen });
        break;
      }
      case 'header:show': {
        this.setState({ showHeader: data.show });
        break;
      }
      case 'paths:show': {
        this.setState({ showPath: data.show });
        break;
      }
      case 'paths:open': {
        const { togglePaths } = this.props;
        togglePaths({ isOpen: true });
        break;
      }
      case 'paths:close': {
        const { togglePaths } = this.props;
        togglePaths({ isOpen: false });
        break;
      }
      case 'location:ready': {
        const { togglePaths } = this.props;

        this.clearTimeOutForTogglePaths();

        if (data.lastLocation) {
          this.timeOutForTogglePaths = setTimeout(() => {
            togglePaths({ isOpen: true });
          }, 5000);
        }

        this.setState({
          canShowHashtagsModal: true,
        });

        this.props.toggleIsStageAnimating({ isStageAnimating: false });
        break;
      }
      case 'location:prev': {
        this.clearTimeOutForTogglePaths();

        this.setState({ enabledTransition: true });
        this.enablePageTransition();

        setTimeout(() => {
          this.disablePageTransition();
          this.setState({ enabledTransition: false });
        }, 1000);

        const { setHideHashtags, setCanNavigate, setDirection, setLocationName } = this.props;

        setHideHashtags(true);
        setCanNavigate(false);
        setDirection(null);
        setLocationName(null);
        break;
      }
      case 'location:prev:end': {
        this.setState({ enabledTransition: true });
        this.enablePageTransition();

        setTimeout(() => {
          this.disablePageTransition();
          this.setState({ enabledTransition: false });
        }, 1000);

        break;
      }
      case 'location:prev:start':
      case 'location:next:start': {
        this.clearTimeOutForTogglePaths();

        this.props.setHideHashtags(true);
        this.props.toggleIsStageAnimating({ isStageAnimating: true });
        this.props.togglePaths({ isOpen: false });
        this.setState({ canShowHashtagsModal: false });
        break;
      }
      case 'location:prev:null': {
        this.clearTimeOutForTogglePaths();

        const { navigate, setHideHashtags, setCanNavigate, setDirection, setLocationName } =
          this.props;
        const { journey } = this.state;

        this.setState({ enabledTransition: true });
        this.enablePageTransition();

        setTimeout(() => {
          this.disablePageTransition();
          this.setState({ enabledTransition: false });
        }, 1000);

        setTimeout(() => {
          navigate(`/${journey.slug}`);
        }, 1500);

        setHideHashtags(true);
        setCanNavigate(false);
        setDirection(null);
        setLocationName(null);
        break;
      }
      case 'location:next:null': {
        this.clearTimeOutForTogglePaths();

        const { navigate, setHideHashtags, setCanNavigate, setDirection, setLocationName } =
          this.props;
        const { nextJourney } = this.state;

        const slug = this.isHome() ? 'workflows-overview' : nextJourney?.slug;

        setHideHashtags(true);
        setCanNavigate(false);
        setDirection(null);
        setLocationName(null);

        this.setState({
          enabledTransition: true,
          canShowHashtagsModal: false,
        });

        this.enablePageTransition();
        setTimeout(() => {
          this.disablePageTransition();
          this.setState({ enabledTransition: false });
        }, 1000);
        setTimeout(() => {
          navigate(`/${slug}`);
        }, 1500);
        break;
      }
      case 'mine-map:visited': {
        this.props.visitWorkflowsOverview();
        break;
      }
      case 'mine-map-2d:visited': {
        this.props.visitWorkflowsOverview2D();
        break;
      }
      case 'journey:change': {
        const { lomixConfig } = this.state;

        const journey = lomixConfig?.find((j) => j.slug === data.id);

        if (journey) {
          let location = null;

          if (Array.isArray(journey.locations) && journey.locations.length > 0) {
            if (data.locationId) {
              location = journey.locations.find((l) => l.slug === data.locationId);
            }

            location = location || journey.locations[0];
          }

          if (location) {
            this.goToPath(`${data.id}/${location.slug}`);
          } else {
            this.goToPath(`${data.id}`);
          }
        }
        break;
      }
      case 'explore-more': {
        if (data?.error) {
          if (data?.source === 'lead') {
            this.errorModalDialogLead = true;
          }
        }

        if (this.isLead() && Object.prototype.hasOwnProperty.call(this.props.params, 'id')) {
          this.props.setJourneyToAssociate({ id: this.props.params.id });
        }

        if (this.props.isUserLoggedIn) {
          this.shareJourney();
        }

        if (this.props.isAvailableWorkflowsOverview) {
          this.goToPath('/workflows-overview');
        } else {
          this.goToPath('/workflows-overview/mine-map');
        }
        break;
      }
      case 'home:goto': {
        if (data?.error) {
          if (data?.source === 'lead') {
            this.errorModalDialogLead = true;
          }
        }
        this.goToPath('/');
        break;
      }
      default: {
        // data
      }
    }
  };

  onEventModalDialogLead = ({ action }) => {
    switch (action) {
      case 'closeError': {
        this.errorModalDialogLead = false;

        this.setState({
          openedModalDialogLead: false,
        });
        break;
      }
      default: {
        // TODO
      }
    }
  };

  isHome() {
    const { journey } = this.state;
    return journey?.slug === 'home';
  }

  clearTimeOutForTogglePaths() {
    if (this.timeOutForTogglePaths) {
      clearTimeout(this.timeOutForTogglePaths);
    }
  }

  isLead() {
    const { journey } = this.state;
    return journey?.slug === 'lead';
  }

  shareJourney() {
    const { loggedUser, shareJourney } = this.props;

    if (!loggedUser?.username) return;

    const emails = [loggedUser?.username];

    shareJourney({ emails });
  }

  showModalDialogErrorLead() {
    this.setState({
      openedModalDialogLead: true,
    });
  }

  showAudioMute() {
    const { subjourneyId, sublocationId } = this.props.params;

    const { journey } = this.state;

    const showed =
      !this.isWorkflowOverviewPage(journey) &&
      !this.isManagerPage(journey) &&
      !this.isWorkflowOverviewPage2D(journey) &&
      journey?.slug !== 'home' &&
      !(this.isLead() && !subjourneyId && !sublocationId);

    return showed;
  }

  navigateToJourneyIfCan() {
    if (!this.props.isUserLoggedIn) return;

    const key = 'deferredJourney';

    const toJourneyAfterSignIn = getFromLS(key);

    if (toJourneyAfterSignIn !== null) {
      removeFromLS(key);

      this.onEvent({
        action: 'journey:change',
        data: {
          id: toJourneyAfterSignIn,
        },
      });
    }
  }

  render() {
    const {
      config,
      params: { journeyId },
      audioMuted,
      openedSiteMap,
      isUserLoggedIn,
      routeName,
      openedPwaModal,
      isMsalUser,
      showHeaderSiteMap,
      showPathSiteMap,
    } = this.props;

    const {
      enabledPortraitOrientation,
      openedDeviceRotate,
      classBlur,
      showDeviceRotate,
      openedContactUs,
      enabledTransition,
      journey,
      location,
      disableClickLogo,
      showHeaderExploreMore,
      showHeaderMyJourneys,
      showHeaderContactUS,
      showHeaderDownload,
      showHeaderProfile,
      openedModalDialogLead,
    } = this.state;

    return (
      <>
        {isMobile && !isTablet ? (
          <MobileBlockScreen />
        ) : (
          <>
            <main className={`${config.className} ${journey?.slug}`}>
              <Header
                showDeviceRotate={showDeviceRotate}
                onEvent={this.handleHeaderEvent}
                blur={openedDeviceRotate}
                workflowsOverview={this.isWorkflowOverviewPage(journey)}
                workflowsOverview2D={this.isWorkflowOverviewPage2D(journey)}
                visible={this.getVisibleHeader()}
                authed={isUserLoggedIn}
                disableClickLogo={disableClickLogo}
                showHeaderExploreMore={false}
                showMyJourneys={showHeaderMyJourneys}
                showContactUS={showHeaderContactUS}
                showSiteMap={showHeaderSiteMap}
                showDownload={showHeaderDownload}
                showProfile={showHeaderProfile}
                msalLogged={isMsalUser}
              />

              <div className="page-transition__container">
                <div className="page-transition__layer page-transition__layer-1" />
                <div className="page-transition__layer page-transition__layer-2" />
                <div className="page-transition__layer page-transition__layer-3" />
                <div className="page-transition__layer page-transition__layer-4" />
              </div>

              {this.getJourney()}
              {enabledPortraitOrientation && <DeviceRotate opened={openedDeviceRotate} />}
              <ModalDialog />

              <Sidebar
                canSowPath={this.canShowPath()}
                pathComponent={<PathsNew journeyId={journeyId} onEvent={this.handlePathsEvent} showPathSiteMap={showPathSiteMap} />}
              />

              <SiteMap
                opened={openedSiteMap}
                locationId={this.props.params.locationId}
                onEvent={this.handleEventSiteMap}
              />
              <ContactUs opened={openedContactUs} onEvent={this.handleEventContactUs} />
            </main>
            <PrivacyPolicy />
            <DownloadApp opened={openedPwaModal} handleClickDownload={this.handleClickDownload} />

            {this.showAudioMute() && (
              <AudioMute
                enabled={audioMuted}
                onEvent={this.handleEventAudio}
                allowEvents={this.isWorkflowOverviewPage(journey) || journey?.slug === 'home'}
              />
            )}

            {/* !!location?.video?.playDefaultSound && (
              <ReactPlayer
                className="audio-player"
                url={require(`assets/audio/ambience_construction.wav`).default}
                playing={!audioMuted}
                loop
                volume={1}
              />
            ) */}
            <ModalDialogLead
              opened={openedModalDialogLead}
              error={this.errorModalDialogLead}
              onEvent={this.onEventModalDialogLead}
            />
          </>
        )}
      </>
    );
  }
}

App.propTypes = {
  config: PropTypes.shape({
    className: PropTypes.string,
  }),
  showedTabletPortraitOrientation: PropTypes.bool,
  showTabletOrientation: PropTypes.func,
  setLomixConfig: PropTypes.func,
  canNavigate: PropTypes.bool,
  openedSiteMap: PropTypes.bool,
  isStageAnimating: PropTypes.bool,
  // i18n: PropTypes.any,
};

App.defaultProps = {
  config: {
    className: 'journey',
  },
  showedTabletPortraitOrientation: false,
  showTabletOrientation: () => {},
  setLomixConfig: () => {},
  canNavigate: false,
  openedSiteMap: false,
  isStageAnimating: false,
  // i18n: null,
};

const mapStateToProps = (state, props) => {
  const loggedUser = selectors.getLoggedUser(state);
  const interests = loggedUser?.interest || {};

  const showHeaderSiteMap = loggedUser || // show for all login users
    selectors.getLoggedUser(state)?.username?.trim().endsWith('@hexagon.com') ||
    selectors.getLoggedUser(state)?.idTokenClaims?.extension_access?.trim().toLowerCase() === 'journeymanagement';

  const showPathSiteMap = showHeaderSiteMap;

  return {
    showedTabletPortraitOrientation: selectors.getShowedTabletPortraitOrientation(state) || false,
    canNavigate: selectors.getCanNavigate(state),
    direction: selectors.getDirection(state),
    toJourney: selectors.getToJourney(state),
    replaceJourney: selectors.getReplaceJourney(state),
    isOpenModelDialog: selectors.isOpenModal(state),
    contentModelDialog: selectors.getContentModal(state),
    isUserLoggedIn: selectors.getIsUserLoggedIn(state),
    isInterests: Object.keys(interests).some((k) => interests[k] === true) ?? false,
    audioMuted: selectors.getAudioMuted(state),
    locationName: selectors.getLocationName(state),
    deferredClickElement: selectors.getDeferredClickElement(state),
    availableWorkflowsOverview: selectors.isAvailableWorkflowsOverview(state),
    openedSiteMap: selectors.isOpenSitemap(state),
    isStageAnimating: selectors.isStageAnimating(state),
    visitedWorkflowsOverview: selectors.getVisitedWorkflowsOverview(state),
    visitedWorkflowsOverview2D: selectors.getVisitedWorkflowsOverview2D(state),
    openedPwaModal: selectors.isOpenPwaModal(state),
    loggedUser: selectors.getLoggedUser(state),
    isMsalUser: !!loggedUser?.idTokenClaims || false,
    showHeaderSiteMap,
    showPathSiteMap,
    // isLoginNeeded: selectors.getIsLoginNeeded(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  showTabletOrientation: (payload) => dispatch(actions.showTabletOrientation({ payload })),
  setLomixConfig: (payload) => dispatch(actions.setLomixConfig({ payload })),
  setJourney: (payload) => dispatch(actions.setJourney({ payload })),
  setLocation: (payload) => dispatch(actions.setLocation({ payload })),
  setLocationName: (payload) => dispatch(actions.setLocationName({ payload })),
  setNextLocation: (payload) => dispatch(actions.setNextLocation({ payload })),
  setPrevLocation: (payload) => dispatch(actions.setPrevLocation({ payload })),
  setNextJourney: (payload) => dispatch(actions.setNextJourney({ payload })),
  togglePaths: (payload) => dispatch(actions.togglePaths({ payload })),
  setDirection: (payload) => dispatch(actions.setDirection({ payload })),
  setCanNavigate: (payload) => dispatch(actions.setCanNavigate({ payload })),
  setToJourney: (payload) => dispatch(actions.setToJourney({ payload })),
  setAudioMuted: (payload) => dispatch(actions.setAudioMuted({ payload })),
  setDeferredClickElement: (payload) => dispatch(actions.setDeferredClickElement({ payload })),
  setHideHashtags: (payload) => dispatch(actions.setHideHashtags({ payload })),
  setSiteMapOpen: (payload) => dispatch(actions.setSiteMapOpen({ payload })),
  toggleIsStageAnimating: (payload) => dispatch(actions.toggleIsStageAnimating({ payload })),
  visitWorkflowsOverview: () => dispatch(actions.visitWorkflowsOverview()),
  visitWorkflowsOverview2D: () => dispatch(actions.visitWorkflowsOverview2D()),
  setLoggedUser: (payload) => dispatch(actions.setLoggedUser({ payload })),
  showPwaModal: (payload) => dispatch(actions.showPwaModal({ payload })),
  setJourneyToAssociate: (payload) => dispatch(actions.setJourneyToAssociate({ payload })),
  shareJourney: (payload) => dispatch(actions.shareJourney({ payload })),
});

export default withNavigate(connect(mapStateToProps, mapDispatchToProps)(App));
