const description = {
  id: '4 of 5',
  currentNumber: 4,
  totalNumber: 5,
  code: 4,
  title: 'safety.Slope Monitoring.title',
  slug: 'slope-monitoring',
  pdfPath: 'SlopeMonitoring',
  leadLayoutKey: 'slope-monitoring',
  apiSlug: 'slope-monitoring',
  productDetailsLink:
    'https://hexagon.com/products/product-groups/hxgn-minemonitoring/critical-slope-monitoring',
  description: 'safety.Slope Monitoring.description',
  video: {
    name: 'wf_Slope_Monitoring.mp4',
    stop: 15.5,
    backgroundImage: 'Slope_Monitoring.jpg',
  },
  drawPoints: {
    hotspots: 6,
    dancingAnts: 10,
    secondaries: 10.5,
  },
};

export default description;
