/* eslint-disable react/prop-types */
// @ts-nocheck
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
// import ProfileDropdown from 'modules/auth/components/ProfileDropdown';
import * as selectors from 'app/selectors';
import { logout, toggleUserIsLoggedIn } from 'modules/auth/authService';
import actions from 'app/actions';
import { AuthenticatedTemplate, useMsal } from '@azure/msal-react';
import { EventType } from '@azure/msal-browser';
import { initAuth } from 'modules/auth/authMsalService';
import PropTypes from 'prop-types';
import ProfileDropdownMsal from '../ProfileDropdownMsal';

const LoginMSAL = ({ setCanNavigate }) => {
  const { instance } = useMsal();

  // console.log('instance', instance);

  useEffect(() => {
    instance.enableAccountStorageEvents();
    const callback = instance.addEventCallback(async (message) => {
      // console.log('message.eventType', message.eventType);
      // console.log('message', message);

      await initAuth();

      // This will be run every time an event is emitted after registering this callback
      if (
        (message.eventType === EventType.LOGIN_SUCCESS ||
          message.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
          message.eventType === EventType.SSO_SILENT_SUCCESS) &&
        message.payload.account
      ) {
        // console.log('message.payload', message.payload);
        // const account = message.payload;
        // if (message.payload.idTokenClaims.tfp === b2cPolicies.names.signUpSignIn) {
        // retrieve the account from initial sing-in to the app
        // const originalSignInAccount = msalInstance
        //   .getAllAccounts()
        //   .find(
        //     (account) =>
        //       account.idTokenClaims.oid === message.payload.idTokenClaims.oid &&
        //       account.idTokenClaims.sub === message.payload.idTokenClaims.sub &&
        //       account.idTokenClaims.tfp === b2cPolicies.names.signUpSignIn,
        //   );
        // console.log('message.payload.account', message.payload.account);
        // const signUpSignInFlowRequest = {
        //   authority: b2cPolicies.authorities.signUpSignIn.authority,
        //   account: originalSignInAccount,
        // };

        toggleUserIsLoggedIn(true, message.payload.account, true);
        setCanNavigate(true);
        // }
      } else if (message.eventType === EventType.LOGOUT_SUCCESS) {
        logout();
      }

      // Do something with the result
    });

    return () => {
      instance.disableAccountStorageEvents();
      // This will be run on component unmount
      if (callback) {
        instance.removeEventCallback(callback);
      }
    };

    // setCallbackId(callback);
  }, []);

  return (
    <>
      <AuthenticatedTemplate>
        <ProfileDropdownMsal />
      </AuthenticatedTemplate>
    </>
  );
};

LoginMSAL.propTypes = {
  setCanNavigate: PropTypes.func,
};

LoginMSAL.defaultProps = {
  setCanNavigate: () => {},
};

const mapStateToProps = (state) => {
  return {
    setCanNavigate: selectors.getCanNavigate(state),
    loggedUser: selectors.getLoggedUser(state),
    isUserLoggedIn: selectors.getIsUserLoggedIn(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  setCanNavigate: (payload) => dispatch(actions.setCanNavigate({ payload })),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginMSAL);
