// @ts-nocheck
/* eslint-disable react/prop-types */
import React, { PureComponent } from 'react';

import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import config from 'modules/home/configs';

import withNavigate from 'app/routers/withNavigate';

import LocationHomeIntroduction from 'modules/home-introduction/layouts';

class Main extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      location: null,
      backgroundImage: null,
    };

    this.cache = {
      action: {
        name: null,
        waitFor: null,
      },
    };
  }

  componentDidMount() {
    const { locationId } = this.props;
    this.init(locationId);
  }

  componentDidUpdate(prevProps) {
    const { locationId } = this.props;
    if (prevProps.locationId !== locationId) {
      this.init(locationId);
    }
  }

  setBackgroundImage(location) {
    this.setState({ backgroundImage: location?.video?.backgroundImage });
  }

  getLocation() {
    const { journey, audioMuted, enabledTransition } = this.props;

    const { location, backgroundImage } = this.state;

    switch (location?.slug) {
      case 'introduction': {
        return (
          <LocationHomeIntroduction
            onEvent={this.onEvent}
            journey={journey} // TEMP. ONLY FOR TEST
            audioMuted={audioMuted}
            enabledTransition={enabledTransition}
            backgroundImage={backgroundImage}
          />
        );
      }
      default: {
        return null;
      }
    }
  }

  toggleIsStageAnimating = (isAnimating) => {
    const { onEvent } = this.props;
    onEvent({
      action: 'toggleIsStageAnimating',
      data: { isStageAnimating: isAnimating },
    });
  };

  onEvent = ({ action, data }) => {
    switch (action) {
      case 'location:destroy': {
        this.goToNextLocation();
        break;
      }
      case 'toggleIsStageAnimating': {
        this.toggleIsStageAnimating(data.isStageAnimating);
        break;
      }
      default: {
        const { onEvent } = this.props;
        onEvent(action, data);
      }
    }
  };

  goToNextLocation = () => {
    const { onEvent } = this.props;

    onEvent({ action: 'location:next:null' });
  };

  init(locationId = null) {
    const { journey } = this.props;

    let location = null;

    if (locationId === 'introduction') {
      location = config.current.info;
    } else if (Array.isArray(journey?.locations)) {
      for (let i = 0, n = journey.locations.length; i < n; i += 1) {
        const lc = journey.locations[i];

        if (lc.slug === locationId) {
          location = lc;
          break;
        }
      }
    }

    this.setBackgroundImage(location);

    this.setState({
      location,
    });

    return {
      location,
    };
  }

  render() {
    return <>{this.getLocation()}</>;
  }
}

Main.propTypes = {
  config: PropTypes.shape({
    className: PropTypes.string,
    delayPrimaries: PropTypes.number,
    delaySecondaries: PropTypes.number,
    staggerBreakdowns: PropTypes.number,
    delayVideoBreakdown: PropTypes.number,
  }),
  enabledTransition: PropTypes.bool,
  audioMuted: PropTypes.bool,
  onEvent: PropTypes.func,
  locationId: PropTypes.string,
};

Main.defaultProps = {
  config: {
    className: 'journey',
    delayPrimaries: 0.5,
    delaySecondaries: 0.25,
    staggerBreakdowns: 0.125,
    delayVideoBreakdown: 1.5,
  },
  enabledTransition: false,
  audioMuted: true,
  onEvent: () => {},
  locationId: null,
};

const mapStateToProps = () => {
  const journey = config.current;

  return {
    config: {
      className: 'journey',
      delayPrimaries: 0.5,
      delaySecondaries: 0.25,
      staggerBreakdowns: 0.125,
      delayVideoBreakdown: 1.5,
    },
    journey,
  };
};

export default withNavigate(connect(mapStateToProps, null)(Main));
