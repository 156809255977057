import {
  PRODUCT_DETAIL_PANEL_SET_IS_EXIST,
  PRODUCT_DETAIL_PANEL_SET_EMPTY,
  PRODUCT_DETAIL_PANEL_OPEN_POPUP,
  PRODUCT_DETAIL_PANEL_CLOSE_POPUP,
} from '../constants';

const initialState = {
  isDataExist: null,
  isPopupOpened: false,
};

const app = (state = initialState, action) => {
  switch (action.type) {
    case PRODUCT_DETAIL_PANEL_SET_IS_EXIST:
      return {
        ...state,
        isDataExist: action.payload.isDataExist,
      };
    case PRODUCT_DETAIL_PANEL_SET_EMPTY:
      return {
        ...state,
        isDataExist: false,
        isPopupOpened: false,
      };
    case PRODUCT_DETAIL_PANEL_OPEN_POPUP:
      return {
        ...state,
        isPopupOpened: true,
      };
    case PRODUCT_DETAIL_PANEL_CLOSE_POPUP:
      return {
        ...state,
        isPopupOpened: false,
      };
    default: {
      return state;
    }
  }
};

export default app;
