/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/button-has-type */
/* eslint-disable react/prop-types */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ArrowLeftIcon from 'app/assets/icons/arrow-left.svg';
import ArrowRightIcon from 'app/assets/icons/arrow-right.svg';

class ArrowButton extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleClick = (e) => {
    const { onClick } = this.props;

    if (onClick) {
      onClick(e);
    }
  };

  render() {
    const { config, additionalClasses, direction } = this.props;

    return (
      <button
        className={`${config.className} ${
          (Array.isArray(additionalClasses) && additionalClasses.join(' ')) || additionalClasses
        }`}
        onClick={this.handleClick}
      >
        {direction === 'forwards' ? <ArrowRightIcon /> : <ArrowLeftIcon />}
      </button>
    );
  }
}

ArrowButton.propTypes = {
  config: PropTypes.shape({
    className: PropTypes.string,
  }),
  direction: PropTypes.string,
  onClick: PropTypes.func,
};

ArrowButton.defaultProps = {
  config: {
    className: 'c-arrow',
  },
  direction: 'forwards',
  onClick: () => {},
};

ArrowButton.id = 'ArrowButton';

const mapStateToProps = () => {
  return {
    additionalClasses: [],
  };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(ArrowButton);
