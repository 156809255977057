// @ts-nocheck
/* eslint-disable no-unused-vars */
/* eslint-disable react/button-has-type,react/prop-types */
import React from 'react';

import { useTranslation } from 'react-i18next';
import Success from './assets/icons/success.svg';
import RemoveIcon from './assets/icons/remove.svg';

const InstalledSuccess = ({ handleClose, handleDeleteInstalledApp }) => {
  const { t } = useTranslation();
  return (
    <>
      <h2>{t('download.Download offline version')}</h2>
      <div className="row margin-top">
        <div className="flex-center icon-wrap">
          <Success />
        </div>
        <div className="margin-left">
          <div>{t('download.Offline version for Life-of-Mine Experience installed')}</div>
          <div className="margin-top">
            {t(
              'download.To view Life-of-Mine Experience in offline mode, just navigate to the same URL in the browser used while downloading the files',
            )}
          </div>
        </div>
      </div>
      <div className="border margin-top" />
      <div className="row margin-top" style={{ alignItems: 'center' }}>
        <div className="icon-wrap-5">
          <RemoveIcon />
        </div>
        <div
          className="text-support margin-left text-center blue-text"
          onClick={handleDeleteInstalledApp}
          onKeyPress={handleDeleteInstalledApp}
          role="button"
          tabIndex={0}
        >
          {t('download.Delete Installed Offline Version')}
        </div>
      </div>
      <div className="c-buttons-list flex-center margin-top" style={{ justifyContent: 'center' }}>
        <button className="c-button c-button_expand" onClick={handleClose}>
          {t('common.OK')}
        </button>
      </div>
    </>
  );
};

export default InstalledSuccess;
