/* eslint-disable react/button-has-type */
import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import './assets/styles/ButtonExploreMore.scss';
import { withTranslation } from 'react-i18next';

class ButtonExploreMore extends PureComponent {
  handleClick = () => {
    const { onClick } = this.props;
    onClick({ action: 'openExploreMore' });
  };

  render() {
    const { t } = this.props;
    return (
      <div className="c-explore-more-us">
        <button className="c-button c-button--size-default" onClick={this.handleClick}>
          <div className="c-button__text">{t('common.Explore More')} </div>
        </button>
      </div>
    );
  }
}

ButtonExploreMore.propTypes = {
  onClick: PropTypes.func,
  t: PropTypes.func,
};

ButtonExploreMore.defaultProps = {
  onClick: () => {},
  t: () => {},
};

export default withTranslation()(
  connect(null, null, null, { forwardRef: true })(ButtonExploreMore),
);
