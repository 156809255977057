const config = {
  revision: 1,
  defaultRevision: 1,
  models: {
    terrain: {
      name: 'Terrain',
      ext: 'gltf',
      fullname: 'Terrain.gltf',
      path: 'modules/workflows-overview/assets/models/Hexagon_Terrain_v3.glb',
      revision: 1,
      size: {
        total: 37623488,
      },
      map: {
        name: 'TerrainSurface_Color',
        ext: 'jpg',
        fullname: 'TerrainSurface_Color.jpg',
        path: 'modules/workflows-overview/assets/models/Terrain_combined_UWs.png',
        size: {
          total: 28125533,
        },
      },
      aoMap: {
        name: 'TerrainAmbient_Occlusion',
        ext: 'jpg',
        fullname: 'TerrainAmbient_Occlusion.jpg',
        path: 'modules/workflows-overview/assets/models/TerrainAmbient_Occlusion.jpg',
        size: {
          total: 136337,
        },
      },
    },
    vehicles: {
      DrillingMachine_Solo: {
        name: 'DrillingMachine_Solo',
        path: 'modules/workflows-overview/assets/models/Hexagon_DrillingMachine_Solo_v3.glb',
        revision: 2,
        size: {
          total: 7250964,
        },
        position: {
          x: 20,
          y: 3.8,
          z: -120,
        },
        lod: {
          hight: {
            name: 'DrillingMachine_Solo',
          },
          low: {
            name: 'DrillingMachine_Solo_LOW',
          },
        },
      },
      DrillSpots_Solo: {
        name: 'DrillSpots_Solo',
        revision: 1,
        size: {
          total: 1213756,
        },
        position: {
          x: 20,
          y: 3.8,
          z: -120,
        },
        lod: {
          hight: {
            name: 'DrillSpots_Solo',
          },
          low: {
            name: 'DrillSpots_Solo_LOW',
          },
        },
      },
      Insights_Combined: {
        name: 'Insights_Combined',
        path: 'modules/workflows-overview/assets/models/3JS_Hexagon_Planing_Combined_Reverse_v5.glb',
        revision: 2,
        size: {
          total: 12310360,
        },
        position: {
          x: -20,
          y: 1.8,
          z: -120,
        },
        lod: {
          hight: {
            name: 'Insights_Combined',
          },
          low: {
            name: 'Insights_Combined_LOW',
          },
        },
      },
      Insights_Drone_Scan_Solo: {
        name: 'Insights_Drone_Scan_Solo',
        path: 'modules/workflows-overview/assets/models/Planning_Drone_Scan_Solo_GLTF.gltf',
        revision: 1,
        size: {
          total: 9394274,
        },
        position: {
          x: -20,
          y: 1.8,
          z: -120,
        },
        lod: {
          hight: {
            name: 'Insights_Drone_Scan_Solo',
          },
          low: {
            name: 'Insights_Drone_Scan_Solo_LOW',
          },
        },
      },
      Insights_GridMotion_Solo: {
        name: 'Insights_GridMotion_Solo',
        path: 'modules/workflows-overview/assets/models/Planning_GridMotion_Solo_GLTF.gltf',
        revision: 1,
        size: {
          total: 6671155,
        },
        position: {
          x: -20,
          y: 1.8,
          z: -120,
        },
        lod: {
          hight: {
            name: 'Insights_GridMotion_Solo',
          },
          low: {
            name: 'Insights_GridMotion_Solo_LOW',
          },
        },
      },
      Insights_ScanDevice_Waves_Solo: {
        name: 'Insights_ScanDevice_Waves_Solo',
        path: 'modules/workflows-overview/assets/models/Planning_ScanDevice_Waves_Solo_GLTF.gltf',
        revision: 1,
        size: {
          total: 15824600,
        },
        position: {
          x: -20,
          y: 1.8,
          z: -120,
        },
        lod: {
          hight: {
            name: 'Insights_ScanDevice_Waves_Solo',
          },
          low: {
            name: 'Insights_ScanDevice_Waves_Solo_LOW',
          },
        },
      },
      Buildings: {
        name: 'Buildings',
        path: 'modules/workflows-overview/assets/models/Hexagon_Buildings_GLB.glb',
        revision: 1,
        size: {
          total: 9535760,
        },
        position: {
          x: -459.2008361816406,
          y: 88.42454528808594,
          z: -371.2225341796875,
        },
        lod: {
          hight: {
            name: 'Buildings',
          },
          low: {
            name: 'Buildings_LOW',
          },
        },
      },
      Insights_Animation: {
        name: 'Insights_Animation',
        path: 'modules/workflows-overview/assets/models/Insights_Animation.glb',
        revision: 1,
        size: {
          total: 9535760,
        },
        position: {
          x: -270,
          y: 210,
          z: -670,
        },
        lod: {
          hight: {
            name: 'Insights_Animation',
          },
          low: {
            name: 'Insights_Animation_LOW',
          },
        },
      },
      Terrain_Tunnel_Entrance: {
        name: 'Terrain_Tunnel_Entrance',
        path: 'modules/workflows-overview/assets/models/Terrain_Tunnel_Entrance.glb',
        revision: 1,
        size: {
          total: 5174284,
        },
        position: {
          x: -20,
          y: 1.8,
          z: -120,
        },
        lod: {
          hight: {
            name: 'Terrain_Tunnel_Entrance',
          },
          low: {
            name: 'Terrain_Tunnel_Entrance_LOW',
          },
        },
      },
      Monitoring_Blocks: {
        name: 'Monitoring_Blocks',
        path: 'modules/workflows-overview/assets/models/3JS_Hexagon_Monitoring_Combined_Reverse_v4.glb',
        revision: 1,
        size: {
          total: 11342956,
        },
        position: {
          x: 200,
          y: 1,
          z: -60,
        },
        lod: {
          hight: {
            name: 'Monitoring_Blocks',
          },
          low: {
            name: 'Monitoring_Blocks_LOW',
          },
        },
      },
      Drill_Blast: {
        name: 'Drill_Blast',
        path: 'modules/workflows-overview/assets/models/3JS_Hexagon_Drill_Blast_Scene_v4.glb',
        revision: 1,
        size: {
          total: 22901468,
        },
        position: {
          x: 100.7198486328125,
          y: 0.014208750799298286,
          z: -40.46917724609375,
        },
        lod: {
          hight: {
            name: 'Drill_Blast',
            size: {
              total: 35269964,
            },
          },
          low: {
            name: 'Drill_Blast_LOW',
            size: {
              total: 35269964,
            },
          },
        },
      },
      LoadHaul: {
        name: 'LoadHaul',
        path: 'modules/workflows-overview/assets/models/3JS_Hexagon_Material_Move_Export_v5.glb',
        revision: 2,
        size: {
          total: 36483404,
        },
        position: {
          // arrow2 -> -26.83798599243164, y: 2.583341121673584, z: 142.84201049804688
          // arriw 11 -> -86.55113220214844, y: -0.06600107252597809, z: -17.22698211669922
          x: 270.1567077636719,
          y: -0.10339627414941788,
          z: -20.74173736572266,
        },
        lod: {
          hight: {
            name: 'LoadHaul',
            size: {
              total: 36483404,
            },
          },
          low: {
            name: 'LoadHaul_LOW',
            size: {
              total: 36483404,
            },
          },
        },
      },
      Tunnel: {
        name: 'Tunnel',
        path: '',
        revision: 1,
        size: {
          total: 0,
        },
        position: {
          x: 35.4157459871554,
          y: 67.15526320309966,
          z: 253.4195387187898,
        },
        lod: {
          hight: {
            name: 'Tunnel',
          },
          low: {
            name: 'Tunnel_LOW',
          },
        },
      },
      Terrain_Explosion: {
        name: 'Terrain_Explosion',
        path: 'modules/workflows-overview/assets/models/Hexagon_Terrain_v3_Expl_Transp.glb',
        revision: 1,
        size: {
          total: 4793644,
        },
        position: {
          x: 270.1567077636719,
          y: -0.10339627414941788,
          z: -20.74173736572266,
        },
        lod: {
          hight: {
            name: 'Terrain_Explosion',
            size: {
              total: 4793644,
            },
          },
          low: {
            name: 'Terrain_Explosion_LOW',
            size: {
              total: 4793644,
            },
          },
        },
      },
      Safety: {
        name: 'Safety',
        path: 'modules/workflows-overview/assets/models/3JS_Personal_Safety.glb',
        revision: 3,
        size: {
          total: 54587148,
        },
        position: {
          x: 190.18865966796875,
          y: 17.88319206237793,
          z: 149.9568634033203,
        },
        /* position: {
          // arrow2 -> -26.83798599243164, y: 2.583341121673584, z: 142.84201049804688
          // arriw 11 -> -86.55113220214844, y: -0.06600107252597809, z: -17.22698211669922
          x: 0, /// 320.1567077636719,
          y: 0, // -0.10339627414941788,
          z: 0, // 20.74173736572266,
        }, */
        lod: {
          hight: {
            name: 'Safety',
            size: {
              total: 54587148,
            },
          },
          low: {
            name: 'Safety_LOW',
            size: {
              total: 54587148,
            },
          },
        },
      },
    },
  },
};

Object.freeze(config);

export default config;
