// @ts-nocheck
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import actions from 'app/actions';
import * as selectors from 'app/selectors';

import Button from 'modules/common/components/Button';

import './assets/scss/index.scss';
import { useTranslation } from 'react-i18next';
import ResizeOpen from './assets/img/resize.svg';
import ResizeClose from './assets/img/resizeClose.svg';

const PowerOfOneCard = ({ config, togglePaths, isFirstVisit, isPathOpen, show }) => {
  const stageCard = useRef();

  const [isExpanded, setIsExpanded] = useState(isFirstVisit);
  const { t } = useTranslation();

  const onExpandToggleClick = (toExpand) => {
    togglePaths({ isOpen: !toExpand });

    setIsExpanded(toExpand);
  };

  useEffect(() => {
    if (isFirstVisit) {
      setIsExpanded(true);
    } else {
      setIsExpanded(false);
    }
  }, [isFirstVisit, setIsExpanded]);

  useEffect(() => {
    if (isPathOpen && !isFirstVisit) {
      setIsExpanded(false);
    }
  }, [isPathOpen, isFirstVisit, setIsExpanded]);

  return show ? (
    <div
      ref={stageCard}
      className={`${config.className} ${isExpanded ? config.expandedClass : ''}`}
    >
      <div className={`${config.className}__title ${isExpanded ? config.expandedClass : ''}`}>
        <div className={`${config.className}__title-block`}>{t('workflow.Power of One')}</div>
        <button
          className={`${config.className}__expand-toggle`}
          onClick={() => {
            onExpandToggleClick(!isExpanded);
          }}
        >
          {isExpanded ? <ResizeClose /> : <ResizeOpen />}
        </button>
      </div>
      <div
        className={`${config.className}__content ${isExpanded ? config.expandedClass : ''} ${
          isFirstVisit ? 'first-visit' : ''
        }`}
      >
        <div className="">{t('workflow.Power of One text')}</div>
        <Button
          onClick={() => {
            onExpandToggleClick(!isExpanded);
          }}
        >
          {t('common.Hide')}
        </Button>
      </div>
    </div>
  ) : (
    ''
  );
};

const mapDispatchToProps = (dispatch) => ({
  togglePaths: (payload) => dispatch(actions.togglePaths({ payload })),
});

const mapStateToProps = (state, props) => {
  return {
    isPathOpen: selectors.arePathsOpen(state),
  };
};

PowerOfOneCard.propTypes = {
  config: PropTypes.shape({
    className: PropTypes.string,
    expandedClass: PropTypes.string,
  }),
  togglePaths: PropTypes.func,
  isFirstVisit: PropTypes.bool,
  isPathOpen: PropTypes.bool,
  show: PropTypes.bool,
  // isExpanded: PropTypes.bool,
  // onEvent: PropTypes.func,
};

PowerOfOneCard.defaultProps = {
  config: {
    className: 'c-power-card',
    expandedClass: 'is-expanded',
  },
  togglePaths: () => {},
  isFirstVisit: false,
  isPathOpen: false,
  show: false,
  // isExpanded: false,
  // onEvent: () => {},
};

export default connect(mapStateToProps, mapDispatchToProps)(PowerOfOneCard);
