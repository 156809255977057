/* eslint-disable class-methods-use-this */
import HttpService from 'core/services/http/Http';
import { acquireTokenSilent, logoutMsalWithRedirect } from 'modules/auth/authMsalService';

class TokenInterceptor {
  init() {
    HttpService.addRequestInterceptor(async (url, allOptions) => {
      const authResult = await acquireTokenSilent();

      if (!authResult || !authResult.accessToken) {
        return allOptions;
      }

      // eslint-disable-next-line no-param-reassign
      allOptions.headers.Authorization = `${authResult.tokenType} ${authResult.accessToken}`;

      return allOptions;
    });

    HttpService.addResponseInterceptor(async (url, allOptions, response) => {
      // eslint-disable-next-line default-case
      switch (response.status) {
        case 401: {
          logoutMsalWithRedirect();
          break;
        }
      }

      return response;
    });
  }
}

const objectTokenInterceptor = new TokenInterceptor();
export default objectTokenInterceptor;
