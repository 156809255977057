/**
 * @example
 * new FormElement('startValue', [required(), pattern(/[0-9]{2-3}/)])
 */
export default class FormElement {
  constructor(value, validators = []) {
    this.value = value;
    this.error = null;
    this.validators = validators;
    this.touched = false;

    this.onChangeCallbackFunctions = [];
    this.onBlurCallbackFunctions = [];
    this.onTouchedCallbackFunctions = [];

    this.checkValidation();
  }

  setValue(value, sendEvent = true) {
    this.value = value;

    this.checkValidation();

    if (sendEvent) {
      this.onChangeCallbackFunctions.forEach((fn) => fn(this.value));
    }
  }

  onChange(fn) {
    this.onChangeCallbackFunctions.push(fn);
  }

  onBlur(fn) {
    this.onBlurCallbackFunctions.push(fn);
  }

  onTouched(fn) {
    this.onTouchedCallbackFunctions.push(fn);
  }

  setTouched() {
    this.touched = true;

    this.checkValidation();

    this.onTouchedCallbackFunctions.forEach((fn) => fn());
  }

  onBlurEvent() {
    this.setTouched();

    this.checkValidation();

    this.onBlurCallbackFunctions.forEach((fn) => fn(this.value));
  }

  onChangeEvent(event) {
    // todo: detect element and handle this
    // eslint-disable-next-line no-prototype-builtins
    if (event.target.hasOwnProperty('checked')) {
      this.value = event.target.checked;
    } else {
      this.value = event.target.value;
    }

    this.checkValidation();

    this.onChangeCallbackFunctions.forEach((fn) => fn(this.value));
  }

  // private
  checkValidation() {
    const error = this.getValidationError();
    this.valid = !error;

    this.error = this.touched ? error : null;
  }

  // private
  getValidationError() {
    let error = null;

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < this.validators.length; i++) {
      if (error) {
        break;
      }

      error = this.validators[i](this.value);
    }

    return error;
  }
}
