import description from './description';
import MarkerClass from './elements/MarkerClass';
import Content from './elements/Content';
import Video from './elements/Video';
// import ContentOne from './elements/ContentOne';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [16.1, 49.1], width: 12.75 }],
  dancingAnts: [
    ['hs1', [0, 33.98], { direction: 'reverse' }],
    ['hs1', [50.57, 0]],
  ],
  tinyBois: [
    { id: 'tb1', position: [16.1, 49.1], offset: [11.3, 2.5] },
    { id: 'tb2', position: [16.1, 49.1], offset: [5.5, 12.1] },
    // { id: 'tb3', position: [16.1, 49.1], offset: [11.3, 8.4] },
  ],
  elements: [
    new MarkerClass([12, 20]),
    new Content([27.5, 43.75]),
    new Video([12, 70]),
    // new ContentOne([27.5, 59.4]),
  ],
};

export default config;
