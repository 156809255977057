import description from './description';
import UndergroundTaskManagement1 from './elements/undergroundTaskManagement_1';
import UndergroundTaskManagement2 from './elements/undergroundTaskManagement_2';
import UndergroundTaskManagement3 from './elements/undergroundTaskManagement_3';
import UndergroundTaskManagement4 from './elements/undergroundTaskManagement_4';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [16, 63.4], width: 12.75 }],
  dancingAnts: [['hs1', [0, 48.3], { direction: 'reverse' }]],
  tinyBois: [
    { id: 'tb1', position: [16, 63.4], offset: [0.4, 9] },
    { id: 'tb2', position: [16, 63.4], offset: [11.2, 9] },
    { id: 'tb3', position: [16, 63.4], offset: [6.25, 12.6] },
  ],

  elements: [
    new UndergroundTaskManagement1([12, 31]),
    new UndergroundTaskManagement2([19.8, 94], { target: 'tb3' }),
    new UndergroundTaskManagement3([4.1, 82], { target: 'tb1', cornerFrom: ['top', 'left'] }),
    new UndergroundTaskManagement4([35.4, 94], { target: 'tb2' }),
  ],
};

export default config;
