/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Pointer from './Pointer';

import './assets/scss/index.scss';

const PointerContainer = ({
  title,
  id,
  position,
  size,
  opacity,
  visibility,
  clickHandler,
  forwardedRef,
}) => {
  const { t } = useTranslation();
  return (
    <div
      ref={forwardedRef}
      className="scene-badge"
      style={{
        top: `${position.top}px`,
        left: `${position.left}px`,
        opacity,
        // @ts-ignore
        visibility: visibility ? '' : 'hidden',
      }}
      onClick={clickHandler}
    >
      <Pointer id={id} size={{ width: size.pointer, height: size.pointer }} />
      <div className="pointer-title">{t(title)}</div>
    </div>
  );
};

PointerContainer.propTypes = {
  position: PropTypes.shape({
    top: PropTypes.number,
    left: PropTypes.number,
  }),
  size: PropTypes.shape({
    pointer: PropTypes.number,
  }),
  id: PropTypes.string,
  opacity: PropTypes.number,
  visibility: PropTypes.bool,
  title: PropTypes.string,
  clickHandler: PropTypes.func,
  forwardedRef: PropTypes.any,
};

PointerContainer.defaultProps = {
  id: '',
  position: { top: 0, bottom: 0, left: 0, right: 0 },
  size: { pointer: 0 },
  opacity: 0,
  visibility: false,
  title: '',
  clickHandler: () => {},
  forwardedRef: null,
};

export default connect(null, null, null, { forwardRef: true })(PointerContainer);
