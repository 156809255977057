// @ts-nocheck
/* eslint-disable react/button-has-type,react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';

import Success from './assets/icons/success.svg';

const CompletedSuccess = ({ handleClose }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="flex-center icon-wrap">
        <Success />
      </div>
      <div className="text-center">
        {t('download.The download process have been completed successfully')}
      </div>
      <div className="padding-top text-center">
        {t(
          'download.To view Life-of-Mine Experience in offline mode, just navigate to the same URL in the browser used while downloading the files',
        )}
      </div>
      <div className="c-buttons-list flex-center padding-top" style={{ justifyContent: 'center' }}>
        <button className="c-button c-button_expand" onClick={handleClose}>
          {t('common.OK')}
        </button>
      </div>
    </>
  );
};

export default CompletedSuccess;
