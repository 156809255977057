import Box from 'modules/common/components/Box';

export default class DrillBlastBlastFragmentationAnalysis3 {
  constructor(position) {
    this.id = 'drillBlastBlastFragmentationAnalysis_3';
    this.component = Box;
    this.title = 'drill-blast.Fragmentation Analysis.title';
    this.type = 'content';
    this.content = 'drill-blast.Fragmentation Analysis.elements.Fragmentation Analysis Content';
    this.props = {
      target: 'tb2',
      position,
      cornerFrom: ['bottom', 'left'],
      hashtags: ['cost', 'productivity'],
    };
  }
}
