const description = {
  id: 'w2d',
  title: 'workflow.Mine Map',
  slug: 'workflows-overview-2d',
  hidePath: true,
  video: {
    backgroundImage: 'wf_2D_Mine_Map_Background.jpg',
  },
  audio: {
    default: {
      disable: true,
    },
  },
  dimensions: { width: 1024, height: 658 },
  description: 'workflow.Mine Map description',
};

export default description;
