/* eslint-disable consistent-return */
import gsap from 'gsap';

export default class AnimationManager {
  constructor({ listener = null } = {}) {
    if (listener) {
      this.listener = listener;
    }

    this.timeline = null;
    this.stage = null;
  }

  init(config = null, drawPoints = null, stage = null) {
    this.kill();

    this.config = config;
    this.drawPoints = drawPoints || { hotspots: 0, dancingAnts: 0, secondaries: 0 };
    this.stage = stage;

    this.timeline = this.composeTimeline();
  }

  onUpdate(options) {
    if (typeof this.listener?.onUpdate === 'function') {
      this.listener.onUpdate(options);
    }
  }

  resume() {
    this.timeline?.resume?.();
  }

  play() {
    this.timeline?.play?.();
  }

  kill() {
    if (this.timeline) {
      this.timeline.resume();
      this.timeline.kill();
      this.timeline = null;
    }
  }

  composeTimeline = () => {
    const drawTimeline = this.composeDrawTimeline();

    if (!drawTimeline) return;

    const masterTimeline = gsap
      .timeline({
        paused: true,
        callbackScope: this,
        onComplete: () => {
          return this.onUpdate({
            action: 'toggleAnimationEnded',
            data: {
              isEnded: true,
            },
          });
        },
      })
      .addLabel('start')
      .call(
        (isAnimating) => {
          return this.onUpdate({
            action: 'toggleIsStageAnimating',
            data: {
              isAnimating,
            },
          });
        },
        [true],
        '<',
      )
      .add(drawTimeline.tweenFromTo('start', 'breakdown', { ease: 'none' }), 'start')
      .addLabel('breakdown')
      .addPause('breakdown')

      .add(drawTimeline.tweenFromTo('breakdown', 'end', { ease: 'none' }), 'end');

    return masterTimeline;
  };

  completeDancingAnts = () => {
    this.stage.killTimelines();
  };

  composeDrawTimeline() {
    const stageTLs = this.stage?.getTimelines();

    if (!this.drawPoints || !stageTLs) return;

    const drawTimeline = gsap.timeline({ paused: true }).addLabel('start');

    drawTimeline
      .addLabel('hotspots', this.drawPoints.hotspots)
      .addLabel('startVideo', '>')
      .call(() => {
        return this.onUpdate({
          action: 'startVideo',
        });
      }, ['startVideo'])
      .add(stageTLs.hotspots?.tweenFromTo('start', 'end'), 'hotspots')
      .add(
        stageTLs.primaries?.tweenFromTo('start', 'end'),
        `hotspots+=${this.config.delayPrimaries}`,
      )

      .addLabel('dancingAnts', this.drawPoints.dancingAnts)
      .add(stageTLs.dancingAnts?.tweenFromTo('start', 'end'), 'dancingAnts')
      .addLabel('secondaries', this.drawPoints.secondaries)
      .add(stageTLs.tinyBois.tweenFromTo('start', 'end'), 'secondaries')

      .add(
        stageTLs.secondaries.tweenFromTo('start', 'end'),
        `secondaries+=${this.config.delaySecondaries}`,
      )
      .addLabel('breakdown')
      .call(() => {
        return this.onUpdate({
          action: 'breakdownVideo',
        });
      }, ['>'])

      .add(
        stageTLs.secondaries.tweenFromTo('end', 'start'),
        `breakdown+=${this.config.staggerBreakdowns}`,
      )
      .addLabel('startVideoEnd', '>')
      .call(() => {
        return this.onUpdate({
          action: 'startVideoEnd',
        });
      }, ['startVideoEnd'])
      .add(
        stageTLs.dancingAnts?.tweenFromTo('end', 'start', {
          onComplete: () => {
            this.completeDancingAnts();
          },
        }),
        `breakdown+=${this.config.staggerBreakdowns}`,
      )
      .add(
        stageTLs.tinyBois.tweenFromTo('end', 'start'),
        `breakdown+=${this.config.staggerBreakdowns * 2}`,
      )
      .add(
        stageTLs.primaries.tweenFromTo('end', 'start'),
        `breakdown+=${this.config.staggerBreakdowns * 3}`,
      )
      .add(
        stageTLs.hotspots.tweenFromTo('end', 'start', {
          onComplete: () => {
            return this.onUpdate({
              action: 'rollbackAnimation',
            });
          },
        }),
        `breakdown+=${this.config.staggerBreakdowns * 4}`,
      )
      .addLabel('end');

    return drawTimeline;
  }

  addEventCallback(eventName = null, callbackName = null, args = null) {
    if (!eventName || !callbackName) return;

    this.timeline.eventCallback(eventName, () => {
      this.onUpdate({
        action: callbackName,
        data: args,
      });
    });
  }
}
