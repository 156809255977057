/* eslint-disable class-methods-use-this */

import { getFromLS, saveToLS } from 'core/services/localStorage';

/**
 * @description created for save to local store (mock Data)
 */
class LocalJourneysManager {
  localStoreName = 'localJourneysList';

  getMyJourneys() {
    return getFromLS(this.localStoreName) || [];
  }

  getJourney(journeyId) {
    return this.getMyJourneys().find((journey) => journey.id === journeyId);
  }

  createJourney(journey) {
    const newJourney = {
      // hidden: true,
      ...journey,
      own: true,
      shared: false,
      savedLocally: true,
      createdAt: +new Date() / 1000,
      updatedAt: +new Date() / 1000,
      id: `${+new Date()}`,
    };

    const allMyJourneys = [...this.getMyJourneys(), newJourney];

    saveToLS(this.localStoreName, allMyJourneys);

    return newJourney;
  }

  updateJourney(journeyId, journey) {
    const oldJourney = this.getJourney(journeyId);

    if (!oldJourney) {
      return null;
    }

    const updatedJourney = {
      ...oldJourney,
      ...journey,
      updatedAt: +new Date() / 1000,
    };

    const allMyJourneys = this.getMyJourneys().map((el) =>
      el.id === journeyId ? updatedJourney : el,
    );

    saveToLS(this.localStoreName, allMyJourneys);

    return updatedJourney;
  }

  deleteJourney(journeyId) {
    const allMyJourneys = this.getMyJourneys().filter((el) => el.id !== journeyId);

    saveToLS(this.localStoreName, allMyJourneys);

    return journeyId;
  }
}

export default Object.freeze(new LocalJourneysManager());
