/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/no-unknown-property */
// @ts-nocheck
/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isIOS, isSafari } from 'react-device-detect';

const SplashVideo = ({ activePointer }) => {
  // const lines = isSafari ? LinesSafari : Lines;
  const linesRef = useRef();
  const lines =
    isSafari || isIOS
      ? require(`modules/mine-map/components/ConnectivityLines/assets/static/${activePointer}.mp4`)
          .default
      : require(`modules/mine-map/components/ConnectivityLines/assets/static/${activePointer}.webm`)
          .default;

  const type = isSafari || isIOS ? 'video/mp4' : 'video/webm';

  useEffect(() => {
    linesRef?.current?.play();
  }, [activePointer, linesRef.current]);

  return (
    <video
      ref={linesRef}
      preload="auto"
      key={activePointer}
      loop
      muted
      volume={0}
      webkit-playsinline="true"
      plays-inline="true"
      className="video_random"
      autoPlay
      disablePictureInPicture
    >
      <source src={lines} type={type} />
      Your browser does not support the video tag.
    </video>
  );
};

SplashVideo.propTypes = {
  activePointer: PropTypes.string,
};

SplashVideo.defaultProps = {
  activePointer: null,
};

export default SplashVideo;
