import JourneysManagerIntroduction from 'modules/journeys-manager-introduction/configs/config1024';
import JourneysManagerJourney from 'modules/journeys-manager-journey/configs/config1024';

const config = {
  id: 'journeys-manager',
  title: 'Journeys manager',
  slug: 'manager',
  apiSlug: 'manager',
  hidePath: true,
  hasMainNav: true,
  dimensions: { width: 1024, height: 658 },
  description: '',
  info: {
    id: 1,
    title: 'Journey manager',
    slug: 'view',
    hasMainNav: true,
    isInPaths: false,
    hidePath: true,
    description: '',
    hotspots: [],
    dancingAnts: [],
    tinyBois: [],
    elements: [],
  },
  locations: [JourneysManagerIntroduction, JourneysManagerJourney],
};

export default config;
