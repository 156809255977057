import description from './description';
import SchedulingOptimising1 from './elements/SchedulingOptimising1';
import SchedulingOptimising2 from './elements/SchedulingOptimising2';
import SchedulingOptimising3 from './elements/SchedulingOptimising3';
import SchedulingOptimising4 from './elements/SchedulingOptimising4';
import SchedulingOptimising5 from './elements/SchedulingOptimising5';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [14, 42.5], width: 6.375 }],
  dancingAnts: [
    ['hs1', [0, 25.29], { direction: 'reverse' }],
    ['hs1', [0, 76.07]],
  ],
  tinyBois: [
    { id: 'tb1', position: [14, 42.5], offset: [5.7, 1.475] },
    { id: 'tb2', position: [14, 42.5], offset: [0.2, 4.45] },
    { id: 'tb3', position: [14, 42.5], offset: [5.7, 4.45] },
    { id: 'tb4', position: [14, 42.5], offset: [3, 6] },
  ],
  elements: [
    new SchedulingOptimising1([12.4, 17.1], { size: 'wide' }),
    new SchedulingOptimising2([51.46, 48.7]),
    new SchedulingOptimising3([12.4, 66.4]),
    new SchedulingOptimising4([59.27, 63.17]),
    new SchedulingOptimising5([35.8, 66.4]),
  ],
};

export default config;
