const HASHTAGS = {
  cost: {
    name: 'hashtags.cost.title',
    description: 'hashtags.cost.description',
  },
  productivity: {
    name: 'hashtags.productivity.title',
    description: 'hashtags.productivity.description',
  },
  digitalisation: {
    name: 'hashtags.digitalisation.title',
    description: 'hashtags.digitalisation.description',
  },
  dataAnalytics: {
    name: 'hashtags.dataAnalytics.title',
    description: 'hashtags.dataAnalytics.description',
  },
  integration: {
    name: 'hashtags.integration.title',
    description: 'hashtags.integration.description',
  },
  autonomy: {
    name: 'hashtags.autonomy.title',
    description: 'hashtags.autonomy.description',
  },
  healthSafety: {
    name: 'hashtags.healthSafety.title',
    description: 'hashtags.healthSafety.description',
  },
  esg: {
    name: 'hashtags.esg.title',
    description: 'hashtags.esg.description',
  },
};

export default HASHTAGS;
