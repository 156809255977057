// @ts-nocheck
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CreateJourney from 'modules/journeys-manager-journey/components/CreateJourney';
import {
  getDuplicateJourney,
  getJourney,
  isJourneyLoading,
} from 'modules/journeys-manager-journey/selectors';

import './assets/scss/index.scss';
import actions from 'modules/journeys-manager-journey/actions';
import ViewJourney from 'modules/journeys-manager-journey/components/ViewJourney';
import EditJourney from 'modules/journeys-manager-journey/components/EditJourney';
import notification from 'core/services/Notification';

class Location extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isEdit: false,
    };
  }

  componentDidMount() {
    const { id, doGetJourneyRequest } = this.props;

    if (id) {
      doGetJourneyRequest({ id });
    }
  }

  componentDidUpdate(prevProps) {
    const { journey, duplicateJourney, isLoading, id } = this.props;
    // const { isEdit } = this.state;

    if (prevProps.isLoading !== isLoading && !isLoading && !journey && id) {
      if (!duplicateJourney) {
        this.onEvent({ action: 'navigationBack', data: { path: '/manager' } });
      }
    }
  }

  componentWillUnmount() {
    const { clearStore } = this.props;

    clearStore();
  }

  onEvent = ({ action, data }) => {
    // eslint-disable-next-line prettier/prettier
    const {
      onEvent,
      createJourney,
      updateJourney,
      deleteJourney,
      doDuplicateJourney,
      clearDuplicateJourney,
      clearStoreJourney,
      permission,
    } = this.props;

    switch (action) {
      case 'createDidMount': {
        notification.closeAll();

        if (permission.createJourney) {
          clearStoreJourney();
        } else {
          onEvent({ action: 'navigationBack', data: { path: '/manager' } });
        }

        break;
      }
      case 'createWillUnmount': {
        notification.closeAll();

        clearDuplicateJourney();
        break;
      }
      case 'viewDidMount': {
        notification.closeAll();

        clearDuplicateJourney();
        break;
      }
      case 'viewWillUnmount': {
        break;
      }
      case 'editDidMount': {
        notification.closeAll();

        clearDuplicateJourney();
        break;
      }
      case 'editWillUnmount': {
        break;
      }

      case 'createJourney': {
        createJourney({ data });
        break;
      }
      case 'updateJourney': {
        updateJourney({ data: data.data, id: data.id });
        break;
      }
      case 'deleteJourney': {
        deleteJourney({ data: data.data, id: data.id });

        // todo: need to check is delete done (can be added flag to store)
        onEvent({ action: 'navigationBack', data: { path: '/manager' } });
        break;
      }
      case 'editJourney': {
        this.setState({
          isEdit: true,
        });
        break;
      }
      case 'goToViewMode': {
        this.setState({
          isEdit: false,
        });
        break;
      }
      case 'duplicateJourney': {
        // this.setState({
        //   isEdit: false,
        // });
        doDuplicateJourney({ data });
        onEvent({ action: 'createNewJourney' });
        break;
      }
      default: {
        // eslint-disable-next-line react/destructuring-assignment, react/prop-types
        onEvent({ action, data });
      }
    }
  };

  render() {
    const { isEdit } = this.state;
    const { allLomixConfigs, isLoading, journey, id, duplicateJourney, permission } = this.props;

    return (
      <>
        {!id && (
          <CreateJourney
            onEvent={this.onEvent}
            allLomixConfigs={allLomixConfigs}
            isLoading={isLoading}
            journey={duplicateJourney}
          />
        )}
        {id && !isEdit && (
          <ViewJourney
            onEvent={this.onEvent}
            allLomixConfigs={allLomixConfigs}
            isLoading={isLoading}
            journey={journey}
            permission={permission}
          />
        )}
        {id && isEdit && (
          <EditJourney
            onEvent={this.onEvent}
            allLomixConfigs={allLomixConfigs}
            isLoading={isLoading}
            journey={journey}
            permission={permission}
          />
        )}
      </>
    );
  }
}

Location.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  allLomixConfigs: PropTypes.arrayOf(PropTypes.any),
  config: PropTypes.shape({
    className: PropTypes.string,
    delayPrimaries: PropTypes.number,
    delaySecondaries: PropTypes.number,
    staggerBreakdowns: PropTypes.number,
    delayVideoBreakdown: PropTypes.number,
  }),
  onEvent: PropTypes.func,
  doGetJourneyRequest: PropTypes.func,
  createJourney: PropTypes.func,
  updateJourney: PropTypes.func,
  deleteJourney: PropTypes.func,
  doDuplicateJourney: PropTypes.func,
  clearStore: PropTypes.func,
  clearDuplicateJourney: PropTypes.func,
  clearStoreJourney: PropTypes.func,
  id: PropTypes.number,
  isLoading: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  journey: PropTypes.shape({
    id: PropTypes.number,
    privateName: PropTypes.string,
    shareableName: PropTypes.string,
    description: PropTypes.string,
    hidden: PropTypes.bool,
    own: PropTypes.bool,
    shared: PropTypes.bool,
    createdAt: PropTypes.number,
    updatedAt: PropTypes.number,
    locations: PropTypes.arrayOf(PropTypes.string),
  }),
  duplicateJourney: PropTypes.shape({
    privateName: PropTypes.string,
    shareableName: PropTypes.string,
    description: PropTypes.string,
    locations: PropTypes.arrayOf(PropTypes.string),
  }),
  permission: PropTypes.shape({
    createJourney: PropTypes.bool,
    edit: PropTypes.bool,
    delete: PropTypes.bool,
    duplicate: PropTypes.bool,
  }),
};

Location.defaultProps = {
  allLomixConfigs: [],
  config: {
    className: 'journey',
    delayPrimaries: 0.5,
    delaySecondaries: 0.25,
    staggerBreakdowns: 0.125,
    delayVideoBreakdown: 1.5,
  },
  onEvent: () => {},
  doGetJourneyRequest: () => {},
  createJourney: () => {},
  updateJourney: () => {},
  deleteJourney: () => {},
  doDuplicateJourney: () => {},
  clearStore: () => {},
  clearDuplicateJourney: () => {},
  clearStoreJourney: () => {},
  id: null,
  isLoading: false,
  journey: null,
  duplicateJourney: null,
  permission: {
    createJourney: false,
    edit: false,
    delete: false,
    duplicate: false,
  },
};

const mapStateToProps = (state) => {
  const config = {
    className: 'journey',
    delayPrimaries: 0.5,
    delaySecondaries: 0.25,
    staggerBreakdowns: 0.125,
    delayVideoBreakdown: 1.5,
  };

  return {
    config,
    journey: getJourney(state),
    duplicateJourney: getDuplicateJourney(state),
    isLoading: isJourneyLoading(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  doGetJourneyRequest: (payload, status) => dispatch(actions.doGetRequest({ payload, status })),
  createJourney: (payload, status) => dispatch(actions.createJourney({ payload, status })),
  updateJourney: (payload, status) => dispatch(actions.updateJourney({ payload, status })),
  deleteJourney: (payload, status) => dispatch(actions.deleteJourney({ payload, status })),
  doDuplicateJourney: (payload, status) => dispatch(actions.duplicateJourney({ payload, status })),
  clearStore: (payload, status) => dispatch(actions.clearStore({ payload, status })),
  clearDuplicateJourney: (payload, status) => dispatch(actions.clearDuplicate({ payload, status })),
  clearStoreJourney: (payload, status) => dispatch(actions.clearData({ payload, status })),
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Location);
