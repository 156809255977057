import * as THREE from 'three';

export default class RendererService {
  constructor(options = {}) {
    if (options?.canvas) {
      this.root = new THREE.WebGLRenderer({
        canvas: options?.canvas,
        antialias: true,
        alpha: true,
      });

      this.root.outputEncoding = THREE.sRGBEncoding;
      this.root.toneMapping = THREE.ACESFilmicToneMapping;
      this.root.toneMappingExposure = 1;
      this.root.shadowMap.enabled = true;

      this.root.toneMapping = THREE.ACESFilmicToneMapping;
      this.root.toneMappingExposure = 1;

      this.root.shadowMap.enabled = true;
      this.root.shadowMap.type = THREE.PCFSoftShadowMap;
    }
  }

  getActiveRenderer() {
    return this.root;
  }

  setSize(width, height, updateStyle = false) {
    this.root.setSize(width, height, updateStyle);
  }

  getContainer() {
    return this.root.domElement;
  }

  render(scene, camera) {
    this.root.render(scene, camera);
  }
}
