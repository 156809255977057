/* eslint-disable import/no-mutable-exports */
// @ts-nocheck
import LOMIXConfig1200 from 'app/configs/LOMIXConfig1200';
import LOMIXConfig1024 from 'app/configs/LOMIXConfig1024';
import LOMIXConfigDefault from 'app/configs/LOMIXConfigDefault';

import { getWindowDimensions } from 'app/helpers';

const { width } = getWindowDimensions();
let screenConfig = LOMIXConfigDefault;

if (width < 1200) {
  screenConfig = LOMIXConfig1024;
}
if (width >= 1200 && width < 1920) {
  screenConfig = LOMIXConfig1200;
}

export default screenConfig;
