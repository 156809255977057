const fields = [
  {
    key: 'firstName',
    localization: 'contactUs.fields.firstName',
    pardotId: '273802_141602pi_273802_141602',
  },
  {
    key: 'lastName',
    localization: 'contactUs.fields.lastName',
    pardotId: '273802_141604pi_273802_141604',
  },
  {
    key: 'workEmail',
    localization: 'contactUs.fields.workEmail',
    pardotId: '273802_141606pi_273802_141606',
  },
  {
    key: 'phone',
    localization: 'contactUs.fields.phone',
    pardotId: null,
  },
  {
    key: 'company',
    localization: 'contactUs.fields.company',
    pardotId: '273802_141610pi_273802_141610',
  },
  {
    key: 'mine',
    localization: 'contactUs.fields.mine',
    pardotId: '273802_165666pi_273802_165666',
  },
  {
    key: 'role',
    localization: 'contactUs.fields.role',
    pardotId: '273802_154052pi_273802_154052',
  },
  {
    key: 'level',
    localization: 'contactUs.fields.level',
    pardotId: '273802_154054pi_273802_154054',
  },
  {
    key: 'country',
    localization: 'contactUs.fields.country',
    pardotId: '273802_141614pi_273802_141614',
  },
  {
    key: 'workflows',
    localization: 'contactUs.fields.workflows',
    pardotId: '273802_151519pi_273802_151519',
  },
  {
    key: 'question',
    localization: 'contactUs.fields.question',
    pardotId: '273802_141620pi_273802_141620',
  },
  {
    key: 'privacy',
    localization: 'contactUs.fields.privacy',
    pardotId: '273802_197010pi_273802_197010',
    pardotValueId: '1226265', // checkbox value
  },
];

export default fields;
