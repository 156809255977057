import { getWindowDimensions } from 'app/helpers';

import config1024 from './config1024';
import config1200 from './config1200';
import config3840 from './config3840';
import configDefault from './configDefault';

const config = {
  c1024: config1024,
  c1200: config1200,
  c3840: config3840,
  cDefault: configDefault,
  get current() {
    const { width } = getWindowDimensions();

    if (width < 1200) {
      return config1024;
    }
    if (width >= 1200 && width < 1920) {
      return config1200;
    }
    if (width >= 2560) {
      return config3840;
    }

    return configDefault;
  },
};

Object.freeze(config);

export default config;
