// @ts-nocheck
/* eslint-disable react/forbid-prop-types */
import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import i18n from 'i18nConfig';

import { connect } from 'react-redux';

import actions from 'app/actions';
import * as selectors from 'app/selectors';

import { ALLOW_EVENTS_CLASS } from 'app/constants/app';

import ButtonAccept from './ButtonAccept';

import './assets/styles/index.scss';

class PrivacyPolicy extends PureComponent {
  componentDidUpdate(prevProps) {
    const { lang } = this.props;

    if (lang !== prevProps.lang) {
      this.forceUpdate();
    }
  }

  render() {
    const { config, opened, acceptPrivacyPolicy, t, lang } = this.props;

    return (
      <div
        className={`${config.className} ${
          opened ? config.openClass : 'closed'
        } ${ALLOW_EVENTS_CLASS}`}
      >
        <div className={`${config.className}__container`}>
          <div className={`${config.className}__container-text`}>
            <div>
              {t(
                'We use cookies on this website to ensure that you get the best browsing experience',
              )}
            </div>
            <div className={`${config.className}__container-text_margin`}>
              {t('By continuing to browse, we assume that you agree to our')}
              <a href="https://hexagon.com/privacy-policy" target="_blank" rel="noreferrer">
                {t('Privacy Policy')}
              </a>
            </div>
          </div>
          <div
            className={`${config.className}__container-accept ${config.className}__container-accept_margin`}
          >
            <ButtonAccept onClick={acceptPrivacyPolicy} language={lang} />
          </div>
        </div>
      </div>
    );
  }
}

PrivacyPolicy.propTypes = {
  config: PropTypes.shape({
    className: PropTypes.string,
    openClass: PropTypes.string,
  }),
  opened: PropTypes.bool,
  acceptPrivacyPolicy: PropTypes.func,
  t: PropTypes.func,
  lang: PropTypes.any,
};

PrivacyPolicy.defaultProps = {
  config: {
    className: 'c-privacy-policy',
    openClass: 'is-open',
  },
  opened: false,
  acceptPrivacyPolicy: () => {},
  t: () => {},
  lang: null,
};

function mapStateToProps(state) {
  return {
    opened: !selectors.isAcceptedPrivacyPolicy(state),
    lang: i18n.language,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    acceptPrivacyPolicy: () => dispatch(actions.acceptPrivacyPolicy()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PrivacyPolicy));
