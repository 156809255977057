import Box from 'modules/common/components/Box';

export default class MaterialMovementPedestrianSafety2 {
  constructor(position) {
    this.id = 'materialMovementPedestrianSafety_2';
    this.component = Box;
    this.title = 'safety.Pedestrian Safety.elements.Protect Personnel';
    this.video = 'md_Protect_Personnel.mp4';
    this.props = {
      target: 'tb1',
      cornerFrom: ['top', 'left'],
      position,
      hashtags: ['healthSafety'],
    };
  }
}
