import * as React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';

import './assets/styles.scss';

export default function LinearProgressWith(props) {
  const { value } = props;
  return (
    <div className="linear-progress">
      <LinearProgress variant="determinate" value={value} />
    </div>
  );
}

LinearProgressWith.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};
