/* eslint-disable react/prop-types */
/* eslint-disable react/button-has-type */
/* eslint-disable dot-notation */
/* eslint-disable react/destructuring-assignment,no-useless-escape,jsx-a11y/label-has-associated-control */
import LocationListElement from 'modules/journeys-manager-journey/components/LocationListElement';
import LocationListEntryScreenElement from 'modules/journeys-manager-journey/components/LocationListEntryScreenElement';
import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import './assets/scss/index.scss';

export default function DnDLocationListElement({
  data,
  elIndex,
  showPathName,
  moveEvent,
  stopEvent,
  showEntryScreen,
}) {
  const ref = useRef(null);

  // @ts-ignore
  // eslint-disable-next-line no-unused-vars
  const [{ handlerId }, drop] = useDrop({
    accept: 'locationListElement',
    // item: { name, pathName, showPathName, index },
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop() {
      stopEvent();
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      // @ts-ignore
      const dragIndex = item.elIndex;
      const hoverIndex = elIndex;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveEvent(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.

      // eslint-disable-next-line no-param-reassign
      // @ts-ignore
      // eslint-disable-next-line no-param-reassign
      item.elIndex = hoverIndex;

      // if (monitor.isOver({ shallow: true }) && item.elIndex === 0) {
      //   stopEvent();
      // }
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'locationListElement',
    item: { data, elIndex },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.1 : 1;

  drag(drop(ref));

  return (
    <div ref={ref} style={{ opacity }} className="dnd-location-list-element">
      {showEntryScreen && (
        <LocationListEntryScreenElement
          name={data.relatedEntryScreen.title}
          pathName={data.relatedJourney.title}
          showPathName={showPathName}
        />
      )}
      <LocationListElement
        key={data.id}
        name={data.title}
        pathName={data.relatedJourney.title}
        elIndex={elIndex + 1}
        showPathName={showPathName}
      />
    </div>
  );
}
