import description from './description';
import SurveyMonitoringMonitoring1 from './elements/surveyMonitoringMonitoring_1';
import SurveyMonitoringMonitoring2 from './elements/surveyMonitoringMonitoring_2';
import SurveyMonitoringMonitoring3 from './elements/surveyMonitoringMonitoring_3';
import SurveyMonitoringMonitoring4 from './elements/surveyMonitoringMonitoring_4';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [16, 58], width: 12.75 }],
  dancingAnts: [['hs1', [0, 89.35], { direction: 'reverse' }]],
  tinyBois: [
    { id: 'tb2', position: [16, 58], offset: [6, 12] },
    { id: 'tb3', position: [16, 58], offset: [11.2, 2.1] },
    { id: 'tb1', position: [16, 58], offset: [11.2, 8.9] },
  ],
  elements: [
    new SurveyMonitoringMonitoring1([12, 26.04], { cornerFrom: ['bottom', 'left'] }),
    new SurveyMonitoringMonitoring2([27.6, 67]),
    new SurveyMonitoringMonitoring3([12, 79]),
    new SurveyMonitoringMonitoring4([27.6, 47.8]),
  ],
};

export default config;
