/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { PureComponent } from 'react';

import { ALLOW_EVENTS_CLASS, CLEAR_DEFER_CLASS } from 'app/constants/app';

import './assets/scss/index.scss';
import AuthTabs from 'modules/auth/components/AuthTabs';

class AuthModal extends PureComponent {
  render() {
    return (
      <div className={`auth-modal ${ALLOW_EVENTS_CLASS} ${CLEAR_DEFER_CLASS}`}>
        <div className="auth-wrapper">
          <AuthTabs />
        </div>
      </div>
    );
  }
}

export default AuthModal;
