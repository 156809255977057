import Box from 'modules/common/components/Box';

export default class DrillBlastSafety2 {
  constructor(position) {
    this.id = 'drillBlastSafety_2';
    this.component = Box;
    this.title = 'drill-blast.Safety.elements.Personal Safety';
    this.video = '3c-1.mp4';
    this.props = {
      target: 'tb1',
      position,
      cornerFrom: ['top', 'right'],
      hashtags: ['healthSafety', 'productivity'],
    };
  }
}
