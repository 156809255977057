const eventBus = {
  events: {},
  on(eventName, callback) {
    this.events[eventName] = (e) => callback(e.detail);

    document.addEventListener(eventName, this.events[eventName]); // TODO: MEMORY LEAK, replace by callback
  },
  dispatch(eventName, data) {
    document.dispatchEvent(new CustomEvent(eventName, { detail: data }));
  },
  remove(eventName, callback) {
    if (this.events[eventName]) {
      document.removeEventListener(eventName, this.events[eventName]);

      delete this.events[eventName];
    } else {
      document.removeEventListener(eventName, callback);
    }
  },
};

export default eventBus;
