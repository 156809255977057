import description from './description';
import DrillBlastBlastDesign1 from './elements/drillBlastBlastDesign_1';
import DrillBlastBlastDesign2 from './elements/drillBlastBlastDesign_2';
import DrillBlastBlastDesign3 from './elements/drillBlastBlastDesign_3';
import DrillBlastBlastDesign4 from './elements/drillBlastBlastDesign_4';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [16, 47.5], width: 6.375 }],
  dancingAnts: [
    ['hs1', [0, 21.28]],
    ['hs1', [0, 93.55], { direction: 'reverse' }],
    ['hs1', [48.33, 0]],
  ],
  tinyBois: [
    { id: 'tb1', position: [16, 47.5], offset: [5.6, 1.2] },
    { id: 'tb2', position: [16, 47.5], offset: [0.2, 4.5] },
    { id: 'tb3', position: [16, 47.5], offset: [5.6, 4.5] },
  ],
  elements: [
    new DrillBlastBlastDesign1([12, 16.9]),
    new DrillBlastBlastDesign2([27.6, 39.74]),
    new DrillBlastBlastDesign3([12, 64.35]),
    new DrillBlastBlastDesign4([27.6, 53]),
  ],
};

export default config;
