import description from './description';
import PlanningProductionDrilling1 from './elements/planningProductionDrilling_1';
import PlanningProductionDrilling2 from './elements/planningProductionDrilling_2';
import PlanningProductionDrilling3 from './elements/planningProductionDrilling_3';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [16.5, 48], width: 9.125, rotation: [58, 0] }],
  dancingAnts: [
    ['hs1', [0, 66.89], { direction: 'reverse' }],
    ['hs1', [51.35, 100]],
  ],
  tinyBois: [
    { id: 'tb1', position: [16.5, 48], offset: [8.8, 4.2] },
    { id: 'tb2', position: [16.5, 48], offset: [4.2, 6.5] },
  ],
  elements: [
    new PlanningProductionDrilling1([11.66, 15.25]),
    new PlanningProductionDrilling2([35.41, 44.12]),
    new PlanningProductionDrilling3([11.65, 64.25]),
  ],
};

export default config;
