import {
  CONTACT_US_CLEAR_STORE,
  CONTACT_US_SEND_FORM_DATA,
} from 'app/components/modals/ContactUs/constants';
import { STATUS } from 'core/constants/statuses';
import BaseActions from 'core/actions';

class Actions extends BaseActions {
  /**
   * @description payload: {...data}, meta, status
   */
  sendForm({ payload = {}, meta = {}, status = null } = {}) {
    return this.create(
      this.getTypeWithStatus(CONTACT_US_SEND_FORM_DATA, status),
      status,
      payload,
      meta,
    );
  }

  clearStore({ payload = {}, meta = {}, status = null } = {}) {
    return this.create(
      this.getTypeWithStatus(CONTACT_US_CLEAR_STORE, status),
      status,
      payload,
      meta,
    );
  }

  getTypeWithStatus = (type, status = '') => {
    if (status) {
      return `${type}/${STATUS}/${status.toLowerCase()}`;
    }

    return type;
  };
}

const acts = new Actions();
Object.freeze(acts);

export default acts;
