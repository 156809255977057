import description from './description';
import OperatorAssist1 from './elements/OperatorAssist1';
import OperatorAssist2 from './elements/OperatorAssist2';
import OperatorAssist3 from './elements/OperatorAssist3';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [63, 70.5], width: 6.375, rotation: [55, 0] }],
  dancingAnts: [['hs1', [0, 83], { direction: 'reverse' }]],
  tinyBois: [
    { id: 'tb1', position: [63, 70.5], offset: [5, 1.6] },
    { id: 'tb2', position: [63, 70.5], offset: [5, 4.2] },
  ],
  elements: [
    new OperatorAssist1([66.7, 20.5], { cornerFrom: ['bottom', 'left'] }),
    new OperatorAssist2([82.3, 47.7]),
    new OperatorAssist3([82.3, 59.1]),
  ],
};

export default config;
