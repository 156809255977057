import Box from 'modules/common/components/Box';

export default class DrillBlastBlastDesign2 {
  constructor(position) {
    this.id = 'drillBlastBlastDesign_2';
    this.component = Box;
    this.title = 'drill-blast.Blast Design.elements.Blast Control';
    this.content = 'drill-blast.Blast Design.elements.Blast Control Content';
    this.type = 'content';
    this.props = {
      target: 'tb1',
      position,
      cornerFrom: ['bottom', 'left'],
      hashtags: ['integration', 'dataAnalytics'],
    };
  }
}
