import description from './description';
import SurveyMonitoringPlanning1 from './elements/surveyMonitoringPlanning_1';
import SurveyMonitoringPlanning2 from './elements/surveyMonitoringPlanning_2';
import SurveyMonitoringPlanning3 from './elements/surveyMonitoringPlanning_3';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [13.7, 41.5], width: 6.375 }],
  dancingAnts: [
    ['hs1', [48.12, 0]],
    ['hs1', [100, 66.69], { direction: 'reverse' }],
  ],
  tinyBois: [
    { id: 'tb1', position: [13.7, 41.5], offset: [5.4, 1] },
    { id: 'tb2', position: [13.7, 41.5], offset: [5.4, 4.9] },
  ],
  elements: [
    new SurveyMonitoringPlanning1([12.4, 17.44]),
    new SurveyMonitoringPlanning2([35.9, 39.8]),
    new SurveyMonitoringPlanning3([43.6, 55.8]),
  ],
};

export default config;
