/* eslint-disable import/named */
import BaseActions from 'core/actions';
import {
  PRODUCT_DETAIL_PANEL_CLOSE_POPUP,
  PRODUCT_DETAIL_PANEL_OPEN_POPUP,
  PRODUCT_DETAIL_PANEL_SET_EMPTY,
  PRODUCT_DETAIL_PANEL_SET_IS_EXIST,
  STATUS,
} from '../constants';

class Actions extends BaseActions {
  productDetailPanelSetIsExist({
    payload = { isDataExist: false },
    meta = {},
    status = null,
  } = {}) {
    return this.create(
      this.getTypeWithStatus(PRODUCT_DETAIL_PANEL_SET_IS_EXIST, status),
      status,
      payload,
      meta,
    );
  }

  productDetailPanelSetEmpty({ payload = {}, meta = {}, status = null } = {}) {
    return this.create(
      this.getTypeWithStatus(PRODUCT_DETAIL_PANEL_SET_EMPTY, status),
      status,
      payload,
      meta,
    );
  }

  productDetailPanelOpenPopup({ payload = {}, meta = {}, status = null } = {}) {
    return this.create(
      this.getTypeWithStatus(PRODUCT_DETAIL_PANEL_OPEN_POPUP, status),
      status,
      payload,
      meta,
    );
  }

  productDetailPanelClosePopup({ payload = {}, meta = {}, status = null } = {}) {
    return this.create(
      this.getTypeWithStatus(PRODUCT_DETAIL_PANEL_CLOSE_POPUP, status),
      status,
      payload,
      meta,
    );
  }

  getTypeWithStatus = (type, status = '') => {
    if (status) {
      return `${type}/${STATUS}/${status.toLowerCase()}`;
    }

    return type;
  };
}

const acts = new Actions();
Object.freeze(acts);

export default acts;
