import Box from 'modules/common/components/Box';

export default class UndergroundMining3 {
  constructor(position) {
    this.id = 'undergroundMining_3';
    this.component = Box;
    this.type = 'content';
    this.title = 'underground.Safety.elements.Serious safety';
    this.content = 'underground.Safety.elements.Serious safety Content';
    this.props = {
      target: 'tb2',
      position,
      hashtags: ['dataAnalytics', 'healthSafety'],
    };
  }
}
