/* eslint-disable no-undef */
// @ts-nocheck
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-destructuring */

import { LogLevel, PublicClientApplication } from '@azure/msal-browser';
// import i18n from 'i18n.js';
import i18next from 'i18next';
import { toggleUserIsLoggedIn } from './authService';

export const b2cPolicies = {
  names: {
    signUpSignIn: process.env.REACT_APP_MSAL_SIGNUP_POLICY_NAME,
    editProfile: process.env.REACT_APP_MSAL_PROFILE_POLICY_NAME,
  },
  authorities: {
    signUpSignIn: {
      authority: process.env.REACT_APP_MSAL_SIGNUP_AUTORITY,
    },
    editProfile: {
      authority: process.env.REACT_APP_MSAL_PROFILE_AUTORITY,
    },
  },
  authorityDomain: process.env.REACT_APP_MSAL_AUTORITY_DOMAIN,
};

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MSAL_CONFIG_CLIENT_ID,
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    scopes: ['openid'],
    redirectUri: '/',
    postLogoutredirect: '/workflow-overview',
    pathToSaveResults: './allUserEmailRecords.json',
    loginHint: 'user@lomix.com',

    // navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    allowRedirectInIframe: true,
    // msal logger  - uncomment for develop,
    // loggerOptions: {
    //   loggerCallback: (level, message, containsPii) => {
    //     if (containsPii) {
    //       return;
    //     }
    //     // eslint-disable-next-line default-case
    //     switch (level) {
    //       case LogLevel.Error:
    //         console.error(message);
    //         return;
    //       case LogLevel.Info:
    //         console.info(message);
    //         return;
    //       case LogLevel.Verbose:
    //         console.debug(message);
    //         return;
    //       case LogLevel.Warning:
    //         console.warn(message);
    //     }
    //   },
    // },
  },
};

globalThis.addEventListener(
  'storage',
  () => {
    // When local storage changes, dump the list to
    // the console.
    console.log(globalThis.localStorage.i18nextLng);
  },
  false,
);

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
const loginRequest = {
  scopes: ['offline_access', msalConfig.auth.clientId],
};

const tokenRequest = {
  scopes: ['offline_access', msalConfig.auth.clientId],
};

export const msalInstance = new PublicClientApplication(msalConfig);

const init = async () => {
  try {
    const tokenResponse = await msalInstance.handleRedirectPromise();
    handleResponse(tokenResponse);
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};

const initResult = init();

let accountId = '';
let idToken = '';
let idTokenObject = '';
let userClaims = '';

initAuth().then(() => null);

export function initAuth() {
  return initResult;
}

const selectAccount = () => {
  const currentAccounts = msalInstance.getAllAccounts();
  if (!currentAccounts || currentAccounts.length < 1) {
    // return;
  } else if (currentAccounts.length > 1) {
    const accounts = currentAccounts.filter(
      (account) =>
        account.homeAccountId
          .toUpperCase()
          .includes(b2cPolicies.names.signUpSignIn.toUpperCase()) &&
        account.idTokenClaims.iss
          .toUpperCase()
          .includes(b2cPolicies.authorityDomain.toUpperCase()) &&
        account.idTokenClaims.aud === msalConfig.auth.clientId,
    );

    if (accounts.length > 1) {
      // localAccountId identifies the entity for which the token asserts information.
      if (accounts.every((account) => account.localAccountId === accounts[0].localAccountId)) {
        // All accounts belong to the same user
        msalInstance.setActiveAccount(accounts[0]);
        setAccount(accounts[0]);
      } else {
        // Multiple users detected. Logout all to be safe.
        logoutMsal();
      }
    } else if (accounts.length === 1) {
      msalInstance.setActiveAccount(accounts[0]);
      setAccount(accounts[0]);
    }
    // Add choose account code here
  } else if (currentAccounts.length === 1) {
    const activeAccount = currentAccounts[0];

    msalInstance.setActiveAccount(activeAccount);
    setAccount(activeAccount);
  }
};

function setAccount(account) {
  accountId = account.homeAccountId;
  idTokenObject = account.idTokenClaims;
  userClaims = JSON.stringify(idTokenObject);
}

function handleResponse(tokenResponse) {
  let accountObj = null;
  if (tokenResponse !== null) {
    // If result is truthy, your app returned from a redirect operation,
    // and it completed successfully
    accountObj = tokenResponse.account;
    idToken = tokenResponse.idToken;

    toggleUserIsLoggedIn(true, accountObj);

    // saveToLS('loginMsal', true);
    msalInstance.setActiveAccount(accountObj);
    setAccount(accountObj);
  } else {
    selectAccount();
  }
}

const langCode = () => {
  const lang = i18next.language;
  if (lang === 'en') {
    return 'en';
  }
  if (lang === 'zh') {
    return 'zh-Hans';
  }
  return 'en';
};

export const editProfileMsal = async () => {
  const editProfileRequest = b2cPolicies.authorities.editProfile;
  editProfileRequest.loginHint = await msalInstance.getActiveAccount()?.username;

  const request = {
    ...editProfileRequest,
    ...loginRequest,
    extraQueryParameters: {
      ui_locales: langCode(),
    },
  };

  await msalInstance.loginRedirect(request).catch((e) => {
    console.error(e);
  });
};

export const loginMsal = async () => {
  const request = {
    ...loginRequest,
    extraQueryParameters: {
      ui_locales: langCode(),
    },
  };
  await msalInstance.loginRedirect(request).catch((e) => {
    console.error(e);
  });
};

export async function logoutMsal() {
  await msalInstance.logoutPopup(loginRequest).catch((e) => {
    logoutMsalWithRedirect();
  });
}

export async function logoutMsalWithRedirect() {
  await logoutMsal();
  loginMsal();
}

export async function acquireTokenSilent() {
  const account = msalInstance.getActiveAccount();

  if (!account) {
    return null;
  }

  try {
    const token = await msalInstance.acquireTokenSilent({ ...tokenRequest, account });

    return token;
  } catch (e) {
    console.error(e);

    return null;
  }
}
