import Box from 'modules/common/components/Box';

export default class PlanningProductionDrilling2 {
  constructor(position) {
    this.id = 'planningProductionDrilling_2';
    this.component = Box;
    this.title = 'planning.Production Drilling.elements.Blast Design Tool';
    this.video = '2b-1.mp4';
    this.props = {
      target: 'tb1',
      position,
      cornerFrom: ['top', 'left'],
      hashtags: ['productivity'],
    };
  }
}
