import Introduction from 'modules/drill-blast-introduction/configs/config1024';
import DrillBlastDesign from 'modules/drill-blast-design/configs/config1024';
// import PlanningProductionDrilling from 'modules/planning-production-drilling/configs/config1024';
import UndergroundDevelopmentDrilling from 'modules/underground-development-drilling/configs/config1024';
import UndergroundProductionDrilling from 'modules/underground-production-drilling/configs/config1024';
// import UndergroundProductionDrilling from 'modules/underground-development-drilling/configs/config1024';
import DrillBlastMonitoring from 'modules/drill-blast-monitoring/configs/config1024';
import DrillBlastMovement from 'modules/drill-blast-movement/configs/config1024';
import DrillBlastFragmentationAnalysis from 'modules/drill-blast-fragmentation-analysis/configs/config1024';
import DrillBlastInsights from 'modules/drill-blast-insights/configs/config1024';
import DrillBlastDrilling from 'modules/drill-blast-drilling/configs/config1024';
import { modifyApiSlug } from 'core/utils';

const config = {
  id: 2,
  title: 'drill-blast.Drill Blast',
  slug: 'drill-blast',
  pdfPath: 'Drill&Blast',
  hasMainNav: true,
  isInPaths: true,
  video: {
    backgroundImage: 'Blast_Design.jpg',
  },
  info: Introduction,
  locations: [
    DrillBlastDesign,
    DrillBlastDrilling,
    // PlanningProductionDrilling,
    UndergroundDevelopmentDrilling,
    UndergroundProductionDrilling,
    DrillBlastMonitoring,
    DrillBlastMovement,
    DrillBlastFragmentationAnalysis,
    DrillBlastInsights,
  ].map((el) => modifyApiSlug(el, 'drill-blast')),
};

export default config;
