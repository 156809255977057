/* eslint-disable react/destructuring-assignment,react/no-unused-state,no-useless-escape */
// @ts-nocheck

import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import actions from 'app/actions';
import { ALLOW_EVENTS_CLASS, AUTH_ERRORS } from 'app/constants/app';

import { sendGTEvent } from 'modules/analytics/services/googleAnalytics';
import { withTranslation } from 'react-i18next';
import PersonalInfo from './PersonalInfo';
import InterestDetails from './InterestDetails';
import './assets/scss/index.scss';

import { signUp } from '../../authService';

class SignUp extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      step: 1,
      formData: {
        email: '',
        name: '',
        company: '',
        interest: {
          cost: false,
          productivity: false,
          digitalisation: false,
          dataAnalytics: false,
          integration: false,
          autonomy: false,
          healthSafety: false,
          esg: false,
        },
        gdpr: false,
      },
      errors: {},
    };
  }

  // go back to previous step
  prevStep = () => {
    const { step } = this.state;
    this.setState({ step: step - 1 });
  };

  // proceed to the next step
  nextStep = () => {
    const { step } = this.state;
    this.setState({ step: step + 1 });
  };

  validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
  };

  // handle field change
  handleInputChange = (inputName, value) => {
    if (inputName === 'email') {
      let { errors } = this.state;
      if (value && !this.validateEmail(value)) {
        errors = {
          ...errors,
          email: AUTH_ERRORS.signUp.email.validate,
        };
      } else {
        delete errors.email;
      }
      this.setState({ errors });
    }

    let { formData } = this.state;
    formData = {
      ...formData,
      [inputName]: value,
    };

    this.setState({ formData });
  };

  handleCheckboxChange = (inputName, value) => {
    const { formData } = this.state;

    let {
      formData: { interest },
    } = this.state;

    interest = {
      ...interest,
      [inputName]: value,
    };

    this.setState({ formData: { ...formData, interest } });
  };

  signUp = () => {
    const { setCanNavigate } = this.props;
    const { formData } = this.state;

    const userInterest = Object.keys(formData.interest).filter(
      (key) => formData.interest[key] === true,
    );

    signUp({ ...this.state.formData });
    sendGTEvent({ event: 'sign_up' });
    sendGTEvent({ event: 'hashtag_selected', hashtags: userInterest });

    setCanNavigate(true);
  };

  saveInterestsFromModal = (interestsObj) => {
    const { formData } = this.state;

    let {
      formData: { interest },
    } = this.state;

    interest = { ...interest, ...interestsObj };

    this.setState({ formData: { ...formData, interest } });
  };

  renderRegistrationStep = (step) => {
    const {
      formData: {
        email,
        name,
        company,
        interest: {
          cost,
          productivity,
          digitalisation,
          dataAnalytics,
          integration,
          autonomy,
          healthSafety,
          esg,
        },
        gdpr,
      },
      errors,
    } = this.state;
    const values = {
      formData: {
        email,
        name,
        company,
        interest: {
          cost,
          productivity,
          digitalisation,
          dataAnalytics,
          integration,
          autonomy,
          healthSafety,
          esg,
        },
        gdpr,
      },
    };
    switch (step) {
      case 1:
        return (
          <PersonalInfo
            nextStep={this.nextStep}
            handleChange={this.handleInputChange}
            values={values.formData}
            errors={errors}
          />
        );
      case 2:
        return (
          <InterestDetails
            signUp={this.signUp}
            prevStep={this.prevStep}
            handleChange={this.handleCheckboxChange}
            values={values.formData.interest}
            saveFromModal={this.saveInterestsFromModal}
          />
        );

      default:
        return <div>error</div>;
    }
  };

  render() {
    const { config, t } = this.props;
    const { step } = this.state;

    return (
      <div className={`${config.className} ${ALLOW_EVENTS_CLASS}`}>
        {/* <div className="auth-title">Sign up</div> */}
        <div className="auth-subtitle">{t('forms.step', { current: step, total: 2 })}</div>
        {this.renderRegistrationStep(step)}
      </div>
    );
  }
}

SignUp.propTypes = {
  config: PropTypes.shape({
    className: PropTypes.string,
  }),
  setCanNavigate: PropTypes.func,
  t: PropTypes.func,
};

SignUp.defaultProps = {
  config: {
    className: 'auth-form',
  },
  setCanNavigate: () => {},
  t: () => {},
};

const mapDispatchToProps = (dispatch) => ({
  setCanNavigate: (payload) => dispatch(actions.setCanNavigate({ payload })),
});

export default withTranslation()(connect(null, mapDispatchToProps)(SignUp));
