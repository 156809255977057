// @ts-nocheck
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types,import/prefer-default-export,jsx-a11y/label-has-associated-control,no-useless-escape */
import React, { useEffect, useRef, useState } from 'react';
import Button from 'modules/common/components/Button';
import { connect } from 'react-redux';
import * as selectors from 'app/selectors';
import { AUTH_INTRO } from 'app/constants/app';
import { loginMsal } from 'modules/auth/authMsalService';
import { getRemAsPx, getWindowDimensions } from 'app/helpers';
import { useTranslation } from 'react-i18next';
import ErrorField from '../ErrorField';
import Gdpr from '../Gdpr';

const PersonalInfo = ({ nextStep, handleChange, values, errors, isJourneyLocation = false }) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const formBodyRef = useRef();
  const [bodyHeight, setBodyHeight] = useState(0);
  const { width: windowWidth, height: windowHeight } = getWindowDimensions();

  const { t } = useTranslation();

  useEffect(() => {
    if (
      Object.keys(errors).length === 0 &&
      values.company &&
      values.name &&
      values.email &&
      values.gdpr
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [values.errors, values.company, values.name, values.email, values.gdpr]);

  useEffect(() => {
    if (formBodyRef) {
      // count height of inputs fields depending on window height for the applying scroll
      const size = formBodyRef?.current?.getBoundingClientRect();

      const topOffset = size.top;
      const bottomOffset = 32;

      const fullHeight = windowWidth >= 2560 ? getRemAsPx(68.94) : getRemAsPx(39.375);

      // set form body height for apply scrolling
      const height =
        fullHeight + bottomOffset + topOffset > windowHeight
          ? windowHeight - topOffset - bottomOffset
          : fullHeight;

      setBodyHeight(height);
    }
    // const height =
  }, [formBodyRef, setBodyHeight, windowWidth, windowHeight]);

  const continueNext = (e) => {
    e.preventDefault();
    nextStep();
  };

  const onChange = (fieldName) => (e) => {
    handleChange(fieldName, e.target.value);
  };

  const onCheck = (fieldName, isChecked) => {
    handleChange(fieldName, isChecked);
  };

  return (
    <>
      <div className="form-describe">
        <div className="text-describe">{t(AUTH_INTRO)}</div>
      </div>

      <div
        className={`form-body ${isJourneyLocation ? 'full-screen' : ''}`}
        ref={formBodyRef}
        style={{ height: bodyHeight }}
      >
        <div className="field">
          <label className="input-label" htmlFor="email">
            {t('forms.Email')}
          </label>
          <input
            placeholder="email@company.com"
            onChange={onChange('email')}
            value={values.email}
            id="email"
            className="text-input"
          />
          {errors.email && <ErrorField error={errors.email} />}
        </div>
        <div className="field">
          <label className="input-label" htmlFor="name">
            {t('forms.Name')}
          </label>
          <input
            placeholder={`${t('forms.Name')}`}
            value={values.name}
            onChange={onChange('name')}
            id="name"
            className="text-input"
          />
          {errors.name && <ErrorField error={errors.name} />}
        </div>
        <div className="field">
          <label className="input-label" htmlFor="company">
            {t('forms.Company')}
          </label>
          <input
            placeholder={`${t('forms.Company')}`}
            onChange={onChange('company')}
            value={values.company}
            id="company"
            className="text-input"
          />
          {errors.company && <ErrorField error={errors.company} />}
        </div>
        <div className="with-scroll">
          <div className="field">
            <Gdpr handleChange={onCheck} value={values.gdpr} />
          </div>
        </div>

        <div className="bottom-button">
          <Button onClick={continueNext} disabled={isDisabled}>
            {t('forms.Continue')}
          </Button>
        </div>
        <div className="emplyee-login" onClick={loginMsal}>
          {t('auth.Hexagon employee log in')}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const journey = selectors.getJourney(state);
  const isNavigableJourney = journey?.isInPaths;

  return {
    isJourneyLocation: isNavigableJourney,
  };
};

export default connect(mapStateToProps, null)(PersonalInfo);
