import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import './css/index.css';

class Spinner extends PureComponent {
  render() {
    return <div className="spinner">Spinner</div>;
  }
}

Spinner.propTypes = {};

Spinner.defaultProps = {};

export default connect()(Spinner);
