import { createSelector } from 'reselect';

import { NAME } from 'app/constants';

const isReadyProp = (state) => {
  const { ready } = state[NAME];
  return ready;
};
export const isReady = createSelector(isReadyProp, (ready) => ready);

const arePathsOpenProp = (state) => {
  const { arePathsOpen } = state[NAME];
  return arePathsOpen;
};
export const arePathsOpen = createSelector(arePathsOpenProp, (arePathsOpened) => arePathsOpened);

const isStageAnimatingProp = (state) => {
  const { isStageAnimating } = state[NAME];
  return isStageAnimating;
};
export const isStageAnimating = createSelector(
  isStageAnimatingProp,
  (stageAnimating) => stageAnimating,
);

const getModalProp = (state) => {
  const { modal } = state[NAME];
  return modal;
};
export const getModal = createSelector(getModalProp, (modal) => modal);

const isOpenModalProp = (state) => {
  const { isOpen } = state[NAME].modal;
  return isOpen;
};
export const isOpenModal = createSelector(isOpenModalProp, (isOpen) => isOpen);

const getContentModalProp = (state) => {
  const { content } = state[NAME].modal;
  return content;
};
export const getContentModal = createSelector(getContentModalProp, (content) => content);

const getLomixConfigProp = (state) => {
  const { lomixConfig } = state[NAME];
  return lomixConfig;
};
export const getLomixConfig = createSelector(getLomixConfigProp, (lomixConfig) => lomixConfig);

const getJourneyProp = (state) => {
  const { journey } = state[NAME].app;
  return journey;
};
export const getJourney = createSelector(getJourneyProp, (journey) => journey);

const getJourneyIdProp = (state) => {
  const { journeyId } = state[NAME].app;
  return journeyId;
};
export const getJourneyId = createSelector(getJourneyIdProp, (journeyId) => journeyId);

const getNextJourneyProp = (state) => {
  const { nextJourney } = state[NAME].app;
  return nextJourney;
};
export const getNextJourney = createSelector(getNextJourneyProp, (nextJourney) => nextJourney);

const getLocationProp = (state) => {
  const { location } = state[NAME].app;
  return location;
};
export const getLocation = createSelector(getLocationProp, (location) => location);

const getLocationNameProp = (state) => {
  const { locationName } = state[NAME].app;
  return locationName;
};
export const getLocationName = createSelector(getLocationNameProp, (locationName) => locationName);

const getNextLocationProp = (state) => {
  const { nextLocation } = state[NAME].app;
  return nextLocation;
};
export const getNextLocation = createSelector(getNextLocationProp, (nextLocation) => nextLocation);

const getPrevLocationProp = (state) => {
  const { prevLocation } = state[NAME].app;
  return prevLocation;
};
export const getPrevLocation = createSelector(getPrevLocationProp, (prevLocation) => prevLocation);

const getDirectionProp = (state) => {
  const { direction } = state[NAME].navigation;
  return direction;
};
export const getDirection = createSelector(getDirectionProp, (direction) => direction);

const getCanNavigateProp = (state) => {
  const { canNavigate } = state[NAME].navigation;
  return canNavigate;
};
export const getCanNavigate = createSelector(getCanNavigateProp, (canNavigate) => canNavigate);

const getToJourneyProp = (state) => {
  const { path } = state[NAME].navigation.toJourney;
  return path;
};
export const getToJourney = createSelector(getToJourneyProp, (toJourney) => toJourney);

const getReplaceJourneyProp = (state) => {
  const { replace } = state[NAME].navigation.toJourney;
  return replace;
};
export const getReplaceJourney = createSelector(getReplaceJourneyProp, (replace) => replace);

const getPwaProgressProp = (state) => {
  const { pwaProgress } = state[NAME];
  return pwaProgress;
};
export const getPwaProgress = createSelector(getPwaProgressProp, (pwaProgress) => pwaProgress);

const getPwaLoadedProp = (state) => {
  const { pwaLoaded } = state[NAME];
  return pwaLoaded;
};
export const getPwaLoaded = createSelector(getPwaLoadedProp, (pwaLoaded) => pwaLoaded);

const getPwaErrorProp = (state) => {
  const { pwaError } = state[NAME];
  return pwaError;
};
export const getPwaError = createSelector(getPwaErrorProp, (pwaError) => pwaError);

const getEmailProp = (state) => {
  const { email } = state[NAME];
  return email;
};
export const getEmail = createSelector(getEmailProp, (email) => email);

const getShowedTabletPortraitOrientationProp = (state) => {
  const { wasShowedPortraitOrientation } = state[NAME].tablet;
  return wasShowedPortraitOrientation;
};
export const getShowedTabletPortraitOrientation = createSelector(
  getShowedTabletPortraitOrientationProp,
  (wasShowedPortraitOrientation) => wasShowedPortraitOrientation,
);

const isAcceptedPrivacyPolicyProp = (state) => {
  const { acceptedPrivacyPolicy } = state[NAME];
  return acceptedPrivacyPolicy;
};
export const isAcceptedPrivacyPolicy = createSelector(
  isAcceptedPrivacyPolicyProp,
  (acceptedPrivacyPolicy) => acceptedPrivacyPolicy,
);

const getLoggedUserProp = (state) => {
  const { loggedUser } = state[NAME].auth;
  return loggedUser;
};
export const getLoggedUser = createSelector(getLoggedUserProp, (loggedUser) => loggedUser);

const getIsUserLoggedInProp = (state) => {
  const { isUserLoggedIn } = state[NAME].auth;
  return isUserLoggedIn;
};
export const getIsUserLoggedIn = createSelector(
  getIsUserLoggedInProp,
  (isUserLoggedIn) => isUserLoggedIn,
);

export const getToLogInProp = (state) => {
  const { userToLogIn } = state[NAME].auth;
  return userToLogIn;
};

export const getToLogIn = createSelector(getToLogInProp, (userToLogIn) => userToLogIn);

export const getShowAuthModalProp = (state) => {
  const { showAuthModal } = state[NAME].auth;
  return showAuthModal;
};

export const getShowAuthModal = createSelector(
  getShowAuthModalProp,
  (showAuthModal) => showAuthModal,
);

const getAudioMutedProp = (state) => {
  const { muted } = state[NAME].app.audio;
  return muted;
};

export const getAudioMuted = createSelector(getAudioMutedProp, (muted) => muted);

const getDeferredClickElemntProp = (state) => {
  const { deferredClickElement } = state[NAME];
  return deferredClickElement;
};
export const getDeferredClickElement = createSelector(
  getDeferredClickElemntProp,
  (deferredClickElement) => deferredClickElement,
);

const getHideHashtagsProp = (state) => {
  const { hideHashtags } = state[NAME];
  return hideHashtags;
};
export const getHideHashtags = createSelector(getHideHashtagsProp, (hideHashtags) => hideHashtags);

const getVisitedWorkflowsOverviewProp = (state) => {
  const { visitedWorkflowsOverview } = state[NAME];
  return visitedWorkflowsOverview;
};
export const getVisitedWorkflowsOverview = createSelector(
  getVisitedWorkflowsOverviewProp,
  (visitedWorkflowsOverview) => visitedWorkflowsOverview,
);

const getVisitedWorkflowsOverview2DProp = (state) => {
  const { visitedWorkflowsOverview2D } = state[NAME];
  return visitedWorkflowsOverview2D;
};
export const getVisitedWorkflowsOverview2D = createSelector(
  getVisitedWorkflowsOverview2DProp,
  (visitedWorkflowsOverview2D) => visitedWorkflowsOverview2D,
);

const getVisibleInfo3DProp = (state) => {
  const { showInfo3D } = state[NAME];
  return showInfo3D;
};

export const getVisibleInfo3D = createSelector(getVisibleInfo3DProp, (show) => show);

const hasError3DProp = (state) => {
  const { error3D } = state[NAME];
  return error3D;
};

export const hasError3D = createSelector(hasError3DProp, (error3D) => error3D);

export const isAvailableWorkflowsOverview = createSelector(
  getPwaLoadedProp,
  hasError3DProp,
  (pwaLoaded, error3D) => !error3D && pwaLoaded,
);

const isOpenSitemapProp = (state) => {
  const { isOpen } = state[NAME].sitemap;
  return isOpen;
};
export const isOpenSitemap = createSelector(isOpenSitemapProp, (isOpen) => isOpen);

const isOpenPwaConfirmDeleteProp = (state) => {
  const { opened } = state[NAME].pwa.confirmDelete;
  return opened;
};
export const isOpenPwaConfirmDelete = createSelector(
  isOpenPwaConfirmDeleteProp,
  (opened) => opened,
);

const getPwaConfirmDeleteProgressProp = (state) => {
  const { progress } = state[NAME].pwa.confirmDelete;
  return progress;
};
export const getPwaConfirmDeleteProgress = createSelector(
  getPwaConfirmDeleteProgressProp,
  (progress) => progress,
);

const getPwaConfirmDeleteStatusProp = (state) => {
  const { status } = state[NAME].pwa.confirmDelete;
  return status;
};
export const getPwaConfirmDeleteStatus = createSelector(
  getPwaConfirmDeleteStatusProp,
  (status) => status,
);

const getPwaNumberOpenedWindowProp = (state) => {
  const { pwaNumberOpenedWindow } = state[NAME];
  return pwaNumberOpenedWindow;
};
export const getPwaNumberOpenedWindow = createSelector(
  getPwaNumberOpenedWindowProp,
  (pwaNumberOpenedWindow) => pwaNumberOpenedWindow,
);

const isOpenPwaModalProp = (state) => {
  const { pwaModalOpened } = state[NAME];
  return pwaModalOpened;
};
export const isOpenPwaModal = createSelector(
  isOpenPwaModalProp,
  (pwaModalOpened) => pwaModalOpened,
);

const getJourneyToAssociateProp = (state) => {
  const { journeyToAssociate } = state[NAME];
  return journeyToAssociate;
};
export const getJourneyToAssociate = createSelector(
  getJourneyToAssociateProp,
  (journeyToAssociate) => journeyToAssociate,
);
