/* eslint-disable no-restricted-globals */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-undef */
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import 'regenerator-runtime/runtime';

import rootReducer from 'app/reducers';

export default function configureStore(initialState = {}) {
  const sagaMiddleware = createSagaMiddleware();

  const middlewares = [sagaMiddleware];

  return {
    ...createStore(
      rootReducer,
      initialState,
      compose(
        applyMiddleware(...middlewares),
        // eslint-disable-next-line no-underscore-dangle
        // @ts-ignore
        // eslint-disable-next-line no-underscore-dangle
        globalThis.__REDUX_DEVTOOLS_EXTENSION__
          ? globalThis.__REDUX_DEVTOOLS_EXTENSION__()
          : (f) => f,
      ),
    ),
    runSaga: sagaMiddleware.run,
  };
}
