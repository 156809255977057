/* eslint-disable react/no-unknown-property */
/* eslint-disable import/no-dynamic-require */
// @ts-nocheck
/* eslint-disable global-require */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* ts-nocheck */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { CSSTransition } from 'react-transition-group';

import { getWindowDimensions } from 'app/helpers';

import SplashVideo from './Video';

import ConnectivityLinesSvg from './assets/icons/сonnectivity-lines_expanded.svg';

import './assets/scss/index.scss';

const ConnectivityLines = ({ location, activePointerId, visible, dimensions }) => {
  const [activePointerName, setActivePointerName] = useState('evaluation-planning-design');

  const getOffset = () => {
    const initialWidth = dimensions?.width;
    const initialHeight = dimensions?.height;

    const { width: currentWidth, height: currentHeight } = getWindowDimensions();

    const offsetX = currentWidth / initialWidth;
    const offsetY = currentHeight / initialHeight;

    return { offsetX, offsetY };
  };

  useEffect(() => {
    if (activePointerId === null) {
      setActivePointerName('lines-random');
    } else {
      setActivePointerName(activePointerId);
    }
  }, [activePointerId, setActivePointerName]);

  const smallestY = location?.pointers.reduce((previous, current) => {
    return current.position[1] < previous.position[1] ? current : previous;
  });

  const smallestX = location?.pointers.reduce((previous, current) => {
    return current.position[0] < previous.position[0] && !current.ignoreMeForParentPosition
      ? current
      : previous;
  });

  const { offsetX: screenOffsetX } = getOffset();

  return (
    <CSSTransition in={visible} timeout={0} classNames="splash_visible" unmountOnExit>
      <div
        className="splash_wrap"
        style={{
          position: 'absolute',
          width: `${location?.connectivityLines?.width * screenOffsetX}px`,
          height: `${location?.connectivityLines?.height * screenOffsetX}px`,
          top: `${
            (smallestY.position[1] + location?.connectivityLines?.offsetY) * screenOffsetX
          }px`,
          left: `${
            (smallestX.position[0] - location?.connectivityLines?.offsetX) * screenOffsetX
          }px`,
        }}
      >
        <SplashVideo activePointer={activePointerName} />
        <ConnectivityLinesSvg />
      </div>
    </CSSTransition>
  );
};

ConnectivityLines.propTypes = {
  dimensions: PropTypes.shape({ width: PropTypes.number, height: PropTypes.number }),
  location: PropTypes.any,
  activePointerId: PropTypes.string,
  visible: PropTypes.bool,
};

ConnectivityLines.defaultProps = {
  dimensions: {
    width: 0,
    height: 0,
  },
  location: {},
  activePointerId: '',
  visible: false,
};

export default ConnectivityLines;
