const roles = [
  {
    key: 'evaluation-planning-design',
    localization: 'contactUs.roles.EvaluationPlanningDesign',
    pardotId: '1022426',
  },
  {
    key: 'geology',
    localization: 'contactUs.roles.geology',
    pardotId: '1022428',
  },
  {
    key: 'survey',
    localization: 'contactUs.roles.survey',
    pardotId: '1022430',
  },
  {
    key: 'engineering',
    localization: 'contactUs.roles.engineering',
    pardotId: '1022432',
  },
  {
    key: 'operations',
    localization: 'contactUs.roles.operations',
    pardotId: '1022434',
  },
  {
    key: 'safety',
    localization: 'contactUs.roles.safety',
    pardotId: '1022436',
  },
  {
    key: 'processing',
    localization: 'contactUs.roles.processing',
    pardotId: '1022438',
  },
  {
    key: 'it',
    localization: 'contactUs.roles.it',
    pardotId: '1022440',
  },
  {
    key: 'finance',
    localization: 'contactUs.roles.finance',
    pardotId: '1022442',
  },
  {
    key: 'consultant',
    localization: 'contactUs.roles.consultant',
    pardotId: '1022444',
  },
  {
    key: 'education',
    localization: 'contactUs.roles.education',
    pardotId: '1022446',
  },
];

export default roles;
