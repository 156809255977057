import Box from 'modules/common/components/Box';

export default class EvaluationPlanningDesignResourceModel4 {
  constructor(position, cornerFrom) {
    this.id = 'EvaluationPlanningDesignResourceModel4';
    this.component = Box;
    this.type = 'content';
    this.title = 'evaluationPlanningDesign.Resource Model.elements.Fully Auditable';
    this.content = 'evaluationPlanningDesign.Resource Model.elements.Fully Auditable Content';
    this.props = {
      target: 'tb3',
      position,
      hashtags: ['digitalisation'],
      cornerFrom: cornerFrom || null,
    };
  }
}
