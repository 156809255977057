/* eslint-disable import/prefer-default-export */
export const AUTH_INTRO = 'auth.Please fill in your details to continue';
export const ALLOW_EVENTS_CLASS = 'allow-event';
export const CLEAR_DEFER_CLASS = 'clear-defer';
export const INTERESTS_INTRO = 'auth.Specify the information';

export const AUTH_ERRORS = {
  login: {
    userNotExists: {
      text: 'auth.That account doesnt exist',
      linkText: 'auth.get a new one',
    },
    validate: {
      text: 'auth.Please enter a valid email address',
    },
  },
  signUp: {
    email: {
      userExists: {
        text: 'auth.That account already exist',
        linkText: 'auth.log in',
      },
      validate: {
        text: 'auth.Please enter a valid email address',
      },
    },
    company: { text: 'auth.This field cant be empty' },
    name: { text: 'auth.This field cant be empty' },
  },
};
