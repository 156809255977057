/* eslint-disable react/no-unknown-property */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { isSafari } from 'react-device-detect';
import HexagonLogoSvg from 'assets/images/brand/hexagon-logo.svg';
import MinePng from 'app/assets/icons/mine.png';
// import DesktopTips from '../../../../app/assets/icons/Desktop_all_in_1.svg';
// import TabletTips from '../../../../app/assets/icons/Tablet_all_in_1.svg';
// @ts-ignore
import SplashLines from 'app/assets/icons/Mine_with_lines_chrome.webm';
// @ts-ignore
import SplashLinesForSafari from 'app/assets/icons/Mine_with_lines_safari.mp4';

import './assets/styles.scss';
import { useTranslation } from 'react-i18next';

const CircularProgressWithLabel = (props) => {
  const config = {
    className: 'brand',
  };
  const SplashVideo = isSafari ? SplashLinesForSafari : SplashLines;
  const { t } = useTranslation();
  return (
    <div className="loading-screen">
      <div className="left-part">
        <img src={MinePng} className="splash-image" alt="Hexagon Life Experience" />

        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video
          className="splashVideo"
          webkit-playsinline="true"
          plays-inline="true"
          muted
          loop
          autoPlay
          disablePictureInPicture
        >
          <source src={SplashVideo} />
        </video>
      </div>
      <div className="right-part">
        <div className={`${config.className}`}>
          <div className={`${config.className}__logo-container`}>
            <div className={`${config.className}__logo`}>
              <HexagonLogoSvg />
            </div>
          </div>
          <h1 className={`${config.className}__title`}>
            <span className={`${config.className}__title-inner`}>
              {t('Life-of-Mine Experience')}
            </span>
          </h1>
        </div>
        <div className={`${config.className}__description`}>
          <div className="text">{t('workflow.progressDescription')}</div>
          <div className="best-experienced">{t('workflow.progressBestDescription')}</div>
        </div>
        {/* <div className="navigation-tips">
          <div className="navigation-tips_header">Navigation tips:</div>
          <div className="navigation-tips_wrapper">
            {isTablet ? <TabletTips /> : <DesktopTips />}
          </div>
        </div> */}
        <div className="loading">
          <Typography variant="caption" component="div" color="text.secondary">
            {`${Math.round(props.value)}%`}
          </Typography>
          <div className="text-loading">{t('workflow.progressLoading')}</div>
          <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <LinearProgress variant="determinate" {...props} />
          </Box>
        </div>
      </div>
    </div>
  );
};

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

export default CircularProgressWithLabel;
