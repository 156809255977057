import description from './description';
import DrillBlastBlastMonitoring1 from './elements/drillBlastBlastMonitoring_1';
import DrillBlastBlastMonitoring2 from './elements/drillBlastBlastMonitoring_2';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [70, 84], width: 16.5, rotation: [62, 0] }],
  dancingAnts: [['hs1', [33.99, 100], { direction: 'reverse' }]],
  tinyBois: [{ id: 'tb1', position: [70, 84], offset: [12.5, 4.6] }],
  elements: [
    new DrillBlastBlastMonitoring1([66.7, 26.8]),
    new DrillBlastBlastMonitoring2([82.3, 56.4]),
  ],
};

export default config;
