import Box from 'modules/common/components/Box';

export default class PlanningInitial4 {
  constructor(position) {
    this.id = 'planningInitial_4';
    this.component = Box;
    this.type = 'content';
    this.title = 'planning.Capture Visualise Design.elements.3d Reality Capture';
    this.content = 'planning.Capture Visualise Design.elements.3d Reality Capture Content';
    this.props = {
      target: 'tb3',
      position,
      cornerFrom: ['bottom', 'left'],
      hashtags: ['digitalisation'],
    };
  }
}
