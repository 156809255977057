import description from './description';
import DrillBlastDrilling1 from './elements/drillBlastDrilling_1';
import DrillBlastDrilling2 from './elements/drillBlastDrilling_2';
import DrillBlastDrilling3 from './elements/drillBlastDrilling_3';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [17.2, 52.25], width: 7.75, rotation: [54, 0] }],
  dancingAnts: [
    ['hs1', [0, 18.35], { direction: 'reverse' }],
    ['hs1', [0, 81.93]],
  ],
  tinyBois: [
    { id: 'tb1', position: [17.2, 52.25], offset: [6, 2] },
    { id: 'tb2', position: [17.2, 52.25], offset: [7.4, 3.3] },
  ],
  elements: [
    new DrillBlastDrilling1([11.6, 16.5]),
    new DrillBlastDrilling2([35.4, 43]),
    new DrillBlastDrilling3([35.4, 57]),
  ],
};

export default config;
