/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-empty,no-unused-vars */
/* eslint-disable prettier/prettier */
// @ts-nocheck
/* eslint-disable global-require,react/destructuring-assignment,react/prop-types,react/no-unused-state,no-restricted-syntax */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Location from 'modules/common/containers/Location';

class Main extends PureComponent {
  onEvent = ({ action, data }) => {
    switch (action) {
      case 'setCanNavigate': {
        this.props.onEvent({ action, data });
        break;
      }
      case 'toggleIsStageAnimating': {
        this.props.onEvent({ action, data });
        break;
      }
      case 'locationReady': {
        this.props.onEvent({ action: 'location:ready', data });
        break;
      }
      case 'startVideoEnd': {
        this.props.onEvent({ action: 'location:destroy:pre', data });
        /* if (this.props.nextLocation) {
          this.setBackImage(this.props.nextLocation);
        } */
        break;
      }
      case 'rollbackedAnimationEnd': {
        /* const { direction, setCanNavigate } = this.props;

        if (direction === 'next') {
          this.checkAnimationEnded();
        } else if (direction === 'prev') {
          setCanNavigate(true);
        } */
        break;
      }
      case 'animation:end': {
        const {
          requestDestroy,
        } = this.props;

        if (requestDestroy) {
          this.props.onEvent({ action: 'location:destroy', data });
        } else {
          this.props.onEvent({ action: 'location:destroy', data });
        }
        break;
      }
      case 'cancelResumeFromCard': {
        this.props.onEvent({ action });
        /* const { setLocationName, togglePaths } = this.props;

        setLocationName(null);
        togglePaths({ isOpen: true });
        */
        break;
      }
      default: {
        this.props.onEvent({ action, data });
      }
    }
  }

  render() {
    const {
      journey,
      location,
      audioMuted,
      requestDestroy,
      backgroundImage,
      disableDefaultAudio,
    } = this.props;

    const drawPoints = location.drawPoints ?? null;

    const video = location?.video;

    return (
      <>
        <Location
          backgroundImage={backgroundImage}
          video={video}
          location={location}
          locationName={location.slug}
          drawPoints={drawPoints}
          muted={audioMuted}
          onEvent={this.onEvent}
          journey={journey} // TEMP.ONLY FOR TEST
          requestDestroy={requestDestroy}
          disableDefaultAudio={disableDefaultAudio}
        />
      </>
    );
  }
}

Main.propTypes = {
  // drawPoints,
  config: PropTypes.shape({
    className: PropTypes.string,
    delayPrimaries: PropTypes.number,
    delaySecondaries: PropTypes.number,
    staggerBreakdowns: PropTypes.number,
    delayVideoBreakdown: PropTypes.number,
  }),
  enabledTransition: PropTypes.bool,
  playDefaultAudio: PropTypes.bool,
  audioMuted: PropTypes.bool,
  location: PropTypes.object,
  requestDestroy: PropTypes.bool,
  disableDefaultAudio: PropTypes.bool,
};

Main.defaultProps = {
  config: {
    className: 'journey',
    delayPrimaries: 0.5,
    delaySecondaries: 0.25,
    staggerBreakdowns: 0.125,
    delayVideoBreakdown: 1.5,
  },
  enabledTransition: false,
  playDefaultAudio: false,
  audioMuted: true,
  location: {},
  requestDestroy: false,
  disableDefaultAudio: false,
};

const mapStateToProps = (state, props) => {
  const playDefaultAudio = !!props.location?.video?.playDefaultSound;

  return {
    config: {
      className: 'journey',
      delayPrimaries: 0.5,
      delaySecondaries: 0.25,
      staggerBreakdowns: 0.125,
      delayVideoBreakdown: 1.5,
    },
    playDefaultAudio,
  };
};

export default connect(mapStateToProps, null)(Main);