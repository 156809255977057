import Box from 'modules/common/components/Box';

export default class MaterialMovementVehicleCollisionAvoidance5 {
  constructor(position, props = {}) {
    this.id = 'materialMovementVehicleCollisionAvoidance_5';
    this.component = Box;
    this.type = 'content';
    this.title =
      "safety.Vehicle Collision Avoidance.elements.Mining's first Level-9 Collision Avoidance";
    this.content =
      "safety.Vehicle Collision Avoidance.elements.Mining's first Level-9 Collision Avoidance Content";

    this.props = {
      target: props.target || null,
      position,
      hashtags: ['healthSafety', 'autonomy', 'integration'],
    };
  }
}
