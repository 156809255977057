const config = {
  id: '1 of 5 mine-map',
  currentNumber: 1,
  totalNumber: 5,
  title: 'workflow.Mine Map',
  slug: 'mine-map',
  hasMainNav: true,
  isInPaths: true,
  hidePath: false,
  description: 'workflow.Mine Map description1',
  video: {
    backgroundImage: 'wf_2D_Mine_Map.jpg',
  },
  audio: {
    default: {
      disable: true,
    },
  },
  drawPoints: {
    hotspots: 1,
    dancingAnts: 4.5,
    secondaries: 5,
  },
  hotspots: [],
  dancingAnts: [],
  tinyBois: [],
  elements: [],
  pointers: [
    {
      id: 'evaluation-planning-design',
      label: {
        number: '01',
        title: 'evaluationPlanningDesign.EvaluationPlanningDesign',
      },
      position: [2312, 468],
      rotation: [0, 0],
      default: {
        label: {
          number: {
            translateX: '-70',
          },
        },
        // width: 2.275,
        width: 120,
        height: 278,
        scale: 0.00833333,
        viewBox: {
          x: 120,
          y: 278,
        },
        rect: {
          x: 0,
          y: 0, // 0.12146,
          width: 120,
          height: 120,
        },
        circle: {
          outer: {
            x: 60,
            y: 68,
            r: 22,
            strokeWidth: 4,
          },
          inner: {
            x: 60,
            y: 68,
            r: 8,
          },
        },
        line: {
          y1: 59,
          d: 'M58 88H62V278H58V88Z',
        },
        splash: {
          opacity: 0,
          width: 120,
          height: 120,
        },
      },
      active: {
        // width: 2.275,
        width: 120,
        height: 278,
        scale: 0.00833333,
        viewBox: {
          x: 120,
          y: 278,
        },
        rect: {
          x: 0,
          y: 0, // 0.12146,
          width: 120,
          height: 120,
        },
        circle: {
          outer: {
            x: 60,
            y: 60,
            r: 30,
            strokeWidth: 4,
          },
          inner: {
            x: 60,
            y: 60,
            r: 8,
          },
        },
        line: {
          y1: 59,

          d: 'M58 88H62V278H58V88Z',
        },
        splash: {
          opacity: 1,
          width: 120,
          height: 120,
        },
      },
      zoomIn: {
        video: {
          name: 'wf_2D_Mine_Map_Evaluation_Planning_And_Design_Zoom_In.mp4',
          backgroundImage: 'wf_2D_Mine_Map_Evaluation_Planning_And_Design_Zoom_Out.jpg',
        },
      },
      zoomOut: {
        video: {
          name: 'wf_2D_Mine_Map_Evaluation_Planning_And_Design_Zoom_Out.mp4',
          backgroundImage: 'wf_2D_Mine_Map.jpg',
        },
      },
    },
    {
      id: 'drill-blast',
      label: {
        number: '02',
        title: 'drill-blast.Drill Blast',
      },
      position: [1802, 712],
      rotation: [0, 0],
      default: {
        label: {
          number: {
            translateX: '-70',
          },
        },
        // width: 2.275,
        width: 120,
        height: 318,
        scale: 0.00833333,
        viewBox: {
          x: 120,
          y: 318,
        },
        rect: {
          x: 0,
          y: 0,
          width: 120,
          height: 120,
        },
        circle: {
          outer: {
            x: 60,
            y: 68,
            r: 22,
            strokeWidth: 4,
          },
          inner: {
            x: 60,
            y: 68,
            r: 8,
          },
        },
        line: {
          y1: 59,
          d: 'M58 88H62V318H58V88Z',
        },
        splash: {
          opacity: 0,
          width: 120,
          height: 120,
        },
      },
      active: {
        // width: 2.275,
        width: 120,
        height: 318,
        scale: 0.00833333,
        viewBox: {
          x: 120,
          y: 318,
        },
        rect: {
          x: 0,
          y: 0,
          width: 120,
          height: 120,
        },
        circle: {
          outer: {
            x: 60,
            y: 60,
            r: 30,
            strokeWidth: 4,
          },
          inner: {
            x: 60,
            y: 59.9999,
            r: 8,
          },
        },
        line: {
          y1: 59,
          d: 'M58 88H62V318H58V88Z',
        },
        splash: {
          opacity: 1,
          width: 120,
          height: 120,
        },
      },
      zoomIn: {
        video: {
          name: 'wf_2D_Mine_Map_Drill_And_Blast_Zoom_In.mp4',
          backgroundImage: 'wf_2D_Mine_Map_Drill_And_Blast_Zoom_Out.jpg',
        },
      },
      zoomOut: {
        video: {
          name: 'wf_2D_Mine_Map_Drill_And_Blast_Zoom_Out.mp4',
          backgroundImage: 'wf_2D_Mine_Map.jpg',
        },
      },
    },
    {
      id: 'load-haul',
      label: {
        number: '03',
        title: 'load-haul.Load & Haul',
      },
      position: [2024, 816],
      rotation: [0, 0],
      default: {
        label: {
          number: {
            translateX: '-70',
          },
        },
        // width: 2.275,
        width: 120,
        height: 218,
        scale: 0.00833333,
        viewBox: {
          x: 120,
          y: 218,
        },
        rect: {
          x: 0,
          y: 0,
          width: 120,
          height: 120,
        },
        circle: {
          outer: {
            x: 60,
            y: 68,
            r: 22,
            strokeWidth: 4,
          },
          inner: {
            x: 60,
            y: 68,
            r: 8,
          },
        },
        line: {
          y1: 59,
          d: 'M58 88H62V218H58V88Z',
        },
        splash: {
          opacity: 0,
          width: 120,
          height: 120,
        },
      },
      active: {
        // width: 2.275,
        width: 120,
        height: 218,
        scale: 0.00833333,
        viewBox: {
          x: 120,
          y: 218,
        },
        rect: {
          x: 0,
          y: 0,
          width: 120,
          height: 120,
        },
        circle: {
          outer: {
            x: 60,
            y: 59.9999,
            r: 30,
            strokeWidth: 4,
          },
          inner: {
            x: 60,
            y: 60,
            r: 8,
          },
        },
        line: {
          y1: 59,
          d: 'M58 88H62V218H58V88Z',
        },
        splash: {
          opacity: 1,
          width: 120,
          height: 120,
        },
      },
      zoomIn: {
        video: {
          name: 'wf_2D_Mine_Map_Load_And_Haul_Zoom_In.mp4',
          backgroundImage: 'wf_2D_Mine_Map_Load_And_Haul_Zoom_Out.jpg',
        },
      },
      zoomOut: {
        video: {
          name: 'wf_2D_Mine_Map_Load_And_Haul_Zoom_Out.mp4',
          backgroundImage: 'wf_2D_Mine_Map.jpg',
        },
      },
    },
    {
      id: 'safety',
      label: {
        number: '05',
        title: 'safety.Safety',
      },
      position: [1858, 888],
      rotation: [0, 0],
      default: {
        label: {
          number: {
            translateX: '-70',
          },
        },
        // width: 2.275,
        width: 120,
        height: 172,
        scale: 0.00833333,
        viewBox: {
          x: 120,
          y: 172,
        },
        rect: {
          x: 0,
          y: 0,
          width: 120,
          height: 120,
        },
        circle: {
          outer: {
            x: 60,
            y: 60,
            r: 22,
            strokeWidth: 4,
          },
          inner: {
            x: 60,
            y: 60,
            r: 8,
          },
        },
        line: {
          x1: 59.9995,
          y1: 90.338,
          x2: 59.9995,
          y2: 172,
          d: 'M58 82H62V172H58V82Z',
        },
        splash: {
          opacity: 0,
          width: 120,
          height: 120,
        },
      },
      active: {
        // width: 2.275,
        width: 120,
        height: 172,
        scale: 0.00833333,
        viewBox: {
          x: 120,
          y: 172,
        },
        rect: {
          x: 0,
          y: 0,
          width: 120,
          height: 120,
        },
        circle: {
          outer: {
            x: 60,
            y: 60,
            r: 30,
            strokeWidth: 4,
          },
          inner: {
            x: 60,
            y: 60,
            r: 8,
          },
        },
        line: {
          x1: 59.9995,
          y1: 97.5969,
          x2: 59.9995,
          y2: 172,
          d: 'M58 90H62V172H58V90Z',
        },
        splash: {
          opacity: 1,
          width: 120,
          height: 120,
        },
      },
      zoomIn: {
        video: {
          name: 'wf_2D_Mine_Map_Safety_Zoom_In.mp4',
          backgroundImage: 'wf_2D_Mine_Map_Safety_Zoom_Out.jpg',
        },
      },
      zoomOut: {
        video: {
          name: 'wf_2D_Mine_Map_Safety_Zoom_Out.mp4',
          backgroundImage: 'wf_2D_Mine_Map.jpg',
        },
      },
    },
    {
      id: 'survey-monitoring',
      label: {
        number: '04',
        title: 'survey-monitoring.Survey Monitoring',
      },
      position: [1636, 462],
      rotation: [0, 0],
      default: {
        label: {
          number: {
            translateX: '-70',
          },
        },
        // width: 2.275,
        width: 120,
        height: 458,
        scale: 0.00833333,
        viewBox: {
          x: 120,
          y: 458,
        },
        rect: {
          x: 0,
          y: 0,
          width: 120,
          height: 120,
        },
        circle: {
          outer: {
            x: 60,
            y: 68,
            r: 22,
            strokeWidth: 4,
          },
          inner: {
            x: 60,
            y: 68,
            r: 8,
          },
        },
        line: {
          y1: 59,
          d: 'M58 88H62V458H58V88Z',
        },
        splash: {
          opacity: 0,
          width: 120,
          height: 120,
        },
      },
      active: {
        // width: 2.275,
        width: 120,
        height: 458,
        scale: 0.00833333,
        viewBox: {
          x: 120,
          y: 458,
        },
        rect: {
          x: 0,
          y: 0,
          width: 120,
          height: 120,
        },
        circle: {
          outer: {
            x: 60,
            y: 60,
            r: 30,
            strokeWidth: 4,
          },
          inner: {
            x: 60,
            y: 60,
            r: 8,
          },
        },
        line: {
          y1: 59,
          d: 'M58 88H62V458H58V88Z',
        },
        splash: {
          opacity: 1,
          width: 120,
          height: 120,
        },
      },
      zoomIn: {
        video: {
          name: 'wf_2D_Mine_Map_Survey_And_Monitoring_Zoom_In.mp4',
          backgroundImage: 'wf_2D_Mine_Map_Survey_And_Monitoring_Zoom_Out.jpg',
        },
      },
      zoomOut: {
        video: {
          name: 'wf_2D_Mine_Map_Survey_And_Monitoring_Zoom_Out.mp4',
          backgroundImage: 'wf_2D_Mine_Map.jpg',
        },
      },
    },
    {
      id: 'insights',
      label: {
        number: '06',
        title: 'insights.Insights',
      },
      ignoreMeForParentPosition: true,
      position: [1488, 600],
      rotation: [0, 0],
      default: {
        label: {
          number: {
            translateX: '-70',
          },
        },
        // width: 2.275,
        width: 120,
        height: 178,
        scale: 0.00833333,
        viewBox: {
          x: 120,
          y: 178,
        },
        rect: {
          x: 0,
          y: 0,
          width: 120,
          height: 120,
        },
        circle: {
          outer: {
            x: 60,
            y: 68,
            r: 22,
            strokeWidth: 4,
          },
          inner: {
            x: 60,
            y: 68,
            r: 8,
          },
        },
        line: {
          y1: 59,
          d: 'M58 88H62V178H58V88Z',
        },
        splash: {
          opacity: 0,
          width: 120,
          height: 120,
        },
      },
      active: {
        // width: 2.275,
        width: 120,
        height: 178,
        scale: 0.00833333,
        viewBox: {
          x: 120,
          y: 178,
        },
        rect: {
          x: 0,
          y: 0,
          width: 120,
          height: 120,
        },
        circle: {
          outer: {
            x: 60,
            y: 60,
            r: 30,
            strokeWidth: 4,
          },
          inner: {
            x: 60,
            y: 60,
            r: 8,
          },
        },
        line: {
          y1: 59,
          d: 'M58 88H62V178H58V88Z',
        },
        splash: {
          opacity: 1,
          width: 120,
          height: 120,
        },
      },
      zoomIn: {
        video: {
          name: 'wf_2D_Mine_Map_Insights_Zoom_In.mp4',
          backgroundImage: 'wf_2D_Mine_Map_Insights-Zoom-Out.jpg',
        },
      },
      zoomOut: {
        video: {
          name: 'wf_2D_Mine_Map_Insights_Zoom_Out.mp4',
          backgroundImage: 'wf_2D_Mine_Map.jpg',
        },
      },
    },
  ],
  connectivityLines: {
    offsetX: 81,
    offsetY: 222,
    width: 852,
    height: 404,
  },
};

export default config;
