import WorkflowsOverview from 'modules/workflows-overview/configs/configDefault';
import WorkflowsOverview2D from 'modules/workflows-overview-2d/configs/configDefault';
import EvaluationPlanningDesign from 'modules/evaluation-planning-design/configs/configDefault';
import DrillBlast from 'modules/drill-blast/configs/configDefault';
import LoadHaul from 'modules/load-haul/configs/configDefault';
import Safety from 'modules/safety/configs/configDefault';
import SurveyMonitoring from 'modules/survey-monitoring/configs/configDefault';
import Insights from 'modules/insights/configs/configDefault';
import Home from 'modules/home/configs/configDefault';

import JourneysManager from 'modules/journeys-manager/configs/configDefault';

import Lead from 'modules/lead/configs/configDefault';

export default [
  Home,
  WorkflowsOverview,
  WorkflowsOverview2D,
  EvaluationPlanningDesign,
  DrillBlast,
  LoadHaul,
  SurveyMonitoring,
  Safety,
  Insights,
  JourneysManager,
  Lead,
];
