import Box from 'modules/common/components/Box';

export default class MaterialMovementPedestrianSafety4 {
  constructor(position) {
    this.id = 'materialMovementPedestrianSafety_4';
    this.component = Box;
    this.type = 'content';
    this.title = 'safety.Pedestrian Safety.elements.Comfortable and easy to configure';
    this.content = 'safety.Pedestrian Safety.elements.Comfortable and easy to configure Content';
    this.props = {
      target: 'tb2',
      position,
      hashtags: ['healthSafety'],
    };
  }
}
