import { createSelector } from 'reselect';

import { NAME } from '../constants';

// info: https://redux-toolkit.js.org/api/createSelector
const getInitialState = (state) => {
  return state[NAME];
};

export const isProductDetailPanelDataExist = createSelector(
  getInitialState,
  (state) => !!state.isDataExist,
);

export const isProductDetailPanelPopupOpened = createSelector(
  getInitialState,
  (state) => state.isPopupOpened,
);

export default {
  isProductDetailPanelDataExist,
  isProductDetailPanelPopupOpened,
};
