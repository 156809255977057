import description from './description';
import ResourceModel1 from './elements/resourceModel1';
import ResourceModel2 from './elements/resourceModel2';
import ResourceModel3 from './elements/resourceModel3';
import ResourceModel4 from './elements/resourceModel4';
import ResourceModel5 from './elements/resourceModel5';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [13.7, 39.3], width: 6.375 }],
  dancingAnts: [
    ['hs1', [0, 33.98], { direction: 'reverse' }],
    ['hs1', [50.57, 0]],
  ],
  tinyBois: [
    { id: 'tb1', position: [13.7, 39.3], offset: [2.9, 6.1] },
    { id: 'tb2', position: [13.7, 39.3], offset: [0.4, 4.8] },
    { id: 'tb3', position: [13.7, 39.3], offset: [5.65, 4.8] },
    { id: 'tb4', position: [13.7, 39.3], offset: [5.65, 1.2] },
  ],
  elements: [
    new ResourceModel1([12.4, 17.18]),
    new ResourceModel2([35.8, 60.2]),
    new ResourceModel3([12.4, 62.9]),
    new ResourceModel4([35.8, 46.62]),
    new ResourceModel5([43.65, 33.3]),
  ],
};

export default config;
