// @ts-nocheck
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/button-has-type */
/* eslint-disable react/require-default-props */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import LocationSvg from 'app/components/modals/assets/icons/location.svg';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

class Column extends PureComponent {
  handleClose = () => {
    const { onEvent } = this.props;
    onEvent({ action: 'close' });
  };

  handleClickOnPath = (event, path) => {
    const { onEvent, journey, locationId } = this.props;

    if (path !== `/${journey?.slug}/${locationId}`) {
      onEvent({ action: 'goToPath', path });
    }
  };

  render() {
    const {
      path: { id, slug, title, locations, disable },
      journey,
      locationId,
      last,
      t,
    } = this.props;
    const marginLeft =
      id === 1
        ? 'container-paths__column_margin-small-left'
        : 'container-paths__column_margin-left';

    const selectedJourney = journey?.slug === slug;
    const selectedClassJourney = selectedJourney ? 'container-paths__item_selected' : '';
    const selectedClassArrowJourney =
      journey?.slug === slug ? 'container-paths__arrow_right_selected' : '';

    return (
      <div
        className={`container-paths__column ${marginLeft}${
          last ? ' container-paths__column_last' : ''
        }`}
      >
        <div className="container-paths__group">
          <Link
            className={`container-paths__item ${disable ? 'disabled-link' : ''}`}
            event=""
            key={'key'}
            to={`/${slug}`}
            onClick={(e) => {
              e.preventDefault();
              if (disable) return;
              this.handleClickOnPath(e, `/${slug}`);
            }}
          >
            <div className="nowrap">
              <span className={`container-paths__item-title-bottom ${selectedClassJourney}`}>
                {t(title)}
              </span>
              <i className={`container-paths__arrow_right ${selectedClassArrowJourney}`} />
            </div>
          </Link>
        </div>
        {locations.length > 0 &&
          locations.map((l) => {
            return (
              <div
                key={l.id}
                className={`container-paths__group-item container-paths__group-item_margin-top nowrap${
                  selectedJourney && l.slug === locationId ? ' container-paths__item_selected' : ''
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  this.handleClickOnPath(e, `/${slug}/${l.slug}`);
                }}
              >
                {selectedJourney && l.slug === locationId ? (
                  <i className="icon-left">
                    <LocationSvg />
                  </i>
                ) : (
                  ''
                )}
                {t(l.title)}
              </div>
            );
          })}
      </div>
    );
  }
}

Column.propTypes = {
  config: PropTypes.shape({
    className: PropTypes.string,
    openClass: PropTypes.string,
  }),
  opened: PropTypes.bool,
  path: PropTypes.any,
  journey: PropTypes.object,
  locationId: PropTypes.string,
  last: PropTypes.bool,
  onEvent: PropTypes.func,
  t: PropTypes.func,
};

Column.defaultProps = {
  config: {
    className: 'c-site-map-modal',
    openClass: 'is-open',
  },
  opened: false,
  path: {},
  journey: {},
  locationId: null,
  last: false,
  onEvent: () => {},
  t: () => {},
};

export default withTranslation()(connect(null, null, null, { forwardRef: true })(Column));
