// @ts-nocheck
/* eslint-disable global-require,react/jsx-props-no-spreading,no-return-assign,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,react/destructuring-assignment,react/no-unused-state */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import HexagonLogoSVG from 'assets/images/brand/hexagon-logo.svg';

import Button from 'modules/common/components/Button';

import { ALLOW_EVENTS_CLASS } from 'app/constants/app';

import './assets/scss/index.scss';

class Sidebar extends PureComponent {
  handleClick = () => {
    this.props.onClick();
  };

  render() {
    const { t } = this.props;

    return (
      <div className={`sidebar-left-content ${ALLOW_EVENTS_CLASS}`}>
        <div className="sidebar-left-content__title">
          <div className="header__brand">
            <div className="header__logo-container">
              <div className="header__logo">
                <HexagonLogoSVG />
              </div>
            </div>
            <h1 className="header__title">
              <span>{t('Life-of-Mine Experience')}</span>
            </h1>
          </div>
        </div>
        <div className="sidebar-left-content__description">
          <div className="sidebar-left-content__description-title">
            {t('workflow.sidebarDescriptionLine1')}
          </div>
          <div className="sidebar-left-content__description-content">
            <div>{t('workflow.sidebarDescriptionLine2')}</div>
            <div>{t('workflow.sidebarDescriptionLine3')}</div>
          </div>
        </div>
        <div className="sidebar-left-content__button">
          <div className="stage-card__buttons">
            <Button onClick={this.handleClick}>{t('workflow.Enter Mine')}</Button>
          </div>
        </div>
      </div>
    );
  }
}

Sidebar.propTypes = {
  config: PropTypes.shape({
    className: PropTypes.string,
  }),
  onClick: PropTypes.func,
  t: PropTypes.func,
};

Sidebar.defaultProps = {
  config: {
    className: 'sidebar',
  },
  onClick: () => {},
  t: () => {},
};

export default withTranslation()(connect(null, null)(Sidebar));
