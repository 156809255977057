import { openDB } from 'idb';

const dbPromise = openDB('threejs', 1, {
  upgrade(db) {
    db.createObjectStore('models');
  },
});

export async function get(key) {
  return (await dbPromise).get('models', key);
}

export async function set(key, val) {
  return (await dbPromise).put('models', val, key);
}

export async function del(key) {
  return (await dbPromise).delete('models', key);
}

export async function clear() {
  return (await dbPromise).clear('models');
}

export async function keys() {
  return (await dbPromise).getAllKeys('models');
}
