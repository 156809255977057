/* eslint-disable no-unused-vars */
import { fork, takeEvery, put, call } from 'redux-saga/effects';

import { ERROR, SUCCESS } from 'core/constants/statuses';

import {
  CONTACT_US_CLEAR_STORE,
  CONTACT_US_SEND_FORM_DATA,
} from 'app/components/modals/ContactUs/constants';
import actions from 'app/components/modals/ContactUs/actions';
import api from 'app/components/modals/ContactUs/api';
import FormDataHelper from 'app/components/modals/ContactUs/services/FormDataHelper';
import notification from 'core/services/Notification';
import i18n from 'i18nConfig';

export default function* root() {
  yield fork(watchInit);
  yield fork(watchDestroy);
}

function* sendFormData({ payload }) {
  try {
    const dataForSend = FormDataHelper.getRequestObject(payload);

    yield call(() => api.sendFormData({ data: dataForSend.rawData }));

    const initedDateTime = Date.now();

    const meta = {
      receivedAt: initedDateTime,
      updatedAt: initedDateTime,
    };

    yield put(actions.sendForm({ status: SUCCESS, payload, meta }));
  } catch (e) {
    const initedDateTime = Date.now();

    const meta = {
      receivedAt: initedDateTime,
      updatedAt: initedDateTime,
    };

    notification.error(i18n.t('common.Request failed'));

    yield put(actions.sendForm({ status: ERROR, payload: {}, meta }));
  }
}

function* destroy() {
  const payload = {};

  const initedDateTime = Date.now();

  const meta = {
    receivedAt: initedDateTime,
    updatedAt: initedDateTime,
  };

  yield put(actions.clearStore({ status: SUCCESS, payload, meta }));
}

function* watchInit() {
  yield takeEvery(CONTACT_US_SEND_FORM_DATA, sendFormData);
}

function* watchDestroy() {
  yield takeEvery(CONTACT_US_CLEAR_STORE, destroy);
}
