/* eslint-disable no-unused-vars */
import { fork, takeEvery, put, call } from 'redux-saga/effects';

import { SUCCESS, ERROR } from 'core/constants/statuses';

import { LEAD_INIT, LEAD_DESTROY } from 'modules/lead/constants';

import actions from 'modules/lead/actions';

import api from 'modules/lead/api';

export default function* root() {
  yield fork(watchInit);
  yield fork(watchDestroy);
}

function* init(params = { payload: {} }) {
  const id = params.payload;

  const journey = yield call(api.getJourney, id);

  const initedDateTime = Date.now();

  const meta = {
    receivedAt: initedDateTime,
    updatedAt: initedDateTime,
  };

  if (!journey || journey?.status === 'error') {
    yield put(actions.init({ status: ERROR, payload: {}, meta }));
    return;
  }

  const payload = { journey };

  yield put(actions.init({ status: SUCCESS, payload, meta }));
}

function* destroy() {
  const payload = {};

  const initedDateTime = Date.now();

  const meta = {
    receivedAt: initedDateTime,
    updatedAt: initedDateTime,
  };

  yield put(actions.destroy({ status: SUCCESS, payload, meta }));
}

function* watchInit() {
  yield takeEvery(LEAD_INIT, init);
}

function* watchDestroy() {
  yield takeEvery(LEAD_DESTROY, destroy);
}
