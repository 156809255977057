import description from './description';
import MaterialMovementVehicleCollisionAvoidance1 from './elements/materialMovementVehicleCollisionAvoidance_1';
import MaterialMovementVehicleCollisionAvoidance3 from './elements/materialMovementVehicleCollisionAvoidance_3';
import MaterialMovementVehicleCollisionAvoidance4 from './elements/materialMovementVehicleCollisionAvoidance_4';
import MaterialMovementVehicleCollisionAvoidance5 from './elements/materialMovementVehicleCollisionAvoidance_5';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [53.9, 46.61], width: 5 }],
  dancingAnts: [
    ['hs1', [0, 5.37], { direction: 'reverse' }],
    ['hs1', [3.85, 100]],
  ],
  tinyBois: [
    { id: 'tb1', position: [53.9, 46.61], offset: [0.4, 4] },
    { id: 'tb4', position: [53.9, 46.61], offset: [2.3, 4.8] },
    { id: 'tb2', position: [53.9, 46.61], offset: [4.1, 4] },
  ],
  elements: [
    new MaterialMovementVehicleCollisionAvoidance1([20.21, 17.1], { size: 'wide-l' }),
    new MaterialMovementVehicleCollisionAvoidance3([43.65, 63.54], { target: 'tb3' }),
    new MaterialMovementVehicleCollisionAvoidance4([20.21, 63.54], {
      target: 'tb1',
      cornerFrom: ['top', 'left'],
    }),
    new MaterialMovementVehicleCollisionAvoidance5([67.1, 62.23], { target: 'tb2' }),
  ],
};

export default config;
