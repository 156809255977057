/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/button-has-type */
/* eslint-disable class-methods-use-this */
/* eslint-disable prettier/prettier */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import Hashtags from 'modules/common/components/Hashtags';

import actions from 'app/actions';

import EyeSVG from 'assets/images/modal/eye.svg';
import PlaySVG from 'app/assets/icons/play.svg';
import { withTranslation } from 'react-i18next';


class VideoLauncher extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  setMediaModal = () => {
    const { img, video, title, setModalContent, setIsModalOpen, locationName } = this.props;

    let mediaString = null;

    const vid = this.vidUrl();

    if (vid) {
      mediaString = {
        vid: video,
        locationName,
        title,
      };
    } else if (img) {
      const newImg = this.imgUrl(img);

      mediaString = {
        src: newImg,
        title,
        locationName,
      };
    }

    setModalContent({ content: mediaString });
    setIsModalOpen({ isOpen: true });
  };

  vidUrl() {
    const { video, i18n } = this.props;

    if (video) {
      return `/media/${i18n.language}/videos/modal/${video}`;
    }

    return null;
  }

  imgUrl(asset) {
    const { i18n } = this.props;
    if (asset) {
      return require(`assets/images/modal/${i18n.language}/${asset}`).default;
      // eslint-disable-next-line no-else-return
    } else {
      return require(`assets/images/modal/${i18n.language}/thumbnail.jpg`).default;
    }
  }

  render() {
    const { config, title, thumb, modalType, hashtags, id } = this.props;

    return (
      // <div className={`${config.className}__container`}>
      <div className={config.className} onClick={this.setMediaModal}>
        <img className={`${config.className}__img`} src={this.imgUrl(thumb)} alt="Lomix video" />
        <div className={`${config.className}__info`}>
          <h3 className={`${config.className}__title`}>{title}</h3>
          <div className={`${config.className}__play-button`}>
            {modalType === 'gallery' ? (
              <div className={`${config.className}__play-button-icon eye`}>
                <EyeSVG />
              </div>
            ) : (
              <div className={`${config.className}__play-button-icon`}>
                <PlaySVG />
              </div>
            )}
          </div>
        </div>

        {hashtags && (
          <div>
            <Hashtags tags={hashtags} id={id} key={id} />
          </div>
        )}
      </div>
    );
  }
}

VideoLauncher.propTypes = {
  config: PropTypes.shape({
    className: PropTypes.string,
  }),
  i18n: PropTypes.shape({
    language: PropTypes.string,
  }),
  id: PropTypes.string,
  title: PropTypes.string,
  img: PropTypes.string,
  thumb: PropTypes.string,
  video: PropTypes.string,
  setModalContent: PropTypes.func,
  setIsModalOpen: PropTypes.func,
  modalType: PropTypes.string,
  locationName: PropTypes.string,
  hashtags: PropTypes.arrayOf(PropTypes.string),
};

VideoLauncher.defaultProps = {
  config: {
    className: 'c-video-launcher',
  },
  i18n: {
    // language: 'en',
  },
  id: '',
  title: 'Title',
  modalType: null,
  img: '',
  thumb: '',
  video: '',
  setModalContent: () => {},
  setIsModalOpen: () => {},
  locationName: '',
  hashtags: [],
};

VideoLauncher.id = 'VideoLauncher';

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  // TODO: JOIN CALL
  setModalContent: (payload) => dispatch(actions.setModalContent({ payload })),
  setIsModalOpen: (payload) => dispatch(actions.setIsModalOpen({ payload })),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  VideoLauncher,
));
