import description from './description';
import ResourceModel1 from './elements/resourceModel1';
import ResourceModel2 from './elements/resourceModel2';
import ResourceModel3 from './elements/resourceModel3';
import ResourceModel4 from './elements/resourceModel4';
import ResourceModel5 from './elements/resourceModel5';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [16, 45.8], width: 6.375 }],
  dancingAnts: [
    ['hs1', [0, 33.98], { direction: 'reverse' }],
    ['hs1', [50.57, 0]],
  ],
  tinyBois: [
    { id: 'tb1', position: [16, 45.8], offset: [2.9, 6.1] },
    { id: 'tb2', position: [16, 45.8], offset: [0.4, 4.8] },
    { id: 'tb3', position: [16, 45.8], offset: [5.65, 4.8] },
    { id: 'tb4', position: [16, 45.8], offset: [5.65, 1.2] },
  ],
  elements: [
    new ResourceModel1([12, 16.8]),
    new ResourceModel2([27.6, 58.8]),
    new ResourceModel3([12, 62.8]),
    new ResourceModel4([27.6, 47.9], ['top', 'left']),
    new ResourceModel5([27.6, 37], ['top', 'left']),
  ],
};

export default config;
