import configAPP from 'app/configs';
import description from './description';
import FleetManagement1 from './elements/FleetManagement1';
import FleetManagement2 from './elements/FleetManagement2';
import FleetManagement3 from './elements/FleetManagement3';
import FleetManagement4 from './elements/FleetManagement4';
import FleetManagement5 from './elements/FleetManagement5';
import FleetManagement6 from './elements/FleetManagement6';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [18, 42.3], width: 6.375 }],
  dancingAnts: [
    ['hs1', [0, 22.36]],
    ['hs1', [39.89, 100], { direction: 'reverse' }],
  ],
  tinyBois: [
    { id: 'tb1', position: [18, 42.3], offset: [4.9240625, 0.5178125] },
    { id: 'tb2', position: [18, 42.3], offset: [5.89, 1.8615625] },
    { id: 'tb3', position: [18, 42.3], offset: [0.2, 4.45] },
    { id: 'tb4', position: [18, 42.3], offset: [6.1, 3.4553125] },
    // eslint-disable-next-line prettier/prettier
    configAPP.enableExhibition ? { id: 'tb5', position: [18, 42.3], offset: [3.1, 6.15] }: undefined,
  ],
  elements: [
    new FleetManagement1([11.7, 15.3], { size: 'wide' }),
    new FleetManagement2([51.3, 32.3]),
    new FleetManagement3([35.4, 45.8]),
    new FleetManagement4([11.7, 71.2]),
    new FleetManagement5([35.4, 58.5]),
    configAPP.enableExhibition ? new FleetManagement6([35.4, 71.2]) : undefined,
  ],
};

export default config;
