import description from './description';
import MaterialMovementPedestrianSafety1 from './elements/materialMovementPedestrianSafety_1';
import MaterialMovementPedestrianSafety2 from './elements/materialMovementPedestrianSafety_2';
import MaterialMovementPedestrianSafety3 from './elements/materialMovementPedestrianSafety_3';
import MaterialMovementPedestrianSafety4 from './elements/materialMovementPedestrianSafety_4';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [16.09, 58.9], width: 12.75 }],
  dancingAnts: [
    ['hs1', [0, 29.88], { direction: 'reverse' }],
    ['hs1', [0, 90.42], { direction: 'reverse' }],
  ],
  tinyBois: [
    { id: 'tb1', position: [16.09, 58.9], offset: [0.4, 8.8] },
    { id: 'tb2', position: [16.09, 58.9], offset: [11.2, 8.8] },
    { id: 'tb3', position: [16.09, 58.9], offset: [11.2, 1.7] },
  ],
  elements: [
    new MaterialMovementPedestrianSafety1([11.8, 22]),
    new MaterialMovementPedestrianSafety2([11.8, 75.23]),
    new MaterialMovementPedestrianSafety3([27, 51.6]),
    new MaterialMovementPedestrianSafety4([27, 65.8]),
  ],
};

export default config;
