import {
  JOURNEYS_MANAGER_JOURNEY_CLEAR_DATA,
  JOURNEYS_MANAGER_JOURNEY_CLEAR_DUPLICATE,
  JOURNEYS_MANAGER_JOURNEY_CLEAR_STORE,
  JOURNEYS_MANAGER_JOURNEY_CREATE,
  JOURNEYS_MANAGER_JOURNEY_DUPLICATE,
  JOURNEYS_MANAGER_JOURNEY_GET,
  JOURNEYS_MANAGER_JOURNEY_UPDATE,
} from 'modules/journeys-manager-journey/constants';

import { ERROR, STATUS, SUCCESS } from 'core/constants/statuses';

const initialState = {
  duplicateJourney: null,
  data: null,
  loading: false,
  error: null,
};

const app = (state = initialState, action) => {
  switch (action.type) {
    case JOURNEYS_MANAGER_JOURNEY_GET:
      return {
        ...state,
        loading: true,
      };
    case JOURNEYS_MANAGER_JOURNEY_CLEAR_STORE:
      return {
        ...state,
        duplicateJourney: null,
        loading: false,
        data: null,
        error: null,
      };
    case JOURNEYS_MANAGER_JOURNEY_CLEAR_DATA:
      return {
        ...state,
        data: null,
      };
    case JOURNEYS_MANAGER_JOURNEY_CLEAR_DUPLICATE:
      return {
        ...state,
        duplicateJourney: null,
      };
    case `${JOURNEYS_MANAGER_JOURNEY_GET}/${STATUS}/${SUCCESS}`:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
      };
    case `${JOURNEYS_MANAGER_JOURNEY_GET}/${STATUS}/${ERROR}`:
      return {
        ...state,
        data: null,
        loading: false,
      };
    case JOURNEYS_MANAGER_JOURNEY_CREATE:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case `${JOURNEYS_MANAGER_JOURNEY_CREATE}/${STATUS}/${SUCCESS}`:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
      };
    case `${JOURNEYS_MANAGER_JOURNEY_CREATE}/${STATUS}/${ERROR}`:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    // todo: add spinner dor delete!!!
    case JOURNEYS_MANAGER_JOURNEY_UPDATE:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case `${JOURNEYS_MANAGER_JOURNEY_UPDATE}/${STATUS}/${SUCCESS}`:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
      };
    case `${JOURNEYS_MANAGER_JOURNEY_UPDATE}/${STATUS}/${ERROR}`:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    case `${JOURNEYS_MANAGER_JOURNEY_DUPLICATE}/${STATUS}/${SUCCESS}`:
      return {
        ...state,
        duplicateJourney: action.payload.data,
      };
    default: {
      return state;
    }
  }
};

export default app;
