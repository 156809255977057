import Box from 'modules/common/components/Box';

export default class RockCharacterization2 {
  constructor(position) {
    this.id = 'RockCharacterization2';
    this.component = Box;
    this.title = 'evaluationPlanningDesign.Rock Characterisation.elements.Fracture Visualisation';
    this.video = 'md_Fracture_Visualization.mp4';
    this.props = {
      target: 'tb1',
      position,
      hashtags: ['dataAnalytics'],
    };
  }
}
