import description from './description';
import EvaluationPlanningInsights1 from './elements/Insights1';
import EvaluationPlanningInsights2 from './elements/Insights2';
import EvaluationPlanningInsights3 from './elements/Insights3';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [46.2, 75], width: 18.25, rotation: [67, 0] }],
  dancingAnts: [
    ['hs1', [50.57, 0]],
    ['hs1', [55, 0], { direction: 'reverse' }],
    ['hs1', [100, 4.78]],
    ['hs1', [100, 14.35], { direction: 'reverse' }],
  ],
  tinyBois: [],
  elements: [
    new EvaluationPlanningInsights1([19.8, 21]),
    new EvaluationPlanningInsights2([51, 23.5]),
    new EvaluationPlanningInsights3([58.9, 50.4]),
  ],
};

export default config;
