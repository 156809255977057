import description from './description';
import DrillBlastBlastFragmentationAnalysis1 from './elements/drillBlastBlastFragmentationAnalysis_1';
import DrillBlastBlastFragmentationAnalysis2 from './elements/drillBlastBlastFragmentationAnalysis_2';
import DrillBlastBlastFragmentationAnalysis3 from './elements/drillBlastBlastFragmentationAnalysis_3';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [60, 70], width: 6.875, rotation: [62, 0] }],
  dancingAnts: [['hs1', [0, 67.38], { direction: 'reverse' }]],
  tinyBois: [
    { id: 'tb1', position: [60, 70], offset: [6, 2.4] },
    { id: 'tb2', position: [60, 70], offset: [6, 4.3] },
  ],
  elements: [
    new DrillBlastBlastFragmentationAnalysis1([28, 47.4], { cornerFrom: ['top', 'right'] }),
    new DrillBlastBlastFragmentationAnalysis2([74.9, 44.1]),
    new DrillBlastBlastFragmentationAnalysis3([74.9, 62.28]),
  ],
};

export default config;
