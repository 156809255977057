import Box from 'modules/common/components/Box';

export default class SchedulingOptimising3 {
  constructor(position) {
    this.id = 'SchedulingOptimising3';
    this.component = Box;
    this.title = 'load-haul.Scheduling Optimising.elements.Activity Scheduler';
    this.img = '';
    this.video = 'md_Activity_Scheduler.mp4';
    this.props = {
      target: 'tb2',
      position,
      hashtags: ['cost', 'productivity'],
    };
  }
}
