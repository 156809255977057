import description from './description';
import SurveyMonitoringMonitoring1 from './elements/surveyMonitoringMonitoring_1';
import SurveyMonitoringMonitoring2 from './elements/surveyMonitoringMonitoring_2';
import SurveyMonitoringMonitoring3 from './elements/surveyMonitoringMonitoring_3';
import SurveyMonitoringMonitoring4 from './elements/surveyMonitoringMonitoring_4';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [13.8, 39.5], width: 6.375 }],
  dancingAnts: [['hs1', [0, 89.35], { direction: 'reverse' }]],
  tinyBois: [
    { id: 'tb1', position: [13.8, 39.5], offset: [5.4, 4.9] },
    { id: 'tb2', position: [13.8, 39.5], offset: [3, 6.3] },
    { id: 'tb3', position: [13.8, 39.5], offset: [5.4, 1] },
  ],
  elements: [
    new SurveyMonitoringMonitoring1([12.4, 17.2], { size: 'wide', cornerFrom: ['bottom', 'left'] }),
    new SurveyMonitoringMonitoring2([35.8, 54.3]),
    new SurveyMonitoringMonitoring3([12.4, 58]),
    new SurveyMonitoringMonitoring4([35.8, 37.6]),
  ],
};

export default config;
