import Box from 'modules/common/components/Box';

export default class MaterialMovementSlopeMonitoring2 {
  constructor(position) {
    this.id = 'materialMovementSlopeMonitoring_2';
    this.component = Box;
    this.type = 'content';
    this.title = 'safety.Slope Monitoring.elements.Critical Slope Monitoring';
    this.content = 'safety.Slope Monitoring.elements.Critical Slope Monitoring Content';
    this.props = {
      target: 'tb1',
      position,
      hashtags: ['healthSafety', 'esg'],
    };
  }
}
