const description = {
  id: '3 of 3',
  currentNumber: 3,
  totalNumber: 3,
  title: 'underground.Production Drilling.title',
  slug: 'underground-production-drilling',
  pdfPath: 'UndergroundProductionDrilling',
  leadLayoutKey: 'underground-production-drilling',
  apiSlug: 'underground-production-drilling',
  productDetailsLink: 'https://hexagon.com/products/hexagon-production-optimiser',
  description: 'underground.Production Drilling.description',
  video: {
    name: 'wf_Underground_Production_Drilling.mp4',
    stop: 32.9,
    backgroundImage: 'Underground_Production_Drilling.jpg',
  },
  drawPoints: {
    hotspots: 31,
    dancingAnts: 32,
    secondaries: 32.9,
  },
};

export default description;
