/* eslint-disable global-require,import/no-dynamic-require,react/prop-types,no-unused-vars */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import useDraggableScroll from 'use-draggable-scroll';

import './assets/scss/index.scss';

const CarouselItem = ({ slug, isActive, onClickItem, scrollToActive }) => {
  const iconRef = useRef();

  const handleClick = () => {
    onClickItem(slug);
  };

  useEffect(() => {
    if (isActive) {
      setTimeout(() => {
        // @ts-ignore
        scrollToActive(iconRef?.current.offsetLeft);
      }, 500);
    }
  }, [isActive]);

  return (
    <div
      className={`slider-item ${isActive ? 'active' : ''}`}
      id={slug}
      onClick={handleClick}
      onKeyDown={handleClick}
      role="button"
      tabIndex={0}
      ref={iconRef}
    >
      <img src={require(`./assets/icons/${slug}.png`).default} alt={slug} />
    </div>
  );
};

const Carousel = ({ activeSlug, changeActive }) => {
  const sliderRef = useRef(null);

  const { onMouseDown } = useDraggableScroll(sliderRef);

  const handleItemClick = (slug) => {
    changeActive(slug);
  };

  const scrollToActiveItem = (leftOffset) => {
    sliderRef.current.scrollLeft = leftOffset;
  };

  return (
    <div className="slider-wrapper">
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <div className="slider" id="slider" ref={sliderRef} onMouseDown={onMouseDown} role="main">
        <CarouselItem
          slug="evaluation-planning-design"
          isActive={activeSlug === 'evaluation-planning-design'}
          onClickItem={handleItemClick}
          scrollToActive={scrollToActiveItem}
        />

        <CarouselItem
          slug="drill-blast"
          isActive={activeSlug === 'drill-blast'}
          onClickItem={handleItemClick}
          scrollToActive={scrollToActiveItem}
        />

        <CarouselItem
          slug="load-haul"
          isActive={activeSlug === 'load-haul'}
          onClickItem={handleItemClick}
          scrollToActive={scrollToActiveItem}
        />

        <CarouselItem
          slug="survey-monitoring"
          isActive={activeSlug === 'survey-monitoring'}
          onClickItem={handleItemClick}
          scrollToActive={scrollToActiveItem}
        />

        <CarouselItem
          slug="safety"
          isActive={activeSlug === 'safety'}
          onClickItem={handleItemClick}
          scrollToActive={scrollToActiveItem}
        />

        <CarouselItem
          slug="insights"
          isActive={activeSlug === 'insights'}
          onClickItem={handleItemClick}
          scrollToActive={scrollToActiveItem}
        />
        {/* <CarouselItem
          slug="underground"
          isActive={activeSlug === 'underground'}
          onClickItem={handleItemClick}
          scrollToActive={scrollToActiveItem}
        /> */}
      </div>
    </div>
  );
};

Carousel.propTypes = {
  activeSlug: PropTypes.string,
  changeActive: PropTypes.func,
};

Carousel.defaultProps = {
  activeSlug: '',
  changeActive: () => {},
};

export default Carousel;
