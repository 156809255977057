import description from './description';
import SchedulingOptimising1 from './elements/SchedulingOptimising1';
import SchedulingOptimising2 from './elements/SchedulingOptimising2';
import SchedulingOptimising3 from './elements/SchedulingOptimising3';
import SchedulingOptimising4 from './elements/SchedulingOptimising4';
import SchedulingOptimising5 from './elements/SchedulingOptimising5';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [16, 56], width: 12.75 }],
  dancingAnts: [
    ['hs1', [0, 25.29], { direction: 'reverse' }],
    ['hs1', [0, 76.07]],
  ],
  tinyBois: [
    { id: 'tb1', position: [16, 56], offset: [11.2, 2.4] },
    { id: 'tb2', position: [16, 56], offset: [0.2, 9.4] },
    { id: 'tb3', position: [16, 56], offset: [11.2, 9.4] },
    { id: 'tb4', position: [16, 56], offset: [6, 12] },
  ],
  elements: [
    new SchedulingOptimising1([12, 19.9]),
    new SchedulingOptimising2([27.6, 43.75]),
    new SchedulingOptimising3([12, 76.3]),
    new SchedulingOptimising4([27.6, 56.7]),
    new SchedulingOptimising5([27.6, 69.7]),
  ],
};

export default config;
