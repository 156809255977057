/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { isTablet } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

const GestureItem = ({ gest }) => {
  const { name, iconDesktop, iconTablet } = gest;
  const { t } = useTranslation();

  return (
    <div className="gest">
      <div className="svg-outer">
        <div className="svg-inner">{isTablet ? iconTablet : iconDesktop}</div>
      </div>

      <div className="title">{t(name)}</div>
    </div>
  );
};

GestureItem.propTypes = {
  gest: PropTypes.shape({
    name: PropTypes.string,
    iconDesktop: PropTypes.any,
    iconTablet: PropTypes.any,
  }),
};

GestureItem.defaultProps = {
  gest: {},
};

export default GestureItem;
