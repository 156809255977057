import description from './description';
import UndergroundMining1 from './elements/undergroundMining_1';
import UndergroundMining2 from './elements/undergroundMining_2';
import UndergroundMining3 from './elements/undergroundMining_3';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [16.09, 57.7], width: 12.75 }],
  dancingAnts: [['hs1', [0, 37.1], { direction: 'reverse' }]],
  tinyBois: [
    { id: 'tb2', position: [16.09, 57.7], offset: [0.6, 9.56] },
    { id: 'tb1', position: [16.09, 57.7], offset: [11, 9.56] },
  ],
  elements: [
    new UndergroundMining1([12, 23.37]),
    new UndergroundMining2([27.6, 92.1]),
    new UndergroundMining3([12, 92.1]),
  ],
};

export default config;
