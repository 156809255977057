import Introduction from 'modules/home-introduction/configs/config1200';

const config = {
  id: 0,
  title: 'home.Home',
  slug: 'home',
  hasMainNav: false,
  isInPaths: false,
  video: {
    backgroundImage: 'journey-0-start-frame.jpg',
  },
  audio: {
    default: {
      disable: true,
    },
  },
  info: Introduction,
  locations: [],
};

export default config;
