import description from './description';
import SafetyInsights1 from './elements/SafetyInsights_1';
import SafetyInsights3 from './elements/SafetyInsights_3';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [57.58, 41.87], width: 6.375 }],
  dancingAnts: [],
  tinyBois: [{ id: 'tb1', position: [57.58, 41.87], offset: [6.1, 3.15] }],
  elements: [
    new SafetyInsights1([19.58, 17.75], { cornerFrom: ['top', 'right'] }),
    new SafetyInsights3([75, 41.87], { cornerFrom: ['top', 'left'] }),
  ],
};

export default config;
