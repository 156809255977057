import { getWindowDimensions } from 'app/helpers';

import LocationEvaluationPlanningDesignIntroduction from 'modules/evaluation-planning-design-introduction/layouts';
import LocationEvaluationPlanningDesignDrillholeDesign from 'modules/evaluation-planning-design-drillhole-design/layouts';
import LocationEvaluationPlanningDesignDrilling from 'modules/evaluation-planning-design-drilling/layouts';
import LocationEvaluationPlanningDesignResourceModel from 'modules/evaluation-planning-design-resource-model/layouts';
import LocationEvaluationPlanningDesignRockCharacterization from 'modules/evaluation-planning-design-rock-characterisation/layouts';
import LocationEvaluationPlanningDesignCaptureVisualiseDesigne from 'modules/planning-capture-visualise-design/layouts';
import LocationEvaluationPlanningDesignGradeControl from 'modules/planning-grade-control/layouts';
import LocationEvaluationPlanningDesignDynamicData from 'modules/evaluation-planning-design-insights/layouts';

import LocationDrillBlastIntroduction from 'modules/drill-blast-introduction/layouts';
import LocationDrillBlastDesign from 'modules/drill-blast-design/layouts';
import LocationDrillBlastDrilling from 'modules/drill-blast-drilling/layouts';
import LocationDrillBlastInsights from 'modules/drill-blast-insights/layouts';
import LocationDrillBlastFragmentationAnalysis from 'modules/drill-blast-fragmentation-analysis/layouts';
import LocationDrillBlastMonitoring from 'modules/drill-blast-monitoring/layouts';
import LocationDrillBlastMovement from 'modules/drill-blast-movement/layouts';
import LocationDrillBlastSafety from 'modules/drill-blast-safety/layouts';

import LocationLoadHaulSchedulingOptimising from 'modules/load-haul-scheduling-optimising/layouts';
import LocationLoadHaulFleetManagement from 'modules/load-haul-fleet-management/layouts';
import LocationLoadHaulUndergroundTaskManagement from 'modules/underground-task-management/layouts';
import LocationLoadHaulOperatorAssist from 'modules/load-haul-operator-assist/layouts';
import LocationLoadHaulAssetHealth from 'modules/load-haul-asset-health/layouts';
import LocationLoadHaulDynamicData from 'modules/load-haul-insights/layouts';
import LocationLoadHaulIntroduction from 'modules/load-haul-introduction/layouts';
import LocationLoadHaulUndergroundSafety from 'modules/underground-safety/layouts';

import LocationSafetyIntroduction from 'modules/safety-introduction/layouts';
import LocationSafetyVehicleCollisionAvoidance from 'modules/safety-vehicle-collision-avoidance/layouts';
import LocationSafetytFatigueAndDistraction from 'modules/safety-fatigue-and-distraction/layouts';
import LocationSafetyPedestrianSafety from 'modules/safety-pedestrian-safety/layouts';
import LocationSafetySlopeMonitoring from 'modules/safety-slope-monitoring/layouts';
import LocationSafetyInsights from 'modules/safety-insights/layouts';

import LocationSurveyMonitoringIntroduction from 'modules/survey-monitoring-introduction/layouts';
import LocationSurveyMonitoringRealityCapture from 'modules/survey-monitoring-reality-capture/layouts';
import LocationSurveyMonitoringPlanning from 'modules/survey-monitoring-planning/layouts';
import LocationSurveyMonitoringMonitoring from 'modules/survey-monitoring-monitoring/layouts';
import LocationSurveyMonitoringGeotech from 'modules/survey-monitoring-geotech/layouts';
import LocationSurveySurveyMonitoringInsights from 'modules/survey-monitoring-insights/layouts';
import LocationUndergroundDevelopmentDrilling from 'modules/underground-development-drilling/layouts';
import LocationUndergroundProductionDrilling from 'modules/underground-production-drilling/layouts';

import LocationInsightsIntroduction from 'modules/insights-introduction/layouts';

import LocationPlanningInsights from 'modules/planning-insights/layouts';

import config1024 from './config1024';
import config1200 from './config1200';
import config3840 from './config3840';
import configDefault from './configDefault';

const config = {
  c1024: config1024,
  c1200: config1200,
  c3840: config3840,
  cDefault: configDefault,
  get current() {
    const { width } = getWindowDimensions();

    if (width < 1200) {
      return config1024;
    }
    if (width >= 1200 && width < 1920) {
      return config1200;
    }

    if (width >= 2560) {
      return config3840;
    }

    return configDefault;
  },
  /**
   * @description {[leadLayoutKey: string]: PureComponent}
   */
  layouts: {
    'evaluation-planning-design-introduction': {
      layout: LocationEvaluationPlanningDesignIntroduction,
    },
    'drillhole-design': {
      layout: LocationEvaluationPlanningDesignDrillholeDesign,
    },
    'underground-development-drilling': {
      layout: LocationUndergroundDevelopmentDrilling,
    },
    'underground-production-drilling': {
      layout: LocationUndergroundProductionDrilling,
    },
    'exploration-drilling': {
      layout: LocationEvaluationPlanningDesignDrilling,
    },
    'resource-model': {
      layout: LocationEvaluationPlanningDesignResourceModel,
    },
    'rock-characterisation': {
      layout: LocationEvaluationPlanningDesignRockCharacterization,
    },
    'capture-visualise-design': {
      layout: LocationEvaluationPlanningDesignCaptureVisualiseDesigne,
    },
    'grade-control': {
      layout: LocationEvaluationPlanningDesignGradeControl,
    },
    // fixme: in future update this (key: a100000)
    'exploration-exploration-dynamic-data': {
      layout: LocationEvaluationPlanningDesignDynamicData,
    },
    'drill-blast-introduction': {
      layout: LocationDrillBlastIntroduction,
    },
    'blast-design': {
      layout: LocationDrillBlastDesign,
    },
    'production-drilling': {
      layout: LocationDrillBlastDrilling,
    },
    'drill-blast-safety': {
      layout: LocationDrillBlastSafety,
    },
    'blast-monitoring': {
      layout: LocationDrillBlastMonitoring,
    },
    'blast-movement': {
      layout: LocationDrillBlastMovement,
    },
    'fragmentation-analysis': {
      layout: LocationDrillBlastFragmentationAnalysis,
    },
    'drill-blast-insights': {
      layout: LocationDrillBlastInsights,
    },
    'load-haul-introduction': {
      layout: LocationLoadHaulIntroduction,
    },
    'scheduling-optimising': {
      layout: LocationLoadHaulSchedulingOptimising,
    },
    'fleet-management': {
      layout: LocationLoadHaulFleetManagement,
    },
    'underground-task-management': {
      layout: LocationLoadHaulUndergroundTaskManagement,
    },
    'operator-assist': {
      layout: LocationLoadHaulOperatorAssist,
    },
    'asset-health': {
      layout: LocationLoadHaulAssetHealth,
    },
    'underground-safety': {
      layout: LocationLoadHaulUndergroundSafety,
    },
    'load-haul-insights': {
      layout: LocationLoadHaulDynamicData,
    },
    'safety-introduction': {
      layout: LocationSafetyIntroduction,
    },
    'vehicle-collision-avoidance': {
      layout: LocationSafetyVehicleCollisionAvoidance,
    },
    'fatigue-distraction': {
      layout: LocationSafetytFatigueAndDistraction,
    },
    'pedastrian-safety': {
      layout: LocationSafetyPedestrianSafety,
    },
    'slope-monitoring': {
      layout: LocationSafetySlopeMonitoring,
    },
    'safety-insights': {
      layout: LocationSafetyInsights,
    },
    'survey-monitoring-introduction': {
      layout: LocationSurveyMonitoringIntroduction,
    },
    'reality-capture': {
      layout: LocationSurveyMonitoringRealityCapture,
    },
    // eslint-disable-next-line prettier/prettier
    'planning': {
      layout: LocationSurveyMonitoringPlanning,
    },
    // eslint-disable-next-line prettier/prettier
    'monitoring': {
      layout: LocationSurveyMonitoringMonitoring,
    },
    // eslint-disable-next-line prettier/prettier
    'geotech': {
      layout: LocationSurveyMonitoringGeotech,
    },
    // not used
    'survey-monitoring-survey-and-monitoring-dynamic-data': {
      layout: LocationSurveySurveyMonitoringInsights,
    },
    'survey-monitoring-insights': {
      layout: LocationSurveySurveyMonitoringInsights,
    },
    'insights-introduction': {
      layout: LocationInsightsIntroduction,
    },
    'planning-insights': {
      layout: LocationPlanningInsights,
    },
  },
};

Object.freeze(config);

export default config;
