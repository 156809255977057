import Box from 'modules/common/components/Box';

export default class MaterialMovementVehicleCollisionAvoidance3 {
  constructor(position, props = {}) {
    this.id = 'materialMovementVehicleCollisionAvoidance_3';
    this.component = Box;
    this.title = 'safety.Vehicle Collision Avoidance.elements.Intersection safety';
    this.video = 'md_Intersection_Safety.mp4';
    this.props = {
      target: props.target || null,
      cornerFrom: ['top', 'left'],
      position,
      hashtags: ['healthSafety', 'dataAnalytics'],
    };
  }
}
