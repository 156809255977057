import HttpService from 'core/services/http/Http';

class Api {
  // eslint-disable-next-line class-methods-use-this
  async sendFormData({ data }) {
    console.log('API::sendFormData:data', data);

    // return new Promise((res) => {
    //   setTimeout(() => res(true), 1000);
    // });

    const response = await HttpService.post(`api/v1/contact-us`, {}, data);

    return response;
  }
}

export default Object.freeze(new Api());
