// @ts-nocheck

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import HASHTAGS from 'app/configs/hashtags';
import { INTERESTS_INTRO } from 'app/constants/app';

import Checkbox from '../../Checkbox';
import Subtract from '../assets/icons/subtract.svg';
// import Round from '../assets/icons/round.svg';

import '../assets/scss/index.scss';

const HashtagList = ({ values, handleChange, toggleWithDescriptions }) => {
  const { t } = useTranslation();
  const onChange = (fieldName) => (isChecked) => {
    handleChange(fieldName, isChecked);
  };

  const openHashtagWithDescription = () => {
    toggleWithDescriptions(true);
  };

  return (
    <>
      <div className="form-describe">
        {/* <Round className="icon-round" /> */}
        {/* eslint-disable-next-line react/no-danger */}
        <div className="text-describe" dangerouslySetInnerHTML={{ __html: t(INTERESTS_INTRO) }} />
        <Subtract className="icon-button" onClick={openHashtagWithDescription} />
      </div>
      <div className="form-body two-columns">
        {Object.keys(values).map((interest) => (
          <div className="field" key={interest}>
            <Checkbox
              name={interest}
              item={HASHTAGS[interest]}
              isChecked={values[interest]}
              handleChange={onChange(interest)}
            />
          </div>
        ))}
      </div>
    </>
  );
};

HashtagList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  values: PropTypes.object,
  handleChange: PropTypes.func,
  toggleWithDescriptions: PropTypes.func,
};

HashtagList.defaultProps = {
  values: null,
  handleChange: () => {},
  toggleWithDescriptions: () => {},
};

export default HashtagList;
