/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class Button extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleClick = (e) => {
    const { onClick } = this.props;

    if (onClick) {
      onClick(e);
    }
  };

  render() {
    const { config, tag, children, href, to, additionalClasses, size, disabled } = this.props;

    const Component = tag;

    return (
      <Component
        to={to}
        className={`${config.className} ${config.className}--size-${size} ${
          (Array.isArray(additionalClasses) && additionalClasses.join(' ')) || additionalClasses
        }`}
        href={href}
        onClick={this.handleClick}
        disabled={disabled}
      >
        <div className={`${config.className}__text `}>{children}</div>
        <div className={`${config.className}__corner`} />
        <div className={`${config.className}__corner `} />
        <div className={`${config.className}__corner `} />
        <div className={`${config.className}__corner `} />
      </Component>
    );
  }
}

Button.propTypes = {
  config: PropTypes.shape({
    className: PropTypes.string,
  }),
  routePath: PropTypes.string,
  href: PropTypes.string,
  size: PropTypes.string,
  additionalClasses: PropTypes.array,
  tag: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  children: PropTypes.any,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  config: {
    className: 'c-button',
  },
  routePath: '',
  href: '',
  size: 'default',
  additionalClasses: [],
  children: 'Button Text',
  disabled: false,
  onClick: () => {},
};

Button.id = 'Button';

const mapStateToProps = (state, props) => {
  let tag = null;

  if (props.routePath) {
    tag = 'router-link';
  } else if (props.href) {
    tag = 'a';
  } else {
    tag = 'button';
  }

  const to = tag === 'router-link' ? { path: `/${props.routePath}` } : '';

  return {
    tag,
    to,
  };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(Button);
