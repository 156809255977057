import description from './description';
import DrillBlastBlastMonitoring1 from './elements/drillBlastBlastMonitoring_1';
import DrillBlastBlastMonitoring2 from './elements/drillBlastBlastMonitoring_2';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [70, 61.6], width: 8.25, rotation: [62, 0] }],
  dancingAnts: [['hs1', [33.99, 100], { direction: 'reverse' }]],
  tinyBois: [{ id: 'tb1', position: [70, 61.6], offset: [7, 2.7] }],
  elements: [
    new DrillBlastBlastMonitoring1([66.7, 22.65]),
    new DrillBlastBlastMonitoring2([82.3, 47.55]),
  ],
};

export default config;
