const description = {
  id: '2 of 5 fleet-management',
  currentNumber: 2,
  totalNumber: 5,
  code: 2,
  title: 'load-haul.Fleet Management.title',
  slug: 'fleet-management',
  pdfPath: 'FleetManagement',
  leadLayoutKey: 'fleet-management',
  apiSlug: 'fleet-management',
  productDetailsLink: 'https://hexagon.com/products/hexagon-op-pro',
  description: 'load-haul.Fleet Management.description',
  video: {
    name: 'wf_Fleet_Management.mp4',
    stop: 18.3,
    backgroundImage: 'Fleet_Management.jpg',
  },
  drawPoints: {
    hotspots: 1,
    dancingAnts: 10,
    secondaries: 10.5,
  },
};

export default description;
