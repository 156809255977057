import Box from 'modules/common/components/Box';

export default class DrillBlastBlastFragmentationAnalysis2 {
  constructor(position) {
    this.id = 'drillBlastBlastFragmentationAnalysis_2';
    this.component = Box;
    this.title = 'drill-blast.Fragmentation Analysis.title';
    this.video = 'md_Fragmentation_Analysis.mp4';
    this.props = {
      target: 'tb1',
      position,
      cornerFrom: ['bottom', 'left'],
      hashtags: ['cost', 'productivity'],
    };
  }
}
