import { createSelector } from 'reselect';

import { NAME } from 'modules/journeys-manager-journey/constants';

// info: https://redux-toolkit.js.org/api/createSelector
const getInitialState = (state) => {
  return state[NAME];
};

export const isJourneyLoading = createSelector(getInitialState, (state) => state.loading);
export const getJourney = createSelector(getInitialState, (state) => state.data);
export const getDuplicateJourney = createSelector(
  getInitialState,
  (state) => state.duplicateJourney,
);

export default {
  isJourneyLoading,
  getJourney,
  getDuplicateJourney,
};
