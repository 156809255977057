/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import { ALLOW_EVENTS_CLASS } from 'app/constants/app';

import Revert from 'app/assets/icons/revert.svg';

import eventBus from 'EventBus';

import './assets/scss/styles.scss';
import { withTranslation } from 'react-i18next';

class Index extends PureComponent {
  resetView = (e) => {
    e.preventDefault();
    eventBus.dispatch('resetView', { message: 'reset button clicked' });
  };

  render() {
    const { config, openedDeviceRotate, pathName, t } = this.props;

    return (
      <div
        className={`common__header ${config.className}__secondary ${config.visibleClass} ${
          openedDeviceRotate ? 'device-rotate__blur' : ''
        } ${ALLOW_EVENTS_CLASS}`}
      >
        <div
          className={`${config.className}__marker ${config.className}__wayfinder ${config.className}__wayfinder--left`}
        >
          <h1 className={`${config.className}__wayfinder-label`}>
            {/* <span className={`${config.className}__wayfinder-number`}>{pathId}:</span> */}
            <span className={`${config.className}__wayfinder-name`}>{t(pathName)}</span>
          </h1>
          <div className="reset-view">
            <button
              type="submit"
              className={`${config.className}__wayfinder-button  c-button`}
              onClick={this.resetView}
              tabIndex={0}
            >
              <Revert />
              <div className="c-button__text">{t('workflow.Reset View')}</div>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

Index.propTypes = {
  config: PropTypes.shape({
    className: PropTypes.string,
    visibleClass: PropTypes.string,
    disabledClass: PropTypes.string,
  }),
  openedDeviceRotate: PropTypes.bool,
  pathName: PropTypes.string,
  t: PropTypes.func,
};

Index.defaultProps = {
  config: {
    className: 'header',
    visibleClass: 'is-visible',
    disabledClass: 'is-disabled',
  },
  openedDeviceRotate: false,
  pathName: '',
  t: () => {},
};

Index.id = 'HeaderNavigation';

export default withTranslation()(connect(null, null, null, { forwardRef: true })(Index));
