class ConfigAdapter {
  constructor(config) {
    this.rawConfig = config;
    this.mappedConfig = config.reduce((accum, el) => ({ ...accum, [el.key]: el }), {});
  }

  getKey(value) {
    return this.mappedConfig[value]?.key || null;
  }

  getLocalizationKey(value) {
    return this.mappedConfig[value]?.localization || value;
  }
}

export default ConfigAdapter;
