import description from './description';
import EvaluationPlanningInsights1 from './elements/Insights1';
import EvaluationPlanningInsights2 from './elements/Insights2';
import EvaluationPlanningInsights3 from './elements/Insights3';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [47.92, 54], width: 9.125, rotation: [67, 0] }],
  dancingAnts: [
    ['hs1', [50.57, 0]],
    ['hs1', [55, 0], { direction: 'reverse' }],
    ['hs1', [100, 4.78]],
    ['hs1', [100, 14.35], { direction: 'reverse' }],
  ],
  tinyBois: [],
  elements: [
    new EvaluationPlanningInsights1([11.75, 15.25], { size: 'wide' }),
    new EvaluationPlanningInsights2([59.1, 15.37]),
    new EvaluationPlanningInsights3([67.08, 41.75]),
  ],
};

export default config;
