/* eslint-disable class-methods-use-this */
class ConfigHelper {
  constructor() {
    this.excludeJourneys = [
      'home',
      'workflows-overview',
      'workflows-overview-2d',
      'manager',
      'lead', // todo: check
    ];
    this.rawConfig = [];
    this.slagMap = new Map();
  }

  setRawConfig(rawConfig) {
    this.rawConfig = rawConfig
      .filter(({ slug }) => !this.excludeJourneys.includes(slug))
      .map((el) => ({
        ...el,
        info: {
          ...(el.info || {}),
          apiSlug: this.getApiSlug(el.info, el.slug),
        },
      })); // without Journey object link

    this.fillSlagMap();
  }

  addLocationToMap(location, parent, isEntryScreen) {
    const apiSlug = this.getApiSlug(location, parent.slug);

    const locationForMap = {
      ...location,
      apiSlug,
      isEntryScreen, // important because need to show in layout with another style
      relatedJourney: {
        slug: parent.slug,
        title: parent.title,
      },
    };

    this.slagMap.set(apiSlug, locationForMap);
  }

  getAllApiSlugs() {
    return [...this.slagMap.keys()];
  }

  getConfigForList(listOfApiSlugs) {
    return listOfApiSlugs.map((apiSlug) => this.slagMap.get(apiSlug));
  }

  getListWithoutEntryScreens(listOfApiSlugs) {
    return listOfApiSlugs.filter((apiSlug) => !this.slagMap.get(apiSlug)?.isEntryScreen);
  }

  getListForDnD(listOfApiSlugs) {
    const list = listOfApiSlugs.map((apiSlug) => this.slagMap.get(apiSlug)).filter((el) => !!el);

    const simpleLocations = list.filter(({ isEntryScreen }) => !isEntryScreen);

    const entryScreensMapByRelatedJourney = list
      .filter(({ isEntryScreen }) => isEntryScreen)
      .reduce((ac, el) => {
        // eslint-disable-next-line no-param-reassign
        ac[el.relatedJourney.slug] = el;

        return ac;
      }, {});

    return simpleLocations.map((location) => ({
      ...location,
      relatedEntryScreen: entryScreensMapByRelatedJourney[location.relatedJourney.slug],
    }));
  }

  getListOfApiSlugsFromDnDList(listOfDnd) {
    return listOfDnd.reduce((slags, el, i, all) => {
      // eslint-disable-next-line prettier/prettier
      const isBothFromSameJourney = all[i - 1] && all[i - 1].relatedJourney.slug === el.relatedJourney.slug;

      if (el.relatedEntryScreen && (i === 0 || !isBothFromSameJourney)) {
        slags.push(el.relatedEntryScreen.apiSlug);
      }

      slags.push(el.apiSlug);

      return slags;
    }, []);
  }

  getApiSlug(location, parentSlug) {
    if (!location.apiSlug) {
      // eslint-disable-next-line no-console
      console.error(`not valid location ${location.title}, slug: ${location.slug}`);
    }

    return location.apiSlug ? location.apiSlug : `${parentSlug}-${location.slug}`;
  }

  normalizeApiSlagList(listOfApiSlugs) {
    return this.getListOfApiSlugsFromDnDList(this.getListForDnD(listOfApiSlugs));
  }

  fillSlagMap() {
    this.rawConfig.forEach((parent) => {
      if (parent.info) {
        this.addLocationToMap(parent.info, parent, true);
      }

      parent.locations.forEach((location) => this.addLocationToMap(location, parent, false));
    });
  }

  combineSelectedAndExisting(oldApiSlagsList, newSelection) {
    const newSelectionMap = newSelection.reduce((acc, el) => ({ ...acc, [el]: true }), {});

    const oldButSelected = oldApiSlagsList.filter((el) => newSelectionMap[el]);

    return [...new Set([...oldButSelected, ...newSelection])];
  }
}

const objectConfigHelper = new ConfigHelper();
export default objectConfigHelper;
