import Box from 'modules/common/components/Box';

export default class DrilholDesign2 {
  constructor(position) {
    this.id = 'DrilholDesign2';
    this.component = Box;
    this.title = 'evaluationPlanningDesign.Drillhole Design.elements.Mineplan Overview';
    this.content = '';
    this.img = '';
    this.video = 'md_Mineplan_Overview.mp4';
    this.props = { target: 'tb1', position, hashtags: ['cost', 'productivity'] };
  }
}
