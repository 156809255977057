import description from './description';
import MaterialMovementVehicleCollisionAvoidance1 from './elements/materialMovementVehicleCollisionAvoidance_1';
import MaterialMovementVehicleCollisionAvoidance3 from './elements/materialMovementVehicleCollisionAvoidance_3';
import MaterialMovementVehicleCollisionAvoidance4 from './elements/materialMovementVehicleCollisionAvoidance_4';
import MaterialMovementVehicleCollisionAvoidance5 from './elements/materialMovementVehicleCollisionAvoidance_5';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [41.4, 43.75], width: 6.375 }],
  dancingAnts: [
    ['hs1', [0, 5.37], { direction: 'reverse' }],
    ['hs1', [3.85, 100]],
  ],
  tinyBois: [
    { id: 'tb1', position: [41.4, 43.75], offset: [0.4, 4.8] },
    { id: 'tb2', position: [41.4, 43.75], offset: [5.6, 4.8] },
    { id: 'tb4', position: [41.4, 43.75], offset: [3.1, 6.1] },
  ],
  elements: [
    new MaterialMovementVehicleCollisionAvoidance1([27.6, 12.9]),
    new MaterialMovementVehicleCollisionAvoidance3([51, 64.35], { target: 'tb4' }),
    new MaterialMovementVehicleCollisionAvoidance4([35.41, 64.35], {
      target: 'tb1',
      cornerFrom: ['top', 'left'],
    }),
    new MaterialMovementVehicleCollisionAvoidance5([66.67, 64.35], { target: 'tb2' }),
  ],
};

export default config;
