/* eslint-disable react/button-has-type,react/prop-types */
import React from 'react';

import LinearProgress from 'modules/common/components/LinearProgress';
import { useTranslation } from 'react-i18next';

const Progress = ({ progress = 0, handleClose }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="title-text text-center">
        {t(
          'download.Your offline version of the Life-of-Mine experience is currently being downloaded',
        )}
      </div>
      <div className="flex-center padding-top">
        <LinearProgress value={progress} />
      </div>
      <div className="text-center">
        {t('download.We’ll let you know when this has been completed')}
      </div>
      <div className="text-center">
        {t('download.In the meantime, you can continue to explore the online version')}
      </div>
      <div className="blue-text padding-top text-center">
        {t('download.Please do not close your browser window until')}
      </div>
      <div className="blue-text text-center">
        {t('download.all the files have been downloaded')}
      </div>
      <div className="c-buttons-list flex-center padding-top" style={{ justifyContent: 'center' }}>
        <button className="c-button c-button_expand" onClick={handleClose}>
          {t('common.OK')}
        </button>
      </div>
    </>
  );
};

export default Progress;
