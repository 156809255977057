/* eslint-disable react/forbid-prop-types */
/* eslint-disable dot-notation */
/* eslint-disable react/destructuring-assignment,no-useless-escape,jsx-a11y/label-has-associated-control */
import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import './assets/scss/index.scss';
import FormControl from 'core/form/FormControl';
import FormElement from 'core/form/FormElement';
import { minArrayLength, required } from 'core/form/validation';
import { Tooltip, Typography } from '@mui/material';
import ErrorField from 'modules/auth/components/ErrorField';
import LocationsList from 'modules/journeys-manager-journey/components/LocationsList';
import notification from 'core/services/Notification';
import { withTranslation } from 'react-i18next';
import InfoIcon from './assets/icons/info.svg';

class JourneyForm extends PureComponent {
  constructor(props) {
    super(props);

    // const listOfSlugs = [
    //   'evaluation-planning-design-drillhole-design',
    //   'evaluation-planning-design-resource-model',
    //   'evaluation-planning-design-evaluation-planning-design-insights',
    //   'drill-blast-introduction',
    //   'drill-blast-design',
    //   'drill-blast-safety',
    //   'drill-blast-blast-movement',
    //   'drill-blast-drilling',
    // ];

    this.form = new FormControl({
      privateName: new FormElement(undefined, [required()]),
      shareableName: new FormElement(undefined, [required()]),
      description: new FormElement(undefined, [required()]),
      locations: new FormElement([], [minArrayLength(1)]),
    });

    if (props.journey) {
      this.form.setValues(props.journey);
    }

    this.state = {
      formValue: this.form.getValues(),
      formErrors: this.form.getErrors(),
    };
  }

  componentDidMount() {
    this.form.onChange(() => {
      this.setState({
        formValue: this.form.getValues(),
        formErrors: this.form.getErrors(),
      });
    });

    this.form.setSubmitFunction(() => {
      this.submit();
    });

    this.props.onFormCreated(this.form);
  }

  // eslint-disable-next-line react/sort-comp
  submit() {
    const { t } = this.props;
    if (!this.form.isFormValid()) {
      // todo: use notification library
      // eslint-disable-next-line no-alert
      notification.error(t('journeys-manager.fill all mandatory fields'));
      return;
    }

    const data = this.form.getValues();

    this.props.onSubmit(data);
  }

  // eslint-disable-next-line class-methods-use-this, class-methods-use-this
  getTooltipContent(field) {
    const { t } = this.props;
    switch (field) {
      case 'shareableName': {
        return (
          <>
            <Typography>{t('journeys-manager.Guide for name')}:</Typography>
            <p>
              {t('journeys-manager.This name will be visible')}
              {t('journeys-manager.Name should be')}
            </p>
            <div>
              {t('journeys-manager.It will be visible')}
              <ul>
                <li>{t('journeys-manager.in the list of Journeys')}</li>
                <li>{t('journeys-manager.on each screen of the journey')}</li>
              </ul>
            </div>
          </>
        );
      }
      case 'description': {
        return (
          <>
            <Typography>{t('journeys-manager.Guide for description')}:</Typography>
            <p>
              {t('journeys-manager.This name will be visible for all')}
              {t('journeys-manager.Description should be')}
            </p>
            <div>
              {t('journeys-manager.It will be visible')}
              <ul>
                <li>{t('journeys-manager.on the journey page')},</li>
                <li>{t('journeys-manager.on intro screen of the journey')}</li>
              </ul>
            </div>
          </>
        );
      }
      default: {
        return <></>;
      }
    }
  }

  render() {
    const { formValue, formErrors } = this.state;
    const { allLomixConfigs, children, t } = this.props;

    return (
      <div className="journey-form">
        <form className="form-body" onSubmit={(e) => this.form.submitForm(e)}>
          <div className="main-info">
            <div className="main-info_data">
              <div className="field field--first">
                <label className="input-label" htmlFor="privateName">
                  <span
                    className={formErrors['privateName'] ? 'input-label__text--with-error' : null}
                  >
                    {t('journeys-manager.form.Private name')}
                  </span>
                </label>
                <div
                  className={`
                    text-input-wrapper
                    ${formErrors['privateName'] ? 'text-input-wrapper--with-error' : ''}
                  `}
                >
                  <textarea
                    onChange={(e) => this.form.getFormElement('privateName').onChangeEvent(e)}
                    onBlur={(e) => this.form.getFormElement('privateName').onBlurEvent(e)}
                    value={formValue['privateName']}
                    id="privateName"
                    className="text-input"
                  />
                </div>
                {formErrors['privateName']?.required && (
                  <ErrorField error={{ text: t('journeys-manager.form.The fields is required') }} />
                )}
              </div>
              <div className="field">
                <div className="input-label-wrapper">
                  <label
                    htmlFor="shareableName"
                    className={`input-label ${
                      formErrors['shareableName'] ? 'input-label__text--with-error' : null
                    }`}
                  >
                    {t('journeys-manager.form.Shareable name')}
                  </label>
                  <Tooltip
                    classes={{ popper: 'tooltip-white' }}
                    title={this.getTooltipContent('shareableName')}
                    enterTouchDelay={10}
                    leaveTouchDelay={10000}
                    placement="top-end"
                    arrow
                  >
                    <span className="input-label__info">
                      <InfoIcon />
                    </span>
                  </Tooltip>
                </div>
                <div
                  className={`
                    text-input-wrapper
                    ${formErrors['shareableName'] ? 'text-input-wrapper--with-error' : ''}
                  `}
                >
                  <textarea
                    onChange={(e) => this.form.getFormElement('shareableName').onChangeEvent(e)}
                    onBlur={(e) => this.form.getFormElement('shareableName').onBlurEvent(e)}
                    value={formValue['shareableName']}
                    id="shareableName"
                    className="text-input"
                  />
                </div>
                {formErrors['shareableName']?.required && (
                  <ErrorField error={{ text: t('journeys-manager.form.The fields is required') }} />
                )}
              </div>
              <div className="field">
                <div className="input-label-wrapper">
                  <label
                    htmlFor="description"
                    className={`input-label ${
                      formErrors['description'] ? 'input-label__text--with-error' : null
                    }`}
                  >
                    {t('journeys-manager.form.Description')}
                  </label>
                  <Tooltip
                    classes={{ popper: 'tooltip-white' }}
                    title={this.getTooltipContent('description')}
                    enterTouchDelay={10}
                    leaveTouchDelay={10000}
                    placement="top-end"
                    arrow
                  >
                    <span className="input-label__info">
                      <InfoIcon />
                    </span>
                  </Tooltip>
                </div>
                <div
                  className={`
                    text-input-wrapper
                    ${formErrors['description'] ? 'text-input-wrapper--with-error' : ''}
                  `}
                >
                  <textarea
                    onChange={(e) => this.form.getFormElement('description').onChangeEvent(e)}
                    onBlur={(e) => this.form.getFormElement('description').onBlurEvent(e)}
                    value={formValue['description']}
                    id="description"
                    className="text-input"
                  />
                </div>
                {formErrors['description']?.required && (
                  <ErrorField error={{ text: t('journeys-manager.form.The fields is required') }} />
                )}
              </div>
            </div>
            <div className="main-info_action">{children}</div>
          </div>

          <div className="locations">
            <LocationsList
              formElement={this.form.getFormElement('locations')}
              allLomixConfigs={allLomixConfigs}
            />
          </div>

          {/* <div className="demo">Demo block</div> */}

          <button type="submit" style={{ display: 'none' }}>
            {t('common.Submit')}
          </button>
        </form>
      </div>
    );
  }
}

JourneyForm.propTypes = {
  children: PropTypes.any,
  allLomixConfigs: PropTypes.arrayOf(PropTypes.any),
  journey: PropTypes.shape({
    privateName: PropTypes.string,
    shareableName: PropTypes.string,
    description: PropTypes.string,
    locations: PropTypes.arrayOf(PropTypes.string),
  }),
  onFormCreated: PropTypes.func,
  onSubmit: PropTypes.func,
  t: PropTypes.func,
};

JourneyForm.defaultProps = {
  children: undefined,
  allLomixConfigs: [],
  journey: null,
  onFormCreated: () => {},
  onSubmit: () => {},
  t: () => {},
};

export default withTranslation()(connect(null, null, null, { forwardRef: true })(JourneyForm));
