import Introduction from 'modules/evaluation-planning-design-introduction/configs/config3840';
import DrillholeDesign from 'modules/evaluation-planning-design-drillhole-design/configs/config3840';
import Drilling from 'modules/evaluation-planning-design-drilling/configs/config3840';
import ResourceModel from 'modules/evaluation-planning-design-resource-model/configs/config3840';
import RockCharacterization from 'modules/evaluation-planning-design-rock-characterisation/configs/config3840';
import CaptureVisualiseDesign from 'modules/planning-capture-visualise-design/configs/config3840';
import DrillBlastDesign from 'modules/drill-blast-design/configs/config3840';
import PlanningGradeControl from 'modules/planning-grade-control/configs/config3840';
import DrillBlastMovement from 'modules/drill-blast-movement/configs/config3840';
import PlanningInsights from 'modules/planning-insights/configs/config3840';
import { modifyApiSlug } from 'core/utils';

const config = {
  id: 1,
  title: 'EvaluationPlanningDesign',
  slug: 'evaluation-planning-design',
  pdfPath: 'EvaluationPlanning&Design',
  hasMainNav: true,
  isInPaths: true,
  video: {
    backgroundImage: 'Drillhole_Design.jpg',
  },
  info: Introduction,
  locations: [
    DrillholeDesign,
    Drilling,
    ResourceModel,
    RockCharacterization,
    CaptureVisualiseDesign,
    DrillBlastDesign,
    PlanningGradeControl,
    DrillBlastMovement,
    PlanningInsights,
  ].map((el) => modifyApiSlug(el, 'evaluation-planning-design')),
};

export default config;
