import description from './description';
import PlanningProductionDrilling1 from './elements/planningProductionDrilling_1';
import PlanningProductionDrilling2 from './elements/planningProductionDrilling_2';
import PlanningProductionDrilling3 from './elements/planningProductionDrilling_3';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [15, 58.44], width: 18.25, rotation: [58, 0] }],
  dancingAnts: [
    ['hs1', [0, 66.89], { direction: 'reverse' }],
    ['hs1', [51.35, 100]],
  ],
  tinyBois: [
    { id: 'tb1', position: [15, 58.44], offset: [14.7, 5] },
    { id: 'tb2', position: [15, 58.44], offset: [8.6, 13.3] },
  ],
  elements: [
    new PlanningProductionDrilling1([12, 19.9]),
    new PlanningProductionDrilling2([27.6, 52.5]),
    new PlanningProductionDrilling3([12, 79.1]),
  ],
};

export default config;
