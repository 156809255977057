/* eslint-disable react/forbid-prop-types */
// @ts-nocheck
/* eslint-disable react/no-unused-prop-types */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import LocationSVG from 'app/assets/icons/location.svg';

import Button from 'modules/common/components/Button';
import Box from 'modules/common/components/Box';

import { isNumeric } from 'core/utils';
import { withTranslation } from 'react-i18next';

class StageCard extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};

    this.stageCard = React.createRef();
  }

  getTimeline() {
    return this.stageCard.current.getTimeline();
  }

  handleClick = () => {
    const { onEvent } = this.props;
    onEvent({ action: 'resumeFromCard' });
  };

  init = () => {
    return this.stageCard.current.init();
  };

  cancelContinue = () => {
    const { onEvent } = this.props;
    onEvent({ action: 'cancelResumeFromCard' });
  };

  render() {
    const { config, title, count, children, showCancel, t } = this.props;

    return (
      <Box
        ref={this.stageCard}
        additionalClasses={[config.className]}
        contentAdditionalClasses={[`${config.className}__content`]}
        hideOuterLine
      >
        <div className="stage-card__title">
          <div className="stage-card__title-block">
            <div>
              <div className="stage-card__title-name stage-card__title_margin">{t(title)}</div>
            </div>
            <div className="stage-card__title-locations">
              {isNumeric(count) && (
                <div className="stage-card__title-locations-number stage-card__title_margin">
                  <LocationSVG />
                  <span className="stage-card__title-locations-number_margin">{count}</span>
                </div>
              )}
            </div>
          </div>
          <div className="stage-card__title-border" />
        </div>

        {children || (
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam malesuada ipsum at nisl
            suscipit, id dictum sapien blandit.
          </p>
        )}

        <div className="stage-card__buttons c-button-group stage-card__buttons_margin">
          <Button onClick={this.handleClick}> {t('common.Enter')} </Button>

          {showCancel && (
            <div
              role="button"
              className="stage-card__button-cancel"
              onClick={this.cancelContinue}
              onKeyDown={this.cancelContinue}
              tabIndex={0}
            >
              {t('common.Cancel')}
            </div>
          )}
        </div>
      </Box>
    );
  }
}

StageCard.propTypes = {
  config: PropTypes.shape({
    className: PropTypes.string,
  }),
  handleJourneyEnter: PropTypes.func,
  title: PropTypes.string,
  count: PropTypes.number,
  showCancel: PropTypes.bool,
  children: PropTypes.any,
  onEvent: PropTypes.func,
  t: PropTypes.func,
};

StageCard.defaultProps = {
  config: {
    className: 'stage-card',
  },
  handleJourneyEnter: () => {},
  title: 'Title',
  count: null,
  showCancel: true,
  children: '',
  onEvent: () => {},
  t: () => {},
};

StageCard.id = 'StageCard';

const mapStateToProps = () => {
  return {};
};

export default withTranslation(null, { withRef: true })(
  connect(mapStateToProps, null, null, { forwardRef: true })(StageCard),
);
