import description from './description';
import DrillBlastDrilling1 from './elements/drillBlastDrilling_1';
import DrillBlastDrilling2 from './elements/drillBlastDrilling_2';
import DrillBlastDrilling3 from './elements/drillBlastDrilling_3';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [13.5, 56.25], width: 6.876, rotation: [54, 0] }],
  dancingAnts: [
    ['hs1', [0, 18.35], { direction: 'reverse' }],
    ['hs1', [0, 81.93]],
  ],
  tinyBois: [
    { id: 'tb1', position: [13.5, 56.25], offset: [5, 1.5] },
    { id: 'tb2', position: [13.5, 56.25], offset: [6.7, 3.2] },
  ],
  elements: [
    new DrillBlastDrilling1([12.4, 17]),
    new DrillBlastDrilling2([35.8, 40.1]),
    new DrillBlastDrilling3([35.8, 53.64]),
  ],
};

export default config;
