/* eslint-disable react/destructuring-assignment,no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable array-callback-return */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-destructuring */
/* eslint-disable consistent-return */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/button-has-type */
// @ts-nocheck
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formatDate } from 'core/services/date';
import LockIcon from 'app/assets/icons/lock.svg';
import ShareNewIcon from 'app/assets/icons/share_new.svg';
import LocationSmallIcon from 'app/assets/icons/location_small.svg';
import ArrowShortNextIcon from 'app/assets/icons/arrow_short_next.svg';
import { withTranslation } from 'react-i18next';
import PlayIcon from './assets/icons/play.svg';
import CloudOffIcon from './assets/icons/cloud_off.svg';

import './assets/scss/index.scss';

// noinspection JSUnresolvedVariable
class JourneyListItem extends PureComponent {
  // eslint-disable-next-line react/sort-comp
  goToJourney = () => {
    const { onEvent, journey } = this.props;
    onEvent({ action: 'goToJourney', data: journey }); // use this
  };

  runJourney = () => {
    const { onEvent, journey } = this.props;
    onEvent({ action: 'runJourney', data: journey }); // use this
  };

  getIcon(journey) {
    if (journey.savedLocally) {
      return <CloudOffIcon />;
    }

    if (journey.shared) {
      return <ShareNewIcon />;
    }

    return <LockIcon />;
  }

  render() {
    const { journey, locationsWithoutEntryScreens, t } = this.props;

    const name = journey.own ? journey.privateName : journey.shareableName;

    const date = formatDate(journey.createdAt * 1000);

    return (
      <>
        <div className="journey-list-item">
          <div className="journey-list-item__name">
            <span className="journey-list-item__name-icon">{this.getIcon(journey)}</span>
            <span className="journey-list-item__name-text">{t(name)}</span>
          </div>

          <div className="journey-list-item__location">
            <LocationSmallIcon />
            <span>{locationsWithoutEntryScreens.length}</span>
          </div>
          <div className="journey-list-item__date">{date}</div>
          <button
            className="journey-list-item__play-journey"
            onClick={() => this.runJourney()}
            aria-labelledby="Run Journey"
          >
            <PlayIcon />
          </button>
          <button
            className="journey-list-item__select"
            onClick={() => this.goToJourney()}
            aria-labelledby="Go To Journey"
          >
            <ArrowShortNextIcon />
          </button>
        </div>
      </>
    );
  }
}

JourneyListItem.propTypes = {
  journey: PropTypes.shape({
    id: PropTypes.number,
    privateName: PropTypes.oneOfType([PropTypes.string, undefined]),
    shareableName: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
    shared: PropTypes.bool,
    own: PropTypes.bool,
    savedLocally: PropTypes.bool,
    locations: PropTypes.array,
    createdAt: PropTypes.number,
  }),
  t: PropTypes.func,
  onEvent: PropTypes.func,
  locationsWithoutEntryScreens: PropTypes.arrayOf(PropTypes.string),
};

JourneyListItem.defaultProps = {
  journey: {},
  onEvent: () => {},
  t: () => {},
  locationsWithoutEntryScreens: [],
};

export default withTranslation()(connect(null, null, null, { forwardRef: true })(JourneyListItem));
