import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { Link } from 'react-router-dom';

import './assets/scss/index.scss';

class Header extends PureComponent {
  render() {
    return (
      <div className="container-header">
        <nav>
          <Link to="/journeys">Home</Link>
          <Link to="/journeys/1">Other</Link>
        </nav>
      </div>
    );
  }
}

Header.propTypes = {};

Header.defaultProps = {};

export default connect()(Header);
