import description from './description';
import DrillBlastBlastFragmentationAnalysis1 from './elements/drillBlastBlastFragmentationAnalysis_1';
import DrillBlastBlastFragmentationAnalysis2 from './elements/drillBlastBlastFragmentationAnalysis_2';
import DrillBlastBlastFragmentationAnalysis3 from './elements/drillBlastBlastFragmentationAnalysis_3';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [58, 73.5], width: 15.625, rotation: [62, 0] }],
  dancingAnts: [['hs1', [0, 67.38], { direction: 'reverse' }]],
  tinyBois: [
    { id: 'tb1', position: [58, 73.5], offset: [12, 4] },
    { id: 'tb2', position: [58, 73.5], offset: [12, 10] },
  ],
  elements: [
    new DrillBlastBlastFragmentationAnalysis1([66.7, 26.1], { cornerFrom: ['bottom', 'left'] }),
    new DrillBlastBlastFragmentationAnalysis2([75.2, 57.8]),
    new DrillBlastBlastFragmentationAnalysis3([82.3, 75.2]),
  ],
};

export default config;
