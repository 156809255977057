import Box from 'modules/common/components/Box';

export default class MaterialMovementFatigueAndDistraction3 {
  constructor(position, props = {}) {
    this.id = 'materialMovementFatigueAndDistraction_3';
    this.component = Box;
    this.type = 'content';
    this.title = 'safety.Fatigue and Distraction.elements.State-of-the-art edge computing';
    this.content =
      'safety.Fatigue and Distraction.elements.State-of-the-art edge computing Content';
    this.props = {
      target: props.target || null,
      cornerFrom: props.cornerFrom || null,
      position,
      hashtags: ['healthSafety'],
    };
  }
}
