/* eslint-disable no-shadow */
/* eslint-disable no-case-declarations */
import {
  STATUS,
  APP_INIT,
  APP_PATHS_TOGGLE,
  APP_IS_STAGE_ANIMATING_TOGGLE,
  APP_MODAL_CONTENT_SET,
  APP_MODAL_OPEN_SET,
  APP_EMAIL_SEND,
  APP_TABLET_ORIENTATION_SHOW,
  APP_PRIVACY_POLICY_ACCEPT,
  SET_LOMIX_CONFIG,
  SET_PWA_PROGRESS,
  SET_PWA_LOADED,
  SET_PWA_ERROR,
  SET_JOURNEY,
  SET_LOCATION,
  SET_NEXT_LOCATION,
  SET_NEXT_JOURNEY,
  SET_PREV_LOCATION,
  SET_DIRECTION,
  SET_CAN_NAVIGATE,
  SET_TO_JOURNEY,
  SET_REPLACE_JOURNEY,
  SET_LOGGED_USER,
  SET_IS_USER_LOGGED_IN,
  SET_TO_LOG_IN,
  SET_AUDIO_MUTED,
  SET_SHOW_AUTH_MODAL,
  SET_LOCATION_NAME,
  SET_DEFERRED_CLICK_ELEMENT,
  SET_HIDE_HASHTAGS,
  APP_WORKFLOWS_OVERVIEW_2D_VISIT,
  APP_INFO_3D_SHOW,
  APP_SITEMAP_OPEN_SET,
  APP_PWA_CONFIRM_DELETE_SHOW,
  APP_PWA_CONFIRM_DELETE_PROGRESS_SET,
  APP_PWA_MODAL_SHOW,
  APP_JOURNEY_TO_ASSOCIATE_SET,
  APP_WORKFLOWS_OVERVIEW_VISIT,
} from 'app/constants';

import { SUCCESS, ERROR } from 'core/constants/statuses';

import configAPP from 'app/configs';

import { getFromLS } from 'core/services/localStorage';

const pwaLoaded = getFromLS('pwa') === 'pwa' || configAPP.enableExhibition;

const initialState = {
  ready: true,
  arePathsOpen: false,
  isStageAnimating: false,
  tablet: {
    wasShowedPortraitOrientation: false,
  },
  acceptedPrivacyPolicy: false,
  showInfo3D: false,
  error3D: false,
  modal: {
    isOpen: false,
    content: null,
  },
  sitemap: {
    isOpen: false,
  },
  email: {
    data: null,
    status: null,
    error: null,
    receivedAt: null,
    updatedAt: null,
  },
  status: null,
  error: null,
  receivedAt: null,
  updatedAt: null,
  lomixConfig: null,
  pwaModalOpened: null,
  pwaProgress: pwaLoaded ? 100 : 0,
  pwaLoaded,
  pwaError: false,
  pwaNumberOpenedWindow: 0,
  pwa: {
    confirmDelete: {
      opened: false,
      progress: 0,
      status: null,
    },
  },
  app: {
    journey: null,
    nextJourney: null,
    location: null,
    locationName: null,
    nextLocation: null,
    prevLocation: null,
    audio: {
      muted: false,
    },
  },
  navigation: {
    direction: null,
    canNavigate: false,
    toJourney: { path: null, replace: false },
  },
  auth: configAPP.enableExhibition
    ? {
        isUserLoggedIn: true,
        showAuthModal: true,
        userToLogIn: false,
        loggedUser: {
          email: 'info.lomix@hexagon.com',
          name: 'Lomix',
          company: 'Lomix',
          interest: {
            cost: true,
            productivity: true,
            digitalisation: true,
            dataAnalytics: true,
            integration: true,
            autonomy: true,
            healthSafety: true,
            esg: true,
          },
          idTokenClaims: configAPP.enableJourneysForExhibition
            ? {
                extension_access: 'journeyManagement',
                name: 'Lomix',
                extension_Company: 'Lomix',
                extension_Level: 'manager',
                extension_Interests:
                  'cost,productivity,digitalisation,dataAnalytics,integration,autonomy,healthSafety,esg',
                extension_gdpr: 'gdpr',
                emails: ['info.lomix@hexagon.com'],
              }
            : null,
        },
      }
    : {
        isUserLoggedIn: getFromLS('isUserLoggedIn'),
        userToLogIn: false,
        loggedUser: getFromLS('loggedUser'),
      },
  deferredClickElement: false,
  hideHashtags: false,
  visitedWorkflowsOverview: 0,
  visitedWorkflowsOverview2D: 0,
  journeyToAssociate: getFromLS('journeyToAssociate'),
};

const app = (state = initialState, action) => {
  switch (action.type) {
    case `${APP_INIT}/${STATUS}/${SUCCESS}`:
      return {
        ...state,
        tablet: {
          ...action.payload.tablet,
        },
        acceptedPrivacyPolicy: action.payload.acceptedPrivacyPolicy,
        visitedWorkflowsOverview: action.payload.visitedWorkflowsOverview,
        visitedWorkflowsOverview2D: action.payload.visitedWorkflowsOverview2D,
        pwaLoaded: action.payload.pwaLoaded,
        status: SUCCESS,
      };
    case `${APP_PATHS_TOGGLE}/${STATUS}/${SUCCESS}`:
      return {
        ...state,
        arePathsOpen: action.payload.isOpen === null ? !state.arePathsOpen : action.payload.isOpen,
        status: SUCCESS,
      };
    case `${APP_IS_STAGE_ANIMATING_TOGGLE}/${STATUS}/${SUCCESS}`:
      return {
        ...state,
        isStageAnimating:
          action.payload.isStageAnimating === null
            ? !state.isStageAnimating
            : action.payload.isStageAnimating,
        status: SUCCESS,
      };
    case `${APP_MODAL_CONTENT_SET}/${STATUS}/${SUCCESS}`:
      return {
        ...state,
        modal: {
          ...state.modal,
          content: action.payload.content,
        },
        status: SUCCESS,
      };
    case `${APP_MODAL_OPEN_SET}/${STATUS}/${SUCCESS}`:
      return {
        ...state,
        modal: {
          ...state.modal,
          isOpen: action.payload.isOpen,
        },
        status: SUCCESS,
      };
    case `${APP_SITEMAP_OPEN_SET}/${STATUS}/${SUCCESS}`:
      return {
        ...state,
        sitemap: {
          ...state.sitemap,
          isOpen: action.payload.isOpen,
        },
        status: SUCCESS,
      };
    case `${APP_EMAIL_SEND}/${STATUS}/${ERROR}`:
      return {
        ...state,
        email: {
          data: null,
          status: ERROR,
          error: null,
          receivedAt: null,
          updatedAt: null,
        },
        status: SUCCESS,
      };
    case `${APP_EMAIL_SEND}/${STATUS}/${SUCCESS}`:
      return {
        ...state,
        email: {
          data: null,
          status: SUCCESS,
          error: null,
          receivedAt: null,
          updatedAt: null,
        },
        status: SUCCESS,
      };
    case `${APP_TABLET_ORIENTATION_SHOW}/${STATUS}/${SUCCESS}`:
      return {
        ...state,
        tablet: {
          ...state.tablet,
          wasShowedPortraitOrientation: action.payload.wasShowedPortraitOrientation,
        },
        status: SUCCESS,
      };
    case `${APP_PRIVACY_POLICY_ACCEPT}/${STATUS}/${SUCCESS}`:
      return {
        ...state,
        acceptedPrivacyPolicy: action.payload.acceptedPrivacyPolicy,
        status: SUCCESS,
      };
    case `${APP_WORKFLOWS_OVERVIEW_VISIT}/${STATUS}/${SUCCESS}`:
      return {
        ...state,
        visitedWorkflowsOverview: action.payload.visitedWorkflowsOverview,
        status: SUCCESS,
      };
    case `${APP_WORKFLOWS_OVERVIEW_2D_VISIT}/${STATUS}/${SUCCESS}`:
      return {
        ...state,
        visitedWorkflowsOverview2D: action.payload.visitedWorkflowsOverview2D,
        status: SUCCESS,
      };
    case `${SET_LOMIX_CONFIG}`:
      return {
        ...state,
        lomixConfig: action.payload,
      };
    case `${SET_PWA_PROGRESS}`:
      return {
        ...state,
        pwaProgress: action.payload.value,
      };
    case `${SET_PWA_LOADED}`: {
      const newState = {
        ...state,
        pwaLoaded: action.payload,
        pwaNumberOpenedWindow: 0,
        pwa: {
          ...state.pwa,
          confirmDelete: {
            ...state.pwa.confirmDelete,
          },
        },
      };

      if (newState.pwaLoaded) {
        newState.pwaProgress = 100;
        newState.pwa.confirmDelete.opened = false;
        newState.pwa.confirmDelete.progress = 0;
        newState.pwa.confirmDelete.status = null;
      } else {
        newState.pwaProgress = 0;
        newState.pwa.confirmDelete.progress = 100;
        newState.pwa.confirmDelete.status = SUCCESS;
      }

      return newState;
    }
    case `${SET_PWA_ERROR}`: {
      const newState = {
        ...state,
        pwaError: action.payload,
        pwaNumberOpenedWindow: 0,
      };

      if (action.payload && newState.pwa.confirmDelete.opened) {
        newState.pwa.confirmDelete.status = ERROR;
      }

      return newState;
    }
    case `${SET_JOURNEY}`:
      return {
        ...state,
        app: { ...state.app, journey: action.payload },
      };

    case `${SET_NEXT_JOURNEY}`:
      return {
        ...state,
        app: { ...state.app, nextJourney: action.payload },
      };

    case `${SET_LOCATION}`:
      return {
        ...state,
        app: { ...state.app, location: action.payload },
      };
    case `${SET_LOCATION_NAME}`:
      return {
        ...state,
        app: { ...state.app, locationName: action.payload },
      };
    case `${SET_NEXT_LOCATION}`:
      return {
        ...state,
        app: { ...state.app, nextLocation: action.payload },
      };
    case `${SET_PREV_LOCATION}`:
      return {
        ...state,
        app: { ...state.app, prevLocation: action.payload },
      };
    case `${SET_DIRECTION}`:
      return {
        ...state,
        navigation: { ...state.navigation, direction: action.payload },
      };

    case `${SET_CAN_NAVIGATE}`:
      return {
        ...state,
        navigation: { ...state.navigation, canNavigate: action.payload },
      };
    case `${SET_TO_JOURNEY}`:
      return {
        ...state,
        navigation: {
          ...state.navigation,
          toJourney: { ...state.navigation.toJourney, path: action.payload },
        },
      };
    case `${SET_REPLACE_JOURNEY}`:
      return {
        ...state,
        navigation: {
          ...state.navigation,
          toJourney: { ...state.navigation.toJourney, replace: action.payload },
        },
      };
    case `${SET_TO_LOG_IN}`:
      return {
        ...state,
        auth: { ...state.auth, userToLogIn: action.payload },
      };
    case `${SET_IS_USER_LOGGED_IN}`:
      return {
        ...state,
        auth: { ...state.auth, isUserLoggedIn: action.payload },
      };
    case `${SET_LOGGED_USER}`:
      return {
        ...state,
        auth: { ...state.auth, loggedUser: action.payload },
      };
    case `${SET_SHOW_AUTH_MODAL}`:
      return {
        ...state,
        auth: { ...state.auth, showAuthModal: action.payload },
      };
    case `${SET_AUDIO_MUTED}`:
      return {
        ...state,
        app: {
          ...state.app,
          audio: {
            ...state.app.audio,
            muted: action.payload.muted,
          },
        },
      };
    case `${SET_DEFERRED_CLICK_ELEMENT}`:
      return {
        ...state,
        deferredClickElement: action.payload,
      };
    case `${SET_HIDE_HASHTAGS}`:
      return {
        ...state,
        hideHashtags: action.payload,
      };
    case `${APP_INFO_3D_SHOW}/${STATUS}/${SUCCESS}`: {
      const newState = {
        ...state,
        showInfo3D: action.payload.show,
        status: SUCCESS,
      };

      if (
        Object.prototype.hasOwnProperty.call(action.payload, 'error3D') &&
        (action.payload.error3D === true || action.payload.error3D === false)
      ) {
        newState.error3D = action.payload.error3D;
      }

      return newState;
    }
    case `${APP_PWA_MODAL_SHOW}/${STATUS}/${SUCCESS}`: {
      const newState = {
        ...state,
      };

      newState.pwaModalOpened = action.payload.show;

      if (newState.pwaLoaded && newState.pwaModalOpened) {
        newState.pwaNumberOpenedWindow += 1;
      }

      return newState;
    }
    case `${APP_PWA_CONFIRM_DELETE_SHOW}/${STATUS}/${SUCCESS}`: {
      const newState = {
        ...state,
      };

      newState.pwa.confirmDelete = {
        ...newState.pwa.confirmDelete,
        opened: action.payload.show,
        status: SUCCESS,
      };

      return newState;
    }
    case `${APP_PWA_CONFIRM_DELETE_PROGRESS_SET}/${STATUS}/${SUCCESS}`: {
      const newState = {
        ...state,
      };

      newState.pwa.confirmDelete = {
        ...newState.pwa.confirmDelete,
        progress: action.payload.value,
        status: SUCCESS,
      };

      return newState;
    }
    case `${APP_PWA_CONFIRM_DELETE_PROGRESS_SET}/${STATUS}/${ERROR}`: {
      const newState = {
        ...state,
      };

      newState.pwa.confirmDelete = {
        ...newState.pwa.confirmDelete,
        status: ERROR,
      };

      return newState;
    }
    case `${APP_JOURNEY_TO_ASSOCIATE_SET}/${STATUS}/${SUCCESS}`:
      return {
        ...state,
        journeyToAssociate: action.payload?.id,
      };
    default: {
      return state;
    }
  }
};

export default app;
