import description from './description';
import DrillBlastSafety1 from './elements/drillBlastSafety_1';
import DrillBlastSafety2 from './elements/drillBlastSafety_2';
import DrillBlastSafety3 from './elements/drillBlastSafety_3';
import DrillBlastSafety4 from './elements/drillBlastSafety_4';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [39.47, 79], width: 6.4375, rotation: [62, 0] }],
  dancingAnts: [
    ['hs1', [0, 91.69], { direction: 'reverse' }],
    ['hs1', [49.53, 100], { direction: 'reverse' }],
  ],
  tinyBois: [
    { id: 'tb1', position: [39.47, 79], offset: [-0.2, 3] },
    { id: 'tb2', position: [39.47, 79], offset: [6, 3] },
    { id: 'tb3', position: [39.47, 79], offset: [4.2, 1.7] },
  ],
  elements: [
    new DrillBlastSafety1([27.6, 26.4]),
    new DrillBlastSafety2([12, 59.4]),
    new DrillBlastSafety3([66.7, 30.76]),
    new DrillBlastSafety4([51, 30.76]),
  ],
};

export default config;
