import Box from 'modules/common/components/Box';

export default class PlanningInitial3 {
  constructor(position) {
    this.id = 'planningInitial_3';
    this.component = Box;
    this.title = 'planning.Capture Visualise Design.elements.Reality Capture';
    this.content = '';
    this.img = '';
    this.video = 'md_Reality_Capture.mp4';
    this.props = { target: 'tb2', position, hashtags: ['digitalisation'] };
  }
}
