import Box from 'modules/common/components/Box';

export default class DrillBlastSafety3 {
  constructor(position) {
    this.id = 'drillBlastSafety_3';
    this.component = Box;
    this.title = 'drill-blast.Safety.elements.Vehicle Safety';
    this.video = '3c-2.mp4';
    this.props = {
      target: 'tb2',
      position,
      cornerFrom: ['bottom', 'left'],
      hashtags: ['healthSafety', 'productivity'],
    };
  }
}
