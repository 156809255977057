/* eslint-disable no-case-declarations */
import {
  JOURNEYS_MANAGER_INIT,
  JOURNEYS_MANAGER_DESTROY,
  JOURNEYS_MANAGER_LIST_PAGINATION_SET,
  JOURNEYS_MANAGER_LIST_FILTER_SET,
  JOURNEYS_MANAGER_LIST_SORT_SET,
  JOURNEYS_MANAGER_LIST_GET,
} from 'modules/journeys-manager-introduction/constants';

import { STATUS, SUCCESS } from 'core/constants/statuses';

const initialState = {
  ready: false,

  listLoading: false,
  list: null,

  sort: null,
  filter: {},
  pagination: {},
};

const app = (state = initialState, action) => {
  switch (action.type) {
    case `${JOURNEYS_MANAGER_INIT}/${STATUS}/${SUCCESS}`:
      return {
        ...state,
        ready: true,
        status: SUCCESS,
      };
    case `${JOURNEYS_MANAGER_DESTROY}/${STATUS}/${SUCCESS}`:
      return {
        ...state,
        ready: false,
        status: SUCCESS,
      };
    case `${JOURNEYS_MANAGER_LIST_PAGINATION_SET}/${STATUS}/${SUCCESS}`:
      return {
        ...state,
        pagination: action.payload.pagination,
      };
    case `${JOURNEYS_MANAGER_LIST_FILTER_SET}/${STATUS}/${SUCCESS}`:
      return {
        ...state,
        filter: action.payload.filter,
      };
    case `${JOURNEYS_MANAGER_LIST_SORT_SET}/${STATUS}/${SUCCESS}`:
      return {
        ...state,
        sort: action.payload.sort,
      };
    case JOURNEYS_MANAGER_LIST_GET:
      return {
        ...state,
        listLoading: true,
      };
    case `${JOURNEYS_MANAGER_LIST_GET}/${STATUS}/${SUCCESS}`:
      return {
        ...state,
        listLoading: false,
        list: action.payload.list,
      };
    default: {
      return state;
    }
  }
};

export default app;
