import Box from 'modules/common/components/Box';

export default class SurveyMonitoringRealityCapture3 {
  constructor(position, props = {}) {
    this.id = 'surveyMonitoringRealityCapture_3';
    this.component = Box;
    this.type = 'content';
    this.title = 'survey-monitoring.Reality Capture.elements.3D Reality Capture';
    this.content = 'survey-monitoring.Reality Capture.elements.3D Reality Capture Content';
    this.props = {
      target: 'tb2',
      position,
      hashtags: ['digitalisation', 'productivity'],
      cornerFrom: props.cornerFrom || null,
    };
  }
}
