import Box from 'modules/common/components/Box';

export default class MaterialMovementFatigueAndDistraction2 {
  constructor(position) {
    this.id = 'materialMovementFatigueAndDistraction_2';
    this.component = Box;
    this.title = 'safety.Fatigue and Distraction.elements.Combat fatigue and distraction';
    this.video = 'md_Combat_Fatigue_And_Distraction.mp4';
    this.props = {
      target: 'tb4',
      position,
      hashtags: ['healthSafety'],
    };
  }
}
