import Box from 'modules/common/components/Box';

export default class SurveyMonitoringGeotech2 {
  constructor(position) {
    this.id = 'surveyMonitoringGeotech_2';
    this.component = Box;
    this.title = 'survey-monitoring.Geotech.elements.Fracture Visualisation';
    this.video = '5d-1.mp4';
    this.props = {
      target: 'tb1',
      position,
      hashtags: ['digitalisation'],
    };
  }
}
