import description from './description';
import DrillBlastBlastMovement1 from './elements/drillBlastBlastMovement_1';
import DrillBlastBlastMovement2 from './elements/drillBlastBlastMovement_2';
import DrillBlastBlastMovement3 from './elements/drillBlastBlastMovement_3';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [57, 66.59], width: 7.752, rotation: [35, 0] }],
  dancingAnts: [['hs1', [34.06, 100], { direction: 'reverse' }]],
  tinyBois: [
    { id: 'tb1', position: [57, 66.59], offset: [6.4, 1.5] },
    { id: 'tb2', position: [57, 66.59], offset: [6.4, 6] },
  ],
  elements: [
    new DrillBlastBlastMovement1([68, 15.75]),
    new DrillBlastBlastMovement2([76, 42.75]),
    new DrillBlastBlastMovement3([76, 66]),
  ],
};

export default config;
