import Box from 'modules/common/components/Box';

export default class RockCharacterization4 {
  constructor(position) {
    this.id = 'RockCharacterization4';
    this.component = Box;
    this.title = 'evaluationPlanningDesign.Rock Characterisation.elements.Implicit Modelling';
    this.video = 'md_Implicit_Modeling.mp4';
    this.props = {
      target: 'tb3',
      position,
      hashtags: ['digitalisation'],
    };
  }
}
