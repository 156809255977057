import Box from 'modules/common/components/Box';

export default class DrillBlastBlastDesign4 {
  constructor(position) {
    this.id = 'drillBlastBlastDesign_4';
    this.component = Box;
    this.title = 'drill-blast.Blast Design.elements.Drillhole Data';
    this.content = 'drill-blast.Blast Design.elements.Drillhole Data Content';
    this.type = 'content';
    this.props = {
      target: 'tb3',
      position,
      cornerFrom: ['top', 'left'],
      hashtags: ['integration', 'dataAnalytics'],
    };
  }
}
