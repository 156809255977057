export const NAME = 'app';
export const STATUS = 'status';

export const APP_INIT = `${NAME}/init`;
export const APP_PATHS_TOGGLE = `${NAME}/paths/toggle`;
export const APP_IS_STAGE_ANIMATING_TOGGLE = `${NAME}/is_stage_animating/toggle`;
export const APP_MODAL_CONTENT_SET = `${NAME}/modal/content/set`;
export const APP_MODAL_OPEN_SET = `${NAME}/modal/isopen/set`;
export const APP_EMAIL_SEND = `${NAME}/email/send`;

export const APP_TABLET_ORIENTATION_SHOW = `${NAME}/tablet/orientation/show`;

export const APP_PRIVACY_POLICY_ACCEPT = `${NAME}/privacy/policy/accept`;

export const APP_LOMIX_CONFIG_SET = `${NAME}/lomix_config/set`;
export const SET_LOMIX_CONFIG = `setLomixConfig`;
export const SET_PWA_PROGRESS = `setPwaProgress`;
export const SET_PWA_LOADED = `setPwaLoaded`;
export const SET_PWA_ERROR = `setPwaError`;

export const SET_JOURNEY = `setJourney`;
export const SET_NEXT_JOURNEY = `setNextJourney`;
export const SET_LOCATION = `setLocation`;
export const SET_LOCATION_NAME = `setLocationName`;
export const SET_NEXT_LOCATION = `setNextLocation`;
export const SET_PREV_LOCATION = `setPrevLocation`;

export const SET_DIRECTION = `setDirection`;
export const SET_CAN_NAVIGATE = `setCanNavigate`;
export const SET_TO_JOURNEY = `setToJourney`;
export const SET_REPLACE_JOURNEY = `setReplaceJourney`;

export const SET_SHOW_AUTH_MODAL = `setShowAuthModal`;

export const SET_LOGGED_USER = `setLoggedUser`;
export const SET_IS_USER_LOGGED_IN = `setIsUserLoggedIn`;
export const SET_TO_LOG_IN = `setToLogIn`;

export const SET_AUDIO_MUTED = `audio/muted/set`;

export const SET_DEFERRED_CLICK_ELEMENT = `setDeferredClickElement`;
export const SET_HIDE_HASHTAGS = `setHideHashtags`;

export const APP_WORKFLOWS_OVERVIEW_VISIT = `${NAME}/workflowsoverview/visit`;
export const APP_WORKFLOWS_OVERVIEW_2D_VISIT = `${NAME}/workflowsoverview2d/visit`;

export const APP_INFO_3D_SHOW = `${NAME}/info3d/show`;
export const APP_SITEMAP_OPEN_SET = `${NAME}/sitemap/open/set`;

export const APP_PWA_CONFIRM_DELETE_SHOW = `${NAME}/pwa/confirm/delete/show`;
export const APP_PWA_CONFIRM_DELETE_PROGRESS_SET = `${NAME}/pwa/confirm/delete/progress/set`;

export const APP_PWA_MODAL_SHOW = `${NAME}/pwa/modal/show`;

export const APP_JOURNEY_TO_ASSOCIATE_SET = `${NAME}/journey/to/associate/set`;
export const APP_JOURNEY_SHARE = `${NAME}/journey/share`;
