import description from './description';
import MarkerClass from './elements/MarkerClass';
import Content from './elements/Content';
import Video from './elements/Video';
// import ContentOne from './elements/ContentOne';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [16.1, 41.5], width: 6.375 }],
  dancingAnts: [
    ['hs1', [0, 33.98], { direction: 'reverse' }],
    ['hs1', [50.57, 0]],
  ],
  tinyBois: [
    { id: 'tb1', position: [16.1, 41.5], offset: [5.6, 1.2] },
    { id: 'tb2', position: [16.1, 41.5], offset: [2.9, 6.1] },
    // { id: 'tb3', position: [16.1, 41.5], offset: [5.6, 4.8] },
  ],
  elements: [
    new MarkerClass([12, 16.8]),
    new Content([27.5, 37]),
    new Video([12, 59]),
    // new ContentOne([27.5, 50]),
  ],
};

export default config;
