const description = {
  id: '4 of 5',
  currentNumber: 4,
  totalNumber: 5,
  code: 4,
  title: 'load-haul.Asset Health.title',
  slug: 'asset-health',
  pdfPath: 'AssetHealth',
  leadLayoutKey: 'asset-health',
  apiSlug: 'asset-health',
  productDetailsLink: 'https://hexagon.com/products/hexagon-asset-health',
  description: 'load-haul.Asset Health.description',
  video: {
    name: 'wf_Asset_Health.mp4',
    stop: 12,
    backgroundImage: 'Asset_Health.jpg',
  },
  drawPoints: {
    hotspots: 0.5,
    dancingAnts: 8,
    secondaries: 8.5,
  },
};

export default description;
