import description from './description';
import DrilholDesignDesign1 from './elements/DrilholDesign1';
import DrilholDesignDesign2 from './elements/DrilholDesign2';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [16.1, 45.94], width: 12.75 }],
  dancingAnts: [['hs1', [0, 76.17], { direction: 'reverse' }]],
  tinyBois: [{ id: 'tb1', position: [16.1, 45.94], offset: [5.6, 12.3] }],
  elements: [new DrilholDesignDesign1([11.97, 19.9]), new DrilholDesignDesign2([11.97, 66.6])],
};

export default config;
