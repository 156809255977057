import React from 'react';
import PropTypes from 'prop-types';

const Announce = ({ announce }) => {
  return (
    <div className="c-announce">
      <p>{announce}</p>
    </div>
  );
};

Announce.propTypes = {
  announce: PropTypes.string,
};

Announce.defaultProps = {
  announce: '',
};

export default Announce;
