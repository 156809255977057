import description from './description';
import PlanningGradeСontrol1 from './elements/planningGradeСontrol_1';
import PlanningGradeСontrol2 from './elements/planningGradeСontrol_2';
import PlanningGradeСontrol3 from './elements/planningGradeСontrol_3';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [13, 45], width: 6.875, rotation: [67, 0] }],
  dancingAnts: [
    ['hs1', [0, 32.03]],
    ['hs1', [0, 87.89], { direction: 'reverse' }],
  ],
  tinyBois: [
    { id: 'tb1', position: [13, 45], offset: [3.1, 4.6] },
    { id: 'tb2', position: [13, 45], offset: [6.7, 3.2] },
  ],
  elements: [
    new PlanningGradeСontrol1([12.4, 18.5]),
    new PlanningGradeСontrol2([12.4, 61.6]),
    new PlanningGradeСontrol3([35.9, 46.6]),
  ],
};

export default config;
