const config = {
  google: {
    enable: !!process.env.REACT_APP_GOOGLE_MEASUREMENT_ID,
    trackingId: `${process.env.REACT_APP_GOOGLE_MEASUREMENT_ID}`,
    debug: false,
    testMode: false,
  },
};

Object.freeze(config);

export default config;
