import description from './description';
import MaterialMovementFatigueAndDistraction1 from './elements/materialMovementFatigueAndDistraction_1';
import MaterialMovementFatigueAndDistraction2 from './elements/materialMovementFatigueAndDistraction_2';
import MaterialMovementFatigueAndDistraction3 from './elements/materialMovementFatigueAndDistraction_3';
import MaterialMovementFatigueAndDistraction4 from './elements/materialMovementFatigueAndDistraction_4';
import MaterialMovementFatigueAndDistraction5 from './elements/materialMovementFatigueAndDistraction_5';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [13.8, 41.92], width: 6.375 }],
  dancingAnts: [
    ['hs1', [0, 29.68]],
    ['hs1', [0, 74.6], { direction: 'reverse' }],
  ],
  tinyBois: [
    { id: 'tb1', position: [13.8, 41.92], offset: [5.1, 5.3] },
    { id: 'tb2', position: [13.8, 41.92], offset: [6, 1.9] },
    { id: 'tb3', position: [13.8, 41.92], offset: [3.4, 5.9] },
    { id: 'tb4', position: [13.8, 41.92], offset: [1, 5.3] },
  ],
  elements: [
    new MaterialMovementFatigueAndDistraction1([12.4, 16.5], { size: 'wide' }),
    new MaterialMovementFatigueAndDistraction2([12.4, 60.3]),
    new MaterialMovementFatigueAndDistraction3([35.8, 60.3], { target: 'tb3' }),
    new MaterialMovementFatigueAndDistraction4([43.65, 43.35], { target: 'tb2' }),
    new MaterialMovementFatigueAndDistraction5([59.3, 60.3], { target: 'tb1' }),
  ],
};

export default config;
