const description = {
  id: 'journeys-manager-introduction',
  title: 'journeys-manager.Journeys manager introduction',
  slug: 'introduction',
  hasMainNav: true,
  isInPaths: false,
  hidePath: true,
  description: '',
  hotspots: [],
  dancingAnts: [],
  tinyBois: [],
  elements: [],
};

export default description;
