/* eslint-disable global-require,react/jsx-props-no-spreading,no-return-assign,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,react/destructuring-assignment,react/no-unused-state */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import HexagonLogoSVG from 'assets/images/brand/hexagon-logo.svg';

import Button from 'modules/common/components/Button';

import { ALLOW_EVENTS_CLASS } from 'app/constants/app';

import './assets/scss/index.scss';
import { withTranslation } from 'react-i18next';

class Sidebar extends PureComponent {
  handleClick = () => {
    this.props.onClick();
  };

  render() {
    const { title, subtitle, buttonConfirmTitle, t } = this.props;

    return (
      <div className={`sidebar-left-content ${ALLOW_EVENTS_CLASS}`}>
        <div className="sidebar-left-content__title">
          <div className="header__brand">
            <div className="header__logo-container">
              <div className="header__logo">
                <HexagonLogoSVG />
              </div>
            </div>
            <h1 className="header__title">
              <span>{t('Life-of-Mine Experience')}</span>
            </h1>
          </div>
        </div>
        {title && (
          <div className="sidebar-left-content__description">
            <div>{t(title)}</div>
          </div>
        )}

        {subtitle && (
          <div className="sidebar-left-content__subdescription">
            <div>{t(subtitle)}</div>
          </div>
        )}

        <div className="sidebar-left-content__button">
          <div className="stage-card__buttons">
            <Button onClick={this.handleClick}>{t(buttonConfirmTitle)}</Button>
          </div>
        </div>
      </div>
    );
  }
}

Sidebar.propTypes = {
  config: PropTypes.shape({
    className: PropTypes.string,
  }),
  onClick: PropTypes.func,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  buttonConfirmTitle: PropTypes.string,
  t: PropTypes.func,
};

Sidebar.defaultProps = {
  config: {
    className: 'sidebar',
  },
  onClick: () => {},
  title: '',
  subtitle: '',
  buttonConfirmTitle: 'Enter Mine',
  t: () => {},
};

export default withTranslation()(connect(null, null)(Sidebar));
