// @ts-nocheck
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Location from 'modules/journeys-manager-journey/containers/Location';

class Main extends PureComponent {
  onEvent = ({ action, data }) => {
    // eslint-disable-next-line react/prop-types
    const { onEvent } = this.props;

    switch (action) {
      // case 'doFirstListRequest': {
      //   doListRequest({});
      //   break;
      // }
      // case 'setFilter': {
      //   setFilter(data);
      //   break;
      // }
      default: {
        onEvent({ action, data });
      }
    }
  };

  render() {
    const { id, allLomixConfigs, permission } = this.props;

    return (
      <>
        <Location
          id={id}
          onEvent={this.onEvent}
          allLomixConfigs={allLomixConfigs}
          permission={permission}
        />
      </>
    );
  }
}

Main.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  allLomixConfigs: PropTypes.arrayOf(PropTypes.any),
  config: PropTypes.shape({
    className: PropTypes.string,
    delayPrimaries: PropTypes.number,
    delaySecondaries: PropTypes.number,
    staggerBreakdowns: PropTypes.number,
    delayVideoBreakdown: PropTypes.number,
  }),
  id: PropTypes.string,
  permission: PropTypes.shape({
    createJourney: PropTypes.bool,
    edit: PropTypes.bool,
    delete: PropTypes.bool,
    duplicate: PropTypes.bool,
  }),
};

Main.defaultProps = {
  allLomixConfigs: [],
  config: {
    className: 'journey',
    delayPrimaries: 0.5,
    delaySecondaries: 0.25,
    staggerBreakdowns: 0.125,
    delayVideoBreakdown: 1.5,
  },
  id: null,
  permission: {
    createJourney: false,
    edit: false,
    delete: false,
    duplicate: false,
  },
};

const mapStateToProps = () => {
  return {
    config: {
      className: 'journey',
      delayPrimaries: 0.5,
      delaySecondaries: 0.25,
      staggerBreakdowns: 0.125,
      delayVideoBreakdown: 1.5,
    },
  };
};

export default connect(mapStateToProps, null)(Main);
