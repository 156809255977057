/* eslint-disable class-methods-use-this */
import { toast } from 'react-toastify';

class Notification {
  // eslint-disable-next-line class-methods-use-this
  success(text) {
    toast(text, {
      position: 'top-center',
      hideProgressBar: true,
      draggable: false,
      progress: undefined,
      theme: 'light',
      autoClose: 10000,
      closeButton: false,
      icon: false,
    });
  }

  error(text) {
    toast.error(text, {
      position: 'top-center',
      hideProgressBar: true,
      draggable: false,
      progress: undefined,
      theme: 'light',
      autoClose: 10000,
      closeButton: false,
      icon: false,
    });
  }

  closeAll() {
    toast.dismiss();
  }
}

export default Object.freeze(new Notification());
