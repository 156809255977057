import description from './description';
import SurveyMonitoringInsights1 from './elements/SurveyMonitoringInsights_1';

const config = {
  ...description,

  hotspots: [{ id: 'hs1', position: [75.88, 57.46], width: 6.875, rotation: [67, 0] }],
  dancingAnts: [
    ['hs1', [41.99, 0]],
    ['hs1', [51.75, 0], { direction: 'reverse' }],
    ['hs1', [61.62, 0]],
    ['hs1', [0, 69.33], { direction: 'reverse' }],
  ],
  tinyBois: [],
  elements: [new SurveyMonitoringInsights1([12.5, 19.8], { size: 'wide' })],
};

export default config;
