const description = {
  id: '4 of 7',
  currentNumber: 4,
  totalNumber: 7,
  title: 'drill-blast.Blast Monitoring.title',
  slug: 'blast-monitoring',
  pdfPath: 'BlastMonitoring',
  leadLayoutKey: 'blast-monitoring',
  apiSlug: 'blast-monitoring',
  productDetailsLink: 'https://idsgeoradar.com/products/interferometric-radar/ibis-fm-evo',
  description: 'drill-blast.Blast Monitoring.description',
  video: {
    name: 'wf_Blast_Monitoring.mp4',
    stop: 11,
    backgroundImage: 'Blast_Monitoring.jpg',
  },
  drawPoints: {
    hotspots: 11,
    dancingAnts: 13,
    secondaries: 13.5,
  },
};

export default description;
