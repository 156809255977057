/* eslint-disable class-methods-use-this */
import { clear } from './DB';

class CacheService {
  async clear() {
    await clear();
  }
}

const objectCacheService = new CacheService();
export default objectCacheService;
