// @ts-nocheck
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/button-has-type,no-nested-ternary */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as selectors from 'app/selectors';

import actions from 'app/actions';

import './assets/styles.scss';

import { getFromLS, saveToLS } from 'core/services/localStorage';

import eventBus from 'EventBus';

import { sendGTEvent } from 'modules/analytics/services/googleAnalytics';

import i18next from 'i18next';
import cacheService from 'app/services/CacheService';
import Download from './Download';
import Progress from './Progress';
import CompletedSuccess from './CompletedSuccess';
import InstalledSuccess from './InstalledSuccess';
import Error from './Error';

import ConfirmDelete from './ConfirmDelete';

const config = {
  className: 'c-download-app-modal',
  openClass: 'is-open',
};

// eslint-disable-next-line react/prop-types,no-unused-vars
const DownloadApp = ({
  opened,
  handleClickDownload,
  pwaProgress,
  pwaExists,
  pwaLoaded,
  pwaError,
  numberOpenedWindow,
  showPwaConfirmDeleteModal,
  setPwaConfirmDeleteProgress,
}) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDownloaded, setIsDownloaded] = useState(false);

  const handleClose = () => {
    handleClickDownload(false);
  };

  useEffect(() => {
    eventBus.on('downloadedOffline', () => {
      sendGTEvent({ event: 'download_offline_version', status: 'success' });
    });
  });

  useEffect(() => {
    if (pwaLoaded || pwaExists) {
      setIsDownloading(false);
      setIsDownloaded(true);
    } else if (!pwaLoaded && !pwaExists) {
      setIsDownloading(false);
      setIsDownloaded(false);
    }
  }, [pwaLoaded, pwaExists]);

  const handleConfirmDownload = () => {
    cacheService.setNewLanguage(i18next.language);
    saveToLS('pwa', 'loading');
    window.dispatchEvent(new Event('storage'));
    setIsDownloading(true);
  };

  const handleDeleteInstalledApp = () => {
    handleClose();
    showPwaConfirmDeleteModal({ show: true });
  };

  const handleCancelDeleteInstalledApp = () => {
    showPwaConfirmDeleteModal({ show: false });
  };

  const handleConfrimDeleteInstalledApp = () => {
    setPwaConfirmDeleteProgress({ value: 1 });
  };

  const getState = () => {
    if (pwaError) {
      return <Error handleClose={handleClose} />;
    }

    if (isDownloading) {
      return <Progress progress={pwaProgress} handleClose={handleClose} />;
    }

    if (isDownloaded) {
      if (numberOpenedWindow === 0) {
        return <CompletedSuccess handleClose={handleClose} />;
      }

      return (
        <InstalledSuccess
          handleClose={handleClose}
          handleDeleteInstalledApp={handleDeleteInstalledApp}
        />
      );
    }

    return <Download handleConfirmDownload={handleConfirmDownload} handleClose={handleClose} />;
  };

  return (
    <>
      <div className={`${config.className} ${opened ? config.openClass : ''}`}>
        <div className={`${config.className}__container`}>
          <div className={`${config.className}__content`}>{getState()}</div>
        </div>
      </div>
      <ConfirmDelete
        handleCancel={handleCancelDeleteInstalledApp}
        handleConfirm={handleConfrimDeleteInstalledApp}
      />
    </>
  );
};

DownloadApp.propTypes = {
  opened: PropTypes.bool,
  // email: PropTypes.string,
  pwaExists: PropTypes.bool,
  pwaLoaded: PropTypes.bool,
  pwaProgress: PropTypes.number,
  pwaError: PropTypes.bool,
  numberOpenedWindow: PropTypes.number,
  handleClickDownload: PropTypes.func,
  showPwaConfirmDeleteModal: PropTypes.func,
  setPwaConfirmDeleteProgress: PropTypes.func,
};

DownloadApp.defaultProps = {
  opened: false,
  // email: '',
  pwaExists: false,
  pwaLoaded: false,
  pwaProgress: 0,
  pwaError: false,
  numberOpenedWindow: 0,
  handleClickDownload: () => {},
  showPwaConfirmDeleteModal: () => {},
  setPwaConfirmDeleteProgress: () => {},
};

const mapStateToProps = (state) => {
  // const email = selectors.getEmail(state);
  const pwaLoaded = selectors.getPwaLoaded(state);
  const pwaProgress = selectors.getPwaProgress(state);
  const pwaError = selectors.getPwaError(state);
  const numberOpenedWindow = selectors.getPwaNumberOpenedWindow(state);
  const pwaExists = getFromLS('pwa') === 'pwa';

  return {
    // email,
    pwaExists,
    pwaLoaded,
    pwaProgress,
    pwaError,
    numberOpenedWindow,
  };
};

const mapDispatchToProps = (dispatch) => ({
  showPwaConfirmDeleteModal: (payload) => dispatch(actions.showPwaConfirmDeleteModal({ payload })),
  setPwaConfirmDeleteProgress: (payload) =>
    dispatch(actions.setPwaConfirmDeleteProgress({ payload })),
});

export default connect(mapStateToProps, mapDispatchToProps)(DownloadApp);
