import Box from 'modules/common/components/Box';

export default class DrillBlastDrilling3 {
  constructor(position) {
    this.id = 'drillBlastDrilling_3';
    this.component = Box;
    this.title = 'drill-blast.Drilling.elements.Machine Guidance Drill';
    this.img = 'md_Machine_Guidance_Drill.png';
    this.modalType = 'gallery';
    this.props = {
      target: 'tb2',
      position,
      cornerFrom: ['top', 'left'],
      hashtags: ['autonomy', 'productivity'],
    };
  }
}
