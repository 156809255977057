import Introduction from 'modules/insights-introduction/configs/config1024';

import LoadHaulDynamicData from 'modules/load-haul-insights/configs/config1024';
import SafetyInsights from 'modules/safety-insights/configs/config1024';
import SurveyMonitoringInsights from 'modules/survey-monitoring-insights/configs/config1024';
import DrillBlastInsights from 'modules/drill-blast-insights/configs/config1024';
import PlanningInsights from 'modules/planning-insights/configs/config1024';
import { modifyApiSlug } from 'core/utils';

const config = {
  id: 6,
  title: 'insights.Insights',
  slug: 'insights',
  pdfPath: 'Insights',
  hasMainNav: true,
  isInPaths: true,
  video: {
    backgroundImage: 'Load_And_Haul_Insights.jpg',
  },
  info: Introduction,
  locations: [
    LoadHaulDynamicData,
    SafetyInsights,
    SurveyMonitoringInsights,
    DrillBlastInsights,
    PlanningInsights,
  ].map((el) => modifyApiSlug(el, 'insights')),
};

export default config;
