/* eslint-disable class-methods-use-this */
import configAPP from 'app/configs';
import HttpService from 'core/services/http/Http';
import localJourneysManager from 'core/services/localJourneysManager';

class Api {
  async getJourney(params) {
    if (configAPP.enableJourneysForExhibition) {
      return localJourneysManager.getJourney(params.id);
    }

    const response = await HttpService.get(`api/v1/journeys/${params.id}`);

    return response;
  }

  async createJourney(params) {
    if (configAPP.enableJourneysForExhibition) {
      return localJourneysManager.createJourney(params.data);
    }

    const response = await HttpService.post(`api/v1/journeys`, {}, params.data);

    return response;
  }

  async updateJourney(params) {
    if (configAPP.enableJourneysForExhibition) {
      return localJourneysManager.updateJourney(params.id, params.data);
    }

    const response = await HttpService.put(`api/v1/journeys/${params.id}`, {}, params.data);

    return response;
  }

  async deleteJourney(params) {
    if (configAPP.enableJourneysForExhibition) {
      return localJourneysManager.deleteJourney(params.id);
    }

    const response = await HttpService.delete(`api/v1/journeys/${params.id}`, {});

    return response;
  }
}

export default Object.freeze(new Api());
