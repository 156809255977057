import * as THREE from 'three';

export default class CameraService {
  constructor() {
    const fov = 75;
    const aspect = 2;
    const near = 0.1;
    const far = 500000;

    this.camera = new THREE.PerspectiveCamera(fov, aspect, near, far);

    // this.camera.position.set(-35, 10000, 25);// 918.1166131124685, y: 6435.3126658003175, z: 5563.984056166446
    this.camera.position.set(409.3355381477025, 1218.553880895423, 1347.210276468731); // 67.1122370922244, 470.406725269528, 406.7145848596653); // (124.19910097824372, 870.5430619502331, 752.673252791368); // 918.1166131124685, 6435.3126658003175, 5563.984056166446);
    // this.camera.lookAt(25, 0.5, -30);
    this.camera.lookAt(149.50252188778518, 1.4100520973129536, 46.80737594906871);
    // const r = new THREE.Vector3(-0.842061904732418, 0.22167622449323388, 0.2415013926079316);
    // this.camera.rotation.set(-0.8115921366196576, 0.19943447902936556, 0.20581919366218868);
    this.camera.updateProjectionMatrix();
  }

  getActiveCamera() {
    return this.camera;
  }

  update(width, height) {
    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();
  }
}
