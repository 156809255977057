import Introduction from 'modules/load-haul-introduction/configs/config1200';
import SchedulingOptimising from 'modules/load-haul-scheduling-optimising/configs/config1200';
import FleetManagement from 'modules/load-haul-fleet-management/configs/config1200';
import UndergroundTaskManagement from 'modules/underground-task-management/configs/config1200';
// import OperatorAssist from 'modules/load-haul-operator-assist/configs/config1200';
import AssetHealth from 'modules/load-haul-asset-health/configs/config1200';
import SafetyVehicleCollisionAvoidance from 'modules/safety-vehicle-collision-avoidance/configs/config1200';
import SafetyFatigueAndDistraction from 'modules/safety-fatigue-and-distraction/configs/config1200';
import SafetyPedestrianSafety from 'modules/safety-pedestrian-safety/configs/config1200';
import UndergroundSafety from 'modules/underground-safety/configs/config1200';
import LoadHaulInsights from 'modules/load-haul-insights/configs/config1200';
import { modifyApiSlug } from 'core/utils';

const config = {
  id: 3,
  title: 'load-haul.Load & Haul',
  slug: 'load-haul',
  pdfPath: 'Load&Haul',
  hasMainNav: true,
  isInPaths: true,
  video: {
    backgroundImage: 'Scheduling_And_Optimizing.jpg',
  },
  info: Introduction,
  locations: [
    SchedulingOptimising,
    FleetManagement,
    UndergroundTaskManagement,
    // OperatorAssist,
    AssetHealth,
    SafetyVehicleCollisionAvoidance,
    SafetyFatigueAndDistraction,
    SafetyPedestrianSafety,
    UndergroundSafety,
    LoadHaulInsights,
  ].map((el) => modifyApiSlug(el, 'load-haul')),
};

export default config;
