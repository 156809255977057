/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-unused-prop-types,react/style-prop-object */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './assets/scss/index.scss';

class Pointer extends PureComponent {
  render() {
    const { config, rotation, isActive, id, size } = this.props;

    return (
      <div
        id={id}
        className={`${config.className} ${isActive ? config.activeClass : ''}`}
        style={{
          transform: `rotateX(${rotation[0]}deg) rotateY(${rotation[1]}deg)`,
        }}
      >
        <div className={`${config.className}__circle`}>
          <svg
            width={size.width}
            height={size.height}
            viewBox="0 0 68 68"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={`${config.className}__circle-svg`}
          >
            <g opacity="0.4">
              <circle cx="34" cy="34" r="28" fill="#9B9B9B" />
            </g>
            <circle
              cx="34"
              cy="34"
              r="28"
              fill="url(#paint0_radial_264_2506)"
              className={`${config.className}__circle-big`}
            />
            <circle
              cx="34"
              cy="34"
              fill="url(#paint1_radial_264_2506)"
              className={`${config.className}__circle-small`}
            />
            <circle
              cx="34"
              cy="34"
              fill="url(#paint1_radial_264_2506)"
              className={`${config.className}__circle-small-secondary`}
            />
            <circle cx="34" cy="34" r="33.5" stroke="white" />
            <circle cx="34" cy="34" r="3" fill="white" />
            <circle
              cx="34"
              cy="34"
              r="5.5"
              stroke="white"
              className={`${config.className}__circle-target`}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M27.254 29.6979L25.5686 28.623C24.6702 30.0318 24.1391 31.6432 24.0238 33.3101C23.9086 34.977 24.2128 36.6462 24.9088 38.1652L26.7253 37.333C26.2596 36.3183 26 35.1895 26 34C26 32.4163 26.4602 30.9402 27.254 29.6979Z"
              fill="#B4DEDA"
              className={`${config.className}__circle-secondary`}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M40.8903 38.0667C39.4978 40.4211 36.933 42 33.9995 42C33.6953 42 33.395 41.9831 33.0996 41.95L32.8748 43.9366C34.7931 44.1537 36.7333 43.8109 38.4611 42.9496C40.1889 42.0882 41.6304 40.7452 42.6116 39.0825L40.8903 38.0667Z"
              fill="#B4DEDA"
              className={`${config.className}__circle-secondary`}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M39.2975 25.5184C37.7087 24.5261 35.8732 24 34 24V26C34 26 34 26 34 26C37.1589 26 39.8902 27.8308 41.1905 30.4893L42.9861 29.6126C42.1643 27.9293 40.8862 26.5107 39.2975 25.5184Z"
              fill="#B4DEDA"
              className={`${config.className}__circle-secondary`}
            />
            <defs>
              <radialGradient
                id="paint0_radial_264_2506"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(34 34) rotate(90) scale(28)"
              >
                <stop stopColor="#DEFCFF" stopOpacity="0" />
                <stop offset="0.546875" stopColor="#DBFCFF" stopOpacity="0" />
                <stop offset="1" stopColor="#AEECFF" />
              </radialGradient>
              <radialGradient
                id="paint1_radial_264_2506"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(34 34) rotate(90) scale(18)"
              >
                <stop offset="0.328125" stopColor="white" stopOpacity="0" />
                <stop offset="1" stopColor="#C0FFF7" />
              </radialGradient>
            </defs>
          </svg>
        </div>
      </div>
    );
  }
}

Pointer.propTypes = {
  config: PropTypes.shape({
    className: PropTypes.string,
    activeClass: PropTypes.string,
    durationCircle: PropTypes.number,
    easeCircle: PropTypes.string,
    delayCircleSecondary: PropTypes.number,
    staggerCircleSecondary: PropTypes.number,
    durationCircleSecondary: PropTypes.number,
  }),
  size: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  rotation: PropTypes.arrayOf(PropTypes.number),
  position: PropTypes.arrayOf(PropTypes.number),
  isActive: PropTypes.bool,
  id: PropTypes.string,
  ownClass: PropTypes.string,
};

Pointer.defaultProps = {
  config: {
    className: 'c-pointer',
    activeClass: 'is-active',
    durationCircle: 0.5,
    easeCircle: 'back.out(2)',
    delayCircleSecondary: -0.15,
    staggerCircleSecondary: 0.15,
    durationCircleSecondary: 0.25,
  },
  size: {
    width: 0,
    height: 0,
  },
  ownClass: '',
  rotation: [0, 0],
  position: [0, 0],
  isActive: true,
  id: 'pointer',
};

Pointer.id = 'Pointer';

const mapStateToProps = () => {
  const config = {
    className: 'c-pointer',
    activeClass: 'is-active',
    durationCircle: 0.5,
    easeCircle: 'back.out(2)',
    delayCircleSecondary: -0.15,
    staggerCircleSecondary: 0.15,
    durationCircleSecondary: 0.25,
  };

  return {
    config,
  };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(Pointer);
