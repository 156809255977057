/* eslint-disable consistent-return */
import gsap from 'gsap';

export default class AnimationManager {
  constructor({ listener = null } = {}) {
    if (listener) {
      this.listener = listener;
    }

    this.timeline = null;
    this.sidebarLeft = null;
  }

  init(config = null, drawPoints = null, sidebarLeft = null) {
    this.kill();

    this.config = config;
    this.drawPoints = drawPoints || { hotspots: 0, dancingAnts: 0, secondaries: 0 };
    this.sidebarLeft = sidebarLeft;

    this.timeline = this.composeTimeline();
  }

  onUpdate(options) {
    if (typeof this.listener?.onUpdate === 'function') {
      this.listener.onUpdate(options);
    }
  }

  resume() {
    this.timeline?.resume?.();
  }

  play() {
    this.timeline?.play?.();
  }

  kill() {
    if (this.timeline) {
      this.timeline.resume();
      this.timeline.kill();
      this.timeline = null;
    }
  }

  composeTimeline = () => {
    const drawTimeline = this.composeDrawTimeline();

    const masterTimeline = gsap
      .timeline({
        paused: true,
        callbackScope: this,
        onComplete: () => {
          return this.onUpdate({
            action: 'toggleAnimationEnded',
            data: {
              isEnded: true,
            },
          });
        },
      })
      .addLabel('start')
      .call(
        (isAnimating) => {
          return this.onUpdate({
            action: 'toggleIsStageAnimating',
            data: {
              isAnimating,
            },
          });
        },
        [true],
        '<',
      )
      .add(drawTimeline.tweenFromTo('start', 'breakdown', { ease: 'none' }), 'start')
      .addLabel('breakdown')
      .addPause('breakdown')

      .add(drawTimeline.tweenFromTo('breakdown', 'end', { ease: 'none' }), 'breakdown');

    return masterTimeline;
  };

  composeDrawTimeline() {
    const drawTimeline = gsap.timeline({ paused: true }).addLabel('start');

    const sidebarLeftTL = this.getTimelinesSidebarLeft();

    if (this.drawPoints && sidebarLeftTL) {
      drawTimeline
        .addLabel('sidebarLeft', 2)
        .addLabel('startVideo', '>')
        .call(() => {
          return this.onUpdate({
            action: 'startVideo',
          });
          // return this.toggleVideoPlaying(true);
        }, ['startVideo'])
        .add(sidebarLeftTL.tweenFromTo('start', 'end'), 'sidebarLeft')
        .addLabel('breakdown')
        .call(() => {
          return this.onUpdate({
            action: 'breakdownVideo',
          });
          /* this.animPaused = true;

          const l = {
            video: {
              backgroundImage: this.props.location?.video?.backgroundImage,
            },
          };

          if (this.props.location?.video?.defaultBackgroundImage) {
            l.video.backgroundImage = this.props.location?.video?.defaultBackgroundImage;
          }

          this.setBackImage(l);

          if (!this.state.video.play || !this.getVideo()?.name) {
            this.toggleIsStageAnimating(false);
          }
          this.setState({ canShowHashtagsModal: true }); */
        }, ['>'])
        //  .add(stageTLs.secondaries.tweenFromTo('end', 'start'))
        .add(
          sidebarLeftTL.tweenFromTo('end', 'start', {
            onComplete: () => {
              return this.onUpdate({
                action: 'rollbackAnimation',
              });
            },
          }),
        )
        .addLabel('startVideoEnd', '>')
        .call(() => {
          this.onUpdate({
            action: 'startVideoEnd',
          });
          /* this.animPaused = false;
          return this.toggleVideoPlaying(true, false); */
        }, ['startVideoEnd'])
        .addLabel('end');

      this.drawTimeline = drawTimeline;
    }

    return drawTimeline;
  }

  getTimelinesSidebarLeft() {
    if (!this.sidebarLeft) return null;

    gsap.set(this.sidebarLeft, { opacity: 0, x: '-30.625rem' });

    const timeline = gsap
      .timeline({ paused: true })
      .addLabel('start')
      .to(this.sidebarLeft, {
        opacity: 1, // .7,
        duration: 1,
        x: 0,
        // ease: 'power4.out',
        // ease: 'back.out(2)',
      })
      /* .to(this.sidebarLeft, {
        opacity: 1,
        duration: .5,
        ease: 'power4.out',
      }) */
      .addLabel('end');
    // this.props.onEvent('test');
    return timeline;
  }

  addEventCallback(eventName = null, callbackName = null, args = null) {
    if (!eventName || !callbackName) return;

    this.timeline.eventCallback(eventName, () => {
      this.onUpdate({
        action: callbackName,
        data: args,
      });
    });
  }
}
