/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/button-has-type */
/* eslint-disable dot-notation */
/* eslint-disable react/destructuring-assignment,no-useless-escape,jsx-a11y/label-has-associated-control */
import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Checkbox } from '@mui/material';

import configHelper from 'modules/journeys-manager/services/ConfigHelper';
import LocationListElement from 'modules/journeys-manager-journey/components/LocationListElement';
import LocationListEntryScreenElement from 'modules/journeys-manager-journey/components/LocationListEntryScreenElement';
import { withTranslation } from 'react-i18next';
import CheckboxCheckedIcon from './assets/icons/checkbox.svg';
import CheckboxDefaultIcon from './assets/icons/checkbox-false.svg';
import LocationIcon from './assets/icons/location.svg';

import './assets/scss/index.scss';

class LocationsListView extends PureComponent {
  constructor(props) {
    super(props);

    configHelper.setRawConfig(props.allLomixConfigs);

    this.state = {
      showPathName: false,
      dndList: this.prepareListForDnD(props.locations),
    };
  }

  // eslint-disable-next-line class-methods-use-this
  prepareListForDnD(slags) {
    const list = configHelper.getListForDnD(slags);

    return list.map((el, elIndex) => ({ data: el, elIndex, id: elIndex }));
  }

  toggleShowingPathName(event) {
    this.setState({ showPathName: event.target.checked });
  }

  // eslint-disable-next-line class-methods-use-this
  isNeedToShowEntryScreenElement(location, prevLocation) {
    const isFirstWithEntryScreen = !prevLocation && location.relatedEntryScreen;
    // eslint-disable-next-line prettier/prettier
    const isBothFromSameJourney =
      prevLocation && prevLocation.relatedJourney.slug !== location.relatedJourney.slug;

    return isFirstWithEntryScreen || (location.relatedEntryScreen && isBothFromSameJourney);
  }

  render() {
    const { t } = this.props;
    const { showPathName, dndList } = this.state;

    const numberOfLocations = dndList ? dndList.length : 0;

    return (
      <>
        <div className="location-list">
          <div className="location-list-head">
            <div className="location-list-head__checkbox">
              <Checkbox
                id="show-path-name"
                icon={<CheckboxDefaultIcon />}
                checkedIcon={<CheckboxCheckedIcon />}
                name="showPathName"
                checked={showPathName}
                onChange={(e) => this.toggleShowingPathName(e)}
              />
              <label className="location-list-head__checkbox-label" htmlFor="show-path-name">
                {t('journeys-manager.Show workflow names')}
              </label>
            </div>

            <div className="location-list-head__locations">
              <span className="location-list-head__locations-icon">
                <LocationIcon />
              </span>
              <span className="location-list-head__locations-text">
                {t('Locations')}: {numberOfLocations}
              </span>
            </div>
          </div>
          <div className="location-list-content">
            {!dndList?.length && (
              <div className="location-list-content__info-with-error">
                {!dndList?.length && (
                  <div className="location-list-content__info">
                    {t('journeys-manager.No locations added yet')}
                  </div>
                )}
              </div>
            )}

            <div className="location-list-content__list-wrapper">
              <div className="location-list-content__list">
                {dndList.map((el, i, all) => (
                  <div key={el.id}>
                    {this.isNeedToShowEntryScreenElement(el.data, all[i - 1]?.data) && (
                      <LocationListEntryScreenElement
                        name={el.data.relatedEntryScreen.title}
                        pathName={el.data.relatedJourney.title}
                        showPathName={showPathName}
                      />
                    )}
                    <LocationListElement
                      key={el.data.id}
                      name={el.data.title}
                      pathName={el.data.relatedJourney.title}
                      elIndex={i + 1}
                      showPathName={showPathName}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

LocationsListView.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.string),
  // eslint-disable-next-line react/forbid-prop-types
  allLomixConfigs: PropTypes.arrayOf(PropTypes.any),
  t: PropTypes.func,
};

LocationsListView.defaultProps = {
  locations: [],
  allLomixConfigs: [],
  t: () => {},
};

export default withTranslation()(connect(null, null)(LocationsListView));
