import Box from 'modules/common/components/Box';

export default class MaterialMovementVehicleCollisionAvoidance4 {
  constructor(position, props = {}) {
    this.id = 'materialMovementVehicleCollisionAvoidance_4';
    this.component = Box;
    this.title = 'safety.Vehicle Collision Avoidance.elements.Multifunctional antenna';
    this.content = '';
    this.img = 'md_Multifunctional_Antenna.png';
    this.video = '';
    this.modalType = 'gallery';
    this.props = {
      target: props.target || null,
      position,
      cornerFrom: props.cornerFrom || null,
      hashtags: ['healthSafety', 'dataAnalytics'],
    };
  }
}
