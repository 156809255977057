import { createSelector } from 'reselect';

import { NAME } from 'modules/journeys-manager/constants';

// info: https://redux-toolkit.js.org/api/createSelector
const getInitialState = (state) => {
  return state[NAME];
};

export const isReady = createSelector(getInitialState, (state) => state.ready);

export default {
  isReady,
};
