import description from './description';
import MaterialMovementVehicleCollisionAvoidance1 from './elements/materialMovementVehicleCollisionAvoidance_1';
import MaterialMovementVehicleCollisionAvoidance3 from './elements/materialMovementVehicleCollisionAvoidance_3';
import MaterialMovementVehicleCollisionAvoidance4 from './elements/materialMovementVehicleCollisionAvoidance_4';
import MaterialMovementVehicleCollisionAvoidance5 from './elements/materialMovementVehicleCollisionAvoidance_5';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [41.4, 43.75], width: 12.67 }],
  dancingAnts: [
    ['hs1', [0, 5.37], { direction: 'reverse' }],
    ['hs1', [3.85, 100]],
  ],
  tinyBois: [
    { id: 'tb1', position: [41.4, 43.75], offset: [0.8, 9.6] },
    { id: 'tb2', position: [41.4, 43.75], offset: [11.2, 9.6] },
    { id: 'tb4', position: [41.4, 43.75], offset: [6.2, 12.2] },
  ],
  elements: [
    new MaterialMovementVehicleCollisionAvoidance1([27.6, 15.2]),
    new MaterialMovementVehicleCollisionAvoidance3([51, 76.3], { target: 'tb4' }),
    new MaterialMovementVehicleCollisionAvoidance4([34.3, 76.3], {
      target: 'tb1',
      cornerFrom: ['bottom', 'left'],
    }),
    new MaterialMovementVehicleCollisionAvoidance5([66.7, 76.3], { target: 'tb2' }),
  ],
};

export default config;
