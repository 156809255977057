import description from './description';
import PlanningGradeСontrol1 from './elements/planningGradeСontrol_1';
import PlanningGradeСontrol2 from './elements/planningGradeСontrol_2';
import PlanningGradeСontrol3 from './elements/planningGradeСontrol_3';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [14.8, 58.8], width: 18.25, rotation: [67, 0] }],
  dancingAnts: [
    ['hs1', [0, 32.03]],
    ['hs1', [0, 87.89], { direction: 'reverse' }],
  ],
  tinyBois: [
    { id: 'tb1', position: [14.8, 58.8], offset: [8.3, 12] },
    { id: 'tb2', position: [14.8, 58.8], offset: [17.6, 8.5] },
  ],
  elements: [
    new PlanningGradeСontrol1([11.9, 19.9]),
    new PlanningGradeСontrol2([11.9, 78.6]),
    new PlanningGradeСontrol3([27.6, 51.96]),
  ],
};

export default config;
