const description = {
  id: '3 of 5 monitoring',
  currentNumber: 3,
  totalNumber: 5,
  title: 'survey-monitoring.Monitoring.title',
  slug: 'monitoring',
  pdfPath: 'Monitoring',
  leadLayoutKey: 'monitoring',
  apiSlug: 'monitoring',
  productDetailsLink:
    'https://hexagon.com/products/product-groups/hxgn-minemonitoring/critical-slope-monitoring',
  description: 'survey-monitoring.Monitoring.description',
  video: {
    name: 'wf_Monitoring.mp4',
    stop: 13.5,
    backgroundImage: 'Monitoring.jpg',
  },
  drawPoints: {
    hotspots: 0.25,
    dancingAnts: 10,
    secondaries: 10.5,
  },
};

export default description;
