const config = {
  id: 0,
  title: 'workflow.Mine Map',
  slug: 'workflows-overview',
  hasMainNav: true,
  isInPaths: false,
  hidePath: true,
  video: {
    backgroundImage: 'journey-0-start-frame.jpg',
  },
  audio: {
    default: {
      disable: true,
    },
  },
  scene: {
    camera: {
      minDistance: 1,
      maxDistance: 1800,
      scale: {
        900: {
          pointer: 108,
          ownClass: 'zoom-in-two',
        },
        1800: {
          pointer: 72,
          ownClass: 'zoom-in-one',
        },
      },
    },
  },
  info: {
    id: 1,
    title: 'workflow.Workflows Overview',
    slug: 'introduction',
    description: '',
    video: {
      backgroundImage: 'journey-0-start-frame.jpg',
    },
    drawPoints: { hotspots: 0, dancingAnts: 0, secondaries: 1 },
    hotspots: [
      {
        id: 'phs1',
        isActive: false,
        position: [79, 14.75],
        width: 5.5,
        rotation: [59, 3],
      },
      {
        id: 'phs2',
        isActive: false,
        position: [61.5, 18],
        width: 6,
        rotation: [57, 0],
      },
      {
        id: 'phs3',
        isActive: false,
        position: [23.25, 26.75],
        width: 6,
        rotation: [54, -1],
      },
      {
        id: 'phs4',
        isActive: false,
        position: [59, 67.5],
        width: 5,
        rotation: [54, 1],
      },
      {
        id: 'phs5',
        isActive: false,
        position: [29.25, 73.5],
        width: 6.5,
        rotation: [50, 0],
      },
    ],
    dancingAnts: [],
    tinyBois: [],
    elements: [],
  },
  locations: [],
};

export default config;
