/* eslint-disable consistent-return */
/* eslint-disable no-await-in-loop */
/* eslint-disable dot-notation */
// @ts-nocheck
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-plusplus */
import { precachePlugins } from 'workbox-precaching/utils/precachePlugins';
import { getOrCreatePrecacheController } from 'workbox-precaching/utils/getOrCreatePrecacheController';
import { createCacheKey } from 'workbox-precaching/utils/createCacheKey';

import { fetchWrapper } from 'workbox-core/_private/fetchWrapper';
import { WorkboxError } from 'workbox-core/_private/WorkboxError';

import { copyResponse } from 'workbox-core/copyResponse';
import { getFriendlyURL, executeQuotaErrorCallbacks } from 'workbox-core/_private';

import { pluginUtils } from 'workbox-core/utils/pluginUtils';

import workflowsOverviewCacheService from 'modules/workflows-overview/services/CacheService';

import { acquireTokenSilent } from 'modules/auth/authMsalService';

import { getFromLS, saveToLS, removeFromLS } from 'core/services/localStorage';

class CacheService {
  constructor() {
    // console.log('CacheService:constructor');
    this.currentIndex = -1;
    this.files = null;
    this.timeoutId = null;

    const localStorageLang = getFromLS('CacheServiceLang');
    this.language = localStorageLang?.code || 'en';
  }

  setNewLanguage(lang = null) {
    this.language = lang;

    if (!lang) {
      removeFromLS('CacheServiceLang');
      return;
    }

    saveToLS('CacheServiceLang', { code: this.language });
  }

  getLanguage() {
    return this.language;
  }

  async installFiles(self, event) {
    try {
      const files = await this.prepareFiles();
      // sendEvent({ state: 'SEND:FILE', files });
      const precacheController = getOrCreatePrecacheController();
      const urlsToCacheKeys = precacheController.getURLsToCacheKeys();
      const plugins = precachePlugins.get();

      const storage = await self.caches.open(precacheController._cacheName);

      // const deletedURLs = [];

      // const filesForCache = [];

      let countErrors = 0;

      let i = -1;
      const n = files.length;
      // console.log('service:worker:files.length', files.length, files, urlsToCacheKeys);
      while (++i < n) {
        // sendEvent({ state: 'iteration:i', i });
        const file = files[i];

        const { cacheKey, url } = createCacheKey({ url: file.url, revision: file.revision });
        // sendEvent({ state: 'cacheKey:url', cacheKey, url });
        if (urlsToCacheKeys.has(url)) {
          const cachedKey = urlsToCacheKeys.get(url);

          if (cachedKey !== cacheKey) {
            // sendEvent({ state: 'cachedKey !== cacheKey', cachedKey, cacheKey });
            // console.log('service:worker:checkedFile:before', file.url);
            const checkedFile = await this.getFile(file.url);
            // console.log('service:worker::checkedFile:after', checkedFile);
            // sendEvent({ state: 'SEND:FILE', checkedFile, file });
            if (checkedFile) {
              // await storage.delete(cachedKey);
              // deletedURLs.push(cachedKey);

              const savedForRestore = urlsToCacheKeys.get(url);

              urlsToCacheKeys.delete(url);

              // filesForCache.push(file);

              const installed = await this.precacheInstallFiles(
                event,
                precacheController,
                plugins,
                [file],
                urlsToCacheKeys,
                storage,
                precacheController._cacheName,
                file?.token,
                file?.method,
              );

              if (installed === 0) {
                await storage.delete(cachedKey);
              } else {
                urlsToCacheKeys.set(url, savedForRestore);
              }
              // sendEvent({ state: 'installed:0', installed });
              countErrors += installed;
            }
          }
        } else {
          // console.log('service:worker:checkedFile1:before', file.url);
          const checkedFile = await this.getFile(file.url);
          // console.log('service:worker::checkedFile1:after', checkedFile);
          // sendEvent({ state: 'SEND:FILE', checkedFile, file, empty: true });
          if (checkedFile) {
            // sendEvent({ state: 'installed:1:before:checkedFile', checkedFile });
            const installed = await this.precacheInstallFiles(
              event,
              precacheController,
              plugins,
              [file],
              urlsToCacheKeys,
              storage,
              precacheController._cacheName,
              file?.token,
              file?.method,
            );
            countErrors += installed;
            // sendEvent({ state: 'installed:1', installed });
            // filesForCache.push(file);
          }
        }
      }
      // console.log('service:worker:countErrors', countErrors, urlsToCacheKeys);
      // await precacheController.activate();
      // sendEvent({ state: 'SEND:FILE', countErrors });

      return { countErrors, error: null };
    } catch (error) {
      return { countErrors: 1, error };
    }
  }

  async installFile(file, self, event) {
    try {
      // sendEvent({ state: 'SEND:FILE', files });
      const precacheController = getOrCreatePrecacheController();
      const urlsToCacheKeys = precacheController.getURLsToCacheKeys();
      const plugins = precachePlugins.get();

      const storage = await self.caches.open(precacheController._cacheName);

      // const deletedURLs = [];

      // const filesForCache = [];

      let countErrors = 0;

      const { cacheKey, url } = createCacheKey({ url: file.url, revision: file.revision });
      /* console.log(
        'SW:installFile',
        url,
        urlsToCacheKeys.has(url),
        urlsToCacheKeys.get(url),
        urlsToCacheKeys,
      ); */
      // sendEvent({ state: 'cacheKey:url', cacheKey, url });
      if (urlsToCacheKeys.has(url)) {
        const cachedKey = urlsToCacheKeys.get(url);

        if (cachedKey !== cacheKey) {
          // sendEvent({ state: 'cachedKey !== cacheKey', cachedKey, cacheKey });
          // console.log('service:worker:checkedFile:before', file.url);
          // const checkedFile = await this.getFile(file.url);
          // console.log('service:worker::checkedFile:after', checkedFile);
          // sendEvent({ state: 'SEND:FILE', checkedFile, file });
          // if (checkedFile) {

          const savedForRestore = urlsToCacheKeys.get(url);

          urlsToCacheKeys.delete(url);

          const installed = await this.precacheInstallFiles(
            event,
            precacheController,
            plugins,
            [file],
            urlsToCacheKeys,
            storage,
            precacheController._cacheName,
            file?.token,
            file?.method,
          );

          if (installed === 0) {
            await storage.delete(cachedKey);
            // deletedURLs.push(cachedKey);
            // filesForCache.push(file);
          } else if (savedForRestore) {
            urlsToCacheKeys.set(url, savedForRestore);
          }

          // sendEvent({ state: 'installed:0', installed });
          countErrors += installed;
          // }
        }
      } else {
        // console.log('service:worker:checkedFile1:before', file.url);
        // const checkedFile = await this.getFile(file.url);
        // console.log('service:worker::checkedFile1:after', checkedFile);
        // sendEvent({ state: 'SEND:FILE', checkedFile, file, empty: true });
        // if (checkedFile) {
        // sendEvent({ state: 'installed:1:before:checkedFile', checkedFile });
        const installed = await this.precacheInstallFiles(
          event,
          precacheController,
          plugins,
          [file],
          urlsToCacheKeys,
          storage,
          precacheController._cacheName,
          file?.token,
          file?.method,
        );
        countErrors += installed;
        // sendEvent({ state: 'installed:1', installed });
        // filesForCache.push(file);
        // }
      }

      // console.log('service:worker:countErrors', countErrors, urlsToCacheKeys);
      // await precacheController.activate();
      // sendEvent({ state: 'SEND:FILE', countErrors });

      return { countErrors, error: null };
    } catch (error) {
      return { countErrors: 1, error };
    }
  }

  async prepareFiles() {
    // todo: add localization logic
    const videos = this.language
      ? await this.getFiles(`/media/${this.language}/videos.json?id=${Date.now()}`)
      : [];

    const assets = await this.getFiles(`/media/assets.json?id=${Date.now()}`);

    const collateralRevision = Date.now();

    const pdfsForMainLang = await this.getFiles(
      `/media/en/collateral.json?id=${collateralRevision}`,
    );
    const pdfsForLocalLang =
      this.language !== 'en'
        ? await this.getFiles(`/media/${this.language}/collateral.json?id=${collateralRevision}`)
        : [];

    const pdfs = [...pdfsForMainLang, ...pdfsForLocalLang];

    const filteredPDFs = [
      {
        url: `/media/${this.language}/collateral.json`,
        revision: collateralRevision,
      },
    ];

    if (this.language !== 'en') {
      filteredPDFs.push({
        url: `/media/en/collateral.json`,
        revision: collateralRevision,
      });
    }

    if (Array.isArray(pdfs)) {
      const existPDFs = {};

      let i = -1;
      const n = pdfs.length;

      while (++i < n) {
        const { revision, url } = pdfs[i];

        if (existPDFs[url]) {
          if (new Date(existPDFs[url].revision) < new Date(revision)) {
            existPDFs[url].revision = revision;
            filteredPDFs[existPDFs[url].index].revision = revision;
          }
        } else {
          filteredPDFs.push({
            url,
            revision,
          });

          existPDFs[url] = {
            revision,
            index: filteredPDFs.length - 1,
          };
        }
      }
    }

    const journeys = [];

    const authResult = await acquireTokenSilent();

    if (authResult?.accessToken) {
      const journeysURL = `${process?.env?.REACT_APP_BASE_API_URL || '/'}api/v1/journeys`;

      const token = `${authResult.tokenType} ${authResult.accessToken}`;

      journeys.push({
        url: journeysURL,
        revision: 1,
        token,
        method: 'GET',
      });

      const response = await fetch(journeysURL, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      });

      const customJourneys = await response.json();

      if (Array.isArray(customJourneys)) {
        let i = -1;
        const n = customJourneys.length;

        while (++i < n) {
          const j = customJourneys[i];

          let journeyURL = `${process?.env?.REACT_APP_BASE_API_URL || '/'}api/v1/lead/journeys/${
            j.id
          }`;

          journeys.push({
            url: journeyURL,
            revision: 1,
          });

          journeyURL = `${process?.env?.REACT_APP_BASE_API_URL || '/'}api/v1/journeys/${j.id}`;

          journeys.push({
            url: journeyURL,
            revision: 1,
            token,
          });
        }
      }
    }

    const filteredFiles = [...videos, ...assets, ...filteredPDFs, ...journeys];

    /* let i = -1;
    const n = files.length;

    while (++i < n) {
      const checkedFile = await this.getFile(files[i].url);

      if (checkedFile) {
        filteredFiles.push(files[i]);
      }
    } */
    // console.log('SW:app:prepareFiles:filteredFiles', filteredFiles);
    this.currentIndex = 0;
    this.files = filteredFiles;
    // console.log('prepareFiles', this.files, this.currentIndex);
    return filteredFiles;
  }

  clearFiles() {
    // console.log('CacheService:clearFiles');
    this.resetTimeout();
    this.currentIndex = -1;
    this.files = null;
    // this.setNewLanguage(null);
  }

  postMessage(receiver) {
    // console.log('postMessage:files', this.files, this.currentIndex);
    if (Array.isArray(this.files)) {
      const file = this.files[this.currentIndex];
      // console.log('postMessage:file', file);
      if (file) {
        this.currentIndex++;

        receiver.postMessage(
          {
            type: 'CHECK:FILE',
            file,
            language: this.language,
          },
          // [this.messageChannel.port2],
        );

        this.timeoutId = setTimeout(() => {
          // console.log('CacheService:setTimeout');
          receiver.postMessage({ type: 'RESEND:AFTER:TIMEOUT' });
          this.resend(receiver);
        }, 20000);

        return;
      }
    }

    return 'FILES:EMPTY';
  }

  clearDeprecatedFiles(receiver) {
    receiver.postMessage({
      type: 'CLEAR:DEPRECATED:FILES',
    });
  }

  updateRouter(receiver) {
    receiver.postMessage({
      type: 'UPDATE:ROUTER',
    });
  }

  async deletePWA(receiver) {
    await workflowsOverviewCacheService.clear();

    receiver.postMessage({
      type: 'PWA:DELETE',
    });
  }

  resend(receiver) {
    this.timeoutId = setTimeout(() => {
      // console.log('CacheService:setTimeout');
      receiver.postMessage({ type: 'RESEND:AFTER:TIMEOUT' });
      this.resend(receiver);
    }, 20000);
  }

  resetTimeout() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
  }

  async precacheInstallFiles(
    event,
    precacheController,
    plugins,
    files,
    urlsToCacheKeys,
    cache,
    cacheName,
    token,
    method,
  ) {
    try {
      precacheController.addToCacheList(files);
      // sendEvent({ state: 'precacheInstallFiles', precacheInstallFiles: 'init', files });
      await this.install({
        event,
        plugins,
        urlsToCacheKeys,
        cache,
        precacheController,
        cacheName,
        token,
        method,
      });
      // sendEvent({ state: 'precacheInstallFiles:install', precacheInstallFiles: 'install', files });
      return 0;
    } catch (error) {
      // console.log('precacheInstallFiles:error', error);
      // sendEvent({ state: 'precacheInstallFiles:error', precacheInstallFiles: error });
      return 1;
    }
  }

  async getFiles(path) {
    try {
      // const filesURL = new URL('/media/files.json');
      // const params = {};

      // Object.keys(params).forEach((key) => filesURL.searchParams.append(key, params[key]));

      /* const body = {}; */
      const response = await fetch(path, {
        // body: JSON.stringify(body),
        headers: { 'Content-Type': 'application/json' },
      });

      const files = await response.json();

      if (files.error) return [];

      const checkedFiles = [];

      let i = -1;
      const n = files.files.length;

      while (++i < n) {
        const f = files.files[i];

        checkedFiles.push(f);
      }

      return checkedFiles;
    } catch (e) {
      return [];
    }
  }

  async getFile(path) {
    try {
      const response = await fetch(`${path}`);

      if (response.ok) {
        return true;
      }

      return false;
    } catch (e) {
      return false;
    }
  }

  async install({
    event,
    plugins,
    urlsToCacheKeys,
    cache,
    precacheController,
    cacheName,
    token,
    method,
  } = {}) {
    const toBePrecached = [];
    const alreadyPrecached = [];
    const alreadyCachedRequests = await cache.keys();
    const existingCacheKeys = new Set(alreadyCachedRequests.map((request) => request.url));
    // sendEvent({ state: 'precacheInstallFiles:install:promise:google:existingCacheKeys:existingCacheKeys, urlsToCacheKeys'});
    for (const [url, cacheKey] of urlsToCacheKeys) {
      if (existingCacheKeys.has(cacheKey)) {
        alreadyPrecached.push(url);
      } else {
        toBePrecached.push({ cacheKey, url });
      }
    }
    // sendEvent({ state: 'precacheInstallFiles:install:promise:google:toBePrecached', toBePrecached });
    const precacheRequests = toBePrecached.map(({ cacheKey, url }) => {
      const integrity = precacheController._cacheKeysToIntegrities.get(cacheKey);
      const cacheMode = precacheController._urlsToCacheModes.get(url);
      return this.addURLToCache({
        cacheKey,
        cacheMode,
        event,
        integrity,
        plugins,
        url,
        cacheName,
        cache,
        token,
        method,
      });
    });
    // sendEvent({ state: 'precacheInstallFiles:install:promise:google:precacheRequests'});
    await Promise.all(precacheRequests);
    // sendEvent({ state: 'precacheInstallFiles:install:promise:google:result:success' });
    const updatedURLs = toBePrecached.map((item) => item.url);
    // sendEvent({ state: 'precacheInstallFiles:install:promise:google:updatedURLs:updatedURLs, alreadyPrecached'});
    return {
      updatedURLs,
      notUpdatedURLs: alreadyPrecached,
    };
  }

  async addURLToCache({
    cacheKey,
    url,
    cacheMode,
    event,
    plugins,
    integrity,
    cacheName,
    cache,
    token,
    method = null,
  }) {
    // sendEvent({ state: 'addURLToCache:start' });
    const options = {
      integrity,
      cache: cacheMode,
      credentials: 'same-origin',
      headers: {
        Authorization: token ?? null,
        // 'Content-Type': 'application/json',
      },
    };

    options.method = method || 'GET';

    const request = new Request(url, options);
    let response = await fetchWrapper.fetch({
      event,
      plugins,
      request,
    });
    // sendEvent({ state: 'addURLToCache:finish' });
    // Allow developers to override the default logic about what is and isn't
    // valid by passing in a plugin implementing cacheWillUpdate(), e.g.
    // a `CacheableResponsePlugin` instance.
    let cacheWillUpdatePlugin;
    for (const plugin of plugins || []) {
      if ('cacheWillUpdate' in plugin) {
        cacheWillUpdatePlugin = plugin;
      }
    }
    const isValidResponse = cacheWillUpdatePlugin
      ? // Use a callback if provided. It returns a truthy value if valid.
        // NOTE: invoke the method on the plugin instance so the `this` context
        // is correct.
        await cacheWillUpdatePlugin.cacheWillUpdate({ event, request, response })
      : // Otherwise, default to considering any response status under 400 valid.
        // This includes, by default, considering opaque responses valid.
        response.status < 400;
    // Consider this a failure, leading to the `install` handler failing, if
    // we get back an invalid response.
    // sendEvent({ state: 'addURLToCache:isValidResponse:before' });
    if (!isValidResponse) {
      // sendEvent({ state: 'addURLToCache:WorkboxError', status: response.status });
      throw new WorkboxError('bad-precaching-response', {
        url,
        status: response.status,
      });
    }
    // Redirected responses cannot be used to satisfy a navigation request, so
    // any redirected response must be "copied" rather than cloned, so the new
    // response doesn't contain the `redirected` flag. See:
    // https://bugs.chromium.org/p/chromium/issues/detail?id=669363&desc=2#c1
    if (response.redirected) {
      // sendEvent({ state: 'addURLToCache:response.redirected ' });
      response = await copyResponse(response);
      // sendEvent({ state: 'addURLToCache:response.redirected.response' });
    }
    // sendEvent({ state: 'addURLToCache:cacheWrapper.put:before' });
    await this.putWrapper({
      event,
      plugins,
      response,
      // `request` already uses `url`. We may be able to reuse it.
      request: cacheKey === url ? request : new Request(cacheKey),
      cacheName,
      matchOptions: {
        ignoreSearch: true,
      },
      cache,
    });
    // sendEvent({ state: 'addURLToCache:cacheWrapper.put:after' });
  }

  async putWrapper({ cacheName, request, response, event, plugins = [], matchOptions, cache }) {
    // sendEvent({ state: 'putWrapper:internal:start' });
    const effectiveRequest = await this._getEffectiveRequest({
      plugins,
      request,
      mode: 'write',
    });
    // sendEvent({ state: 'putWrapper:internal:effectiveRequest:after' });
    if (!response) {
      throw new WorkboxError('cache-put-with-no-response', {
        url: getFriendlyURL(effectiveRequest.url),
      });
    }
    // sendEvent({ state: 'putWrapper:internal:_isResponseSafeToCache' });
    const responseToCache = await this._isResponseSafeToCache({
      event,
      plugins,
      response,
      request: effectiveRequest,
    });
    // sendEvent({ state: 'putWrapper:internal:_isResponseSafeToCache:before' });
    if (!responseToCache) {
      // sendEvent({ state: 'putWrapper:internal:_isResponseSafeToCache:responseToCache:fail' });
      return;
    }
    // sendEvent({ state: 'putWrapper:internal:_isResponseSafeToCache:oldResponse:before' });
    const updatePlugins = pluginUtils.filter(plugins, 'cacheDidUpdate' /* CACHE_DID_UPDATE */);
    const oldResponse =
      updatePlugins.length > 0
        ? await this.matchWrapper({ cacheName, matchOptions, request: effectiveRequest, cache })
        : null;

    // sendEvent({ state: 'putWrapper:internal:_isResponseSafeToCache:oldResponse:after' });
    try {
      // console.log('effectiveRequest, responseToCache', effectiveRequest, responseToCache);
      // sendEvent({ state: 'putWrapper:internal:_isResponseSafeToCache:cache.put:before' });
      await cache.put(effectiveRequest, responseToCache); // ISSUES
      // sendEvent({ state: 'putWrapper:internal:_isResponseSafeToCache:cache.put:after' });
    } catch (error) {
      // sendEvent({ state: 'putWrapper:internal:_isResponseSafeToCache:updatePlugins:error' });
      // See https://developer.mozilla.org/en-US/docs/Web/API/DOMException#exception-QuotaExceededError
      if (error.name === 'QuotaExceededError') {
        await executeQuotaErrorCallbacks();
      }
      throw error;
    }
    // sendEvent({ state: 'putWrapper:internal:_isResponseSafeToCache:updatePlugins:iterate' });
    for (const plugin of updatePlugins) {
      await plugin['cacheDidUpdate' /* CACHE_DID_UPDATE */].call(plugin, {
        cacheName,
        event,
        oldResponse,
        newResponse: responseToCache,
        request: effectiveRequest,
      });
    }
  }

  async _getEffectiveRequest({ request, mode, plugins = [] }) {
    // sendEvent({ state: '_getEffectiveRequest:before' });
    const cacheKeyWillBeUsedPlugins = pluginUtils.filter(
      plugins,
      'cacheKeyWillBeUsed' /* CACHE_KEY_WILL_BE_USED */,
    );
    let effectiveRequest = request;
    for (const plugin of cacheKeyWillBeUsedPlugins) {
      effectiveRequest = await plugin['cacheKeyWillBeUsed' /* CACHE_KEY_WILL_BE_USED */].call(
        plugin,
        { mode, request: effectiveRequest },
      );
      if (typeof effectiveRequest === 'string') {
        effectiveRequest = new Request(effectiveRequest);
      }
    }
    // sendEvent({ state: '_getEffectiveRequest' });
    return effectiveRequest;
  }

  async _isResponseSafeToCache({ request, response, event, plugins = [] }) {
    // sendEvent({ state: '_isResponseSafeToCache:before' });
    let responseToCache = response;
    let pluginsUsed = false;
    for (const plugin of plugins) {
      if ('cacheWillUpdate' /* CACHE_WILL_UPDATE */ in plugin) {
        pluginsUsed = true;
        const pluginMethod = plugin['cacheWillUpdate' /* CACHE_WILL_UPDATE */];
        responseToCache = await pluginMethod.call(plugin, {
          request,
          response: responseToCache,
          event,
        });

        if (!responseToCache) {
          break;
        }
      }
    }
    if (!pluginsUsed) {
      responseToCache =
        responseToCache && responseToCache.status === 200 ? responseToCache : undefined;
    }
    // sendEvent({ state: '_isResponseSafeToCache:after' });
    return responseToCache || null;
  }

  async matchWrapper({ cacheName, request, event, matchOptions, plugins = [], cache }) {
    // sendEvent({ state: 'matchWrapper1:before' });
    const effectiveRequest = await this._getEffectiveRequest({
      plugins,
      request,
      mode: 'read',
    });
    // sendEvent({ state: 'matchWrapper1:before:effectiveRequest' });
    let cachedResponse = await cache.match(effectiveRequest, matchOptions);
    // sendEvent({ state: 'matchWrapper1:before:cachedResponse' });
    // sendEvent({ state: 'matchWrapper1:before:plugins:iterate:before' });
    for (const plugin of plugins) {
      if ('cachedResponseWillBeUsed' /* CACHED_RESPONSE_WILL_BE_USED */ in plugin) {
        const pluginMethod = plugin['cachedResponseWillBeUsed' /* CACHED_RESPONSE_WILL_BE_USED */];
        cachedResponse = await pluginMethod.call(plugin, {
          cacheName,
          event,
          matchOptions,
          cachedResponse,
          request: effectiveRequest,
        });
      }
    }
    // sendEvent({ state: 'matchWrapper1:before:plugins:iterate:after' });
    return cachedResponse;
  }

  getProgress() {
    if (this.currentIndex <= -1 || this.files?.length <= 0) return 0;

    return ((this.currentIndex + 1) / this.files.length) * 100;
  }
}

const objectCacheService = new CacheService();
export default objectCacheService;
