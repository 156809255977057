export const NAME = 'journeys_manager-introduction';
export const JOURNEYS_MANAGER_INIT = `${NAME}/init`;
export const JOURNEYS_MANAGER_DESTROY = `${NAME}/destroy`;

export const JOURNEYS_MANAGER_LIST_PAGINATION_SET = `${NAME}/list_pagination/set`;
export const JOURNEYS_MANAGER_LIST_FILTER_SET = `${NAME}/list_filter/set`;
export const JOURNEYS_MANAGER_LIST_SORT_SET = `${NAME}/list_sort/set`;

export const JOURNEYS_MANAGER_LIST_GET = `${NAME}/list/get`;

export const STATUS = 'status';
