/* eslint-disable react/button-has-type */
/* eslint-disable global-require,react/jsx-props-no-spreading,no-return-assign,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,react/destructuring-assignment,react/no-unused-state */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as menuSelectors from 'app/selectors';
import menuActions from 'app/actions';

import * as productDetailSelectors from 'modules/common/components/ProductDetailPanel/selectors';
import productDetailActions from 'modules/common/components/ProductDetailPanel/actions';

import { MENU, PRODUCT_DETAILS } from './sidebar-tabs';

import './assets/scss/index.scss';

class Sidebar extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      locationInfo: null,
      selectedTab: null,
    };

    this.paths = React.createRef();
  }

  componentDidMount() {
    // this.setLocation();
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps) {
    // const { locationId } = this.props;
  }

  getContentForTab() {
    const { selectedTabConfig } = this.state;

    const key = selectedTabConfig ? selectedTabConfig.key : null;

    switch (key) {
      case MENU:
        return <div>menu</div>;
      case PRODUCT_DETAILS:
        return <div>productDetails</div>;
      default:
        return null;
    }
  }

  /**
   *
   * @param {string} tab 'menu' | 'productDetails'
   */
  toggle(tab) {
    const {
      toggleMenu,
      areMenuOpen,
      isProductDetailOpen,
      openProductDetailPopup,
      closeProductDetailPopup,
    } = this.props;
    // const { selectedTab } = this.state;

    switch (tab) {
      case MENU:
        if (isProductDetailOpen) {
          closeProductDetailPopup();
        }

        toggleMenu();
        break;
      case PRODUCT_DETAILS:
        if (areMenuOpen) {
          toggleMenu();
        }

        if (isProductDetailOpen) {
          closeProductDetailPopup();
        } else {
          openProductDetailPopup();
        }
        break;
      // eslint-disable-next-line no-fallthrough
      default:
    }
    this.setState((prev) => {
      const isAlreadySelected = prev.selectedTabConfig && tab === prev.selectedTabConfig.id;

      return {
        selectedTab: isAlreadySelected ? null : tab,
      };
    });
  }

  render() {
    const { t, areMenuOpen, isProductDetailExist, isProductDetailOpen, canSowPath, pathComponent } =
      this.props;

    const sideBarList = [];

    if (canSowPath) {
      sideBarList.push({
        key: MENU,
        text: t('common.Menu'),
        isOpened: areMenuOpen,
      });
    }

    if (isProductDetailExist) {
      sideBarList.push({
        key: PRODUCT_DETAILS,
        text: t('pdf.PRODUCT DETAILS'),
        isOpened: isProductDetailOpen,
      });
    }

    // const modifiedConfig = sidebarTabsConfig.map((el) => ({
    //   ...el,
    //   content: this.props[el.contentComponentInProps] || null,
    // }));
    // const { locationInfo } = this.state;
    return (
      <div className="base-sidebar">
        <div className="base-sidebar__tabs">
          {sideBarList.map((el, i) => (
            <button
              key={el.key}
              className={`base-sidebar__tab ${i === 0 && 'base-sidebar__tab_first'} ${
                el.isOpened && 'base-sidebar__tab_active'
              }`}
              onClick={(e) => {
                e.stopPropagation();
                this.toggle(el.key);
              }}
            >
              <span className="base-sidebar__tab-text">{el.text}</span>
            </button>
          ))}
        </div>
        <div className="base-sidebar__content">{canSowPath && areMenuOpen && pathComponent}</div>
      </div>
    );
  }
}

Sidebar.propTypes = {
  config: PropTypes.shape({
    className: PropTypes.string,
  }),
  canSowPath: PropTypes.bool,
  pathComponent: PropTypes.element,
  toggleMenu: PropTypes.func,
  openProductDetailPopup: PropTypes.func,
  closeProductDetailPopup: PropTypes.func,
  // onGoIntoLocation: PropTypes.func,
  isProductDetailExist: PropTypes.bool,
  isProductDetailOpen: PropTypes.bool,
  areMenuOpen: PropTypes.bool,
  t: PropTypes.func,
};

Sidebar.defaultProps = {
  config: {
    className: 'sidebar',
  },
  canSowPath: false,
  pathComponent: null,
  areMenuOpen: false,
  isProductDetailExist: false,
  isProductDetailOpen: false,
  toggleMenu: () => {},
  openProductDetailPopup: () => {},
  closeProductDetailPopup: () => {},
  // isUserLoggedIn: false,
  t: () => {},
};

const mapStateToProps = (state) => {
  return {
    areMenuOpen: menuSelectors.arePathsOpen(state),
    isUserLoggedIn: menuSelectors.getIsUserLoggedIn(state),

    isProductDetailExist: productDetailSelectors.isProductDetailPanelDataExist(state),
    isProductDetailOpen: productDetailSelectors.isProductDetailPanelPopupOpened(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  toggleMenu: (payload) => dispatch(menuActions.togglePaths({ payload })),
  openProductDetailPopup: (payload) =>
    dispatch(productDetailActions.productDetailPanelOpenPopup({ payload })),
  closeProductDetailPopup: (payload) =>
    dispatch(productDetailActions.productDetailPanelClosePopup({ payload })),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Sidebar));
