import description from './description';
import SurveyMonitoringMonitoring1 from './elements/surveyMonitoringMonitoring_1';
import SurveyMonitoringMonitoring2 from './elements/surveyMonitoringMonitoring_2';
import SurveyMonitoringMonitoring3 from './elements/surveyMonitoringMonitoring_3';
import SurveyMonitoringMonitoring4 from './elements/surveyMonitoringMonitoring_4';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [24.6, 42], width: 6.375 }],
  dancingAnts: [['hs1', [0, 89.35], { direction: 'reverse' }]],
  tinyBois: [
    { id: 'tb1', position: [24.6, 42], offset: [5.4, 4.9] },
    { id: 'tb2', position: [24.6, 42], offset: [3, 6.3] },
    { id: 'tb3', position: [24.6, 42], offset: [5.4, 1] },
  ],
  elements: [
    new SurveyMonitoringMonitoring1([11.6, 17], { cornerFrom: ['bottom', 'left'] }),
    new SurveyMonitoringMonitoring2([43.3, 55.7]),
    new SurveyMonitoringMonitoring3([19.6, 58.25]),
    new SurveyMonitoringMonitoring4([43.3, 37.5]),
  ],
};

export default config;
