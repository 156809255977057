/* eslint-disable react/no-unused-prop-types */
// @ts-nocheck
/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import collateralService from 'app/services/CollateralService';

// import { eachFistLeetersUppercase } from 'app/helpers';
import actions from './actions';
import * as selectors from './selectors';

import ProductDetailModal from './ProductDetailModal';

import './assets/scss/index.scss';

const ProductDetailPanel = ({
  journey,
  location,
  setIsExist,
  isPopupOpen,
  isDataExist,
  setEmpty,
  closePopup,
}) => {
  const [locationFiles, setLocationFiles] = useState(null);
  const { i18n } = useTranslation();

  // const openPropductInfo = () => {
  //   openPopup();
  // };

  const closePropductInfo = () => {
    closePopup();
  };

  const setPath = () => {
    const activeJourney = journey.activeLocation?.journey ?? journey;

    // let journeyTitle = eachFistLeetersUppercase(t(activeJourney?.title));
    // journeyTitle = journeyTitle.replace(/\s+/g, '');
    // const journeyPart = `0${activeJourney?.id}${journeyTitle}`;

    // const locationId =
    //   activeJourney.locations.findIndex((loc) => loc.slug === location?.slug) + 1 || 0;
    // let locationTitle = eachFistLeetersUppercase(t(location?.title));
    // locationTitle = locationTitle.replace(/\s+/g, '');
    // const locationPart = `0${locationId}${locationTitle}`;

    // return `${journeyPart}/${locationPart}`;
    return `${activeJourney.pdfPath}/${location.pdfPath}`;
  };

  useEffect(async () => {
    let filesResponse = (await collateralService.getCollateralFiles(i18n.language)) || [];

    const locationFilesPath = setPath();
    let currentFiles = filesResponse.files.filter((file) => {
      return file.path === locationFilesPath;
    });

    if (i18n.language !== 'en' && currentFiles.length === 0) {
      filesResponse = collateralService.getCollateralFiles('en') || [];

      currentFiles = filesResponse.files.filter((file) => {
        return file.path === locationFilesPath;
      });
    }

    setIsExist({ isDataExist: currentFiles && currentFiles.length });

    setLocationFiles(currentFiles);
  }, [location, i18n.language]);

  useEffect(async () => {
    setIsExist({ isDataExist: locationFiles && locationFiles.length });
  }, [locationFiles]);

  useEffect(() => {
    return () => {
      setEmpty();
      closePopup();
    };
  }, []);

  return (
    isDataExist && (
      <>
        {/* {!isPopupOpen && (
          <div className="panel product-detail bottom" onClick={openPropductInfo} role="button">
            {t(location.title)} {t('pdf.PRODUCT DETAILS')}
            <SpreadOut />
          </div>
        )} */}
        <ProductDetailModal
          locationFiles={locationFiles}
          isOpen={isPopupOpen}
          handleClose={closePropductInfo}
          location={location}
        />
      </>
    )
  );
};
ProductDetailPanel.propTypes = {
  location: PropTypes.any,
  journey: PropTypes.any,
  isPopupOpen: PropTypes.bool,
  isDataExist: PropTypes.bool,
  setIsExist: PropTypes.func,
  setEmpty: PropTypes.func,
  openPopup: PropTypes.func,
  closePopup: PropTypes.func,
};

ProductDetailPanel.defaultProps = {
  location: {},
  journey: {},
  isPopupOpen: false,
  isDataExist: false,
  setIsExist: () => {},
  setEmpty: () => {},
  openPopup: () => {},
  closePopup: () => {},
};

const mapStateToProps = (state) => {
  return {
    isPopupOpen: selectors.isProductDetailPanelPopupOpened(state),
    isDataExist: selectors.isProductDetailPanelDataExist(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  setIsExist: (payload) => dispatch(actions.productDetailPanelSetIsExist({ payload })),
  setEmpty: (payload) => dispatch(actions.productDetailPanelSetEmpty({ payload })),
  openPopup: (payload) => dispatch(actions.productDetailPanelOpenPopup({ payload })),
  closePopup: (payload) => dispatch(actions.productDetailPanelClosePopup({ payload })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetailPanel);
