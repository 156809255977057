/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import eventBus from 'EventBus';
import { withTranslation } from 'react-i18next';

import './assets/styles/index.scss';

import Tooltip from '@mui/material/Tooltip';

import { ALLOW_EVENTS_CLASS } from 'app/constants/app';
import UnmuteSVG from './assets/icons/unmute.svg';
import MuteSVG from './assets/icons/mute.svg';

class Mute extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isSidebarOpened: false,
    };
  }

  componentDidMount() {
    eventBus.on('toggleSidebarOpened', (data) => this.moveWithSidebar(data.isOpen));
  }

  componentWillUnmount() {
    eventBus.remove('toggleSidebarOpened');
  }

  moveWithSidebar = (isOpen) => {
    this.setState({ isSidebarOpened: isOpen });
  };

  handleEvent = () => {
    const { onEvent } = this.props;

    const { enabled } = this.props;

    onEvent({ action: 'toogleMute', enabled: !enabled });
  };

  render() {
    const { config, enabled, allowEvents, t } = this.props;
    const { isSidebarOpened } = this.state;

    let iconSVG = null;
    let title = null;

    if (enabled) {
      iconSVG = <MuteSVG />;
      title = t('Turn sound on');
    } else {
      iconSVG = <UnmuteSVG />;
      title = t('Turn sound off');
    }

    return (
      <div
        className={`${config.className} ${enabled ? config.muteClass : ''} ${
          isSidebarOpened ? config.rightOffsetClass : ''
        } ${allowEvents && ALLOW_EVENTS_CLASS}`}
      >
        <Tooltip className={`${config.className}_tooltip`} title={title} placement="top-end">
          <div onClick={this.handleEvent}>{iconSVG}</div>
        </Tooltip>
      </div>
    );
  }
}

Mute.propTypes = {
  config: PropTypes.shape({
    className: PropTypes.string,
    muteClass: PropTypes.string,
    rightOffsetClass: PropTypes.string,
  }),
  enabled: PropTypes.bool,
  allowEvents: PropTypes.bool,
  onEvent: PropTypes.func,
  t: PropTypes.func,
};

Mute.defaultProps = {
  config: {
    className: 'c-audio-mute',
    muteClass: 'is-mute',
    rightOffsetClass: 'right-offset',
  },
  enabled: true,
  onEvent: () => {},
  t: () => {},
  allowEvents: false,
};

export default withTranslation()(connect(null, null, null, { forwardRef: true })(Mute));
