const description = {
  id: '3 of 3',
  currentNumber: 3,
  totalNumber: 3,
  title: 'underground.Development Drilling.title',
  slug: 'development-drilling',
  pdfPath: 'UndergroundDevelopmentDrilling',
  leadLayoutKey: 'underground-development-drilling',
  apiSlug: 'underground-development-drilling',
  productDetailsLink: 'https://hexagon.com/products/hexagon-development-optimiser',
  description: 'underground.Development Drilling.description',
  video: {
    name: 'wf_Underground_Development_Drilling.mp4',
    stop: 23.3,
    backgroundImage: 'Underground_Development_Drilling.jpg',
  },
  drawPoints: {
    hotspots: 22.5,
    dancingAnts: 23,
    secondaries: 23,
  },
};

export default description;
