/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/button-has-type */
/* eslint-disable dot-notation */
/* eslint-disable react/destructuring-assignment,no-useless-escape,jsx-a11y/label-has-associated-control */
import React, { PureComponent } from 'react';
import { DndProvider } from 'react-dnd-multi-backend';
import { HTML5toTouch } from 'rdndmb-html5-to-touch';
import { isAndroid } from 'react-device-detect';
import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Checkbox } from '@mui/material';
import DnDLocationListElement from 'modules/journeys-manager-journey/components/DnDLocationListElement';
import SelectLocationsModal from 'modules/journeys-manager-journey/components/SelectLocationsModal';
import configHelper from 'modules/journeys-manager/services/ConfigHelper';
import FormElement from 'core/form/FormElement';
import CheckboxCheckedIcon from './assets/icons/checkbox.svg';
import CheckboxDefaultIcon from './assets/icons/checkbox-false.svg';
import LocationIcon from './assets/icons/location.svg';
import DragAndDropIcon from './assets/icons/drag-and-drop.svg';

import './assets/scss/index.scss';

// info for touch support: https://react-dnd.github.io/react-dnd/about#touch-support
// info for touch support: https://codesandbox.io/s/dnd-i79zd?file=/src/App.js:290-313
// info for touch support: https://www.npmjs.com/package/react-dnd-multi-backend

class LocationsList extends PureComponent {
  constructor(props) {
    super(props);
    this.formElement = props.formElement;

    configHelper.setRawConfig(props.allLomixConfigs);

    this.state = {
      isSelectLocationsModalOpened: false,
      showPathName: false,
      error: props.formElement.error,
      locationList: props.formElement.value,
      dndList: this.prepareListForDnD(props.formElement.value),
    };
  }

  componentDidMount() {
    this.props.formElement.onChange(() => this.whenFormElementUpdated());
    this.props.formElement.onTouched(() => this.whenFormElementUpdated());
  }

  // eslint-disable-next-line class-methods-use-this
  prepareListForDnD(slags) {
    const list = configHelper.getListForDnD(slags);

    return list.map((el, elIndex) => ({ data: el, elIndex, id: elIndex }));
  }

  whenFormElementUpdated() {
    this.setState({
      locationList: configHelper.normalizeApiSlagList(this.formElement.value),
      dndList: this.prepareListForDnD(this.formElement.value),
      error: this.formElement.error,
    });
  }

  toggleShowingPathName(event) {
    this.setState({ showPathName: event.target.checked });
  }

  manageLocation() {
    this.setState({
      isSelectLocationsModalOpened: true,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  moveEvent(dragIndex, hoverIndex) {
    this.setState((state) => {
      const dndList = this.updateList(state.dndList, dragIndex, hoverIndex);

      return {
        dndList,
      };
    });
  }

  stopEvent() {
    const { dndList } = this.state;

    const apiSlugs = configHelper.getListOfApiSlugsFromDnDList(dndList.map((el) => el.data));

    this.formElement.setValue(apiSlugs);
  }

  // eslint-disable-next-line class-methods-use-this
  updateList(list, mainIndex, replaceIndex) {
    const mainElement = list[mainIndex];
    list.splice(mainIndex, 1);
    list.splice(replaceIndex, 0, mainElement);
    // console.log(list[mainIndex - 1], list[replaceIndex - 1]);
    return [...list];
  }

  setNewSelection(list) {
    if (list) {
      this.formElement.setValue(configHelper.normalizeApiSlagList(list));
    }

    this.setState({
      isSelectLocationsModalOpened: false,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  isNeedToShowEntryScreenElement(location, prevLocation) {
    const isFirstWithEntryScreen = !prevLocation && location.relatedEntryScreen;
    // eslint-disable-next-line prettier/prettier
    const isBothFromSameJourney =
      prevLocation && prevLocation.relatedJourney.slug !== location.relatedJourney.slug;

    return isFirstWithEntryScreen || (location.relatedEntryScreen && isBothFromSameJourney);
  }

  render() {
    const { showPathName, locationList, dndList, isSelectLocationsModalOpened, error } = this.state;
    const { t } = this.props;

    const numberOfLocations = dndList ? dndList.length : 0;

    const touchOptions = {
      delayTouchStart: 500,
    };

    HTML5toTouch.backends.forEach((el) => {
      if (el.id === 'touch') {
        // eslint-disable-next-line no-param-reassign
        el.options = { enableMouseEvents: !isAndroid, ...touchOptions };
      }
    });

    return (
      <>
        <div className="location-list">
          <div className="location-list-head">
            <div className="location-list-head__checkbox">
              <Checkbox
                id="show-path-name"
                icon={<CheckboxDefaultIcon />}
                checkedIcon={<CheckboxCheckedIcon />}
                name="showPathName"
                checked={showPathName}
                onChange={(e) => this.toggleShowingPathName(e)}
              />
              <label className="location-list-head__checkbox-label" htmlFor="show-path-name">
                {t('journeys-manager.Show workflow names')}
              </label>
            </div>

            <div
              className={`location-list-head__locations${
                error ? ' location-list-head__locations--error' : ''
              }`}
            >
              <span className="location-list-head__locations-icon">
                <LocationIcon />
              </span>
              <span className="location-list-head__locations-text">
                {t('Locations')}: {numberOfLocations}
              </span>
            </div>
          </div>
          <div className="location-list-content">
            {(error || !dndList?.length) && (
              <div className="location-list-content__info-with-error">
                {!dndList?.length && (
                  <div className="location-list-content__info">
                    {t('journeys-manager.No locations added yet')}
                  </div>
                )}
                {error?.minArrayLength && (
                  <div className="location-list-content__error">
                    {t('journeys-manager.form.The fields is required')}
                  </div>
                )}
              </div>
            )}

            <div className="location-list-content__list-wrapper">
              <div className="location-list-content__list">
                <DndProvider options={HTML5toTouch} /* backend={HTML5Backend} */>
                  {dndList.map((el, i, all) => (
                    <div key={el.id}>
                      <DnDLocationListElement
                        elIndex={i}
                        data={el.data}
                        showPathName={showPathName}
                        moveEvent={(dragIndex, hoverIndex) => this.moveEvent(dragIndex, hoverIndex)}
                        stopEvent={() => this.stopEvent()}
                        showEntryScreen={this.isNeedToShowEntryScreenElement(
                          el.data,
                          all[i - 1]?.data,
                        )}
                      />
                    </div>
                  ))}
                </DndProvider>
              </div>
              {!!dndList.length && (
                <div className="location-list-content__description">
                  <div className="location-list-content__description-content">
                    <DragAndDropIcon />
                    <span>{t('journeys-manager.Drag and drop to change order')}</span>
                  </div>
                </div>
              )}
            </div>
            <div className="location-list-content__actions">
              <button
                type="button"
                className="c-button c-button--size-default"
                onClick={() => this.manageLocation()}
              >
                <div className="c-button__text">{t('journeys-manager.Manage locations')}</div>
              </button>
            </div>
          </div>
        </div>

        <SelectLocationsModal
          open={isSelectLocationsModalOpened || false}
          handleClose={(list) => this.setNewSelection(list)}
          selectedList={locationList}
        />
      </>
    );
  }
}

LocationsList.propTypes = {
  formElement: PropTypes.instanceOf(FormElement),
  // eslint-disable-next-line react/forbid-prop-types
  allLomixConfigs: PropTypes.arrayOf(PropTypes.any),
  t: PropTypes.func,
};

LocationsList.defaultProps = {
  formElement: null,
  allLomixConfigs: [],
  t: () => {},
};

export default withTranslation()(connect(null, null)(LocationsList));
