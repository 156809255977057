import description from './description';
import PlanningInitial1 from './elements/planningInitial_1';
import PlanningInitial2 from './elements/planningInitial_2';
import PlanningInitial3 from './elements/planningInitial_3';
import PlanningInitial4 from './elements/planningInitial_4';
import PlanningInitial5 from './elements/planningInitial_5';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [23.7, 52.3], width: 13.75, rotation: [60, 0] }],
  dancingAnts: [['hs1', [0, 32.03]]],
  tinyBois: [
    { id: 'tb1', position: [23.7, 52.3], offset: [2.3, 8.6] },
    { id: 'tb2', position: [23.7, 52.3], offset: [7.2, 9.7] },
    { id: 'tb3', position: [23.7, 52.3], offset: [11.8, 8.8] },
    { id: 'tb4', position: [23.7, 52.3], offset: [11.8, 3.3] },
  ],
  elements: [
    new PlanningInitial1([11.98, 18.51]),
    new PlanningInitial2([19.79, 70.37], { cornerFrom: ['top', 'left'] }),
    new PlanningInitial3([35.41, 68.05]),
    new PlanningInitial4([43.22, 54.9]),
    new PlanningInitial5([35.41, 42.47]),
  ],
};

export default config;
