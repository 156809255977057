/* eslint-disable no-case-declarations */
import { LEAD_INIT, LEAD_DESTROY } from 'modules/lead/constants';

import { STATUS, SUCCESS, ERROR } from 'core/constants/statuses';

const initialState = {
  ready: false,
  journey: null,
  journeyToAssociate: null,
  status: null,
};

const app = (state = initialState, action) => {
  switch (action.type) {
    case `${LEAD_INIT}/${STATUS}/${SUCCESS}`:
      return {
        ...state,
        ready: true,
        journey: action.payload?.journey,
        status: SUCCESS,
      };
    case `${LEAD_INIT}/${STATUS}/${ERROR}`:
      return {
        ...state,
        ready: true,
        journey: null,
        status: ERROR,
      };
    case `${LEAD_DESTROY}/${STATUS}/${SUCCESS}`:
      return {
        ready: false,
        journey: null,
        journeyToAssociate: null,
        status: null,
      };
    default: {
      return state;
    }
  }
};

export default app;
