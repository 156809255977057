const description = {
  id: '5 of 5 insights',
  currentNumber: 5,
  totalNumber: 5,
  title: 'evaluationPlanningDesign.Insights.title',
  slug: 'insights',
  pdfPath: '05DynamicData',
  // fixme: in future update this (key: a100000)
  leadLayoutKey: 'exploration-exploration-dynamic-data',
  apiSlug: 'exploration-exploration-dynamic-data',
  productDetailsLink: 'https://hexagon.com/products/product-groups/hxgn-mineplan/geosciences',
  description: 'evaluationPlanningDesign.Insights.description',
  video: {
    backgroundImage: 'Path_1_location_5_frame.jpg',
    playDefaultSound: true,
  },
  drawPoints: {
    hotspots: 1,
    dancingAnts: 1.5,
    secondaries: 2,
  },
};

export default description;
