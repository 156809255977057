import { combineReducers } from 'redux';

import { NAME } from 'app/constants';
import appReducer from 'app/reducers/app';

import { NAME as NAME_PRODUCT_DETAIL_PANEL } from 'modules/common/components/ProductDetailPanel/constants';
import productDetailPanelReducer from 'modules/common/components/ProductDetailPanel/reducers';

import { NAME as NAME_JOURNEYS_MANAGER } from 'modules/journeys-manager/constants';
import journeysManagerReducer from 'modules/journeys-manager/reducers';

import { NAME as NAME_JOURNEYS_MANAGER_LIST } from 'modules/journeys-manager-introduction/constants';
import journeysManagerListReducer from 'modules/journeys-manager-introduction/reducers';

import { NAME as NAME_JOURNEYS_MANAGER_JOURNEY } from 'modules/journeys-manager-journey/constants';
import journeysManagerJourneyReducer from 'modules/journeys-manager-journey/reducers';

import { NAME as NAME_LEAD } from 'modules/lead/constants';
import leadReducer from 'modules/lead/reducers';

import contactUsReducer from 'app/components/modals/ContactUs/reducers';
import { NAME as CONTACT_US } from 'app/components/modals/ContactUs/constants';

const root = combineReducers({
  [NAME]: appReducer,
  [NAME_PRODUCT_DETAIL_PANEL]: productDetailPanelReducer,
  [NAME_JOURNEYS_MANAGER]: journeysManagerReducer,
  [NAME_JOURNEYS_MANAGER_LIST]: journeysManagerListReducer,
  [NAME_JOURNEYS_MANAGER_JOURNEY]: journeysManagerJourneyReducer,
  [NAME_LEAD]: leadReducer,
  [CONTACT_US]: contactUsReducer,
});

export default root;
