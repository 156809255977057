const description = {
  id: '6 of 7',
  currentNumber: 6,
  totalNumber: 7,
  title: 'drill-blast.Fragmentation Analysis.title',
  slug: 'fragmentation-analysis',
  pdfPath: 'FragmentationAnalysis',
  leadLayoutKey: 'fragmentation-analysis',
  apiSlug: 'fragmentation-analysis',
  productDetailsLink: 'https://hexagon.com/products/hxgn-minemeasure-split',
  description: 'drill-blast.Fragmentation Analysis.description',
  video: {
    name: 'wf_Fragmentation_Analysis.mp4',
    stop: 9,
    backgroundImage: 'Fragmentation_Analysis.jpg',
  },
  drawPoints: {
    hotspots: 0.25,
    dancingAnts: 7.5,
    secondaries: 8,
  },
};

export default description;
