import Box from 'modules/common/components/Box';

export default class SurveyMonitoringRealityCapture1 {
  constructor(position, props = {}) {
    this.id = 'surveyMonitoringRealityCapture_1';
    this.component = Box;
    this.isPrimary = true;
    this.type = 'marker';
    this.size = props.size || null;
    this.props = { target: 'hs1', position, cornerFrom: props.cornerFrom || null };
  }
}
