import Introduction from 'modules/survey-monitoring-introduction/configs/config1200';
import SurveyMonitoringRealityCapture from 'modules/survey-monitoring-reality-capture/configs/config1200';
import SurveyMonitoringPlanning from 'modules/survey-monitoring-planning/configs/config1200';
import SurveyMonitoringMonitoring from 'modules/survey-monitoring-monitoring/configs/config1200';
import SurveySurveyMonitoringInsights from 'modules/survey-monitoring-insights/configs/config1200';
import { modifyApiSlug } from 'core/utils';

const config = {
  id: 4,
  title: 'survey-monitoring.Survey Monitoring',
  slug: 'survey-monitoring',
  pdfPath: 'Survey&Monitoring',
  hasMainNav: true,
  isInPaths: true,
  video: {
    backgroundImage: 'Reality_Capture.jpg',
  },
  info: Introduction,
  locations: [
    SurveyMonitoringRealityCapture,
    SurveyMonitoringPlanning,
    SurveyMonitoringMonitoring,
    SurveySurveyMonitoringInsights,
  ].map((el) => modifyApiSlug(el, 'survey-monitoring')),
};

export default config;
