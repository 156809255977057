/* eslint-disable no-unused-vars */
import { fork, takeEvery, put, select, call, all } from 'redux-saga/effects';

import { SUCCESS } from 'core/constants/statuses';

import actions from 'modules/journeys-manager-introduction/actions';
import {
  JOURNEYS_MANAGER_INIT,
  JOURNEYS_MANAGER_DESTROY,
  JOURNEYS_MANAGER_LIST_GET,
  JOURNEYS_MANAGER_LIST_FILTER_SET,
  JOURNEYS_MANAGER_LIST_SORT_SET,
  JOURNEYS_MANAGER_LIST_PAGINATION_SET,
} from 'modules/journeys-manager-introduction/constants';
import * as selectors from 'modules/journeys-manager-introduction/selectors';
import api from 'modules/journeys-manager-introduction/api';

export default function* root() {
  yield fork(watchInit);
  yield fork(watchDestroy);
  yield fork(watchDoListRequest);
  yield fork(watchSetFilter);
  yield fork(watchSetSorting);
  yield fork(watchSetPagination);
}

function* init() {
  const payload = {};

  const initedDateTime = Date.now();

  const meta = {
    receivedAt: initedDateTime,
    updatedAt: initedDateTime,
  };

  yield put(actions.init({ status: SUCCESS, payload, meta }));
}

function* destroy() {
  const payload = {};

  const initedDateTime = Date.now();

  const meta = {
    receivedAt: initedDateTime,
    updatedAt: initedDateTime,
  };

  yield put(actions.destroy({ status: SUCCESS, payload, meta }));
}

function* setFilter({ payload }) {
  const initedDateTime = Date.now();

  const meta = {
    receivedAt: initedDateTime,
    updatedAt: initedDateTime,
  };

  yield put(actions.doListRequest({ payload: { filter: payload }, meta }));
}

function* setSorting({ payload }) {
  const initedDateTime = Date.now();

  const meta = {
    receivedAt: initedDateTime,
    updatedAt: initedDateTime,
  };

  yield put(actions.doListRequest({ payload: { sort: payload }, meta }));
}

function* setPagination({ payload }) {
  const initedDateTime = Date.now();

  const meta = {
    receivedAt: initedDateTime,
    updatedAt: initedDateTime,
  };

  yield put(actions.doListRequest({ payload: { pagination: payload }, meta }));
}

function* doListRequest({ payload }) {
  let { filter, sort, pagination } = payload;

  if (!filter) {
    filter = yield select(selectors.getFilter);
  }

  if (!sort) {
    sort = yield select(selectors.getSort);
  }

  if (!pagination) {
    pagination = yield select(selectors.getPagination);
  }

  const list = yield call(() =>
    api.getMyJourneys({
      ...filter,
      ...sort,
      ...pagination,
    }),
  );

  const initedDateTime = Date.now();

  const meta = {
    receivedAt: initedDateTime,
    updatedAt: initedDateTime,
  };

  yield all([
    put(actions.setPagination({ status: SUCCESS, payload: { pagination }, meta })),
    put(actions.setFilter({ status: SUCCESS, payload: { filter }, meta })),
    put(actions.setSorting({ status: SUCCESS, payload: { sort }, meta })),
    put(actions.doListRequest({ status: SUCCESS, payload: { list }, meta })),
  ]);
}

function* watchInit() {
  yield takeEvery(JOURNEYS_MANAGER_INIT, init);
}

function* watchDestroy() {
  yield takeEvery(JOURNEYS_MANAGER_DESTROY, destroy);
}

function* watchDoListRequest() {
  yield takeEvery(JOURNEYS_MANAGER_LIST_GET, doListRequest);
}
function* watchSetFilter() {
  yield takeEvery(JOURNEYS_MANAGER_LIST_FILTER_SET, setFilter);
}
function* watchSetSorting() {
  yield takeEvery(JOURNEYS_MANAGER_LIST_SORT_SET, setSorting);
}
function* watchSetPagination() {
  yield takeEvery(JOURNEYS_MANAGER_LIST_PAGINATION_SET, setPagination);
}
