import description from './description';
import MaterialMovementVehicleCollisionAvoidance1 from './elements/materialMovementVehicleCollisionAvoidance_1';
import MaterialMovementVehicleCollisionAvoidance3 from './elements/materialMovementVehicleCollisionAvoidance_3';
import MaterialMovementVehicleCollisionAvoidance4 from './elements/materialMovementVehicleCollisionAvoidance_4';
import MaterialMovementVehicleCollisionAvoidance5 from './elements/materialMovementVehicleCollisionAvoidance_5';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [38.75, 42.87], width: 5 }],
  dancingAnts: [
    ['hs1', [0, 5.37], { direction: 'reverse' }],
    ['hs1', [3.85, 100]],
  ],
  tinyBois: [
    { id: 'tb1', position: [38.75, 42.87], offset: [0.4, 4] },
    { id: 'tb2', position: [38.75, 42.87], offset: [4.1, 4] },
    { id: 'tb3', position: [38.75, 42.87], offset: [2.3, 4.8] },
  ],
  elements: [
    new MaterialMovementVehicleCollisionAvoidance1([11.66, 16.5], { size: 'wide-l' }),
    new MaterialMovementVehicleCollisionAvoidance3([35.41, 57], { target: 'tb3' }),
    new MaterialMovementVehicleCollisionAvoidance4([59.16, 57], {
      target: 'tb2',
      cornerFrom: ['top', 'left'],
    }),
    new MaterialMovementVehicleCollisionAvoidance5([11.66, 57], { target: 'tb1' }),
  ],
};

export default config;
