import Introduction from 'modules/safety-introduction/configs/config1024';
import VehicleCollisionAvoidance from 'modules/safety-vehicle-collision-avoidance/configs/config1024';
import FatigueAndDistraction from 'modules/safety-fatigue-and-distraction/configs/config1024';
import PedestrianSafety from 'modules/safety-pedestrian-safety/configs/config1024';
import SlopeMonitoring from 'modules/safety-slope-monitoring/configs/config1024';
import SafetyInsights from 'modules/safety-insights/configs/config1024';
import { modifyApiSlug } from 'core/utils';

const config = {
  id: 5,
  title: 'safety.Safety',
  slug: 'safety',
  pdfPath: 'Safety',
  hasMainNav: true,
  isInPaths: true,
  video: {
    backgroundImage: 'Vehicle_Collision_Avoidance.jpg',
  },
  info: Introduction,
  locations: [
    VehicleCollisionAvoidance,
    FatigueAndDistraction,
    PedestrianSafety,
    SlopeMonitoring,
    SafetyInsights,
  ].map((el) => modifyApiSlug(el, 'safety')),
};

export default config;
