export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export const breakPointHeight = () => {
  const { width } = getWindowDimensions();
  let height;

  if (width < 1200) {
    height = 768;
  } else if (width >= 1200 && width < 1920) {
    height = 800;
  } else if (width >= 1920 && width < 2560) {
    height = 1024;
  } else {
    height = 1728;
  }

  // 3840 * 2160

  return height;
};

const baseRemSize = parseFloat(getComputedStyle(document.documentElement).fontSize); // always 16px

export const percentToRem = (fullValue, percent) => {
  const pixels = (fullValue * percent) / 100;
  return (pixels / baseRemSize).toFixed(2);
};
// Gets a REM unit as a pixel value
export const getRemAsPx = (rem) => {
  const remSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
  return parseFloat(rem) * remSize;
};

// Gets a REM unit as a pixel value
export const getPxAsRem = (px) => {
  const remSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
  return parseFloat(px) / remSize;
};

export const filterKeyEnter = (handler) => {
  return (e) => {
    if (e.keyCode === 13) {
      handler(e);
    }
  };
};

export const accessibleOnClick = (handler, tabIndex = 0) => {
  return {
    role: 'button',
    tabIndex,
    onKeyDown: filterKeyEnter(handler),
    onClick: handler,
  };
};

// Compares two complex values and sees if they are different
export const isDifferent = (val1, val2) => {
  return JSON.stringify(val1) !== JSON.stringify(val2);
};

// Gets a pixel-value offset as a percentage of a parent element's dimensions
export const getPixelsAsPercentage = (offset, dimension = 'x') => {
  return (offset / (dimension === 'y' ? window.innerHeight : window.innerWidth)) * 100;
};

// Strips HTML tags from a string
// This probably isn't necessary, but whatever
// Thanks, https://coursesweb.net/javascript/strip_tags-stripslashes-javascript_cs
export const stripHTMLTags = (str, allow = '') => {
  // Make sure the allow arg is a string containing only tags in lowercase (<a><b><c>)
  const newAllow = (`${allow || ''}`.toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []).join('');

  const tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi;
  const commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi;
  return str.replace(commentsAndPhpTags, '').replace(tags, ($0, $1) => {
    return newAllow.indexOf(`<${$1.toLowerCase()}>`) > -1 ? $0 : '';
  });
};

// Creates a component instance with props and listeners
export const forgeComponent = (name, props = {}, listeners = {}, html = '') => {
  return { name, props, listeners, html };
};

// Returns an HTML string as an array of components
export const getHTMLStringAsComponents = (
  string,
  allowedTags = ['p', 'ul', 'ol', 'li', 'span', 'strong', 'em'],
) => {
  const components = [];

  // If there's HTML in the string, convert it into elements and add safe ones to components
  if (/<[a-z][\s\S]*>/i.test(string)) {
    const parser = new DOMParser();
    const vDoc = parser.parseFromString(string, 'text/html');
    const elements = vDoc.body.children;

    // eslint-disable-next-line no-restricted-syntax
    for (const element of elements) {
      if (allowedTags.includes(element.tagName.toLowerCase())) {
        components.push(
          forgeComponent(
            element.tagName,
            {},
            {},
            stripHTMLTags(element.innerHTML, allowedTags.map((tag) => `<${tag}>`).join('')),
          ),
        );
      }
    }
  } else {
    components.push(forgeComponent('p', {}, {}, string));
  }

  return components;
};

export const eachFistLeetersUppercase = (string) => {
  const arr = string.split(' ');

  // loop through each element of the array and capitalize the first letter.

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }

  // Join all the elements of the array back into a string
  // using a blankspace as a separator
  const strUppercase = arr.join(' ');
  return strUppercase;
};
