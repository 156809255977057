import Box from 'modules/common/components/Box';

export default class UndergroundProductionDrilling3 {
  constructor(position, props = {}) {
    this.id = 'undergroundProductionDrilling_3';
    this.component = Box;
    this.type = 'content';
    this.title = 'underground.Production Drilling.elements.Supporting sustainability';
    this.content = 'underground.Production Drilling.elements.Supporting sustainability Content';
    this.props = {
      target: props.target || 'tb2',
      position,
      hashtags: ['productivity', 'esg'],
      cornerFrom: props.cornerFrom || null,
    };
  }
}
