/* eslint-disable no-unused-vars */
import { fork, takeEvery, put, call } from 'redux-saga/effects';

import { ERROR, SUCCESS } from 'core/constants/statuses';

import actions from 'modules/journeys-manager-journey/actions';
import {
  JOURNEYS_MANAGER_JOURNEY_CREATE,
  JOURNEYS_MANAGER_JOURNEY_DELETE,
  JOURNEYS_MANAGER_JOURNEY_DUPLICATE,
  JOURNEYS_MANAGER_JOURNEY_GET,
  JOURNEYS_MANAGER_JOURNEY_UPDATE,
} from 'modules/journeys-manager-journey/constants';
import api from 'modules/journeys-manager-journey/api';
import notification from 'core/services/Notification';
import i18n from 'i18nConfig';

export default function* root() {
  yield fork(watchDoGetRequest);
  yield fork(watchCreateJourney);
  yield fork(watchUpdateJourney);
  yield fork(watchDeleteJourney);
  yield fork(watchDuplicateJourney);
}

function* doGetRequest({ payload }) {
  const { id } = payload;

  try {
    const data = yield call(() => api.getJourney({ id }));

    const initedDateTime = Date.now();

    const meta = {
      receivedAt: initedDateTime,
      updatedAt: initedDateTime,
    };

    yield put(actions.doGetRequest({ status: SUCCESS, payload: { data }, meta }));
  } catch (e) {
    const initedDateTime = Date.now();

    const meta = {
      receivedAt: initedDateTime,
      updatedAt: initedDateTime,
    };

    notification.error(i18n.t('journeys-manager.failedRequest'));
    yield put(actions.doGetRequest({ status: ERROR, payload: {}, meta }));
  }
}

function* createJourney({ payload }) {
  const { data } = payload;

  try {
    const response = yield call(() => api.createJourney({ data }));

    const initedDateTime = Date.now();

    const meta = {
      receivedAt: initedDateTime,
      updatedAt: initedDateTime,
    };

    yield put(actions.createJourney({ status: SUCCESS, payload: { data: response }, meta }));
    notification.success(i18n.t('journeys-manager.Journey was successfully created'));
  } catch (error) {
    const initedDateTime = Date.now();

    const meta = {
      receivedAt: initedDateTime,
      updatedAt: initedDateTime,
    };

    notification.error('Journey creation failed');
    yield put(actions.createJourney({ status: ERROR, payload: { error }, meta }));
  }
}

function* updateJourney({ payload }) {
  const { id, data } = payload;
  try {
    const response = yield call(() => api.updateJourney({ data, id }));

    const initedDateTime = Date.now();

    const meta = {
      receivedAt: initedDateTime,
      updatedAt: initedDateTime,
    };

    yield put(actions.updateJourney({ status: SUCCESS, payload: { data: response }, meta }));
    notification.success(i18n.t('journeys-manager.Journey was successfully updated'));
  } catch (error) {
    const initedDateTime = Date.now();

    const meta = {
      receivedAt: initedDateTime,
      updatedAt: initedDateTime,
    };

    notification.error('Journey updating failed');
    yield put(actions.updateJourney({ status: ERROR, payload: { error }, meta }));
  }
}

function* deleteJourney({ payload }) {
  const { id } = payload;
  try {
    yield call(() => api.deleteJourney({ id }));

    const initedDateTime = Date.now();

    const meta = {
      receivedAt: initedDateTime,
      updatedAt: initedDateTime,
    };

    notification.success(i18n.t('journeys-manager.Journey was successfully deleted'));
    yield put(actions.clearStore({ meta }));
  } catch (error) {
    const initedDateTime = Date.now();

    const meta = {
      receivedAt: initedDateTime,
      updatedAt: initedDateTime,
    };

    notification.error('Journey deleting failed');
    yield put(actions.updateJourney({ status: ERROR, payload: { error }, meta }));
  }
}

function* duplicateJourney({ payload }) {
  const { data } = payload;

  const { privateName, shareableName, description, locations } = data;

  const newJourney = { privateName, shareableName, description, locations };

  const initedDateTime = Date.now();

  const meta = {
    receivedAt: initedDateTime,
    updatedAt: initedDateTime,
  };

  yield put(actions.duplicateJourney({ status: SUCCESS, payload: { data: newJourney }, meta }));
}

function* watchDoGetRequest() {
  yield takeEvery(JOURNEYS_MANAGER_JOURNEY_GET, doGetRequest);
}

function* watchCreateJourney() {
  yield takeEvery(JOURNEYS_MANAGER_JOURNEY_CREATE, createJourney);
}

function* watchUpdateJourney() {
  yield takeEvery(JOURNEYS_MANAGER_JOURNEY_UPDATE, updateJourney);
}
function* watchDeleteJourney() {
  yield takeEvery(JOURNEYS_MANAGER_JOURNEY_DELETE, deleteJourney);
}

function* watchDuplicateJourney() {
  yield takeEvery(JOURNEYS_MANAGER_JOURNEY_DUPLICATE, duplicateJourney);
}
