import description from './description';
import AssetHealth1 from './elements/AssetHealth1';
import AssetHealth2 from './elements/AssetHealth2';
import AssetHealth3 from './elements/AssetHealth3';
import AssetHealth4 from './elements/AssetHealth4';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [14.9, 41.25], width: 5 }],
  dancingAnts: [['hs1', [0, 24], { direction: 'reverse' }]],
  tinyBois: [
    { id: 'tb1', position: [14.9, 41.25], offset: [4.5, 1.475] },
    { id: 'tb2', position: [14.9, 41.25], offset: [2.2, 4.7] },
  ],
  elements: [
    new AssetHealth1([12.4, 17.2], { size: 'wide' }),
    new AssetHealth2([28, 41.25]),
    new AssetHealth3([12.4, 55.6]),
    new AssetHealth4([34.8, 55.5]),
  ],
};

export default config;
