import Box from 'modules/common/components/Box';

export default class SurveyMonitoringPlanning3 {
  constructor(position) {
    this.id = 'surveyMonitoringPlanning_3';
    this.component = Box;
    this.title = 'survey-monitoring.Planning.elements.Mineplan Survey';
    this.video = 'md_Mineplan_Survey.mp4';
    this.props = {
      target: 'tb2',
      position,
      hashtags: ['digitalisation', 'integration'],
    };
  }
}
