import Introduction from 'modules/workflows-overview-2d-introduction/configs/configDefault';
import MineMap from 'modules/mine-map/configs/configDefault';
import description from './description';

const config = {
  ...description,
  dimensions: { width: 1920, height: 1080 },
  info: Introduction,
  locations: [MineMap],
};

export default config;
