import description from './description';
import PlanningProductionDrilling1 from './elements/planningProductionDrilling_1';
import PlanningProductionDrilling2 from './elements/planningProductionDrilling_2';
import PlanningProductionDrilling3 from './elements/planningProductionDrilling_3';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [15, 49.3], width: 9.125, rotation: [58, 0] }],
  dancingAnts: [
    ['hs1', [0, 66.89], { direction: 'reverse' }],
    ['hs1', [51.35, 100]],
  ],
  tinyBois: [
    { id: 'tb1', position: [15, 49.3], offset: [8.8, 4.2] },
    { id: 'tb2', position: [15, 49.3], offset: [4.2, 6.5] },
  ],
  elements: [
    new PlanningProductionDrilling1([11.9, 16.8]),
    new PlanningProductionDrilling2([27.6, 44.35]),
    new PlanningProductionDrilling3([11.9, 66.8]),
  ],
};

export default config;
