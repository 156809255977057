import { OrbitControls } from './OrbitControls';

// import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls.js';

export default class ControlsService {
  constructor({ camera = null, container = null, listener = null, minDistance, maxDistance } = {}) {
    if (camera && container) {
      this.init(camera, container, {
        minDistance,
        maxDistance,
      });

      if (listener?.onUpdate) {
        this.listener = listener;
        this.controls.addEventListener('change', this.change);
      }
    }
  }

  change = (e) => {
    this.listener.onUpdate({ source: 'controls', action: 'change', data: e });
  };

  init(camera, container, { minDistance = 1, maxDistance = 1800 }) {
    /* const controls = new OrbitControls(this.camera, this.renderer.domElement);
      controls.enableDamping = true; */
    // controls.target.set(0, 3, 3);

    /*
    this.controls = new TrackballControls(this.camera, this.renderer.domElement);

    this.controls.rotateSpeed = 1.0;
    this.controls.zoomSpeed = 1.2;
    this.controls.panSpeed = 0.8;

    this.controls.keys = [ 'KeyA', 'KeyS', 'KeyD' ];
    */

    this.controls = new OrbitControls(camera, container);

    // this.controls.listenToKeyEvents(window);
    /* this.controls.mouseButtons = {
      ORBIT: THREE.MOUSE.LEFT,
      ZOOM: THREE.MOUSE.MIDDLE,
      PAN: THREE.MOUSE.RIGHT
    }; */

    // this.controls.enableRotate = true;
    // this.controls.listenToKeyEvents(window); // optional

    // this.controls.addEventListener('change', render ); // call this only in static scenes (i.e., if there is no animation loop)

    // this.controls.enableDamping = true; // an animation loop is required when either damping or auto-rotation are enabled
    /* this.controls.dampingFactor = 0.5;

    this.controls.screenSpacePanning = false;
    this.controls.panSpeed = 1.2;

    this.controls.enableZoom = true;
    this.controls.zoomSpeed = 3; */
    this.controls.screenSpacePanning = false;
    this.controls.minDistance = minDistance;
    this.controls.maxDistance = maxDistance;

    // this.controls.maxPolarAngle = Math.PI / 2;

    // this.controls.maxPolarAngle = Math.PI / 2 - 30 * (Math.PI / 180);
    this.controls.target.set(149.50252188778518, 1.4100520973129536, 46.80737594906871);
    this.pivotPoint = this.controls.target.clone();
  }

  getActiveControls() {
    return this.controls;
  }

  update() {
    this.controls?.update();
  }

  enable(enable = true) {
    this.controls.enabled = enable;
  }

  clear() {
    this.controls.removeEventListener('change', this.change);
    this.listener = null;
    this.controls.dispose();
  }

  setCenter(v) {
    this.controls.setCenter(v);
  }

  /* var centerPosition = controls.target.clone();
  centerPosition.y = 0;
  var groundPosition = camera.position.clone();
  groundPosition.y = 0;
  var d = (centerPosition.distanceTo(groundPosition));

  var origin = new THREE.Vector2(controls.target.y,0);
  var remote = new THREE.Vector2(0,d); // replace 0 with raycasted ground altitude
  var angleRadians = Math.atan2(remote.y - origin.y, remote.x - origin.x);
  controls.maxPolarAngle = angleRadians; */
}
