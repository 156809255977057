import React, { PureComponent } from 'react';

import Header from 'app/containers/Header';

import './assets/scss/index.scss';

class NotFound extends PureComponent {
  handleClick = () => {
    this.redirectToHome();
  };

  handleKeyUp = (event) => {
    if (event.code === 'Enter') {
      this.redirectToHome();
    }
  };

  redirectToHome = () => {
    window.location.href = '/';
  };

  render() {
    return (
      <div className="container-not-found">
        <Header />
        <div className="content">Not Found</div>
      </div>
    );
  }
}

NotFound.propTypes = {};

export default NotFound;
