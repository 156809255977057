/* eslint-disable react/prop-types */
/* eslint-disable react/button-has-type */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import configHelper from 'modules/journeys-manager/services/ConfigHelper';

import ArrowLeftIcon from 'app/assets/icons/arrow-left.svg';

import './assets/scss/index.scss';
import JourneyListItem from 'modules/journeys-manager-introduction/components/JourneyListItem';
import { getList } from 'modules/journeys-manager-introduction/selectors';
import { withTranslation } from 'react-i18next';

class Location extends PureComponent {
  constructor(props) {
    super(props);

    configHelper.setRawConfig(props.allLomixConfigs);
  }

  onEvent = ({ action, data }) => {
    const { onEvent } = this.props;

    onEvent({ action, data });
  };

  createNewJourney() {
    const { onEvent } = this.props;

    onEvent({ action: 'createNewJourney' });
  }

  navigationBack() {
    const { onEvent } = this.props;

    onEvent({ action: 'navigationBack', data: { path: '/workflows-overview' } });
  }

  render() {
    const { list, permission, t } = this.props;
    return (
      <>
        <div className="journey-manager-page">
          <div className="journey-manager-header">
            <div>
              <button
                className="journey-manager-header__navigation"
                onClick={() => this.navigationBack()}
              >
                <ArrowLeftIcon />
                <span>{t('common.BACK TO MINE MAP')}</span>
              </button>
              <div className="journey-manager-header__title">
                {t('common.PERSONALISED JOURNEYS')}
              </div>
            </div>
            <div className="journey-manager-header__actions">
              {permission.createJourney && (
                <button
                  className="c-button c-button--size-default"
                  onClick={() => this.createNewJourney()}
                >
                  <div className="c-button__text"> {t('common.CREATE new JOURNEY')} </div>
                </button>
              )}
            </div>
          </div>

          <div className="journey-manager-content journey-manager-content--row">
            {/* <div className="journey-manager-content__filter">Filter</div> */}

            <div className="journey-manager-content__list">
              {list &&
                !!list.length &&
                list.map((item) => (
                  <JourneyListItem
                    key={item.id}
                    journey={item}
                    locationsWithoutEntryScreens={configHelper.getListWithoutEntryScreens(
                      item.locations,
                    )}
                    onEvent={this.onEvent}
                  />
                ))}
            </div>
            <div className="journey-manager-content__list">
              {list && list.length === 0 && t('journeys-manager.noJourneys')}
            </div>
          </div>
        </div>
      </>
    );
  }
}

Location.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  allLomixConfigs: PropTypes.arrayOf(PropTypes.any),
  config: PropTypes.shape({
    className: PropTypes.string,
    delayPrimaries: PropTypes.number,
    delaySecondaries: PropTypes.number,
    staggerBreakdowns: PropTypes.number,
    delayVideoBreakdown: PropTypes.number,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  list: PropTypes.array,
  onEvent: PropTypes.func,
  t: PropTypes.func,

  permission: PropTypes.shape({
    createJourney: PropTypes.bool,
    edit: PropTypes.bool,
    delete: PropTypes.bool,
    duplicate: PropTypes.bool,
  }),
};

Location.defaultProps = {
  allLomixConfigs: [],
  config: {
    className: 'journey',
    delayPrimaries: 0.5,
    delaySecondaries: 0.25,
    staggerBreakdowns: 0.125,
    delayVideoBreakdown: 1.5,
  },
  list: [],
  onEvent: () => {},
  t: () => {},

  permission: {
    createJourney: false,
    edit: false,
    delete: false,
    duplicate: false,
  },
};

const mapStateToProps = (state) => {
  const config = {
    className: 'journey',
    delayPrimaries: 0.5,
    delaySecondaries: 0.25,
    staggerBreakdowns: 0.125,
    delayVideoBreakdown: 1.5,
  };

  return {
    config,
    list: getList(state),
  };
};

export default withTranslation()(
  connect(mapStateToProps, null, null, { forwardRef: true })(Location),
);
