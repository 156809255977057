const description = {
  id: '3 of 5 pedestrian-safety',
  currentNumber: 3,
  totalNumber: 5,
  code: 3,
  title: 'safety.Pedestrian Safety.title',
  slug: 'pedestrian-safety',
  pdfPath: 'PedestrianSafety',
  leadLayoutKey: 'pedastrian-safety',
  apiSlug: 'pedastrian-safety',
  productDetailsLink: 'https://hexagon.com/products/hexagon-personal-alert',
  description: 'safety.Pedestrian Safety.description',
  video: {
    name: 'wf_Pedestrian_Safety.mp4',
    stop: 23,
    backgroundImage: 'Pedestrian_Safety.jpg',
  },
  drawPoints: {
    hotspots: 23,
    dancingAnts: 23.5,
    secondaries: 24,
  },
};

export default description;
