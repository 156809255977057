const levels = [
  {
    key: 'executive',
    localization: 'contactUs.levels.executive',
    pardotId: '1022448',
  },
  {
    key: 'vp',
    localization: 'contactUs.levels.vp',
    pardotId: '1022450',
  },
  {
    key: 'director',
    localization: 'contactUs.levels.director',
    pardotId: '1022452',
  },
  {
    key: 'manager',
    localization: 'contactUs.levels.manager',
    pardotId: '1022454',
  },
  {
    key: 'supervisor',
    localization: 'contactUs.levels.supervisor',
    pardotId: '1022456',
  },
  {
    key: 'operator',
    localization: 'contactUs.levels.operator',
    pardotId: '1022458',
  },
  {
    key: 'endUser',
    localization: 'contactUs.levels.endUser',
    pardotId: '1022460',
  },
  {
    key: 'student',
    localization: 'contactUs.levels.student',
    pardotId: '1022462',
  },
];

export default levels;
