/* eslint-disable react/button-has-type */
/* eslint-disable dot-notation */
/* eslint-disable react/destructuring-assignment,no-useless-escape,jsx-a11y/label-has-associated-control */
import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import './assets/scss/index.scss';
import { withTranslation } from 'react-i18next';

class LocationListEntryScreenElement extends PureComponent {
  render() {
    const { name, pathName, showPathName, t } = this.props;

    return (
      <>
        <div className="location-list-entry-screen-element">
          <div className="location-list-entry-screen-element__content">
            <div className="location-list-entry-screen-element__name">{t(name)}</div>
            {showPathName && (
              <div className="location-list-entry-screen-element__path-name">{t(pathName)}</div>
            )}
          </div>
        </div>
      </>
    );
  }
}

LocationListEntryScreenElement.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  name: PropTypes.string,
  pathName: PropTypes.string,
  showPathName: PropTypes.bool,
  t: PropTypes.func,
};

LocationListEntryScreenElement.defaultProps = {
  name: null,
  pathName: null,
  showPathName: false,
  t: () => {},
};

export default withTranslation()(connect(null, null)(LocationListEntryScreenElement));
