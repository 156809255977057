/* eslint-disable no-plusplus */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-unreachable */
/* eslint-disable react/sort-comp */
/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-empty,no-unused-vars */
/* eslint-disable prettier/prettier */
// @ts-nocheck
/* eslint-disable global-require,react/destructuring-assignment,react/prop-types,react/no-unused-state,no-restricted-syntax */
import React, { PureComponent } from 'react';

import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import config from 'modules/lead/configs';

import withNavigate from 'app/routers/withNavigate';

import HeaderNavigation from 'modules/common/components/HeaderNavigation';

import LeadJourneys from 'modules/lead-journeys/layouts';

import { isNumeric } from 'core/utils';
import { ALLOW_EVENTS_CLASS } from 'app/constants/app';

import actions from 'modules/lead/actions';
import * as selectors from 'modules/lead/selectors';

import ProgressScreen from 'modules/lead/components/ProgressScreen';

import ModalDialog from 'modules/lead/components/modals';

import { withTranslation } from 'react-i18next';

class Main extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      location: null,
      locationIndex: null,
      locationIndexPrev: null,
      locationIndexNext: null,
      locationId: null,
      locationRequestDestroy: false,
      backgroundImage: null,
      pathName: null,
      showNavigationArrows: true,
      nextTitle: null,
      nextLabel: null,
      openedModalDialog: false,
      errorModalDialog: false,
    };

    this.cache = {
      action: {
        name: null,
        waitFor: null,
      },
    };

    this.mapLocations = null;

    this.locationIndexIntroduction = null;

    this.createMapLocations();
  }

  componentDidMount() {
    const { id, ready, init } = this.props;

    if (!ready) {
      this.onEvent({
        action: 'loading',
      });

      this.onEvent({
        action: 'paths:show',
        data: {
          show: false,
        },
      });

      init({ id });
    }

    // const { locationId } = this.props;
    // this.init(locationId);
  }

  componentDidUpdate(prevProps, prevState) {
    const { ready, error, subjourneyId, sublocationId, journey, modules } = this.props;

    if (prevProps.ready !== ready) {
      if (ready) {
        if (error || !this.isValidLocations()) {
          this.showError();
          return;
        }

        this.updateLocations(journey.locations);
        this.findLocatinoIntroduction();
        this.init(subjourneyId, sublocationId);
      }
    }

    if (ready) {
      if (prevProps.subjourneyId !== subjourneyId || prevProps.sublocationId !== sublocationId) {
      }
    }

    this.saveActiveLocation(subjourneyId, sublocationId);

    if (prevProps.modules !== modules) {
      this.createMapLocations();
    }
  }

  componentWillUnmount() {
    this.updateLocations([]);
    const { destroy } = this.props;
    destroy();
  }

  createMapLocations() {
    this.mapLocations = {};
    this.mapInfoLocations = {};

    const { modules } = this.props;

    let i = -1;
    const n = modules.length;

    while (++i < n) {
      const { slug, info, locations } = modules[i];

      if (info.apiSlug) {
        this.mapLocations[info.apiSlug] = {
          journeyId: slug,
          locationId: info.slug,
          layout: config.layouts[info.leadLayoutKey]?.layout,
          config: {
            current: {
              ...info,
              journey: {
                ...modules[i],
              },
            },
          },
        };

        this.mapInfoLocations[info.apiSlug] = this.mapLocations[info.apiSlug];
      }

      let j = -1;
      const m = locations.length;

      while (++j < m) {
        const l = locations[j];

        if (l.apiSlug) {
          this.mapLocations[l.apiSlug] = {
            moduleIndex: i,
            moduleLocationIndex: j,
            journeyId: slug,
            locationId: l.slug,
            layout: config.layouts[l.leadLayoutKey]?.layout,
            config: {
              current: {
                ...l,
                journey: {
                  ...modules[i],
                },
              },
            },
          };
        }
      }
    }
  }

  onEvent = ({ action, data }) => {
    switch (action) {
      case 'header:navigation:previous': {
        const { disablePrev } = this.state;

        if (disablePrev) {
          return false;
        }

        this.cache.action.name = 'location:prev';
        this.cache.action.waitFor = 'location:destroy';

        this.disableNavigation();

        this.props.onEvent({ action: 'location:prev:start' });

        this.setState({
          locationRequestDestroy: true,
        });
        break;
      }
      case 'header:navigation:next': {
        const { disableNext } = this.state;

        if (disableNext) {
          return false;
        }

        const { locationIndexNext } = this.state;

        if (!isNumeric(locationIndexNext)) {
          this.props.onEvent({ action: 'explore-more' });
          return;
        }

        if (!this.canContinueAnimationForNextLocation()) {
          this.goToNextLocation();
          return;
        }

        this.cache.action.name = 'location:next';
        this.cache.action.waitFor = 'location:destroy';

        this.disableNavigation();

        this.props.onEvent({ action: 'location:next:start' });

        this.setState({
          locationRequestDestroy: true,
        });
        break;
      }
      case 'location:ready': {
        this.enableNavigation();

        this.props.onEvent({
          action: 'location:ready',
          data: {
            lastLocation: this.state.location && this.state.locationIndexNext === null,
          },
        });

        this.checkIfLastLocationAndShowModalDialogWithFinish();
        break;
      }
      case 'location:destroy:pre': {
        if (this.cache.action.waitFor === 'location:destroy') {
          switch (this.cache.action.name) {
            case 'location:next': {
              const { locationIndexNext } = this.state;

              if (isNumeric(locationIndexNext)) {
                const location = this.getLocation(locationIndexNext);
                this.setBackgroundImage(location);
              }
              break;
            }
            case 'location:prev': {
              this.cache.action.name = null;
              this.cache.action.waitFor = null;

              this.props.onEvent({ action: 'location:prev:end' });

              setTimeout(() => {
                this.goToPrevLocation();
              }, 1500);
              break;
            }
            default: {
            }
          }
        } else {
          const { locationIndexNext } = this.state;

          if (data?.action === 'resumeFromCard' && locationIndexNext !== null) {
            this.cache.action.name = 'location:next';

            const location = this.getLocation(locationIndexNext);
            this.setBackgroundImage(location);
          }
        }
        break;
      }
      case 'location:destroy': {
        if (this.cache.action.waitFor === 'location:destroy') {
          switch (this.cache.action.name) {
            case 'location:next': {
              this.cache.action.name = null;
              this.cache.action.waitFor = null;
              this.goToNextLocation();
              break;
            }
            default: {
            }
          }
        } else {
          switch (this.cache.action.name) {
            case 'location:next': {
              this.cache.action.name = null;
              this.cache.action.waitFor = null;
              this.goToNextLocation();
              break;
            }
            default: {
            }
          }
        }
        break;
      }
      case 'setCanNavigate': {
        this.props.onEvent({
          action: 'setCanNavigate',
          data: {
            value: data.value,
          },
        });
        this.checkLocation();
        break;
      }
      case 'toggleIsStageAnimating': {
        this.props.onEvent({ action: 'toggleIsStageAnimating', data });
        break;
      }
      case 'mine-map:visited': {
        this.props.onEvent({ action: 'mine-map-2d:visited' });
        break;
      }
      case 'journey:change': {
        this.props.onEvent({
          action: 'journey:change',
          data: {
            id: data.id,
          },
        });
        break;
      }
      case 'paths:show': {
        this.props.onEvent({
          action: 'paths:show',
          data: {
            show: data.show,
          },
        });
        break;
      }
      case 'paths:open': {
        this.props.onEvent({ action: 'paths:open' });
        break;
      }
      case 'paths:close': {
        this.props.onEvent({ action: 'paths:close' });
        break;
      }
      case 'explore-more': {
        this.props.onEvent({ action: 'explore-more' });
        break;
      }
      case 'home:goto': {
        this.props.onEvent({ action: 'explore-more', data });
        break;
      }
      default: {
        // TODO
      }
    }
  };

  goToNextLocation = () => {
    const { journeyId, locationId, id, navigate } = this.props;

    const { locationIndexNext } = this.state;

    if (isNumeric(locationIndexNext)) {
      const location = this.getLocation(locationIndexNext);

      const { journeyId: subjourneyId, locationId: sublocationId } =
        this.mapLocations[location?.hashId];

      if (subjourneyId && sublocationId) {
        this.props.onEvent({
          action: 'goToPathWithoutTransition',
          data: {
            path: `/${journeyId}/${locationId}/${id}/${subjourneyId}/${sublocationId}`,
          },
        });
        // navigate(`/${journeyId}/${locationId}/${id}/${subjourneyId}/${sublocationId}`); // TODO:refactor and remove navigate
        this.init(subjourneyId, sublocationId, locationIndexNext);
      }
    } else {
      // onEvent({ action: 'location:next:null' });
    }
  };

  goToPrevLocation = () => {
    /* const {
      journey,
      navigate,
      // setCanNavigate,
      // setLocationName,
      onEvent,
    } = this.props;

    const { locationIndexPrev } = this.state;

    // setCanNavigate(false);
    // setLocationName(null);

    if (isNumeric(locationIndexPrev)) {
      navigate(`/${journey.slug}/${journey.locations[locationIndexPrev].slug}`);
      // this.updateLocationByPrev();
    } else {
      onEvent('location:prev:null');
    } */

    const { journeyId, locationId, id, navigate } = this.props;

    const { locationIndexPrev } = this.state;

    if (isNumeric(locationIndexPrev)) {
      const location = this.getLocation(locationIndexPrev);

      const { journeyId: subjourneyId, locationId: sublocationId } =
        this.mapLocations[location?.hashId];

      if (subjourneyId && sublocationId) {
        navigate(`/${journeyId}/${locationId}/${id}/${subjourneyId}/${sublocationId}`); // TODO:refactor and remove navigate
        this.init(subjourneyId, sublocationId);
      }
    } else {
      // onEvent({ action: 'location:next:null' });
    }
  };

  goToStart() {
    const { journeyId, locationId, id, navigate } = this.props;
    this.props.onEvent({
      action: 'goToPathWithoutTransition',
      data: {
        path: `/${journeyId}/${locationId}/${id}`,
      },
    });
    // navigate(`/${journeyId}/${locationId}/${id}`); // TODO:refactor and remove navigate
    this.init();
  }

  init(subjourneyId = null, sublocationId = null, sublocationIndex = null) {
    const { journeyId, locationId, journey, t } = this.props;

    let location = null;
    let locationIndex = null;
    let locationIndexPrev = null;
    let locationIndexNext = null;

    let nextLabel = null;
    let nextTitle = null;

    const locationsWithoutInfo =
      journey?.locations.filter((el) => !this.mapInfoLocations[el]) || [];
    const n = journey?.locations.length || 0;
    const nWithoutInfo = locationsWithoutInfo.length || 0;

    if (locationId === 'journeys' && (!subjourneyId || !sublocationId)) {
      location = {
        ...config.current.info,
        hashId: `${journeyId}/${locationId}`,
      };

      if (journey) {
        location.title = journey.shareableName;
        location.subtitle = journey.description;
      }

      locationIndexPrev = null;
      locationIndexNext = 0;

      this.cache.action.name = 'location:next';
      this.cache.action.waitFor = 'location:destroy';
    } else if (n) {
      const { locations } = journey;

      for (let i = 0; i < n; i += 1) {
        const lc = this.mapLocations[locations[i]];

        if (
          lc.journeyId === subjourneyId &&
          lc.locationId === sublocationId &&
          (sublocationIndex === null || (isNumeric(sublocationIndex) && sublocationIndex === i))
        ) {
          locationIndex = i;
          location = this.getLocation(locationIndex);

          locationIndexPrev = locationIndex === 0 ? null : locationIndex - 1;
          locationIndexNext = locationIndex === n - 1 ? null : locationIndex + 1;
          break;
        }
      }

      if (!location) {
        this.showError();
        return;
      }
    }

    if (location) {
      config.current.hasMainNav =
        typeof location?.hasMainNav === 'undefined' ? true : location?.hasMainNav;

      const indexOfLocation = locationsWithoutInfo.indexOf(location.apiSlug);

      location.id = `${indexOfLocation + 1} of ${nWithoutInfo}`;
      location.currentNumber = indexOfLocation + 1;
      location.totalNumber = nWithoutInfo;

      // if (this.locationIndexIntroduction === null) {
      //   location.id = `${locationIndex + 1} of ${n}`;
      // } else {
      //   const index = locationIndex < this.locationIndexIntroduction ? locationIndex: locationIndex - 1;
      //   location.id = `${index + 1} of ${n - 1}`;
      // }

      config.current.activeLocation = location;
    } else {
      config.current.hasMainNav = !config.current.hideHeader;
    }

    if (locationIndexNext === null) {
      nextLabel = null;
      nextTitle = 'Explore More';
    } else {
      nextLabel = 'Location';
      nextTitle = this.mapLocations[journey?.locations[locationIndexNext]]?.config?.current?.title;
    }

    if (location?.slug === 'introduction' && isNumeric(locationIndexNext)) {
      const { locations } = journey;

      this.setBackgroundImage(this.mapLocations[locations[locationIndexNext]].config.current);
    } else {
      this.setBackgroundImage(location);
    }

    this.setState({
      location,
      locationIndex,
      locationIndexPrev,
      locationIndexNext,
      pathName: journey?.shareableName,
      disablePrev: true,
      disableNext: true,
      locationRequestDestroy: false,
      showNavigationArrows: locationsWithoutInfo.includes(location.apiSlug),
      nextLabel,
      nextTitle,
    });

    return {
      location,
      locationIndex,
      locationIndexPrev,
      locationIndexNext,
    };
  }

  // todo: fix this in future!!!!!
  saveActiveLocation(subjourneyId = null, sublocationId = null, sublocationIndex = null) {
    const { journeyId, locationId, journey} = this.props;

    let location = null;
    let locationIndex = null;
    const n = journey?.locations.length || 0;




    if (locationId === 'journeys' && (!subjourneyId || !sublocationId)) {
      location = {
        ...config.current.info,
        hashId: `${journeyId}/${locationId}`,
      };

      if (journey) {
        location.title = journey.shareableName;
        location.subtitle = journey.description;
      }

    } else if (n) {
      const { locations } = journey;

      for (let i = 0; i < n; i += 1) {
        const lc = this.mapLocations[locations[i]];

        if (
          lc.journeyId === subjourneyId &&
          lc.locationId === sublocationId &&
          (sublocationIndex === null || (isNumeric(sublocationIndex) && sublocationIndex === i))
        ) {
          locationIndex = i;
          location = this.getLocation(locationIndex);
          break;
        }
      }
    }

    if (location) {
      config.current.hasMainNav =
        typeof location?.hasMainNav === 'undefined' ? true : location?.hasMainNav;

      config.current.activeLocation = location;
    } else {
      config.current.hasMainNav = !config.current.hideHeader;
    }
  }

  getLocation(index = null) {
    if (index === null) return null;

    const { journey } = this.props;

    if (journey?.locations.length > 0) {
      const hashId = journey.locations[index];

      const location = this.mapLocations[hashId];

      if (location) {
        return {
          ...location.config.current,
          hashId,
        };
      }
    }

    return null;
  }

  getHeader() {
    const {
      pathName,
      location,
      nextTitle,
      nextLabel,
      disablePrev,
      disableNext,
      showNavigationArrows,
    } = this.state;

    if (location?.hideHeader) return;

    return (
      <HeaderNavigation
        openedDeviceRotate={false}
        pathName={pathName}
        nextTitle={nextTitle}
        nextLabel={nextLabel}
        disablePrev={disablePrev}
        disableNext={disableNext}
        showNavigationArrows={showNavigationArrows}
        onEvent={this.onEvent}
      />
    );
  }

  getLocationContent() {
    const { audioMuted, enabledTransition } = this.props;

    const { location, locationRequestDestroy, backgroundImage } = this.state;

    switch (location?.hashId) {
      case 'lead/journeys': {
        return (
          <LeadJourneys
            onEvent={this.onEvent}
            requestDestroy={locationRequestDestroy}
            location={location}
            audioMuted={audioMuted}
            enabledTransition={enabledTransition}
            backgroundImage={backgroundImage}
          />
        );
        break;
      }
      default: {
        if (typeof location?.hashId === 'string') {
          const locationConfig = this.mapLocations[location?.hashId];

          const Component = locationConfig.layout;

          return (
            <Component
              key={location?.apiSlug}
              drawPoints={location?.drawPoints}
              onEvent={this.onEvent}
              journey={config.current} // TEMP. ONLY FOR TEST
              requestDestroy={locationRequestDestroy}
              audioMuted={audioMuted}
              enabledTransition={enabledTransition}
              backgroundImage={backgroundImage}
            />
          );
        }

        return null;
      }
    }
  }

  checkLocation() {
    const { isStageAnimating } = this.props;

    const { locationIndexPrev } = this.state;

    const disablePrev = isStageAnimating || locationIndexPrev === null;
    const disableNext = isStageAnimating;

    this.setState({
      disablePrev,
      disableNext,
    });
  }

  enableNavigation() {
    const { locationIndexPrev } = this.state;

    this.setState({
      disablePrev: locationIndexPrev === null || false,
      disableNext: false,
    });
  }

  disableNavigation() {
    const { locationIndexPrev } = this.state;

    this.setState({
      disablePrev: locationIndexPrev === null || true,
      disableNext: true,
    });
  }

  setBackgroundImage(location) {
    this.setState({ backgroundImage: location?.video?.backgroundImage });
  }

  isReady() {
    const { ready } = this.props;
    return ready;
  }

  updateLocations(locations = []) {
    config.current.locations = locations;
  }

  findLocatinoIntroduction() {
    let i = -1;
    const n = config.current.locations.length;

    while (++i < n) {
      const location = this.getLocation(i);

      if (location.slug === 'introduction') {
        this.locationIndexIntroduction = i;
        break;
      }
    }
  }

  checkIfLastLocationAndShowModalDialogWithFinish() {
    const { location, locationIndexNext } = this.state;

    if (location && locationIndexNext === null) {
      this.setState({
        openedModalDialog: true,
        errorModalDialog: false,
      });
    }
  }

  onEventModalDialog = ({ action }) => {
    switch (action) {
      case 'closeError': {
        this.onEvent({
          action: 'home:goto',
        });

        this.setState({
          openedModalDialog: false,
          errorModalDialog: false,
        });
        break;
      }
      case 'close': {
        this.setState({
          openedModalDialog: false,
          errorModalDialog: false,
        });
        break;
      }
      case 'watchJourneyAgain': {
        this.goToStart();
        this.setState({
          openedModalDialog: false,
          errorModalDialog: false,
        });
        break;
      }
      case 'exploreMore': {
        this.onEvent({
          action: 'explore-more',
        });

        this.setState({
          openedModalDialog: false,
          errorModalDialog: false,
        });
        break;
      }
      default: {
        // TODO
      }
    }
  };

  showError() {
    this.onEvent({
      action: 'home:goto',
      data: {
        error: true,
        source: 'lead',
      },
    });
  }

  canContinueAnimationForNextLocation() {
    const { modules, journey } = this.props;

    const { locationIndex, locationIndexNext } = this.state;

    const currentLocation = this.mapLocations[journey?.locations[locationIndex]];
    const nextLocation = this.mapLocations[journey?.locations[locationIndexNext]];

    if (currentLocation?.journeyId === nextLocation?.journeyId) {
      const { locations } = modules[currentLocation.moduleIndex];

      const nLocation = locations[currentLocation.moduleLocationIndex + 1];

      return nLocation?.slug === nextLocation.locationId;
    }

    return false;
  }

  isValidLocations() {
    const { journey } = this.props;

    if (!Array.isArray(journey?.locations)) return false;

    const { locations } = journey;

    for (let i = 0, n = locations.length; i < n; i += 1) {
      if (!this.mapLocations[locations[i]]) return false;
    }

    return true;
  }

  render() {
    const { pathName, openedModalDialog, errorModalDialog } = this.state;

    const { error } = this.props;

    return (
      <>
        {this.isReady() ? (
          <div className={`${ALLOW_EVENTS_CLASS}`}>
            {!error && this.getHeader()}
            {!error && this.getLocationContent()}
          </div>
        ) : (
          <ProgressScreen />
        )}
        <ModalDialog
          opened={openedModalDialog}
          error={errorModalDialog}
          name={pathName}
          onEvent={this.onEventModalDialog}
        />
      </>
    );
  }
}

Main.propTypes = {
  config: PropTypes.shape({
    className: PropTypes.string,
    delayPrimaries: PropTypes.number,
    delaySecondaries: PropTypes.number,
    staggerBreakdowns: PropTypes.number,
    delayVideoBreakdown: PropTypes.number,
  }),
  journeyId: PropTypes.string,
  locationId: PropTypes.string,
  subjourneyId: PropTypes.string,
  sublocationId: PropTypes.string,
  enabledTransition: PropTypes.bool,
  audioMuted: PropTypes.bool,
  visited: PropTypes.number,
  authed: PropTypes.bool,
  modules: PropTypes.array,
  onEvent: PropTypes.func,
};

Main.defaultProps = {
  config: {
    className: 'journey',
    delayPrimaries: 0.5,
    delaySecondaries: 0.25,
    staggerBreakdowns: 0.125,
    delayVideoBreakdown: 1.5,
  },
  journeyId: null,
  locationId: null,
  subjourneyId: null,
  sublocationId: null,
  enabledTransition: false,
  audioMuted: true,
  visited: 0,
  authed: false,
  modules: [],
  onEvent: () => {},
};

const mapStateToProps = (state) => {
  return {
    config: {
      className: 'journey',
      delayPrimaries: 0.5,
      delaySecondaries: 0.25,
      staggerBreakdowns: 0.125,
      delayVideoBreakdown: 1.5,
    },
    ready: selectors.isReady(state),
    error: selectors.isError(state),
    journey: selectors.getJourney(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  init: (payload) => dispatch(actions.init({ payload })),
  destroy: (payload) => dispatch(actions.destroy({ payload })),
});

export default withTranslation()(
  withNavigate(connect(mapStateToProps, mapDispatchToProps)(Main))
);