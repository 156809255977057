/* eslint-disable react/forbid-prop-types */
/* eslint-disable dot-notation */
/* eslint-disable react/destructuring-assignment,no-useless-escape,jsx-a11y/label-has-associated-control */
import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import './assets/scss/index.scss';
import FormControl from 'core/form/FormControl';
import FormElement from 'core/form/FormElement';
import { withTranslation } from 'react-i18next';
import { Checkbox } from '@mui/material';
import CheckboxCheckedIcon from './assets/icons/checkbox.svg';
import CheckboxDefaultIcon from './assets/icons/checkbox-false.svg';
import workflows from '../../configs/workflows';

class WorkflowsCheckBoxes extends PureComponent {
  constructor(props) {
    super(props);

    const formElements = workflows.reduce(
      (accum, el) => ({ ...accum, [el.key]: new FormElement(false, []) }),
      {},
    );

    this.form = new FormControl(formElements);

    if (props.value) {
      this.form.setValues(this.prepareForForm(props.value));
    }

    this.state = {
      formValue: this.form.getValues(),
    };
  }

  componentDidMount() {
    this.form.onChange(() => {
      this.setState({
        formValue: this.form.getValues(),
      });

      this.props.onChange({ target: { value: this.formatValues() } });
    });
  }

  formatValues() {
    const data = this.form.getValues();
    return Object.keys(data).filter((e) => !!data[e]);
  }

  // eslint-disable-next-line class-methods-use-this
  prepareForForm(data) {
    return (data || []).reduce((accum, el) => ({ ...accum, [el]: true }), {});
  }

  render() {
    const { formValue } = this.state;
    const { t } = this.props;

    return (
      <div className="workflows-check-boxes">
        {workflows.map((el) => (
          <div className="workflows-check-boxes__element" key={el.key}>
            <Checkbox
              id={el.key}
              icon={<CheckboxDefaultIcon />}
              checkedIcon={<CheckboxCheckedIcon />}
              name="showPathName"
              onChange={(e) => this.form.getFormElement(el.key).onChangeEvent(e)}
              onBlur={(e) => this.form.getFormElement(el.key).onBlurEvent(e)}
              checked={formValue[el.key] || false}
            />
            <label className="workflows-check-boxes__element-label" htmlFor={el.key}>
              {t(el.localization)}
            </label>
          </div>
        ))}
      </div>
    );
  }
}

WorkflowsCheckBoxes.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  t: PropTypes.func,
};

WorkflowsCheckBoxes.defaultProps = {
  value: [],
  onChange: () => {},
  t: () => {},
};

export default withTranslation()(
  connect(null, null, null, { forwardRef: true })(WorkflowsCheckBoxes),
);
