import StageCard from 'modules/common/components/StageCard';
import description from './description';

const config = {
  ...description,
  drawPoints: {
    hotspots: 0.5,
    dancingAnts: 0.5,
    secondaries: 1,
  },
  hotspots: [],
  dancingAnts: [],
  tinyBois: [],
  elements: [{ id: 'leadIntroduction_1', component: StageCard }],
};

export default config;
