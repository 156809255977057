/* eslint-disable react/button-has-type */
/* eslint-disable dot-notation */
/* eslint-disable react/destructuring-assignment,no-useless-escape,jsx-a11y/label-has-associated-control */
import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ArrowLeftIcon from 'app/assets/icons/arrow-left.svg';
import JourneyForm from 'modules/journeys-manager-journey/components/JourneyForm';

import './assets/scss/index.scss';
import { withTranslation } from 'react-i18next';

class CreateJourney extends PureComponent {
  constructor(props) {
    super(props);
    this.journeyForm = null;
  }

  componentDidMount() {
    const { onEvent } = this.props;

    onEvent({ action: 'createDidMount' });
  }

  componentWillUnmount() {
    const { onEvent } = this.props;

    onEvent({ action: 'createWillUnmount' });
  }

  setChildForm(form) {
    this.journeyForm = form;
  }

  onEvent = ({ action, data }) => {
    const { onEvent } = this.props;

    onEvent({ action, data });
  };

  cancel() {
    this.navigationBack();
  }

  /**
   * @description for triggering form submit functionality
   */
  // eslint-disable-next-line class-methods-use-this
  createJourney() {
    this.journeyForm.submitForm();
  }

  // eslint-disable-next-line class-methods-use-this
  sendRequest(data) {
    const { onEvent } = this.props;

    onEvent({ action: 'createJourney', data });
  }

  navigationBack() {
    const { onEvent } = this.props;

    onEvent({ action: 'navigationBack', data: { path: '/manager' } });
  }

  render() {
    const { allLomixConfigs, journey, t } = this.props;

    return (
      <>
        <div className="journey-manager-edit-page">
          <div className="journey-manager-edit-header">
            <div>
              <button
                className="journey-manager-edit-header__navigation"
                onClick={() => this.navigationBack()}
              >
                <ArrowLeftIcon />
                <span>{t('common.back')}</span>
              </button>
              <div className="journey-manager-edit-header__title">
                {t('journeys-manager.NEW JOURNEY')}
              </div>
            </div>
            <div className="journey-manager-edit-header__actions">
              <button
                type="button"
                className="c-button c-button--size-sm c-button--link journey-manager-edit-header__action-cancel"
                onClick={() => this.cancel()}
              >
                <div className="c-button__text">{t('common.Cancel')}</div>
              </button>
              <button
                className="c-button c-button--size-default  journey-manager-edit-header__action-create"
                onClick={() => this.createJourney()}
              >
                <div className="c-button__text">{t('journeys-manager.CREATE JOURNEY')}</div>
              </button>
            </div>
          </div>

          <div className="journey-manager-edit-content journey-manager-edit-content--row">
            <JourneyForm
              onSubmit={(data) => this.sendRequest(data)}
              onFormCreated={(FormControl) => this.setChildForm(FormControl)}
              allLomixConfigs={allLomixConfigs}
              journey={journey}
            />
          </div>
        </div>
      </>
    );
  }
}

CreateJourney.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  allLomixConfigs: PropTypes.arrayOf(PropTypes.any),
  journey: PropTypes.shape({
    privateName: PropTypes.string,
    shareableName: PropTypes.string,
    description: PropTypes.string,
    locations: PropTypes.arrayOf(PropTypes.string),
  }),
  onEvent: PropTypes.func,
  t: PropTypes.func,
};

CreateJourney.defaultProps = {
  allLomixConfigs: [],
  journey: null,
  onEvent: () => {},
  t: () => {},
};

export default withTranslation()(connect(null, null)(CreateJourney));
