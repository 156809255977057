import React from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from './Checkbox';

// eslint-disable-next-line react/prop-types
const Gdpr = ({ value, handleChange }) => {
  const onChange = (fieldName) => (isChecked) => {
    handleChange(fieldName, isChecked);
  };

  const { t } = useTranslation();

  return (
    <div className="c-gdpr">
      <Checkbox name="gdpr" isChecked={value} handleChange={onChange('gdpr')} />
      <div className="c-gdpr--description">
        <p>{t('auth.I consent to receive electronic marketing communications')}</p>
        <p>
          {t('auth.Our')}{' '}
          <a href="https://hexagon.com/legal/privacy-notice" target="_blank" rel="noreferrer">
            {t('auth.privacy_policy.title')}{' '}
          </a>
          {t('auth.privacy_policy.description')}{' '}
          <a href="https://hexagon.com/legal/terms-of-use" target="_blank" rel="noreferrer">
            {t('auth.Terms of Use')}
          </a>
        </p>
      </div>
    </div>
  );
};

export default Gdpr;
