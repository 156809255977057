/* eslint-disable class-methods-use-this */
import configAPP from 'app/configs';
import HttpService from 'core/services/http/Http';
import localJourneysManager from 'core/services/localJourneysManager';

class Api {
  getJourney = async ({ id = null }) => {
    if (!id) return {};

    if (configAPP.enableJourneysForExhibition) {
      return localJourneysManager.getJourney(id);
    }

    const response = await HttpService.get(`api/v1/lead/journeys/${id}`).catch(() => {
      return { status: 'error' };
    });

    return response;
  };
}

export default Object.freeze(new Api());
