import description from './description';
import UndergroundDevelopmentDrilling1 from './elements/undergroundDevelopmentDrilling_1';
import UndergroundDevelopmentDrilling2 from './elements/undergroundDevelopmentDrilling_2';
import UndergroundDevelopmentDrilling3 from './elements/undergroundDevelopmentDrilling_3';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [16.1, 67.18], width: 6.375 }],
  dancingAnts: [['hs1', [0, 48.3], { direction: 'reverse' }]],
  tinyBois: [
    { id: 'tb1', position: [16.1, 67.18], offset: [5.6, 4.9] },
    { id: 'tb2', position: [16.1, 67.18], offset: [5.6, 1.2] },
  ],

  elements: [
    new UndergroundDevelopmentDrilling1([19.8, 36.8]),
    new UndergroundDevelopmentDrilling2([27.6, 62.3], {
      target: 'tb2',
      cornerFrom: ['top', 'left'],
    }),
    new UndergroundDevelopmentDrilling3([27.6, 75.2], { target: 'tb1' }),
  ],
};

export default config;
