import description from './description';
import SchedulingOptimising1 from './elements/SchedulingOptimising1';
import SchedulingOptimising2 from './elements/SchedulingOptimising2';
import SchedulingOptimising3 from './elements/SchedulingOptimising3';
import SchedulingOptimising4 from './elements/SchedulingOptimising4';
import SchedulingOptimising5 from './elements/SchedulingOptimising5';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [16, 47], width: 6.375 }],
  dancingAnts: [
    ['hs1', [0, 25.29], { direction: 'reverse' }],
    ['hs1', [0, 76.07]],
  ],
  tinyBois: [
    { id: 'tb1', position: [16, 47], offset: [5.7, 1.475] },
    { id: 'tb2', position: [16, 47], offset: [0.2, 4.45] },
    { id: 'tb3', position: [16, 47], offset: [5.7, 4.45] },
    { id: 'tb4', position: [16, 47], offset: [3, 6] },
  ],
  elements: [
    new SchedulingOptimising1([11.8, 16.8]),
    new SchedulingOptimising2([27.6, 36.9]), // .Activity Scheduler content
    new SchedulingOptimising3([11.8, 64.35]), // .Activity Scheduler video
    new SchedulingOptimising4([27.6, 47.5], { cornerFrom: ['top', 'left'] }), // Schedule OPTIMISER content
    new SchedulingOptimising5([27.8, 58.8]), // Schedule OPTIMISER video
  ],
};

export default config;
