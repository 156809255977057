import description from './description';
import MaterialMovementSlopeMonitoring1 from './elements/materialMovementSlopeMonitoring_1';
import MaterialMovementSlopeMonitoring2 from './elements/materialMovementSlopeMonitoring_2';
import MaterialMovementSlopeMonitoring3 from './elements/materialMovementSlopeMonitoring_3';
import MaterialMovementSlopeMonitoring4 from './elements/materialMovementSlopeMonitoring_4';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [17.8, 47.5], width: 6.815, rotation: [60, 0] }],
  dancingAnts: [
    ['hs1', [0, 20.11], { direction: 'reverse' }],
    ['hs1', [0, 87.5], { direction: 'reverse' }],
  ],
  tinyBois: [
    { id: 'tb1', position: [17.8, 47.5], offset: [0.9, 4.5] },
    { id: 'tb2', position: [17.8, 47.5], offset: [5.5, 4.5] },
    { id: 'tb3', position: [17.8, 47.5], offset: [5.5, 2.1] },
  ],
  elements: [
    new MaterialMovementSlopeMonitoring1([11.7, 16.5]),
    new MaterialMovementSlopeMonitoring2([11.7, 62.3]), // Critical Slope Monitoring Content
    new MaterialMovementSlopeMonitoring3([43.3, 58.3]), // Quick Movement Monitoring Content
    new MaterialMovementSlopeMonitoring4([43.3, 44.62]), // .Detailed Monitoring Content
  ],
};

export default config;
