/* eslint-disable no-case-declarations */
import {
  JOURNEYS_MANAGER_INIT,
  JOURNEYS_MANAGER_DESTROY,
} from 'modules/journeys-manager/constants';

import { STATUS, SUCCESS } from 'core/constants/statuses';

const initialState = {
  ready: false,
};

const app = (state = initialState, action) => {
  switch (action.type) {
    case `${JOURNEYS_MANAGER_INIT}/${STATUS}/${SUCCESS}`:
      return {
        ...state,
        ready: true,
        status: SUCCESS,
      };
    case `${JOURNEYS_MANAGER_DESTROY}/${STATUS}/${SUCCESS}`:
      return {
        ...state,
        ready: false,
        status: SUCCESS,
      };
    default: {
      return state;
    }
  }
};

export default app;
