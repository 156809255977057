import Box from 'modules/common/components/Box';

export default class SurveyMonitoringRealityCapture2 {
  constructor(position, props = {}) {
    this.id = 'surveyMonitoringRealityCapture_2';
    this.component = Box;
    this.title = 'survey-monitoring.Reality Capture.elements.3D Reality Capture';
    this.content = '';
    this.img = '';
    this.video = 'md_Reality_Capture.mp4';
    this.props = {
      target: 'tb1',
      position,
      cornerFrom: props.cornerFrom || null,
      hashtags: ['digitalisation', 'productivity'],
    };
  }
}
