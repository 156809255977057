import React from 'react';

import './styles.scss';
import { useTranslation } from 'react-i18next';

const MobileBlockScreen = () => {
  const { t } = useTranslation();
  return (
    <div className="block-wrapper">
      <div className="block-screen">
        <div className="logo">
          {/* eslint-disable-next-line global-require */}
          <img src={require('assets/images/brand/logo-text.png').default} alt="Hexagon" />
        </div>
        <div className="horizontal-line" />
        <div className="content">
          <p>
            {t('Please view Hexagon’s')} <br />
            {t('Life-of-Mine Experience')}
            <br /> {t('on desktop or tablet')}.
          </p>
        </div>
      </div>
    </div>
  );
};

export default MobileBlockScreen;
