import description from './description';
import SurveyMonitoringPlanning1 from './elements/surveyMonitoringPlanning_1';
import SurveyMonitoringPlanning2 from './elements/surveyMonitoringPlanning_2';
import SurveyMonitoringPlanning3 from './elements/surveyMonitoringPlanning_3';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [8.3, 52.3], width: 12.75 }],
  dancingAnts: [
    ['hs1', [48.12, 0]],
    ['hs1', [100, 66.69], { direction: 'reverse' }],
  ],
  tinyBois: [
    { id: 'tb1', position: [8.3, 52.3], offset: [11.2, 2.1] },
    { id: 'tb2', position: [8.3, 52.3], offset: [11.2, 8.9] },
  ],
  elements: [
    new SurveyMonitoringPlanning1([4.1, 25.7]),
    new SurveyMonitoringPlanning2([19.8, 52.4]),
    new SurveyMonitoringPlanning3([27.6, 67.36]),
  ],
};

export default config;
