const description = {
  id: '1 of 5 vehicle-collision-avoidance',
  currentNumber: 1,
  totalNumber: 5,
  code: 1,
  title: 'safety.Vehicle Collision Avoidance.title',
  slug: 'vehicle-collision-avoidance',
  pdfPath: 'VehicleCollisionAvoidance',
  leadLayoutKey: 'vehicle-collision-avoidance',
  apiSlug: 'vehicle-collision-avoidance',
  productDetailsLink: 'https://hexagon.com/products/hexagon-op-collision-avoidance-system',
  description: 'safety.Vehicle Collision Avoidance.description',
  video: {
    name: 'wf_Vehicle_Collision_Avoidance.mp4',
    stop: 39,
    backgroundImage: 'Vehicle_Collision_Avoidance.jpg',
  },
  drawPoints: {
    hotspots: 37,
    dancingAnts: 39,
    secondaries: 39.5,
  },
};

export default description;
