/* eslint-disable no-restricted-syntax */
import HTTPError from 'core/services/http/HTTPError';
import { isObject } from 'core/utils';

class HttpService {
  constructor() {
    this.baseURL = process?.env?.REACT_APP_BASE_API_URL || '/';
    this.requestInterceptors = [];
    this.responseInterceptors = [];

    this.defaultOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      params: {},
      credentials: 'include',
      body: null,
    };
  }

  async get(url, options = {}, data = null) {
    const response = await this.doRequest(url, {
      ...options,
      method: 'GET',
      body: data,
    });

    return response;
  }

  async put(url, options = {}, data = {}) {
    const response = await this.doRequest(url, {
      ...options,
      method: 'PUT',
      body: JSON.stringify(data),
    });

    return response;
  }

  async post(url, options = {}, data = {}) {
    const response = await this.doRequest(url, {
      ...options,
      method: 'POST',
      body: JSON.stringify(data),
    });

    return response;
  }

  async delete(url, options = {}, data = null) {
    const response = await this.doRequest(url, {
      ...options,
      method: 'DELETE',
      body: data ? JSON.stringify(data) : null,
    });

    return response;
  }

  /**
   * @example addRequestInterceptor(async (url, options) => {...options, header: {...options.header, token: 'token'}});
   *
   * @param {(url: String, requestOptions, response: Response | null) => requestOptions} interceptorFunction (url: String, requestOptions, response: Response | null) => requestOptions
   */
  addRequestInterceptor(interceptorFunction) {
    this.requestInterceptors.push(interceptorFunction);
  }

  /**
   * @example addRequestInterceptor(async (url, options, response) => response);
   *
   * @param {(url: String, requestOptions, response: Response | null) => requestOptions} interceptorFunction (url: String, requestOptions, response: Response | null) => requestOptions
   */
  addResponseInterceptor(interceptorFunction) {
    this.responseInterceptors.push(interceptorFunction);
  }

  async doRequest(url, options) {
    let allOptions = this.prepareRequestOptions(this.defaultOptions, options);

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < this.requestInterceptors.length; i++) {
      // eslint-disable-next-line no-await-in-loop
      allOptions = await this.requestInterceptors[i](url, allOptions, null);
    }

    const { params, notUseHeaders, ...requestOptions } = allOptions;

    const formattedParams = this.prepareFormattedParams(params);

    // todo: solve this issue
    // @ts-ignore
    let requestParams = String(new URLSearchParams(formattedParams));
    requestParams = requestParams ? `?${requestParams}` : '';

    let requestUrl = null;

    if (url.startsWith('http')) {
      requestUrl = `${url}${requestParams}`;
    } else {
      requestUrl = `${this.baseURL}${url}${requestParams}`;
    }

    let response;

    try {
      response = await fetch(requestUrl, requestOptions);
    } catch (error) {
      console.log(error);

      response = {
        status: null,
        statusText: 'Failed request',
      };
    }

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < this.responseInterceptors.length; i++) {
      // eslint-disable-next-line no-await-in-loop
      response = await this.responseInterceptors[i](url, allOptions, response);
    }

    if (response.ok) {
      try {
        const result = await response.json();
        return result;
      } catch (e) {
        return null;
      }
    }

    throw new HTTPError(response.status, response.statusText);
  }

  // eslint-disable-next-line class-methods-use-this
  prepareRequestOptions(defaultOptions, options) {
    return {
      ...defaultOptions,
      ...options,
      headers: {
        ...(options.notUseHeaders ? {} : defaultOptions.headers),
        ...(options.notUseHeaders ? {} : options.headers || {}),
      },
      params: {
        ...defaultOptions.params,
        ...(options.params || {}),
      },
    };
  }

  // eslint-disable-next-line class-methods-use-this
  prepareFormattedParams(params) {
    const formattedParams = {};

    for (const [param, value] of Object.entries(params)) {
      if (isObject(value) || Array.isArray(value)) {
        if (Object.keys(value).length > 0 || value.length > 0) {
          formattedParams[param] = JSON.stringify(value);
        }
      } else {
        formattedParams[param] = value;
      }
    }
  }
}

export default Object.freeze(new HttpService());
